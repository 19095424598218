import { FormikErrors } from 'formik';

import { BlockEntryFormState, BlockSide } from '../services/block/blockTradeEntryService';

export const validateBlockSide = (
  values?: BlockSide,
  isFuturesProduct?: boolean,
): FormikErrors<BlockSide> | undefined => {
  const errors: FormikErrors<BlockSide> = {};
  let errorsChanged = false;

  if (!values?.accountLabel) {
    errors.accountLabel = 'Account is required';
    errorsChanged = true;
  }

  if (!values?.clOrdId) {
    errors.clOrdId = 'Client Order ID is required';
    errorsChanged = true;
  }

  if (isFuturesProduct && !values?.customerAccountRef) {
    errors.customerAccountRef = 'Cust Acct Ref is required';
    errorsChanged = true;
  }

  if (isFuturesProduct && !values?.origin) {
    errors.origin = 'Origin is required';
    errorsChanged = true;
  }

  if (isFuturesProduct && !values?.cti) {
    errors.cti = 'CTI is required';
    errorsChanged = true;
  }

  if (isFuturesProduct && !values?.clearingMemberId) {
    errors.clearingMemberId = 'Clearing Member Id is required';
    errorsChanged = true;
  }

  if (isFuturesProduct && !values?.exchangeMemberId) {
    errors.exchangeMemberId = 'Exchange Member Id is required';
    errorsChanged = true;
  }

  return errorsChanged ? errors : undefined;
};

export const checkBlockSideValue = (
  values: BlockEntryFormState,
  isFuturesProduct?: boolean,
) => {
  const buySideValidation = validateBlockSide(values.buySide, isFuturesProduct);
  const sellSideValidation = validateBlockSide(values.sellSide, isFuturesProduct);

  if (buySideValidation) {
    return true;
  }
  if (sellSideValidation) {
    return true;
  }
  return false;
};

export const validateBlockOrderEntryForm = (values: BlockEntryFormState):
FormikErrors<BlockEntryFormState> => {
  const errors: FormikErrors<BlockEntryFormState> = {};

  if (!values.price) {
    errors.price = 'Price is required';
  }

  if (!values.quantity) {
    errors.quantity = 'Quantity is required.';
  }

  if (!values.negotiatedTime) {
    errors.negotiatedTime = 'Negotiated time is required';
  }

  if (!values.senderSubId) {
    errors.senderSubId = 'Sender sub ID is required';
  }

  return errors;
};
