import { bind } from '@react-rxjs/core';
import React from 'react';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Direction } from '../../shared/Direction';
import { isWebsocketOpen } from '../../shared/helperFunctions/connectionStatusUtils';
import { withSubscribe } from '../../shared/helperFunctions/withSubscribe';
import { SecurityStatusEnum } from '../../shared/services/securityStatus';
import { securityStatusState$ } from '../../shared/services/securityStatusService';
import { TitleWrapper } from '../../shared/style/styled';
import { connectionStatusState$ } from '../../shared/websocket/connectionStatus';
import { MidPrice } from './MidPrice';
import { OrderBookRow } from './OrderBookRow';
import { orderBook$ } from './services/orderBookService';
import {
  buyUserOpenOrders$,
  sellUserOpenOrders$,
} from './services/userOrdersService';
import {
  BarHeader,
  Body,
  Book,
  MyQtyHeader,
  OfferBook,
  OrderBookPanel,
  PriceHeader,
  QuantityHeader,
  TableHeaders,
} from './styles';

const [useOrderBookState, orderBookState$] = bind(
  combineLatest([
    buyUserOpenOrders$,
    sellUserOpenOrders$,
    orderBook$,
    securityStatusState$,
    connectionStatusState$,
  ]).pipe(
    map(
      ([
        userBuyOrders,
        userSellOrders,
        book,
        securityStatusMap,
        connectionStatus,
      ]) => {
        const currentMarketSymbol = book?.market?.symbol;
        const currentMarketStatus = securityStatusMap.get(
          currentMarketSymbol || '',
        );
        const securityStatus = currentMarketStatus
          || (SecurityStatusEnum.OPEN as SecurityStatusEnum);
        const disabled = !isWebsocketOpen(connectionStatus)
          || securityStatus !== SecurityStatusEnum.OPEN;
        return {
          userBuyOrders,
          userSellOrders,
          book,
          securityStatus,
          connectionStatus,
          disabled,
        };
      },
    ),
  ),
);

const OrderBookComponent = () => {
  const {
    userBuyOrders,
    userSellOrders,
    book,
    disabled,
    securityStatus,
    connectionStatus,
  } = useOrderBookState();
  return (
    <OrderBookPanel>
      <TitleWrapper>Order Book</TitleWrapper>
      <TableHeaders>
        <MyQtyHeader>My Qty</MyQtyHeader>
        <PriceHeader>Price</PriceHeader>
        <QuantityHeader>Qty</QuantityHeader>
        <BarHeader />
      </TableHeaders>
      <Body>
        <OfferBook>
          {book
            && book.offers.map((orderBookEntry) => (
              <OrderBookRow
                key={orderBookEntry.price.toString()}
                disabled={disabled}
                market={book.market}
                direction={Direction.Sell}
                orderBookEntry={orderBookEntry}
                userQty={userSellOrders.get(orderBookEntry.price)}
                total={book.offerTotal}
              />
            ))}
        </OfferBook>
        <MidPrice
          securityStatus={securityStatus}
          connectionStatus={connectionStatus}
          disabled={disabled}
        />
        <Book>
          {book
            && book.bids.map((orderBookEntry) => (
              <OrderBookRow
                key={orderBookEntry.price.toString()}
                disabled={disabled}
                market={book.market}
                direction={Direction.Buy}
                orderBookEntry={orderBookEntry}
                userQty={userBuyOrders.get(orderBookEntry.price)}
                total={book.bidTotal}
              />
            ))}
        </Book>
      </Body>
    </OrderBookPanel>
  );
};

export const OrderBook = withSubscribe(OrderBookComponent, orderBookState$);
