import { Map } from 'immutable';
import { ExecutionReport } from '../openOrders/openOrdersReducer';

export type AlgoOrderMap = Map<string, ExecutionReport>;

export const initialAlgoOrdersMap: AlgoOrderMap = Map<string, ExecutionReport>();

type AlgoOrderEvent = {
  type: 'newAlgoOrder', payload: ExecutionReport,
} | {
  type: 'newMassOrderRequest', payload: string,
};

export const algoOrdersReducer = (
  existingOrders: AlgoOrderMap,
  event: AlgoOrderEvent,
): AlgoOrderMap => {
  if (event.type === 'newMassOrderRequest') {
    return existingOrders.withMutations((map) => map.clear());
  }
  const orderUpdate = event.payload;
  if (orderUpdate.leavesQty === 0) {
    return existingOrders.withMutations((map) => map.delete(orderUpdate.orderID));
  }
  return existingOrders.withMutations((map) => map.set(orderUpdate.orderID, orderUpdate));
};
