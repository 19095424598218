import React from 'react';
import styled from 'styled-components';
import logo from './CboeDigital2023-RGBDarkBG-161x110px.svg';

const Logo = styled.div<{ width: number; height: number }>`
  background-repeat: no-repeat;
  background-image: url(${logo});
  background-size: cover;
  background-position: center center;
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  display: inline-block;
`;

/** Original logo was wrapped within an <a> tag which limited the height it
 *  could take before pushing down the NavBar. Using an empty <p>
 *  as anchor content allows us to workaround this.
 */
const Link = styled.p`
  width: 100%;
  height: 100%;
`;

export const CboeDigitalLogo: React.FC<{
  width?: number;
  height?: number;
}> = ({ height = 48, width = 80 }) => (
  <Logo height={height} width={width}>
    <a href="https://www.CboeDigital.com/" target="_blank" rel="noopener noreferrer">
      <Link />
    </a>
  </Logo>
);
