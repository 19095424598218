import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import {
  useAccounts,
  UserAccountRaw,
} from '../../../../../../shared/services/accountsService';

import {
  SettlementConfirmationType,
  SettlementRow,
  SettlementStatus,
} from '../../../types';
import { submitSettlementConfirmationAction } from '../../SettlementConfirmationService';

import {
  Container,
  Tick,
  Cross,
  Action,
} from './ConfirmationActionButtons.styled';

interface ConfirmationActionButtonsProps extends ICellRendererParams {
  data: SettlementRow;
}

const isApproveEnabled = (
  settlement: SettlementRow,
  allAccounts?: UserAccountRaw[],
) => {
  // eslint-disable-next-line
  const { to_account_label, from_account_label, status } = settlement;
  const accountLabels = allAccounts?.map((a) => a.account_number) || [];

  switch (status) {
    case SettlementStatus.ALLEGED:
      return true;

    case SettlementStatus.CONFIRMED_FROM:
      return accountLabels?.includes(to_account_label) || false;

    case SettlementStatus.CONFIRMED_TO:
      return accountLabels?.includes(from_account_label) || false;

    default:
      return false;
  }
};

export const ConfirmationActionButtons: React.FC<ConfirmationActionButtonsProps> = (
  props,
) => {
  const { data: settlement } = props;
  const accountsResponse = useAccounts();
  const approveEnabled = isApproveEnabled(
    settlement,
    accountsResponse?.accounts,
  );

  return (
    <Container>
      <Action
        disabled={!approveEnabled}
        onClick={() => approveEnabled
          && submitSettlementConfirmationAction({
            settlement,
            type: SettlementConfirmationType.APPROVAL,
          })
        }
      >
        <Tick disabled={!approveEnabled} />
      </Action>

      <Action
        onClick={() => submitSettlementConfirmationAction({
          settlement,
          type: SettlementConfirmationType.REJECTION,
        })
        }
      >
        <Cross />
      </Action>
    </Container>
  );
};
