import React from 'react';
import { AlertIcon } from '../../icons/AlertIcon';
import { SuccessIcon } from '../../icons/SuccessIcon';
import { formatNumber } from '../../shared/formatters';
import { NewNotification, NotificationType } from './NewNotification';
import {
  TradeNotificationContent,
  TradeNotificationTitle,
  TradeNotificationValue,
  TradeNotificationWrapper,
} from './styled';

interface Props {
  newNotification: NewNotification;
}

const getTitle = (newNotification: NewNotification) => {
  if (newNotification.type === NotificationType.TRADE) {
    return 'NEW TRADE';
  }
  if (newNotification.type === NotificationType.ORDER_CANCEL) {
    return `ORDER: ${newNotification.notificationID}`;
  }
  if (newNotification.type === NotificationType.INSTRUCTION) {
    return `ALGO: ${newNotification.notificationID}`;
  }
  if (newNotification.type === NotificationType.OTC_TRADE) {
    return `TRADE: ${newNotification.notificationID}`;
  }
  if (newNotification.type === NotificationType.OTC_REJECTED) {
    return `TRADE: ${newNotification.notificationID}`;
  }
  if (newNotification.type === NotificationType.ORDER_CANCEL_REJECT) {
    return `ORDER: ${newNotification.notificationID}`;
  }
  return '';
};

const getText = (newNotification: NewNotification) => {
  if (newNotification.type === NotificationType.TRADE) {
    return `${newNotification.direction} ${formatNumber(newNotification.quantity)} ${newNotification.symbol} @ ${formatNumber(newNotification.price)}`;
  }
  if (newNotification.type === NotificationType.ORDER_CANCEL) {
    return <><AlertIcon width={15} height={15} /> ORDER CANCELED</>;
  }
  if (newNotification.type === NotificationType.INSTRUCTION && newNotification.status === 'CANCELED') {
    return <><AlertIcon width={15} height={15} /> ALGO CANCELED</>;
  }
  if (newNotification.type === NotificationType.INSTRUCTION && newNotification.status === 'COMPLETE') {
    return <><SuccessIcon width={15} height={15} /> ALGO COMPLETE</>;
  }
  if (newNotification.type === NotificationType.INSTRUCTION && newNotification.status === 'TRIGGERED') {
    return <><SuccessIcon width={15} height={15} /> ALGO TRIGGERED</>;
  }
  if (newNotification.type === NotificationType.OTC_TRADE) {
    return <><SuccessIcon width={15} height={15} /> TRADE COMPLETED</>;
  }
  if (newNotification.type === NotificationType.OTC_REJECTED) {
    return <><AlertIcon width={15} height={15} /> TRADE REJECTED</>;
  }
  if (newNotification.type === NotificationType.ORDER_CANCEL_REJECT) {
    return <><AlertIcon width={15} height={15} /> ORDER CANCEL REJECTED </>;
  }
  return '';
};

export const NotificationPopup: React.FC<Props> = (props) => <TradeNotificationWrapper>
  <TradeNotificationContent>
    <TradeNotificationTitle> {getTitle(props.newNotification)}</TradeNotificationTitle>
    <TradeNotificationValue>
      {getText(props.newNotification)}
    </TradeNotificationValue>
  </TradeNotificationContent>
</TradeNotificationWrapper >;
