import { ListSubheader } from '@material-ui/core';
import styled from 'styled-components';

export const MarketSelectorWrapper = styled.div`
  label {
    color: ${({ theme }) => theme.palette.secondary.secondary5};
    font-size: 13px;
    padding-top: 10px;
    padding-left: 10px;
  }

  #grouped-select {
    color: ${({ theme }) => theme.palette.secondary.secondary};
    font-size: 11px;
    padding-left: 20px;
  }

  #market-selector-form {
    background-color: ${({ theme }) => theme.palette.primary.primary1};
    min-width: 125px;
  }

  svg {
    width: 18px;
    height: 18px;
    color: ${({ theme }) => theme.palette.secondary.secondary};
    padding-top: 5px;
  }
`;

export const MarketSelectorHeader = styled(ListSubheader)`
  background-color: ${({ theme }) => theme.palette.primary.primary};
  color: ${({ theme }) => theme.palette.secondary.secondary};
  line-height: 32px;
  font-size: 12px;
  font-weight: 600;
  padding: 8px;
`;

export const MarketSelectorSubheader = styled(ListSubheader)`
  background-color: ${({ theme }) => theme.palette.primary.primary};
  color: ${({ theme }) => theme.palette.secondary.secondary};
  line-height: 32px;
  font-size: 12px;
  font-weight: 600;
  padding-left: 8px;
  top: 69px;
`;

export const MarketSelectorGroupedSubHeader = styled(MarketSelectorSubheader)`
  background-color: ${({ theme }) => theme.palette.primary.primary};
  color: ${({ theme }) => theme.palette.secondary.secondary};
  font-size: 10px;
  padding-left: 10px;
`;

export const MarketSelectorFilterButtonsWrapper = styled.div`
  padding-top: 8px;
`;
