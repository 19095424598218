import { datadogRum } from '@datadog/browser-rum';
import {
  AuthStatus,
  authStatusState$,
} from './services/authStatusService';
import { accountState$ } from './services/accountsService';
import { Config } from '../config/config';
import { VERSION } from './config/version';

export const initDatadog = (): void => {
  authStatusState$.subscribe((authStatus) => {
    if (authStatus === AuthStatus.AUTHENTICATED) {
      if (Config.DD_RUM_APPLICATION_ID && Config.DD_RUM_APPLICATION_ID !== ''
        && Config.DD_RUM_CLIENT_TOKEN && Config.DD_RUM_CLIENT_TOKEN !== '') {
        datadogRum.init({
          applicationId: Config.DD_RUM_APPLICATION_ID,
          clientToken: Config.DD_RUM_CLIENT_TOKEN,
          site: 'datadoghq.com',
          version: VERSION,
          env: Config.DD_ENV,
          service: Config.DD_SERVICE,
          sampleRate: 100,
          trackInteractions: Config.DD_RUM_TRACK_INTERACTIONS
            ? Config.DD_RUM_TRACK_INTERACTIONS === 'true'
            : true,
          allowedTracingOrigins: Config.DD_RUM_TRACING_ORIGINS
            ? Config.DD_RUM_TRACING_ORIGINS.trim()
              .split(',')
              .map((x) => x.trim())
              .filter((x) => x !== '')
            : [''],
          /* eslint-disable no-param-reassign */
          beforeSend: (event: any) => {
            if (event.resource && event.resource.url && event.resource.url !== '') {
              // remove the id_token query parameter
              event.resource.url = event.resource.url.replace(/id_token=[^&]*/, 'id_token=masked');
              // remove the access_token query parameter
              event.resource.url = event.resource.url.replace(/access_token=[^&]*/, 'access_token=masked');
              // remove the state query parameter
              event.resource.url = event.resource.url.replace(/state=[^&]*/, 'state=masked');
              // remove the auth0Client query parameter
              event.resource.url = event.resource.url.replace(/auth0Client=[^&]*/, 'auth0Client=masked');
              // remove the nonce query parameter
              event.resource.url = event.resource.url.replace(/nonce=[^&]*/, 'nonce=masked');
            }

            if (event.action && event.action.target && event.action.target.name && event.action.target.name !== '') {
              event.action.target.name = event.action.target.name.replace(/\S+@\S+/g, 'masked_email');
            }

            // Mask a JWT token
            if (event.error) {
              const jwtMask = new RegExp('([A-Za-z0-9-_=]{20,}\\.)([A-Za-z0-9-_=]{3,}\\.)([A-Za-z0-9-_.+/=]{43,})', 'g');
              if (event.error.message && event.error.message !== '') {
                event.error.message = event.error.message.replace(jwtMask, '$1$2[masked_sig]');
              }
              if (event.error.stack && event.error.stack !== '') {
                event.error.stack = event.error.stack.replace(jwtMask, '$1$2[masked_sig]');
              }
            }
          },
          /* eslint-enable no-param-reassign */
        });
      }
    }
  });

  accountState$.subscribe((accountStatus) => {
    if (accountStatus && accountStatus.accounts && accountStatus.accounts.length > 0) {
      const account = accountStatus.accounts[0];

      const { member_users } = account; // eslint-disable-line @typescript-eslint/naming-convention

      if (account.fix_ids && account.fix_ids.length > 0) {
        const partyId = account.fix_ids[0];
        const tcsUserId = member_users && member_users.length > 0 && member_users[0] !== '' ? member_users[0] : partyId;
        datadogRum.setUser({
          id: partyId,
          name: tcsUserId,
        });
      }
    }
  });
};
