import { bind } from '@react-rxjs/core';
import { createListener } from '@react-rxjs/utils';
import { Observable } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { switchMap, tap, share } from 'rxjs/operators';
import { Config } from '../../../../config/config';
import { getToken } from '../../../../shared/websocket/token';
import { fetchResponseJsonHandler } from '../helpers/fetchResponseJsonHandler';
import {
  filterResponseIsComplete, filterResponseIsFailure,
  filterResponseIsSuccessful, useIsPendingResponse,
} from '../helpers/responseUtils';

import {
  SettlementConfirmationType,
  SettlementConfirmationAction,
  SettlementResponse,
  ApiResponse,
  SettlementConfirmationRequest,
} from '../types';

const getConfirmationUrlFromSettlementType = (
  settlementType: SettlementConfirmationType,
) => `${Config.CLEARING_REST_API_URL}${
  settlementType === SettlementConfirmationType.APPROVAL
    ? 'v1/confirm_settlement'
    : 'v1/reject_settlement'
}`;

export const postConfirmSettlement$: (
  request: SettlementConfirmationRequest
) => Observable<ApiResponse<SettlementResponse>> = ({
  type,
  ...request
}) => fromFetch(getConfirmationUrlFromSettlementType(type), {
  method: 'POST',
  body: JSON.stringify(request),
  headers: {
    authorization: `Bearer ${getToken()}`,
    'Content-Type': 'application/json',
  },
}).pipe(fetchResponseJsonHandler<SettlementResponse>());

export const [
  settlementConfirmationAction$,
  submitSettlementConfirmationAction,
] = createListener<SettlementConfirmationAction | undefined>();
export const [useSettlementConfirmationAction] = bind(
  settlementConfirmationAction$,
  undefined,
);

export const [
  newSettlementConfirmationRequest$,
  createSettlementRequest,
] = createListener<SettlementConfirmationRequest>();

export const settlementConfirmationResponse$: Observable<
ApiResponse<SettlementResponse>
> = newSettlementConfirmationRequest$.pipe(
  switchMap(postConfirmSettlement$),
  share(),
);

export const useIsPending = useIsPendingResponse(settlementConfirmationResponse$);

export const completedSettlementConfirmationResponse$ = settlementConfirmationResponse$.pipe(
  filterResponseIsComplete(),
);

export const [useSettlementConfirmationResponseFailure] = bind(
  completedSettlementConfirmationResponse$.pipe(filterResponseIsFailure()),
  undefined,
);

export const successfulSettlementConfirmationResponse$ = (
  completedSettlementConfirmationResponse$.pipe(
    filterResponseIsSuccessful(),
    // side effect, hide modal by emitting undefined action
    tap(() => submitSettlementConfirmationAction(undefined)),

  ));
