import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ViewHeader } from '../../AccountManagement.styled';
import { TabPanel } from '../../../tabs/TabPanel';
import {
  Container,
  Header,
  Body,
  TabButton,
  ContentWrapper,
  GridWrapper,
  AddNewSettlementButton,
  RefreshLink,
} from './SettlementsList.styled';
import {
  HistoricalSettlementsGrid,
  PendingSettlementsGrid,
} from './components/SettlementsGrid/SettlementsGrid';
import { SettlementRow, SettlementStatus } from '../types';
import { DismissButton } from '../../../../shared/components/DismissButton/DismissButton';

interface SettlementsListProps {
  errorMessage?: string;
  isLoading: boolean;
  settlements: SettlementRow[] | undefined;
  onAddNewSettlement: () => void;
  onClose: () => void;
  onRefresh: () => void;
}

const isHistoricalStatus = (status:SettlementStatus) => [
  SettlementStatus.CLEARED,
  SettlementStatus.REJECTED,
  SettlementStatus.ADMIN_CXL,
].includes(status);

export const SettlementsList: React.FC<SettlementsListProps> = ({
  errorMessage,
  isLoading,
  settlements,
  onAddNewSettlement,
  onClose,
  onRefresh,
}) => (
  <Container>
    <Header>
      <ViewHeader>Settlement Service</ViewHeader>
      <DismissButton onClick={onClose} />
    </Header>
    <Body>
      {errorMessage ? (
        <h3>There was an error loading Settlement Instructions: {errorMessage}</h3>
      ) : (
        <TabPanel
          headerContent={
            <>
              <RefreshLink disabled={isLoading} onClick={onRefresh}>
                <RefreshIcon />
                Refresh
              </RefreshLink>
            </>
          }
          componentOverrides={{ TabButton, ContentWrapper }}
          tabs={[
            {
              title: 'Pending',
              tabId: 'pending',
              content: (
                <GridWrapper>
                  <PendingSettlementsGrid
                    data={settlements?.filter(({ status }) => !isHistoricalStatus(status))}
                  />
                </GridWrapper>
              ),
            },
            {
              title: 'History',
              tabId: 'history',
              content: (
                <GridWrapper>
                  <HistoricalSettlementsGrid
                    data={settlements?.filter(({ status }) => isHistoricalStatus(status))}
                  />
                </GridWrapper>
              ),
            },
          ]}
        />
      )}
    </Body>

    <AddNewSettlementButton onClick={onAddNewSettlement}>
      Add New Settlement Instruction
    </AddNewSettlementButton>
  </Container>
);
