import { RowClassParams } from '../../../../../../../../../types/agGrid.d';
import { SettlementRow } from '../../../../../../types';
import { isDetailsNode } from './isDetailsNode';

export const getRowClass = (params:RowClassParams<SettlementRow>) => {
  if (isDetailsNode(params.node)) {
    return 'ag-row-settlement-detail';
  }
  return '';
};
