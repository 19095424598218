import {
  filter, map, switchMap,
} from 'rxjs/operators';

import { AuthStatus, authStatusState$, setAuthStatus } from './authStatusService';
import { FetchResponse, getFetchClearing$ } from './fetchService';

export const authorizeUser$ = authStatusState$.pipe(
  filter((status) => status === AuthStatus.NOT_DETERMINED),
  switchMap(() => getFetchClearing$({
    restCallName: 'party_ids',
  })),
  map((fetchResponse: FetchResponse) => {
    if (fetchResponse.error || fetchResponse.response.error) {
      setAuthStatus(AuthStatus.UNAUTHENTICATED);
    } else {
      setAuthStatus(AuthStatus.AUTHENTICATED);
    }
    return [];
  }),
);
