/* eslint-disable no-underscore-dangle */

/*
 * Add all runtime config variables here, then to use them to do:
 * import { Config } from './config/config'
 * Config.TRADE_WEBSOCKET_URL
 *
 * On your dev machine it will load the values from .env if they aren't manually overwritten
 * with an environment variable
 *
 * Remember to add the default values to the .env.default file!
 */

export interface ConfigVariables {
  TRADE_WEBSOCKET_URL: string;
  ALGO_WEBSOCKET_URL: string;
  TRADE_WEBSOCKET_URL_UNAUTHENTICATED: string;
  TRADE_REST_API_URL: string;
  HISTORY_REST_API_URL: string;
  HISTORY_REST_API_URL_UNAUTHENTICATED: string;
  CLEARING_REST_API_URL: string;
  EMP_ENDPOINT: string;
  auth0_DOMAIN: string;
  CLIENT_ID: string;
  REDIRECT_URI: string;
  LOGOUT_URL: string;
  /* should be used for an environment banner, example:
   * if PRODUCTION don't set a banner, otherwise show a banner with the contents of this string
   */
  APP_ENV: string;
  BANNER_TEXT: string;
  OTC_EXT_IDENT_USER_ID: string;
  OTC_EXT_IDENT_COUNTER_PARTY_ID: string;
  OTC_PRODUCTS: string;
  OTC_ENABLED: string;

  DD_RUM_APPLICATION_ID?: string;
  DD_RUM_CLIENT_TOKEN: string;
  DD_SERVICE?: string;
  DD_ENV?: string;
  DD_RUM_TRACK_INTERACTIONS?: string;
  DD_RUM_TRACING_ORIGINS?: string;
  CLEARED_BLOCK_TRADES_RETRY_MS: number;
  CLEARED_BLOCK_TRADES_MAX_RETRY: number;
  DISABLED_FEATURES_MOBILE?: string;
  CAR_CGM_PREFERENCES_ENABLED?: string;
  AUTHENTICATED_GET_TRADES_PAGE_SIZE?: string;
  UNAUTHENTICATED_GET_TRADES_PAGE_SIZE?: string;
}

export class Config {
  static TRADE_WEBSOCKET_URL = ((window as any)._env_ as ConfigVariables)
    .TRADE_WEBSOCKET_URL;

  static ALGO_WEBSOCKET_URL = ((window as any)._env_ as ConfigVariables)
    .ALGO_WEBSOCKET_URL;

  static TRADE_WEBSOCKET_URL_UNAUTHENTICATED = ((window as any)
    ._env_ as ConfigVariables).TRADE_WEBSOCKET_URL_UNAUTHENTICATED;

  static TRADE_REST_API_URL = ((window as any)._env_ as ConfigVariables)
    .TRADE_REST_API_URL;

  static HISTORY_REST_API_URL = ((window as any)
    ._env_ as ConfigVariables).HISTORY_REST_API_URL;

  static HISTORY_REST_API_URL_UNAUTHENTICATED = ((window as any)
    ._env_ as ConfigVariables).HISTORY_REST_API_URL_UNAUTHENTICATED;

  static CLEARING_REST_API_URL = ((window as any)
    ._env_ as ConfigVariables).CLEARING_REST_API_URL;

  static EMP_ENDPOINT = ((window as any)._env_ as ConfigVariables)
    .EMP_ENDPOINT;

  static auth0_DOMAIN = ((window as any)._env_ as ConfigVariables)
    .auth0_DOMAIN;

  static CLIENT_ID = ((window as any)._env_ as ConfigVariables)
    .CLIENT_ID;

  static REDIRECT_URI = ((window as any)._env_ as ConfigVariables)
    .REDIRECT_URI;

  static LOGOUT_URL = ((window as any)._env_ as ConfigVariables)
    .LOGOUT_URL;

  static APP_ENV = ((window as any)._env_ as ConfigVariables).APP_ENV;

  static BANNER_TEXT = ((window as any)._env_ as ConfigVariables)
    .BANNER_TEXT;

  static OTC_EXT_IDENT_USER_ID = ((window as any)
    ._env_ as ConfigVariables).OTC_EXT_IDENT_USER_ID;

  static OTC_EXT_IDENT_COUNTER_PARTY_ID = ((window as any)
    ._env_ as ConfigVariables).OTC_EXT_IDENT_COUNTER_PARTY_ID;

  static OTC_PRODUCTS = ((window as any)._env_ as ConfigVariables)
    .OTC_PRODUCTS;

  static OTC_ENABLED = ((window as any)._env_ as ConfigVariables)
    .OTC_ENABLED;

  static DD_RUM_APPLICATION_ID = ((window as any)
    ._env_ as ConfigVariables).DD_RUM_APPLICATION_ID;

  static DD_RUM_CLIENT_TOKEN = ((window as any)._env_ as ConfigVariables)
    .DD_RUM_CLIENT_TOKEN;

  static DD_SERVICE = ((window as any)._env_ as ConfigVariables)
    .DD_SERVICE;

  static DD_ENV = ((window as any)._env_ as ConfigVariables).DD_ENV;

  static DD_RUM_TRACK_INTERACTIONS = ((window as any)
    ._env_ as ConfigVariables).DD_RUM_TRACK_INTERACTIONS;

  static DD_RUM_TRACING_ORIGINS = ((window as any)
    ._env_ as ConfigVariables).DD_RUM_TRACING_ORIGINS;

  static CLEARED_BLOCK_TRADES_RETRY_MS = ((window as any)
    ._env_ as ConfigVariables).CLEARED_BLOCK_TRADES_RETRY_MS;

  static CLEARED_BLOCK_TRADES_MAX_RETRY = ((window as any)
    ._env_ as ConfigVariables).CLEARED_BLOCK_TRADES_MAX_RETRY;

  static DISABLED_FEATURES_MOBILE = ((window as any)
    ._env_ as ConfigVariables).DISABLED_FEATURES_MOBILE;

  static CAR_CGM_PREFERENCES_ENABLED = ((window as any)
    ._env_ as ConfigVariables).CAR_CGM_PREFERENCES_ENABLED;

  static AUTHENTICATED_GET_TRADES_PAGE_SIZE = ((window as any)
    ._env_ as ConfigVariables).AUTHENTICATED_GET_TRADES_PAGE_SIZE;

  static UNAUTHENTICATED_GET_TRADES_PAGE_SIZE = ((window as any)
    ._env_ as ConfigVariables).UNAUTHENTICATED_GET_TRADES_PAGE_SIZE;
}
