import React from 'react';
import styled from 'styled-components/macro';
import { ToastContainer } from 'react-toastify';

const ToasterContainerWrapper = styled.div<{ mobile?: boolean }>`
.Toastify__toast-container{
    margin-right: ${({ mobile }) => !mobile && '-50px'};
    margin-top:  50px;
}
 .trade-toaster {
   background-color: ${({ theme }) => theme.palette.primary.primary5};
   font-size: 12px;
   font-family: Roboto Mono;
   max-width: 250px;
   margin-bottom: ${({ mobile }) => mobile && '10px'};
 }
 .trade-toaster-sell {
    border-left: 4px solid ${({ theme }) => theme.palette.trading.tradingSell};
  .Toastify__progress-bar {
    background-color: ${({ theme }) => theme.palette.trading.tradingSell};
  }
 }
 .trade-toaster-buy {
    border-left: 4px solid ${({ theme }) => theme.palette.trading.tradingBuy};
  .Toastify__progress-bar {
    background-color: ${({ theme }) => theme.palette.trading.tradingBuy};
  }
 }
 svg {
   fill: ${({ theme }) => theme.palette.secondary.secondary2};
   opacity: .7;
   :hover {
    opacity: 1;
   }
 }
`;

interface Props {
  mobile?: boolean;
}

export const MainToasterContainer: React.FC<Props> = (props) => (
  <ToasterContainerWrapper mobile={props.mobile}>
    <ToastContainer />
  </ToasterContainerWrapper>
);
