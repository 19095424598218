import numbro from 'numbro';
import { format, parse, subMinutes } from 'date-fns';
import BigNumber from 'bignumber.js';
import { UserPrefsState } from '../components/navbar/services/userPrefsService';

export const getDecimalScale = (val?: number) => (val ? new BigNumber(val).dp() : 0);

// eslint-disable-next-line max-len
export const formatPrice = (value: number, minPriceIncrement: number): string => numbro(value).format({
  thousandSeparated: true,
  mantissa: getDecimalScale(minPriceIncrement),
});

export const formatQuantity = (value: number, roundLot: number): string => numbro(value).format({
  thousandSeparated: true,
  mantissa: getDecimalScale(roundLot),
});

export const formatQuantityRoundDown = (
  value: number,
  roundLot: number,
): string => numbro(value).format({
  thousandSeparated: true,
  mantissa: getDecimalScale(roundLot),
  roundingFunction: Math.floor,
});

// eslint-disable-next-line max-len
export const formatThousandSeparator = (value: number): string => numbro(value).format({ thousandSeparated: true });

export const formatNumber = (
  value: number | undefined | null,
  decimalScale: number = 10,
): string => (!(typeof value === 'number')
  ? ''
  : numbro(value).format({
    thousandSeparated: true,
    optionalMantissa: true,
    mantissa: decimalScale,
    trimMantissa: true,
  }));

export const formatBalance = (value: number | undefined | null): string => (!(typeof value === 'number') ? '' : numbro(value).format('0,0.000000'));

export const formatPercentage = (value: number | undefined | null): string => (!(typeof value === 'number')
  ? ''
  : numbro(value).format({
    output: 'percent',
    mantissa: 2,
    spaceSeparated: true,
  }));

export const removeUnderscore = (value: string) => value && value.split('_').join(' ');

export const addSpaceToCapitol = (value: string) => value && value.replace(/([A-Z])/g, ' $1').trim();

export const formatDateFromMilliseconds = (originTime: number) => {
  const date = new Date(originTime);
  return `${format(date, 'LLLL').substr(0, 3).toUpperCase()} ${format(
    date,
    'd,  yyyy  H:mm',
  ).toLowerCase()}`;
};

export const getExecutionOrderDateMilliseconds = (originTime: string) => {
  const utcOffset = new Date().getTimezoneOffset();
  return subMinutes(
    parse(originTime, 'yyyyMMdd-HH:mm:ss.SSSSSSSSS', new Date()),
    utcOffset,
  ).getTime();
};

export const getTradeDate = (originTime: string) => new Date(originTime);

export const formatTradeTime = (originTime: string) => {
  const date = new Date(originTime);
  return `${format(date, 'LLLL').substr(0, 3).toUpperCase()} ${format(
    date,
    'd,  yyyy  H:mm',
  ).toLowerCase()}`;
};

// To find non-zero part for a number
export const findNonZero = (originNum: string) => {
  if (originNum === '0.0000') return '';
  if (!originNum.includes('.')) return originNum;
  for (let i = originNum.length - 1; i >= 0; i -= 1) {
    if (originNum.charAt(i) !== '0') {
      return originNum.substring(0, i + 1);
    }
  }
  return '';
};

// To find ending zeros for a number
export const findZero = (originNum: string) => {
  if (originNum === '0.0000') return originNum;
  if (!originNum.includes('.')) return '';
  for (let i = originNum.length - 1; i >= 0; i -= 1) {
    if (originNum.charAt(i) !== '0') {
      return originNum.substring(i + 1, originNum.length);
    }
  }
  return '';
};

export const pegOrOOf = (originType: string) => {
  if (originType === 'PegInstructionResponse') {
    return 'Pegged';
  }
  return 'Order on Fill';
};

export const CURRENCYPREFIX = 'T';

// We store products in the app with a slash, i.e. BTC/USD,
// but we store them in the URL with a dash, which is more friendly to URL params.
export const formatProductForURLString = (product: string) => product.replace('/', '-');

export const formatProductFromURLString = (product: string) => product.replace('-', '/');

export const getLocation = (userPrefs: UserPrefsState) => {
  if (
    userPrefs.locationCountry === 'US'
    || userPrefs.locationCountry === 'CA'
  ) {
    return `${userPrefs.locationCountry},${userPrefs.locationState}`;
  }
  return userPrefs.locationCountry;
};
