import styled from 'styled-components/macro';

export interface WrapperProp {
  children?: React.ReactNode,
  isFilterHidden?: boolean
}

export const AgGridWrapper = styled.div`

    height: 100%;
    margin-left: 5px;
    width: 100%;

    /*HEADER*/


    .ag-header {
        background-color: ${({ theme }) => theme.palette.primary.primary};
        color: ${({ theme }) => theme.palette.secondary.secondary};
        font-size: 10px;
        font-weight: bold; 
        margin-right: 2px;
        border-bottom: .5px solid ${(props) => (props.isFilterHidden ? '#668090' : 'transparent')};
    } 

    .ag-header-cell-label {
        padding-left: 12px;
        padding-bottom: 5px;
    } 

    .ag-center-cols-container {
        padding-left: 12px;
    }
    
    .ag-header-cell-sorted-none,
    .ag-header-cell-sorted-desc,
    .ag-header-cell-sorted-asc {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
    }

    .ag-header-cell-sorted-desc::after,
    .ag-header-cell-sorted-asc::after {
        content: '';
        width: 0;
        height: 0;
        z-index: 1;

        border-left: 3px solid transparent;
        border-right: 3px solid transparent;

        position: absolute;
        left: 50%;
        bottom: 0;

        transition: opacity 300ms;
    }

    .ag-icon-desc::before{
        color: ${({ theme }) => theme.palette.accent.accentPrimary};
        content: "▼";
        font-size: 8px;
        position: relative;
        left: 3px;
    }

    .ag-icon-asc::before{
        color: ${({ theme }) => theme.palette.accent.accentPrimary};
        content: "▲";
        font-size: 8px;
        position: relative;
        left: 3px;
    }

    .ag-header-cell {
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        background: ${({ theme }) => theme.palette.primary.primary};
        i {
            font-size: 14px;
            color: ${({ theme }) => theme.palette.secondary.secondary};
        }

    }

    /* ROWS */

    .ag-row {
        font-size: 11px;
        color: ${({ theme }) => theme.palette.secondary.secondary};
        background-color: ${({ theme }) => theme.palette.primary.primary};
    }
    
    .ag-row-odd {
        background-color: ${({ theme }) => theme.palette.primary.primary1};
    }

    .ag-grid-watchlist-header {
        .ag-header-cell-label{
            padding-left: 12px;
            padding-bottom: 5px;
            display: flex;
            justify-content: flex-end;
        }
        .ag-icon-desc::before{
            left: 0.5px;
        }

        .ag-icon-asc::before{
            left: 0.5px;
        }
    } 

    /* CELLS */

    .ag-cell {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
        text-indent: 4px;
    }

    .ag-grid-number-cell {
        display: flex;
        align-items: center;
        justify-content: flex-end;   
        font-family: 'Roboto Mono';
        box-sizing: border-box;
        padding-right: 10px;
    }
        
    .ag-cell-focus,
    .ag-cell-no-focus {
        border: 1px solid ${({ theme }) => theme.palette.accent.accentPrimary} !important;
       
    }
    .ag-cell:focus {
        border: 1px solid ${({ theme }) => theme.palette.accent.accentPrimary} !important;
        outline: none;
    }

    .ag-cell-range-selected {
        background: ${({ theme }) => theme.palette.primary.primary2};
        border: 1px solid ${({ theme }) => theme.palette.accent.accentPrimary} !important;
        outline: none;
    }

    .ag-cell-label-container {
        width: 100%;
        height: 100%;
    }

    .ag-grid-no-padding-cell,
    .ag-grid-no-padding-cell:focus,
    .ag-grid-no-padding-cell.ag-cell-range-selected
     {
        padding: 0;
        border: none !important;
        background: inherit;
        outline: none;
        > .ag-cell-wrapper > .ag-cell-value {
            flex: 1;
            height: 100%;
        }
    }

    .ag-grid-watchlist-cell {
        font-size: 12px;
    }


    /* FILTER */

    .ag-header-row-floating-filter {
        margin-bottom: 0px;
        height: 25px!important;
        display: ${(props: WrapperProp) => (props.isFilterHidden ? 'none' : 'block')};
        .ag-input-field{
            box-shadow: none;
        }
        .ag-focus-managed{
            background-color: ${({ theme }) => theme.palette.primary.primary1};
        }
    }

    .ag-filter {
        background-color: ${({ theme }) => theme.palette.primary.primary};
    }

    .ag-floating-filter-body {
        width: 70%;
        input {
            font-family: inherit;
            background-color: ${({ theme }) => theme.palette.primary.primary};
            color: ${({ theme }) => theme.palette.secondary.secondary};
            font-size: 10px;
            max-width: calc(100% - 18px);
            width: 70%;
            height: 16px;
            margin-top: 0.5px;
            outline: none;
            border-style: none;
            margin-left: 12px;
            border-radius: 2px;
            border: solid 1px ${({ theme }) => theme.palette.secondary.secondary5};
        }
    }

    
    .ag-tab-header {
        padding: 5px 4px;
        i {
            font-size: 14px;
            color: ${({ theme }) => theme.palette.secondary.secondary};
        }
    }

    .ag-floating-filter-button {
        display: none;
    }

    .ag-react-container {
        width: 100%;
        height: 100%;
    }

    /* Overlays */

    .ag-overlay-custom {
        color: white;
        font-size: 14px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* PAGINATION */
    .ag-paging-panel {
        margin-left: -50px;
    }

    /* CONTEXT MENU */

       .ag-menu {
        z-index: 1;
        border-radius: 2px;
        background-color: ${({ theme }) => theme.palette.primary.primary5};
        padding: 4px 5px;
        font-size: 10px;
    }
    .ag-menu-option {
        border-radius: 2px;
        cursor: pointer;
    }

    .ag-menu-option-text {
        padding: 3px 6px;
        color: ${({ theme }) => theme.palette.secondary.secondary};
    }

    .ag-menu-option-disabled .ag-menu-option-text {
        font-style: italic;
        color: ${({ theme }) => theme.palette.secondary.secondary};
        cursor: initial;
    }

    .ag-menu-option-active {
        background-color: ${({ theme }) => theme.palette.primary.primary5};
    }

    .ag-menu-option-icon,
    .ag-menu-option-shortcut,
    .ag-menu-option-popup-pointer {
        display: none;
    }

    
    .ag-input-field-input {
        width: auto;
    }

    .ag-pivot-off{
        min-height: 0!important;
        height: ${(props: WrapperProp) => (props.isFilterHidden ? '33px' : '73px')}!important;
    }

    .ag-side-button{
        display: none;
    }

    .ag-tool-panel-wrapper{
        width: 119px;
    }
    
    .ag-column-panel{
        background-color: ${({ theme }) => theme.palette.primary.primary1};
    }

    .ag-checked{
        color: ${({ theme }) => theme.palette.accent.accentPrimary};
    }

    .ag-column-drop-empty{
        display: none;
    }

    .ag-toggle-button-input-wrapper{
        display: none;
    }

    .ag-toggle-button-label{
        visibility:hidden;
    }

    .ag-toggle-button-label:after{
        visibility: visible;
        content: 'Columns';
        position: relative;
        right: 74px;
        font-family: Roboto;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: white;
    }

    .ag-column-select-header{
        display: none;
    }

    .ag-column-select-column-label{
        font-size: 11px;
    }

    .ag-tool-panel-horizontal-resize {
        display: none;
    }

    input[type="checkbox"] { /* same styling as ThemedCheckbox component */
        -webkit-appearance: none;
        cursor: pointer;
        background-color: ${({ theme }) => theme.palette.primary.primary1};
        border: 1px solid ${({ theme }) => theme.palette.primary.primary5};
        width: 12px;
        height: 12px;
        display: inline-block;
        position: relative;
        :active {
            box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
        }
        :focus {
            outline: none;
        }
        :checked {
            background-color: ${({ theme }) => theme.palette.accent.accentPrimary};
            border: 1px solid ${({ theme }) => theme.palette.accent.accentPrimary};
            color: ${({ theme }) => theme.palette.secondary.secondary1};
            :after {
            content: url('tick.svg');
            position: absolute;
            top: -5px;
            left: -6px;
            color: ${({ theme }) => theme.palette.primary.primary1};
            svg {
                color : black;
            }
            }
            :active {
            box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
            }
        }
    }

    .RefreshIcon{
        position: relative;
        right: 2px;
        bottom: 1px;
        width: 20px;
        height: 20px;
    }

    .RefreshIcon:hover {
        fill: ${({ theme }) => theme.palette.accent.accentPrimary};
    }

    .ag-body-viewport {
        width: 99%;
    }

    /* 
        Remove the inner scrollbar on the column content, 
        since we already have a scrollbar for the full container.
        The outer scrollbar can cut off some content, 
        so add some extra padding to its scrollable content.
    */

    .ag-center-cols-viewport {
        ::-webkit-scrollbar {
            height: 0;    
        }
    }

    .ag-body-horizontal-scroll-container {
        padding-right: 60px;
    }

    /* draggable row */

    .ag-drag-handle{
        width: 10px;
        margin-right: 10px;
    }
    .ag-icon-grip::before{
        color: ${({ theme }) => theme.palette.accent.accentPrimary};
        content: "⋮";
        font-size: 18px;
    }

    .ag-column-select-column{
        
        .ag-icon-grip::before{
            display: none;
        }
    }
`;
