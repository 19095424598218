import { shareLatest } from '@react-rxjs/core';
import { GroupedObservable, Observable } from 'rxjs';
import { groupBy, scan } from 'rxjs/operators';

import {
  subscribeToMessageStream$,
} from '../../../shared/websocket/transport';
import { MarketDataTradeMessage } from '../../tradeHistory/tradeHistoryServiceHelpers';

export const incrementalUpdatesBySymbol$: Observable<
Record< string, GroupedObservable<string, MarketDataTradeMessage>>
> = subscribeToMessageStream$('MarketDataIncrementalRefreshTrade').pipe(
  groupBy((msg) => (msg as MarketDataTradeMessage).symbol),
  scan((acc, message$) => ({ ...acc, [message$.key]: message$ }), {}),
  shareLatest(),
);
incrementalUpdatesBySymbol$.subscribe();
