import winston from 'winston';
import { Config } from '../../config/config';

const errorStackFormat = winston.format((info) => {
  if (info.err instanceof Error) {
    return {
      ...info,
      stack: info.err.stack,
      message: info.err.message,
    };
  }
  return info;
});

const winstonLogger = winston.createLogger({
  level: Config.APP_ENV !== 'PRODUCTION' ? 'debug' : 'info',
  transports: [
    new winston.transports.Console(),
  ],
  format: winston.format.combine(
    winston.format.splat(), // Necessary to produce the 'meta' property
    errorStackFormat(),
    winston.format.simple(),
  ),
});

const error = (msg: string, err: any) => {
  winstonLogger.error(msg, { err });
};

const debug = (msg: string) => {
  winstonLogger.debug(msg);
};

export const logger = {
  error,
  debug,
};

winstonLogger.exceptions.handle(
  new winston.transports.Console(),
);
