import React from 'react';

export const USDIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
}> = ({ height = 20, width = 20 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" className='usd-icon'>
    <defs>
      <circle id="l4d30u6swa" cx="11" cy="11" r="11" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-907 -364) translate(907 364)">
          <g transform="translate(1 1)">
            <mask id="lnxvhp1cvb" fill="#fff">
              <use href="#l4d30u6swa" />
            </mask>
            <use fill="#FFF" fillRule="nonzero" href="#l4d30u6swa" />
            <path fill="#F44336" fillRule="nonzero" d="M-7.32-1L31.4-1 31.4 1.667-7.32 1.667zM-7.32 4.333L31.4 4.333 31.4 7-7.32 7zM-7.32 9.667L31.4 9.667 31.4 12.333-7.32 12.333zM-7.32 15L31.4 15 31.4 17.667-7.32 17.667zM-7.32 20.333L31.4 20.333 31.4 23-7.32 23z" mask="url(#lnxvhp1cvb)" />
            <path fill="#3F51B5" fillRule="nonzero" d="M-7.32 -1L11.16 -1 11.16 12.333 -7.32 12.333z" mask="url(#lnxvhp1cvb)" />
            <path fill="#FFF" fillRule="nonzero" d="M-1.82.778L-1.411 1.656-.5 1.795-1.16 2.479-1.002 3.444-1.82 2.99-2.638 3.444-2.48 2.479-3.14 1.795-2.229 1.656zM5.22.778L5.629 1.656 6.54 1.795 5.88 2.479 6.038 3.444 5.22 2.99 4.402 3.444 4.56 2.479 3.9 1.795 4.811 1.656zM8.52.778L8.929 1.656 9.84 1.795 9.18 2.479 9.338 3.444 8.52 2.99 7.702 3.444 7.86 2.479 7.2 1.795 8.111 1.656zM1.7 7.889L2.109 8.767 3.02 8.906 2.36 9.59 2.518 10.556 1.7 10.101.882 10.556 1.04 9.59.38 8.906 1.291 8.767zM5.22 7.889L5.629 8.767 6.54 8.906 5.88 9.59 6.038 10.556 5.22 10.101 4.402 10.556 4.56 9.59 3.9 8.906 4.811 8.767zM8.52 7.889L8.929 8.767 9.84 8.906 9.18 9.59 9.338 10.556 8.52 10.101 7.702 10.556 7.86 9.59 7.2 8.906 8.111 8.767zM2.58 4.333L2.989 5.212 3.9 5.351 3.24 6.035 3.398 7 2.58 6.545 1.762 7 1.92 6.035 1.26 5.351 2.171 5.212zM6.1 4.333L6.509 5.212 7.42 5.351 6.76 6.035 6.918 7 6.1 6.545 5.282 7 5.44 6.035 4.78 5.351 5.691 5.212zM9.4 4.333L9.809 5.212 10.72 5.351 10.06 6.035 10.218 7 9.4 6.545 8.582 7 8.74 6.035 8.08 5.351 8.991 5.212z" mask="url(#lnxvhp1cvb)" />
          </g>
          <circle fill="#282E39" style={{ mixBlendMode: 'color' }} cx="12" cy="12" r="11" />
        </g>
      </g>
    </g>
  </svg>
);
