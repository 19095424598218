import { FileCopy } from '@material-ui/icons';
import React from 'react';
import { SuccessIcon } from '../../../../icons/SuccessIcon';
import { copyToClipboard } from '../../../../shared/helperFunctions/copyToClipboard';
import { BlockTradeEntryResponse } from '../../services/block/blockTradeEntryService';
import {
  OrderEntryCloseButton,
  OrderEntryCorrelation,
  OrderEntryFormTransitionsWrapper,
  OrderEntryTransitionTitle,
} from '../styled';

interface Props {
  latestEntryMessage: BlockTradeEntryResponse;
  setIsOverlayActive: (isOverlayActive: boolean) => void;
}

export const BlockOrderEntrySuccess: React.FunctionComponent<Props> = (props) => {
  const { latestEntryMessage } = props;
  return (<OrderEntryFormTransitionsWrapper>
    <OrderEntryTransitionTitle><SuccessIcon /> {' BLOCK ORDER SUBMITTED:'}</OrderEntryTransitionTitle>
    <OrderEntryCorrelation>
      {latestEntryMessage.request_id}
      <FileCopy onClick={() => copyToClipboard(latestEntryMessage.request_id!)} />
    </OrderEntryCorrelation>
    <hr />
    <OrderEntryCloseButton
      onClick={() => {
        props.setIsOverlayActive(false);
      }}
    >
      CLOSE
      </OrderEntryCloseButton>
  </OrderEntryFormTransitionsWrapper>);
};
