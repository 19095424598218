import { Subject } from 'rxjs';
import {
  filter,
  map,
  skip,
  startWith,
  distinctUntilChanged,
} from 'rxjs/operators';
import { shareLatest, bind } from '@react-rxjs/core';
// eslint-disable-next-line import/no-cycle

export enum ConnectionStatus {
  OPEN = 'open',
  CONNECTING = 'connecting',
  AUTHENTICATED = 'authenticated',
  CLOSED = 'closed',
}

export const connectionStatus$ = new Subject<ConnectionStatus>();

export const setConnectionStatus = (status: ConnectionStatus) => {
  connectionStatus$.next(status);
};

export const [useConnectionStatus, connectionStatusState$] = bind(
  connectionStatus$.pipe(distinctUntilChanged()),
  ConnectionStatus.CONNECTING,
);
// Start with immediately emitting connecting, since the app
// immediately starts making a websocket connection on startup
connectionStatusState$.subscribe();

// gives the current connection number, which increments whenever the
// websocket fails then successfully reconnects
export const websocketConnectionNumber$ = connectionStatusState$.pipe(
  filter((status) => status === ConnectionStatus.AUTHENTICATED),
  skip(1),
  map((value, indx) => indx + 1),
  startWith(0),
  shareLatest(),
);
