import React from 'react';
import styled from 'styled-components/macro';
import { CSSTransition } from 'react-transition-group';
import { PortalContent } from './Portal';

export const MODAL_PORTAL_NAME = 'outer-modal';

export const AbsoluteModalWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: rgba(23, 46, 58, 0.5);

  &.appear {
    opacity: 0;
  }

  &.enter-active {
    opacity: 1;
  }
  transition: opacity 120ms ease-in;
`;

export const ModalContainer = styled.div<{ noPadding: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.primary.primary};
  border: solid 1px ${({ theme }) => theme.palette.primary.primary4};
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  padding: ${({ noPadding = false }) => (noPadding ? '0' : '15px')};
  font-size: 9px;
  box-shadow: 0px 0px 16px #10263b;
`;

const ModalContent = styled.div``;

export const ModalTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 15px;
`;

const ModalBody = styled.div`
  flex: 1;
`;

const ModalHeader = styled.div`
  flex: 1;
`;

interface Props {
  title?: string;
  content: React.ReactNode;
  renderHeader?: (headerContent: React.ReactNode) => React.ReactNode;
  noPadding?: boolean;
}

const defaultRenderHeader = (headerContent: React.ReactNode): React.ReactNode => headerContent;

export const Modal: React.FC<Props> = ({
  title,
  content,
  renderHeader = defaultRenderHeader,
  noPadding = false,
}) => (
    <PortalContent name={MODAL_PORTAL_NAME}>
      <CSSTransition in={true} timeout={120} appear>
          <AbsoluteModalWrapper >
            <ModalContainer noPadding={noPadding}>
              <ModalHeader>
                {renderHeader(title ? <ModalTitle>{title}</ModalTitle> : null)}
              </ModalHeader>
              <ModalBody>
                <ModalContent>{content}</ModalContent>
              </ModalBody>
            </ModalContainer>
          </AbsoluteModalWrapper>
      </CSSTransition>
    </PortalContent>
);
