import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import styled from 'styled-components/macro';
import useResizeObserver from 'use-resize-observer/polyfilled';

import { Config } from '../../config/config';
import { AccordionWidget } from '../../shared/components/AccordionWidget';
import { Panel } from '../../shared/components/Panel';
import { TitleWrapper } from '../../shared/style/styled';
import { BalanceTotalWidget } from '../balances/BalanceTotalWidget';
import { BalanceWidget } from '../balances/BalanceMainWidget';
import { ChartContainer as Chart } from '../chart/ChartContainer';
import { NavBar } from '../navbar/NavBar';
import { MainToasterContainer } from '../notifications/MainToasterContainer';
import { OrderBookContainer as OrderBook } from '../orderBook/OrderBookContainer';
import {
  BarContainer,
  Book,
  MyQty as OrderBookMyQty,
  MyQtyHeader as OrderBookMyQtyHeader,
  Price as OrderBookPrice,
  PriceHeader as OrderBookPriceHeader,
  Quantity as OrderBookQuantity,
  QuantityHeader as OrderBookQuantityHeader,
} from '../orderBook/styles';
import { OrderEntry } from '../orderEntry/OrderEntry';
import { OrderEntryWrapper } from '../orderEntry/styled';
import { OrdersGridsTabPanel } from '../orderGridsTabPanel/OrdersGridsTabPanel';
import { GridWrapper } from '../orderGridsTabPanel/styled';
import { HeaderWrapper, Root as TabPanelRoot, TabHeader as DefaultTabHeader } from '../tabs/styled';
import { TabPanel } from '../tabs/TabPanel';
import {
  IconWrapper,
  PriceCell as TradeHistoryPrice,
  PriceHeader,
  QuantityCell as TradeHistoryQuantity,
  QuantityHeader,
  Table as TradeHistoryTable,
  TimeCell as TradeHistoryTime,
  TimeHeader,
} from '../tradeHistory/styled';
import { TradeHistoryContainer as TradeHistory } from '../tradeHistory/TradeHistoryContainer';
import { Watchlist } from '../watchlist/Watchlist';
import { setShowBalanceBackground } from '../balances/services/balanceService';
import { OTCStreams } from '../otcStreams';
import { usePermissions } from '../../shared/services/userPermissionsService';
import { Feature, useFeatureEnabled } from '../../shared/featureToggle';

const MobileTradeWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 110px 29px;
  height: 100%;
`;

// tabPanelHeight is used to dynamically set the height of the whole tabpanel,
// to fix a bug in iOS making 100% height not work.
const MobileOrderEntryWrapper = styled.div<{ tabPanelHeight: number }>`
  overflow-y: hidden;
  ${OrderEntryWrapper} {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    grid-template-rows: auto;
    margin-bottom: 0;
  }

  ${TabPanelRoot} {
    height: ${({ tabPanelHeight }) => tabPanelHeight}px;
  }
`;

const MobileGridWrapper = styled.div`
  height: inherit;

  ${GridWrapper} {
    height: calc(100% - 34px);
  }
`;

const MobileHistoryWrapper = styled.div`
  height: inherit;

  ${IconWrapper} {
    margin-left: 10%;
    display: block;
  }

  ${TradeHistoryTable} {
    height: 100%;
  }

  ${TradeHistoryPrice} {
    width: 10%;
  }

  ${TradeHistoryQuantity} {
    width: 35%;
  }

  ${TradeHistoryTime} {
    width: 30%;
  }

  ${PriceHeader} {
    width: calc(20% + 24px);
  }

  ${QuantityHeader} {
    width: 35%;
  }

  ${TimeHeader} {
    width: 30%;
  }

  ${Panel} {
    height: calc(100% - 34px);
  }
`;

const MobileOrderBookWrapper = styled.div<{ isQuickView?: boolean }>`
  height: inherit;

  && ${TitleWrapper} {
    display: ${({ isQuickView }) => (isQuickView ? 'block' : 'none')};
  }

  ${OrderBookPrice} {
    width: 30%;
  }

  ${OrderBookQuantity} {
    width: 25%;
  }

  ${BarContainer} {
    width: 20%;
    padding-left: 5%;
  }

  ${Book} {
    overflow: scroll;
  }

  ${OrderBookPriceHeader} {
    width: 25%;
  }

  ${OrderBookQuantityHeader} {
    width: 20%;
  }

  ${OrderBookMyQty} {
    width: 35%;
  }

  ${OrderBookMyQtyHeader} {
    width: 30%;
  }

  ${Panel} {
    height: calc(100% - 34px);
  }
`;

const ChartMobileWrapper = styled.div`
  height: inherit;

  ${Panel} {
    height: calc(100% - 34px);
  }
`;

const MobileWatchlistWrapper = styled.div`
  height: inherit;
`;

const MobileOTCStreamsWrapper = styled.div`
  height: inherit;
`;

const TopHeight = Config.BANNER_TEXT !== 'none' ? 162 : 98;

const Body = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.secondary.main};
  margin: 0;
  height: calc(100% - ${TopHeight}px);
  box-sizing: border-box;

  ${DefaultTabHeader} {
    justify-content: space-around;
    z-index: 5;
  }

  ${MobileTradeWrapper},
  ${MobileOrderBookWrapper},
  ${MobileGridWrapper},
  ${MobileHistoryWrapper},
  ${ChartMobileWrapper} {
    ${DefaultTabHeader} {
      z-index: auto;
    }
  }

  ${MobileGridWrapper} ${DefaultTabHeader} {
    justify-content: flex-start;
  }

  ${TitleWrapper} {
    display: none;
  }
`;

const MobileBalanceAccordionWrapper = styled.div<{offset: number}>`
  .accordionRoot {
    bottom: ${({ offset }) => offset}px;
    position: absolute;
    width: 100%;
    z-index: 5;
  }
`;

const MobileWatchlistTabHeader = styled(DefaultTabHeader)`
  && {
    justify-content: flex-start;
  }
`;

const MobileWatchlist:React.FC = () => (
  <MobileWatchlistWrapper>
    <TabPanel
      componentOverrides={{ TabHeader: MobileWatchlistTabHeader }}
      tabs={[
        {
          title: 'Watchlist',
          tabId: 'watchlist',
          content: (
            <MobileWatchlistWrapper>
            <Watchlist />
          </MobileWatchlistWrapper>
          ),
        },
      ]}
      dropdownOptions={true}
      />
    </MobileWatchlistWrapper>
);

interface Props {
  isBalanceOpen: boolean;
  logUserOut: () => void;
}

export const MobileLayout: React.FC<Props> = ({
  isBalanceOpen,
  logUserOut,
}) => {
  const toggleBalance = useCallback(
    () => setShowBalanceBackground(!isBalanceOpen), [isBalanceOpen],
  );

  // workaround for iOS
  // the Root component on tabpanel is not setting its height correctly
  // to 100% of parent, so we set it dynamically to the parent's height through javascript.
  const orderEntryTabPanelRef = useRef(null);
  const { height = 1 } = useResizeObserver({ ref: orderEntryTabPanelRef });
  const userPermissions = usePermissions();
  const isOtcFeatureEnabled = useFeatureEnabled(Feature.OTC_STREAMS);
  const [tabHeight, setTabHeight] = useState(0);

  const MobileBalanceWidget = useMemo(() => (
    <MobileBalanceAccordionWrapper offset={tabHeight}>
      <AccordionWidget
        isOpen={isBalanceOpen}
        onChange={toggleBalance}
        collapsedText="Purchasing Power"
        rightContent={<BalanceTotalWidget />}
      >
        <BalanceWidget />
      </AccordionWidget>
    </MobileBalanceAccordionWrapper>
  ),
  [isBalanceOpen, tabHeight, toggleBalance]);

  return (
    <>
      <NavBar logUserOut={logUserOut} />
      <Body>
        <TabPanel
          componentOverrides={{
            HeaderWrapper: ({ children }) => (
              <HeaderWrapper ref={(e) => setTabHeight(e?.getBoundingClientRect().height || 0)}>
                {children}
              </HeaderWrapper>
            ),
          }}
          headerOnBottom
          tabs={[
            {
              title: 'Trade',
              tabId: 'trade',
              content: (
                <MobileTradeWrapper>
                  <MobileOrderEntryWrapper
                    ref={orderEntryTabPanelRef}
                    tabPanelHeight={height}
                  >
                    <OrderEntry />
                  </MobileOrderEntryWrapper>
                  <OrderBook isTopView />
                  {MobileBalanceWidget}
                </MobileTradeWrapper>
              ),
            },
            {
              title: 'Order Book',
              tabId: 'orderBook',
              content: (
                <MobileOrderBookWrapper>
                  <OrderBook />
                  {MobileBalanceWidget}
                </MobileOrderBookWrapper>
              ),
            },
            {
              title: 'History',
              tabId: 'history',
              content: (
                <MobileHistoryWrapper>
                  <TradeHistory />
                  {MobileBalanceWidget}
                </MobileHistoryWrapper>
              ),
            },
            {
              title: 'Charts',
              tabId: 'charts',
              content: (
                <ChartMobileWrapper>
                  <Chart />
                  {MobileBalanceWidget}
                </ChartMobileWrapper>
              ),
            },
            {
              title: 'Orders',
              tabId: 'orders',
              content: (
                <MobileGridWrapper>
                  <OrdersGridsTabPanel />
                  {MobileBalanceWidget}
                </MobileGridWrapper>
              ),
            },
            {
              title: 'Watchlist',
              tabId: 'watchlist',
              content: (
                <MobileWatchlistWrapper>
                  <MobileWatchlist />
                  {MobileBalanceWidget}
                </MobileWatchlistWrapper>
              ),
            },
            // TODO: remove OTC_ENABLED once fully supported in prod - DA
            ...(Config.OTC_ENABLED === 'true'
            && !userPermissions?.error
            && userPermissions?.blockTrades
            && isOtcFeatureEnabled
              ? [
                {
                  title: 'Quote Streams',
                  tabId: 'otcstreams',
                  content: (
                      <MobileOTCStreamsWrapper>
                        <OTCStreams />
                        {MobileBalanceWidget}
                      </MobileOTCStreamsWrapper>
                  ),
                },
              ]
              : []),
          ]}
        />
        <MainToasterContainer mobile={true} />
      </Body>
    </>
  );
};
