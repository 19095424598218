import React from 'react';

export const PaymentsIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
}> = ({ height = 20, width = 20 }) => (
<svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.667 9.333V4c0-.733-.6-1.333-1.334-1.333H2c-.733 0-1.333.6-1.333 1.333v5.333c0 .734.6 1.334 1.333 1.334h9.333c.734 0 1.334-.6 1.334-1.334zm-1.334 0H2V4h9.333v5.333zM6.667 4.667c-1.107 0-2 .893-2 2 0 1.106.893 2 2 2 1.106 0 2-.894 2-2 0-1.107-.894-2-2-2zm8.666 0V12c0 .733-.6 1.333-1.333 1.333H2.667V12H14V4.667h1.333z" fill="currentColor"/>
</svg>

);
