import { Map } from 'immutable';
import { Direction } from '../../../../shared/Direction';
import { ExecutionReport } from '../openOrders/openOrdersReducer';

export interface PositionResponse {
  account_id: string;
  positions: ProductPosition[]
}
export type HistoricalPositionResponse = {
  response: PositionResponse[];
  error?: boolean;
};

export interface PositionRow {
  account: string;
  product: string;
  contract: string;
  netPosition: number;
  unrealisedPnl: number;
}

export interface ProductPosition {
  product_code: string;
  contract_symbol: string;
  total_long: string;
  total_short: string;
  positions: Position[];
  closing_px_date: string;
  contract_code:string;
  expiration_time: string;
  total_reserve_ote: string;
}
interface Position {
  cl_ord_id: string;
  customer_account_ref: string;
  et: string;
  notional: string;
  position_id: string;
  px: string;
  qty: string;
  reserve_margin_l: string;
  reserve_ote: string;
}

export const convertPositionToPositionRow = (
  position: ProductPosition, accountNumber?: string,
) => ({
  account: accountNumber || '',
  product: position.product_code,
  contract: position.contract_symbol,
  netPosition: Number(position.total_long) - Number(position.total_short),
  unrealisedPnl: 0,
});

export const convertERToPositionRow = (eReport: ExecutionReport) => ({
  account: eReport.accountNumber,
  product: eReport.productCode,
  contract: eReport.symbol,
  netPosition: eReport.lastQty,
  unrealisedPnl: 0,
});

export type PositionMap = Map<string, PositionRow>;
export const initialPositionMap: PositionMap = Map<string, PositionRow>();

const getNetPosition = (oldNetPosition: number, newOrder: ExecutionReport) => {
  if (newOrder.side === Direction.Sell) {
    return oldNetPosition - newOrder.lastQty;
  }

  return oldNetPosition + newOrder.lastQty;
};

type PositionEventType = {
  type: 'newPositionUpdate';
  payload: ExecutionReport;
} | {
  type: 'newHistoricalPositions';
  payload: HistoricalPositionResponse;
};

export const positionsReducer = (existingOpenOrders: PositionMap,
  event: PositionEventType): PositionMap => {
  if (event.type === 'newHistoricalPositions') {
    return existingOpenOrders.withMutations((map) => map.clear());
  }
  const orderUpdatePayload = event.payload;
  if (orderUpdatePayload.execType === 'TRADE') {
    return existingOpenOrders.withMutations((map) => {
      const row = convertERToPositionRow(orderUpdatePayload);
      if (map.has(row.account + row.contract)) {
        map.update(row.account + row.contract,
          (oldRow) => ({
            ...oldRow,
            netPosition: getNetPosition(oldRow.netPosition, orderUpdatePayload),
          }));
      } else {
        map.set(row.account + row.contract, {
          ...row,
          netPosition: orderUpdatePayload.side === Direction.Buy
            ? orderUpdatePayload.lastQty
            : -orderUpdatePayload.lastQty,
        });
      }
    });
  }
  return existingOpenOrders.withMutations((map) => map);
};
