import { FormControl, InputLabel, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { bind } from '@react-rxjs/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { formatProductFromURLString } from '../../../shared/formatters';
import {
  requestMarketData,
  useUrl,
} from '../../../shared/services/selectedMarketService';
import { MenuItemStyled } from '../../../shared/style/styled';
import { FUT } from '../../orderEntry/services/trade/sendEntry';
import { pageView } from '../../tracking';
import { FilterButtons } from '../../../shared/components/FilterButtons/FilterButton';

import {
  MarketSelectorWrapper,
  MarketSelectorHeader,
  MarketSelectorSubheader,
  MarketSelectorGroupedSubHeader,
  MarketSelectorFilterButtonsWrapper,
} from './MarketSelector.styled';
import {
  contractsListState$,
  selectedProductContract$,
  setContractFilterPredicate,
} from './MarketSelectorState/contractSelectorState';
import {
  allSecuritiesState$,
  futuresListState$,
  isNoFilter,
  isFutureFilter,
  ProductFilter,
  setSelectedProductFilter,
  setProductFilterPredicate,
  setSelectedContract,
  setSelectedProduct,
  spotsListState$,
  useFilteredFuturesList,
  useFilteredSpots,
  useSelectedProductFilter,
  isFutureSecurity,
} from './MarketSelectorState/productSelectorState';
import { FilterSearchPredicate } from '../../../shared/components/FilterSearchPredicate/FilterSearchPredicate';
import { ProductValue } from '../../../shared/ProductValue';

const isValidProductValue = (product: ProductValue) => Boolean(product.symbol);
export const [useMarketSelectorState, marketSelectorState$] = bind(
  combineLatest([
    spotsListState$,
    futuresListState$,
    allSecuritiesState$,
    contractsListState$,
    selectedProductContract$,
  ]).pipe(
    map(
      ([
        spots,
        futures,
        allSecurities,
        contracts,
        { selectedProduct, selectedContract },
      ]) => ({
        spots,
        futures,
        allSecurities,
        contracts,
        selectedProduct,
        selectedContract,
      }),
    ),
  ),
);

const getDefaultProduct = (urlParams: URLSearchParams) => {
  const urlProduct = urlParams.get('product');
  return urlProduct ? formatProductFromURLString(urlProduct) : urlProduct;
};
const getDefaultSymbol = (
  defaultProduct?: string | null,
  defaultContract?: string | null,
) => {
  if (defaultContract && defaultContract !== 'null') return defaultContract;
  if (defaultProduct) return defaultProduct;
  return window.localStorage.getItem('market');
};
export const MarketSelector = () => {
  const {
    spots,
    futures,
    allSecurities,
    contracts,
    selectedProduct,
    selectedContract,
  } = useMarketSelectorState();
  const history = useHistory();
  const url = useUrl();
  const [pastUrl, setPastUrl] = useState(url);

  const filteredSpots = useFilteredSpots();
  const filteredFutureList = useFilteredFuturesList();
  const selectedProductFilter = useSelectedProductFilter();

  useEffect(() => {
    const currentUrlParams = new URLSearchParams(window.location.search);
    // Get product and contract from url
    const defaultProduct = getDefaultProduct(currentUrlParams);
    const defaultContract = currentUrlParams.get('contract');
    // Infer symbol from product and contract
    const defaultSymbol = getDefaultSymbol(defaultProduct, defaultContract);
    // Use futures securities only
    const futuresSecurities = allSecurities.filter(isFutureSecurity);
    // First try and find security matching symbol. If any, find by product
    const securityMatchingSymbol = futuresSecurities.find(
      (s) => s.symbol === defaultSymbol,
    );
    const security = securityMatchingSymbol
      || futuresSecurities.find((s) => s.product === defaultProduct);

    // If found, use that one. If not, use the first future security with valid product code
    const defaultSecurity = security || futuresSecurities.find((s) => Boolean(s.productCode));
    if (defaultSecurity) {
      requestMarketData({
        // Type can only be FUT
        type: FUT,
        symbol: defaultSecurity.symbol,
        value: defaultSecurity.symbol,
        minTradeVol: defaultSecurity.minTradeVol,
        maxTradeVol: defaultSecurity.maxTradeVol,
        roundLot: defaultSecurity.roundLot,
        minPriceIncrement: defaultSecurity.minPriceIncrement,
        currency: defaultSecurity.currency,
        contractMultiplier: defaultSecurity.contractMultiplier,
        productCode: defaultSecurity.productCode,
      });
    }
  }, [spots, futures, allSecurities]);

  useEffect(() => {
    if (
      contracts
      && selectedProduct?.type === FUT
      && (!selectedContract
        || selectedContract?.productCode !== selectedProduct?.symbol)
    ) {
      setSelectedContract(contracts[0]);
      requestMarketData(contracts[0]);
    }
  }, [contracts, history, selectedContract, selectedProduct]);

  useEffect(() => {
    if (url !== pastUrl) {
      setPastUrl(url);
      history.push(url);
      pageView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return selectedProduct ? (
    <>
      <MarketSelectorWrapper>
        <FormControl id="market-selector-form">
          <InputLabel focused={false}>Product</InputLabel>
          <Select
            onClose={() => {
              setProductFilterPredicate('');
            }}
            value={selectedProduct.value}
            id="grouped-select"
            IconComponent={ExpandMoreIcon}
            MenuProps={{
              MenuListProps: { disablePadding: true },
              PaperProps: { style: { paddingRight: '10px' } },
            }}
            disableUnderline
          >
            <MarketSelectorHeader>
              <FilterSearchPredicate onChange={setProductFilterPredicate} />
              <MarketSelectorFilterButtonsWrapper>
                <FilterButtons
                  options={[
                    ProductFilter.FUTURE,
                  ]}
                  currentSelection={selectedProductFilter}
                  onFilterSelect={setSelectedProductFilter}
                  disabledOptions={[
                    ...(Object.keys(filteredSpots).length === 0
                      ? [ProductFilter.SPOT]
                      : []),
                    ...(filteredFutureList.length === 0
                      ? [ProductFilter.FUTURE]
                      : []),
                  ]}
                />
              </MarketSelectorFilterButtonsWrapper>
            </MarketSelectorHeader>

            {isNoFilter(selectedProductFilter) && (
              <MarketSelectorSubheader>SPOT</MarketSelectorSubheader>
            )}

            {!isFutureFilter(selectedProductFilter)
            && Object.keys(filteredSpots).length > 0 ? (
                Object.keys(filteredSpots).map((quoteCurrency) => [
                <MarketSelectorGroupedSubHeader key={quoteCurrency}>
                  {quoteCurrency}
                </MarketSelectorGroupedSubHeader>,
                filteredSpots[quoteCurrency].map((product) => (
                  <MenuItemStyled
                    key={product.symbol}
                    onClick={() => {
                      setSelectedProduct(product);
                      setSelectedContract(null);
                      requestMarketData(product);
                    }}
                    value={product.value}
                    selected={selectedProduct?.symbol === product.symbol}
                  >
                    {product.symbol}
                  </MenuItemStyled>
                )),
                ])
              ) : !isFutureFilter(selectedProductFilter)
              && Object.keys(filteredSpots).length === 0 ? (
              <MarketSelectorSubheader>No matches</MarketSelectorSubheader>
                ) : null}

            <MarketSelectorSubheader>FUTURES</MarketSelectorSubheader>
            {filteredFutureList.length > 0 ? (
              filteredFutureList.filter(isValidProductValue).map((product) => (
                <MenuItemStyled
                  key={product.symbol}
                  value={product.value}
                  onClick={() => {
                    setSelectedProduct(product);
                    setSelectedContract(null);
                  }}
                  selected={selectedProduct?.symbol === product.symbol}
                >
                  {product.symbol}
                </MenuItemStyled>
              ))
            ) : filteredFutureList.length === 0 ? (
              <MarketSelectorSubheader>No matches</MarketSelectorSubheader>
            ) : null}
          </Select>
        </FormControl>
      </MarketSelectorWrapper>
      {selectedProduct!.type === FUT
        && selectedContract?.productCode === selectedProduct?.symbol && (
          <MarketSelectorWrapper>
            <FormControl id="market-selector-form">
              <InputLabel focused={false}>Contract</InputLabel>
              <Select
                value={selectedContract!.value}
                id="grouped-select"
                IconComponent={ExpandMoreIcon}
                MenuProps={{ MenuListProps: { disablePadding: true } }}
                disableUnderline
                onClose={() => setContractFilterPredicate('')}
              >
                <MarketSelectorHeader>
                  <FilterSearchPredicate
                    onChange={setContractFilterPredicate}
                  />
                </MarketSelectorHeader>
                {contracts
                  && contracts.map((contract) => (
                    <MenuItemStyled
                      key={contract.symbol}
                      value={contract.value}
                      onClick={() => {
                        setSelectedContract(contract);
                        requestMarketData(contract);
                      }}
                      selected={selectedContract?.symbol === contract.symbol}
                    >
                      {contract.symbol}
                    </MenuItemStyled>
                  ))}
              </Select>
            </FormControl>
          </MarketSelectorWrapper>
      )}
    </>
  ) : null;
};
