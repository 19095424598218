import React, { useMemo, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MenuItemStyled } from '../../shared/style/styled';

import {
  ContentSection,
  ContentWrapper as DefaultContentWrapper,
  Root,
  TabButton as DefaultTabButton,
  TabHeader as DefaultTabHeader,
  HeaderWrapper as DefaultHeaderWrapper,
  ButtonLabel,
  TabSelectDropdown,
} from './styled';

export interface Tab {
  title: string;
  content: React.ReactNode;
}

export interface TabParams {
  title: string;
  content: React.ReactNode;
  tabId: string;
}

interface TabPanelComponents {
  ContentWrapper: React.JSXElementConstructor<React.ComponentProps<typeof DefaultContentWrapper>>;
  MenuItem: React.JSXElementConstructor<React.ComponentProps<typeof MenuItemStyled>>;
  TabButton: React.JSXElementConstructor<React.ComponentProps<typeof DefaultTabButton>>;
  TabHeader: React.JSXElementConstructor<React.ComponentProps<typeof DefaultTabHeader>>;
  HeaderWrapper: React.JSXElementConstructor<React.ComponentProps<typeof DefaultHeaderWrapper>>;
}

const defaultTabPanelComponents:TabPanelComponents = {
  ContentWrapper: DefaultContentWrapper,
  MenuItem: MenuItemStyled,
  TabButton: DefaultTabButton,
  TabHeader: DefaultTabHeader,
  HeaderWrapper: DefaultHeaderWrapper,
};

interface Props {
  tabs: TabParams[];
  hideHeaderWhenOneTab?: boolean;
  headerOnBottom?: boolean;
  switchTab?: (input: string | undefined) => void,
  headerContent?: React.ReactNode,
  dropdownOptions?: boolean;
  componentOverrides?: Partial<TabPanelComponents>;
}

export const TabPanel: React.FunctionComponent<Props> = (props) => {
  const [selectedTabId, setSelectedTabId] = useState<string>(props.tabs[0].tabId);
  const TabPanelComponents:TabPanelComponents = useMemo(() => ({
    ...defaultTabPanelComponents,
    ...props.componentOverrides,
  }), [props.componentOverrides]);

  const visibleTabs = useMemo(() => props.tabs.map((tab) => {
    if (props.tabs.length === 1 && props.hideHeaderWhenOneTab) {
      return [];
    }

    const { tabId, title } = tab;

    return (
      <React.Fragment key={tabId}>
        <TabPanelComponents.TabButton
          key={tabId}
          data-testid={`${tabId}-tab`}
          selected={selectedTabId === tabId}
          onClick={() => {
            setSelectedTabId(tabId);
            if (props.switchTab) props.switchTab(tabId);
          }}
        >
          <ButtonLabel>{title}</ButtonLabel>
        </TabPanelComponents.TabButton>
      </React.Fragment>
    );
  }), [props, selectedTabId]);

  const visibleSelectTabs = useMemo(() => {
    if (props.tabs.length === 1 && props.hideHeaderWhenOneTab) {
      return [];
    }

    return (
      <TabSelectDropdown
        value={selectedTabId}
        IconComponent={ExpandMoreIcon}
        MenuProps={{ MenuListProps: { disablePadding: true } }}
        disableUnderline
      >
        {props.tabs.map(({ tabId, title }) => (
           <TabPanelComponents.MenuItem
            key={tabId}
            value={tabId}
            onClick={() => {
              setSelectedTabId(tabId);
              if (props.switchTab) props.switchTab(tabId);
            }}
          >{title}</TabPanelComponents.MenuItem>
        ))}
      </TabSelectDropdown>
    );
  }, [props, selectedTabId]);

  return (
    <Root>
      {!props.headerOnBottom && (
        <TabPanelComponents.HeaderWrapper>
          <TabPanelComponents.TabHeader width={props.headerContent ? 'calc(100% - 120px)' : '100%'}>
            {props.dropdownOptions ? visibleSelectTabs : visibleTabs}
          </TabPanelComponents.TabHeader>
          {props.headerContent}
        </TabPanelComponents.HeaderWrapper>
      )}
      <ContentSection>
        {props.tabs.map((tab) => (
          <TabPanelComponents.ContentWrapper
            key={tab.tabId}
            isSelected={selectedTabId === tab.tabId}
          >
            {tab.content}
          </TabPanelComponents.ContentWrapper>
        ))}
      </ContentSection>
      {props.headerOnBottom && !props.dropdownOptions && (
        <TabPanelComponents.HeaderWrapper>
          <TabPanelComponents.TabHeader width={props.headerContent ? 'calc(100% - 120px)' : '100%'}>{visibleTabs}</TabPanelComponents.TabHeader>
          {props.headerContent}
        </TabPanelComponents.HeaderWrapper>
      )}
    </Root>
  );
};
