import styled from 'styled-components';
import Done from '@material-ui/icons/Done';
import React from 'react';
import { CrossIcon } from '../../../../../../icons/CrossIcon';

export const Container = styled.div`
    display flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
`;

export const Tick = styled(Done)<{disabled?: boolean}>`
  color: ${({ theme, disabled = false }) => (disabled ? theme.palette.secondary.secondary4 : theme.palette.success.main)};
`;

export const Action = styled.div<{disabled?: boolean}>`
  padding: 0 3px;
  margin: 5px 0;
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.secondary.secondary};
  width: 25px;
  border-right: 1px solid ${({ theme }) => theme.palette.secondary.secondary1};
`;

export const Cross = styled<typeof CrossIcon>((props) => (
  <CrossIcon height={25} width={25} {...props} />
))`
  transform: rotate(45deg);
  color: ${({ theme }) => theme.palette.accent.accentNegative1};
`;
