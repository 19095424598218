import React, { useEffect } from 'react';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format';
import styled from 'styled-components/macro';
import Decimal from 'decimal.js';
import { ContentWithTooltip } from './ContentWithTooltip';

// the input mode determines what type of keyboard is used on mobile
// on iphone, negative numbers can only be inputted on the text keyboard
// similarly, we need to specify whether the number can be decimal in order to input a dot
const getInputMode = (allowNegative?: boolean, decimalScale?: number) => {
  if (allowNegative) {
    return 'text';
  }
  if (decimalScale) {
    return 'decimal';
  }
  return 'numeric';
};

export const NumberInputStyled = styled(NumberFormat)<{ error?: string }>`
  height: 100%;
  color: inherit;
  appearance: none;
  width: 100%;
  display: flex;
  border: ${({ error, theme }) => (error ? `1px solid ${theme.palette.trading.tradingSell}` : 'none')};
  align-items: center;
  font-family: "Roboto Mono";
  font-size: 11px;
  font-weight: 550;
  padding-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  :focus {
    outline: none;
    box-shadow: none;
    border-bottom: ${({ theme }) => `2px solid ${theme.palette.accent.accentPositive}`};
  }
  :hover {
    border-bottom: ${({ theme, error }) => !error && `2px solid ${theme.palette.accent.accentPositive}`};
  }
`;

export const NumberInputWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  ${NumberInputStyled} {
    background-color: ${({ theme }) => theme.palette.primary.primary1};
  }
`;

export interface NumberInputOpts extends NumberFormatProps {
  onValueChange?: (values: NumberFormatValues, isCorrection?: boolean) => void;
  backgroundColor?: string;
  suppressErrorTooltip?: boolean;
  error?: string;
}

export const QuantityOptions: NumberFormatProps = {
  thousandSeparator: true,
  allowNegative: false,
};

export const sanitizeNumberValue = (value: number, decimalScale?: number) =>
  new Decimal(value).toDecimalPlaces(decimalScale).toNumber(); // eslint-disable-line

export const NumberInput: React.FC<NumberInputOpts> = ({
  children,
  name,
  value,
  onValueChange,
  defaultValue,
  disabled,
  decimalScale,
  allowNegative = false,
  suppressErrorTooltip = false,
  error,
  ...props
}) => {
  useEffect(() => {
    if (value && typeof value === 'number') {
      const sanitizedValue = sanitizeNumberValue(value, decimalScale);
      if (sanitizedValue !== value && !disabled && onValueChange) {
        const correctedEditingState = {
          floatValue: sanitizedValue,
          formattedValue: sanitizedValue.toString(),
          value: sanitizedValue.toString(),
        };
        onValueChange(correctedEditingState, true);
      }
    }
  }, [onValueChange, decimalScale, disabled, value]);

  return suppressErrorTooltip ? (
    <NumberInputWrapper>
      <NumberInputStyled
        inputMode={getInputMode(allowNegative, decimalScale)}
        value={value ?? ''}
        defaultValue={defaultValue}
        {...props}
        decimalScale={decimalScale}
        allowNegative={allowNegative}
        onValueChange={(e: NumberFormatValues) => {
          if (onValueChange && !disabled) {
            onValueChange(e);
          }
        }}
        disabled={disabled}
        error={error}
      />
    </NumberInputWrapper>
  ) : (
    <ContentWithTooltip openOnError tooltipText={error || ''}>
      <NumberInputWrapper>
        <NumberInputStyled
          inputMode={getInputMode(allowNegative, decimalScale)}
          value={value ?? ''}
          defaultValue={defaultValue}
          {...props}
          decimalScale={decimalScale}
          allowNegative={allowNegative}
          onValueChange={(e: NumberFormatValues) => {
            if (onValueChange && !disabled) {
              onValueChange(e);
            }
          }}
          disabled={disabled}
          error={error}
        />
      </NumberInputWrapper>
    </ContentWithTooltip>
  );
};
