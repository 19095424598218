import jwt from 'jsonwebtoken';

export const getTokenFromKeys = () => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const apiKey = params.get('apiKey') || localStorage.getItem('apiKey') as string;
  const secret = params.get('secret') || localStorage.getItem('secret') as string;
  if (!apiKey || !secret) return '';
  const payload = {
    iat: Date.now(),
    sub: apiKey,
  };
  const token = jwt.sign(payload, secret, { algorithm: 'HS256' });
  if (params.get('apiKey') && params.get('secret')) {
    localStorage.setItem('apiKey', apiKey);
    localStorage.setItem('secret', secret);
  }
  return token;
};

export const getToken = () => window.localStorage.getItem('access_token') || getTokenFromKeys();
