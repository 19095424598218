import { FileCopy } from '@material-ui/icons';
import React from 'react';
import { AlertIcon } from '../../../../icons/AlertIcon';
import { WarningIcon } from '../../../../icons/WarningIcon';
import { copyToClipboard } from '../../../../shared/helperFunctions/copyToClipboard';
import { OrderResponse } from '../../services/trade/sendEntry';
import {
  OrderEntryCloseButton,
  OrderEntryCorrelation,
  OrderEntryFailureReason,
  OrderEntryFormTransitionsWrapper,
  OrderEntrySummary,
  OrderEntryTransitionTitle,
} from '../styled';

interface Props {
  latestEntryMessage: OrderResponse;
  type: 'REJECTED' | 'FAILED'
  setIsOverlayActive: (isOverlayActive: boolean) => void;
}

const getMessage = (type: 'REJECTED' | 'FAILED', latestEntryMessage: OrderResponse) => {
  if (type === 'FAILED') {
    return 'Please review your order and try again.';
  }

  if (latestEntryMessage.type === 'BusinessMessageRejectMessage') {
    return latestEntryMessage.businessRejectReason;
  }
  return latestEntryMessage.message;
};

export const OrderEntryUnsuccessful: React.FunctionComponent<Props> = (props) => {
  const { latestEntryMessage } = props;
  return (<OrderEntryFormTransitionsWrapper>
    <OrderEntryTransitionTitle>
      {props.type === 'REJECTED' ? <WarningIcon /> : <AlertIcon />}
      {` ORDER ${props.type} `}
    </OrderEntryTransitionTitle>
    <hr />
    <OrderEntrySummary>
      {'Unable to place order:'}
    </OrderEntrySummary>
    <OrderEntryFailureReason>
      {getMessage(props.type, props.latestEntryMessage)}
    </OrderEntryFailureReason>
    <OrderEntryCorrelation>
      {latestEntryMessage.clOrdID}
      <FileCopy onClick={() => copyToClipboard(latestEntryMessage.clOrdID!)} />
    </OrderEntryCorrelation>
    <hr />
    <OrderEntryCloseButton
      onClick={() => props.setIsOverlayActive(false)}
    >
      CLOSE
      </OrderEntryCloseButton>

  </OrderEntryFormTransitionsWrapper>);
};
