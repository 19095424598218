import { bind } from '@react-rxjs/core';
import {
  defer,
  merge,
  Observable,
  Subject,
} from 'rxjs';
import {
  filter,
  map,
  scan,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { subscribeToMessageStream$ } from '../../../../shared/websocket/transport';
import {
  accountMap$,
  accountState$,
} from '../../../../shared/services/accountsService';
import { OrderResponse } from '../../../orderEntry/services/trade/sendEntry';
import { initialOpenOrderMap } from '../openOrders/openOrdersReducer';
import { orderHistoryReducer } from './orderHistoryReducer';

export const getOrders$ = () => defer(() => merge(
  subscribeToMessageStream$('ExecutionReport'),
  subscribeToMessageStream$('OrderReject'),
)) as Observable<OrderResponse>;

export const orderHistoryRequest$ = new Subject();

export const requestOrderHistory = () => {
  orderHistoryRequest$.next();
};

export const [useOrderHistory, orderHistoryState$] = bind(
  orderHistoryRequest$.pipe(
    switchMap(getOrders$),
    withLatestFrom(accountMap$),
    map(([orderResponse, accountMap]) => ({
      ...orderResponse,
      accountNumber:
        orderResponse.partyIDs
        && (accountMap.get(orderResponse.partyIDs[0])
          || orderResponse.partyIDs[0]),
    })),
    filter(
      (report) => report.type === 'OrderReject' || report.execType !== 'ORDER_STATUS',
    ),
    scan(orderHistoryReducer, initialOpenOrderMap),
  ),
  initialOpenOrderMap,
);

export const getHistoryWhenAccounts$ = accountState$.pipe(
  filter((accounts) => Boolean(accounts.accounts.length)),
  tap(() => {
    requestOrderHistory();
  }),
);
