import styled from 'styled-components';

export const SETTLEMENT_ROW_HEIGHT = 42;

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-self: stretch;
    justify-content: center;
`;

export const Row = styled.div<{offset: number}>`
    position: relative;
    left: ${({ offset }) => offset}px;
    box-sizing: border-box;
    height: ${SETTLEMENT_ROW_HEIGHT}px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D4D8DB;
    &:last-child {
        border-bottom: 1px solid transparent;
    }
`;

export const AssetDetails = styled.div`
    font-family: 'Roboto Mono';
    padding-left: 69px;
`;
