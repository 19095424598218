import { MenuItem, Select as MuiSelect } from '@material-ui/core';
import styled, { DefaultTheme, createGlobalStyle } from 'styled-components/macro';
import { ConnectionStatus } from '../websocket/connectionStatus';

export const TitleWrapper = styled.div`
  display: inline-block;
  font-size: 11px;
  padding: 10px;
  font-weight: 550;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
`;

export const MenuItemStyled = styled(MenuItem) <{ selected?: boolean, disabled?: boolean }>`
  background-color: ${({ theme, selected }) => (selected ? theme.palette.primary.primary4 : theme.palette.primary.primary)};
  color: ${({ theme, selected }) => (selected
    ? theme.palette.primary.primary4
    : theme.palette.secondary.secondary)};
  font-size: 11px;
  font-style: ${({ disabled }) => (disabled && 'italic')};
  :hover {
    background-color: ${({ theme }) => theme.palette.primary.primary2};
  }
  &.MuiListItem-root.Mui-focusVisible {
    color: ${({ theme }) => theme.palette.accent.accentPositive2};
  }
`;

// The media query for HTML is a workaround for iphone landscape mode
// the address bar height is included in height 100%,
// so we dynamically set the height to window.innerHeight.
export const GlobalStyle = createGlobalStyle<{ windowHeight: number, isMobile: boolean }>`
  html, body, #root {
    height: 100%;
  }
  html {
    @media screen and (orientation:landscape)
      and (min-device-width: 319px)
      and (max-device-width: 480px) {
        position: fixed;
        height:  ${({ windowHeight }) => windowHeight}px;
        width: 100%;
      }
  }
  body {
    background-color: ${({ theme }) => theme.palette.primary.main};
    overflow: ${({ isMobile }) => (isMobile ? 'hidden' : 'visible')}
  }
`;

export const PrimaryButton = styled.button`
    :hover {
    filter: ${({ disabled }) => !disabled && 'brightness(1.3)'};
    }
    :focus {
    filter: ${({ disabled }) => !disabled && 'brightness(1.3)'};
    }
    :active {
      opacity: 0.7;
    }
  }
`;

export const PrimaryDivButton = styled.div<{ disabled?: boolean }>`
    :hover {
    filter: ${({ disabled }) => !disabled && 'brightness(1.3)'};
    }
    :focus {
    filter: ${({ disabled }) => !disabled && 'brightness(1.3)'};
    }
    :active {
      opacity: 0.7;
    }
  }`;

const getCircleColor = (theme: DefaultTheme, connectionStatus: ConnectionStatus): string => {
  switch (connectionStatus) {
    case ConnectionStatus.AUTHENTICATED:
      return theme.palette.accent.accentPrimary;
    case ConnectionStatus.OPEN:
      return theme.palette.accent.accentPrimary;
    case ConnectionStatus.CONNECTING:
      return theme.palette.accent.accentBeware2;
    default:
      return theme.palette.accent.accentNegative;
  }
};

// For indicating connectivity status
export const StatusCircle = styled.div<{ connectionStatus: ConnectionStatus }>`
  height: 9px;
  width: 9px;
  border-radius: 50%;
  margin-right: 6px;
  background-color: ${({ theme, connectionStatus }) => getCircleColor(theme, connectionStatus)};
`;

export const Select = styled(MuiSelect)<{ errorfield?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.primary.primary1};
  max-height: 25px;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  font-size: 11px;
  padding-left: 10px;
  font-weight: 550;

  border: ${({ errorfield, theme }) => (errorfield ? `1px solid ${theme.palette.trading.tradingSell}` : 'none')};

  &.MuiInput-root {
    &, &.Mui-disabled {
      &:before {
          border-color:  transparent;
      }
    }

    &:hover, &:active, &.Mui-focused {
      &:not(.Mui-disabled):before {
        border-bottom:  2px solid ${({ theme, errorfield }) => (errorfield ? 'transparent' : theme.palette.accent.accentPositive)};
      }
    }

    &:after {
      transform: scaleX(0);
    }
  }

  .MuiSelect-select.Mui-disabled {
    color: ${({ theme }) => theme.palette.secondary.secondary};
  }

  .MuiSelect-icon {
    color: ${({ theme }) => theme.palette.secondary.secondary};
  }
`;
