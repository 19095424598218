export enum ActionTypes {
  CREATE = 'create',
  UPDATE = 'update',
  REMOVE = 'remove'
}

export enum ExecutionType {
  TRADE = 'TRADE',
  CANCELLED = 'CANCELED',
}

export enum OrderStatus {
  FILLED = 'FILLED',
  PARTIALLY_FILLED = 'PARTIALLY_FILLED',
  CANCELED = 'CANCELED',
  ERROR_MESSAGE = 'ERROR_MESSAGE',
  REJECTED = 'REJECTED'
}
