import { shareLatest } from '@react-rxjs/core';
import { defer, Subject } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Direction } from '../../../../shared/Direction';
import { correlationId } from '../../../../shared/helperFunctions/correlationId';
import { send } from '../../../../shared/websocket/transport';
import { accountState$ } from '../../../../shared/services/accountsService';
import { ExecutionReport } from './openOrdersReducer';
import { openOrderState$ } from './openOrdersService';
import { userPrefsState$ } from '../../../navbar/services/userPrefsService';

interface OrderCancelRequest {
  correlation: string;
  type: 'CancelLimitOrderSingleRequest' | 'CancelStopLimitOrderSingleRequest';
  partyID: string;
  clOrdID: string;
  origClOrdID: string;
  orderID: string;
  currency: string;
  side: Direction;
  symbol: string;
  senderLocationId?: string;
  senderSubId?: string;
}

interface OrderCancelAllRequest {
  correlation: string;
  type: 'CancelAllOrdersRequest';
  partyID: string;
}

export const convertOrderToCancelRequest = (
  order: ExecutionReport,
  traderId?: string,
): OrderCancelRequest => ({
  correlation: correlationId(),
  type:
    order.ordType === 'LIMIT'
      ? 'CancelLimitOrderSingleRequest'
      : 'CancelStopLimitOrderSingleRequest',
  partyID: order.partyIDs[0],
  clOrdID: order.clOrdID,
  origClOrdID: order.clOrdID,
  orderID: order.orderID,
  currency: order.currency,
  side: order.side,
  symbol: order.symbol,
  senderLocationId: order?.targetLocationId,
  senderSubId: traderId,
});

export const orderCancel$ = new Subject<ExecutionReport>();

export const submitOrderCancel = (order: ExecutionReport) => {
  orderCancel$.next(order);
};

export const sendOrderCancel$ = (order: ExecutionReport, traderId?: string) => defer(() => {
  const cancelRequest = convertOrderToCancelRequest(order, traderId);
  return send(cancelRequest);
});

orderCancel$
  .pipe(
    withLatestFrom(userPrefsState$),
    switchMap(([order, userPrefs]) => {
      const traderId = userPrefs?.traderId;
      return sendOrderCancel$(order, traderId);
    }),
    shareLatest(),
  )
  .subscribe();

export const convertOrderToCancelAllRequest = (
  partyId: string,
): OrderCancelAllRequest => ({
  correlation: correlationId(),
  type: 'CancelAllOrdersRequest',
  partyID: partyId,
});

export const orderCancelAll$ = new Subject();

export const submitOrderCancelAll = () => {
  orderCancelAll$.next();
};

export const sendOrderCancelAll = (partyId: string) => {
  const cancelRequest = convertOrderToCancelAllRequest(partyId);
  return send(cancelRequest);
};

orderCancelAll$
  .pipe(
    withLatestFrom(accountState$),
    map(([, accountState]) => {
      accountState.accounts.forEach((account) => {
        if (account.fix_ids && account.fix_ids[0]) {
          sendOrderCancelAll(account.fix_ids[0]);
        }
      });
    }),
    shareLatest(),
  )
  .subscribe();

export const buyCancelAll$ = new Subject();

export const submitBuyCancelAll = () => {
  buyCancelAll$.next();
};

buyCancelAll$
  .pipe(
    withLatestFrom(openOrderState$),
    map(([, currentOpenOrder]) => {
      currentOpenOrder.valueSeq().forEach((order) => {
        if (order.side === Direction.Buy) {
          submitOrderCancel(order);
        }
      });
    }),
  )
  .subscribe();

export const sellCancelAll$ = new Subject();

export const submitSellCancelAll = () => {
  sellCancelAll$.next();
};

sellCancelAll$
  .pipe(
    withLatestFrom(openOrderState$),
    map(([, currentOpenOrder]) => {
      currentOpenOrder.valueSeq().forEach((order) => {
        if (order.side === Direction.Sell) {
          submitOrderCancel(order);
        }
      });
    }),
  )
  .subscribe();
