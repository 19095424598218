import { bind } from '@react-rxjs/core';
import { createListener, mergeWithKey, selfDependant } from '@react-rxjs/utils';
import {
  defer, merge, Observable,
} from 'rxjs';
import {
  filter, map, scan, withLatestFrom,
} from 'rxjs/operators';
import { subscribeToMessageStream$ } from '../../../../shared/websocket/transport';
import { MessageType } from '../../../../shared/websocket/constants';
import { ExecutionReport } from '../../../orderGridsTabPanel/services/openOrders/openOrdersReducer';
import {
  isMarketOrderType, getOrderEntryRequest$, OrderResponse,
} from '../../services/trade/sendEntry';
// eslint-disable-next-line
import { INITIAL_STATE, marketOrderSummaryReducer, MarketOrderSummaryState } from './marketOrderExecutionSummaryReducer';
import { ActionTypes, OrderStatus } from './constants';
import { isNotNil } from '../../../../shared/helperFunctions/typeHelpers';

const isOrderSuccessful = (report: ExecutionReport) => report.ordStatus !== OrderStatus.REJECTED;

const [connectedMarketState$, connectMarketState] = selfDependant<MarketOrderSummaryState>();

const filterReportExistsInMarketOrderSummaryState = () => (
  <T extends ExecutionReport | OrderResponse>(source: Observable<T>) => source.pipe(
    withLatestFrom(connectedMarketState$),
    filter<[T, MarketOrderSummaryState]>(
      ([report, marketSummaryState]) => (
        isNotNil(report.clOrdID) && marketSummaryState.has(report.clOrdID)
      ),
    ),
    map(([report]) => report),
  ));

const marketOrderEntryRequest$ = defer(() => getOrderEntryRequest$().pipe(
  filter(({ ordType }) => isMarketOrderType(ordType)),
));

const executionReport$ = defer(
  () => subscribeToMessageStream$<ExecutionReport>(MessageType.EXECUTION_REPORT),
);

const marketOrderReport$ = executionReport$.pipe(
  filter(({ ordType }) => (ordType ? isMarketOrderType(ordType) : false)),
);

const marketOrderSuccessfulUpdateReport$ = marketOrderReport$.pipe(
  // filterReportExistsInMarketOrderSummaryState(),
  filter(isOrderSuccessful),
);

const marketOrderFailedUpdateReport$ = defer(() => merge(
  subscribeToMessageStream$<OrderResponse>(MessageType.ORDER_REJECT),
  subscribeToMessageStream$<OrderResponse>(MessageType.ERROR_MESSAGE),
));

export const [removeMarketOrder$, onRemoveMarketOrder] = createListener<string>();

export const marketOrderActions$ = mergeWithKey({
  [ActionTypes.CREATE]: marketOrderEntryRequest$,
  [ActionTypes.UPDATE]: marketOrderSuccessfulUpdateReport$,
  [ActionTypes.REMOVE]: merge(
    removeMarketOrder$,
    marketOrderFailedUpdateReport$.pipe(
      filterReportExistsInMarketOrderSummaryState(),
      map((report) => report.clOrdID),
      filter(isNotNil),
    ),
  ),
});

export type MarketOrderActions$ = typeof marketOrderActions$;

const marketOrderSummaryState$ = marketOrderActions$
  .pipe(
    scan(marketOrderSummaryReducer, INITIAL_STATE),
  )
  .pipe(connectMarketState());

const completedMarketOrder$ = marketOrderSummaryState$.pipe(
  map((stateMap) => [...stateMap.values()].find((_) => _.isComplete)),
);

export const [useMarketOrderSummary, marketOrderSummary$] = bind(
  completedMarketOrder$,
  undefined,
);
