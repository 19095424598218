import React, { useEffect } from 'react';
import { FormikProps } from 'formik';

import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ContentWithTooltipIcon } from '../../../shared/components/ContentWithTooltip';
import { NumberInput, QuantityOptions } from '../../../shared/components/NumberInput';
import { Direction } from '../../../shared/Direction';
import { correlationId } from '../../../shared/helperFunctions/correlationId';
import { ProductValue } from '../../../shared/ProductValue';
import { PriceSelectType, setSelectedPrice, useSelectedPrice } from '../../../shared/services/selectedPriceService';
import { useReviewOrder } from '../../navbar/services/userPrefsService';
import { OrderEntryOrderReviewPopup } from '../popups/OrderEntryOrderReviewPopup';
import { BlockEntryFormState, BlockSide, BlockTradeEntryResponse } from '../services/block/blockTradeEntryService';
import {
  BlockOrderEntryFormWrapper, OrderEntryButtonsWrapper,
  OrderEntryClearButton,
  OrderEntryDirectionRow,
  OrderEntryFormCurrency,
  OrderEntryFormName,
  OrderEntryFormQuantitiyInput,
  OrderEntryFormRow,
  OrderEntryFormRowsWrapper,
  OrderEntrySelect,
  OrderEntrySubmitButton,
  OrderEntryText,
} from '../styled';
import { BlockEntryDateField } from './BlockEntryDateField';
import { checkBlockSideValue } from './blockOrderEntryValidation';
import { MenuItemStyled } from '../../../shared/style/styled';

const NEGOTIATED_TIME_TOOLTIP = 'Time at which the block trade was negotiated.';
const CLEARING_MEMBER_ID_TOOLTIP = 'Clearing Member Id';
const EXCHANGE_MEMBER_ID_TOOLTIP = 'Exchange Member Id';

interface Props {
  isOverlayActive: boolean;
  isSelected: boolean;
  market: ProductValue;
  formikProps: FormikProps<BlockEntryFormState>;
  submitButtonAlreadyClicked: boolean,
  setSubmitButtonAlreadyClicked: (clicked: boolean) => void;
  lastUsedCorrelation: string;
  latestEntryResponse: BlockTradeEntryResponse | null;
  setFormNeedsToBeReset: (reset: boolean) => void;
  formNeedsToBeReset: boolean;
}

export const getEmptyBlockSide = (): BlockSide => ({
  accountLabel: '',
  clOrdId: '',
  customerAccountRef: '',
  cti: '',
  origin: '',
  clearingMemberId: '',
  exchangeMemberId: '',
});

const CTIOptions = ['1', '2', '3', '4'];
const OriginOptions = [
  { text: 'Customer', value: '1' },
  { text: 'House', value: '2' },
];

const CTITooltipSection = styled.div`
  margin: 5px 0;
`;

const CTITooltip = () => (
  <div>
    <CTITooltipSection>
      1 - Transactions initiated and executed by an individual TPH for the
      TPH's own account, for an account the TPH controls, or for the account
      in which the TPH has an ownership or financial interest.
    </CTITooltipSection>
    <CTITooltipSection>
      2 - Transactions executed for the proprietary account of a clearing
      member or non-clearing member TPH.
    </CTITooltipSection>
    <CTITooltipSection>
      3 - Transactions where an individual TPH or authorized trader executes
      for the personal account of another individual TPH, for an account the
      other individual TPH controls or for an account in which the other
      individual TPH has an ownership or financial interest.
    </CTITooltipSection>
    <CTITooltipSection>
      4 - Any transaction not meeting the definition of CTI 1, 2 or 3. (These
      should be non-TPH customer transactions)
    </CTITooltipSection>
  </div>
);

export const BlockOrderEntryFormComponent: React.FunctionComponent<Props> = (props) => {
  const {
    market, submitButtonAlreadyClicked, setSubmitButtonAlreadyClicked, isSelected,
  } = props;
  const {
    values, setFieldValue, resetForm, handleSubmit, errors, setFieldError,
  } = props.formikProps;

  const reviewOrder = useReviewOrder();
  const selectedPrice = useSelectedPrice();

  useEffect(() => {
    if (selectedPrice && isSelected && selectedPrice.type === PriceSelectType.PRICE) {
      setFieldValue('price', selectedPrice.price);
      setSelectedPrice(undefined);
    }
  }, [selectedPrice, setFieldValue, isSelected]);

  useEffect(() => {
    if (props.formNeedsToBeReset
      && props.latestEntryResponse) {
      if (props.latestEntryResponse?.state === 'accepted') {
        resetForm({
          values: {
            ...values,
            price: undefined,
            quantity: undefined,
            negotiatedTime: undefined,
            senderSubId: '',
          },
        });
      }
      props.setFormNeedsToBeReset(false);
    }
  }, [props,
    props.lastUsedCorrelation,
    props.latestEntryResponse,
    resetForm,
    values]);

  const isFuturesContract = market.type === 'FUT';
  const formDisabled = !values.price
    || !values.quantity || checkBlockSideValue(values, isFuturesContract);

  return (
    (<>
      <BlockOrderEntryFormWrapper isOverlayActive={props.isOverlayActive}>
        <OrderEntryDirectionRow>
        </OrderEntryDirectionRow>
        <OrderEntryFormRowsWrapper>

          <OrderEntryFormRow>
            <OrderEntryFormName>
              Quantity:
            </OrderEntryFormName>
            <OrderEntryFormQuantitiyInput controlled={false}>
              <NumberInput
                error={errors.quantity}
                value={values.quantity}
                {...QuantityOptions}
                onValueChange={(val) => {
                  setFieldValue('quantity', val.floatValue);
                  setFieldError('quantity', '');
                }} />
              {!isFuturesContract
                && <OrderEntryFormCurrency>{market.currency}</OrderEntryFormCurrency>}
            </OrderEntryFormQuantitiyInput>
          </OrderEntryFormRow>

          <OrderEntryFormRow>
            <OrderEntryFormName>
              Price:
      </OrderEntryFormName>
            <NumberInput
              error={errors.price}
              value={values.price}
              {...QuantityOptions}
              onValueChange={(val) => {
                setFieldValue('price', val.floatValue);
                setFieldError('price', '');
              }} />

          </OrderEntryFormRow>

          <OrderEntryFormRow>
            <OrderEntryFormName>
              Neg Time:
            </OrderEntryFormName>
            <ContentWithTooltipIcon tooltipText={NEGOTIATED_TIME_TOOLTIP}>
              <BlockEntryDateField
                errorField={errors.negotiatedTime}
                values={values}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
              />
            </ContentWithTooltipIcon>
          </OrderEntryFormRow>

          <OrderEntryFormRow>
            <OrderEntryFormName>
              Sender Sub Id:
            </OrderEntryFormName>
            <OrderEntryText
              title={errors.senderSubId}
              errorfield={errors.senderSubId}
              InputProps={{ disableUnderline: true }}
              value={values.senderSubId}
              onChange={(e) => {
                setFieldValue('senderSubId', e.target.value);
                setFieldError('senderSubId', '');
              }} />
          </OrderEntryFormRow>

          <div> Buyer Information</div>

          <OrderEntryFormRow>
            <OrderEntryFormName>
              Account:
            </OrderEntryFormName>
            <OrderEntryText
              title={errors.buySide?.accountLabel}
              errorfield={errors.buySide?.accountLabel}
              InputProps={{ disableUnderline: true }}
              value={values.buySide.accountLabel}
              onChange={(e) => {
                setFieldValue('buySide', { ...values.buySide, accountLabel: e.target.value });
                setFieldError('buySide.accountLabel', '');
              }} />
          </OrderEntryFormRow>

          <OrderEntryFormRow>
            <OrderEntryFormName>
              Client Ord Id:
            </OrderEntryFormName>
            <OrderEntryText
              title={errors.buySide?.clOrdId}
              errorfield={errors.buySide?.clOrdId}
              InputProps={{ disableUnderline: true }}
              value={values.buySide.clOrdId}
              onFocus={() => {
                if (!values.buySide.clOrdId) {
                  setFieldValue('buySide', { ...values.buySide, clOrdId: correlationId() });
                }
              }}
              onChange={(e) => {
                setFieldValue('buySide', { ...values.buySide, clOrdId: e.target.value });
                setFieldError('buySide.clOrdId', '');
              }} />
          </OrderEntryFormRow>

          {isFuturesContract && (<OrderEntryFormRow>
            <OrderEntryFormName>
              Origin:
            </OrderEntryFormName>
            <OrderEntrySelect
              value={values.buySide.origin}
              IconComponent={ExpandMoreIcon}
              MenuProps={{ MenuListProps: { disablePadding: true } }}
              disableUnderline
            >
              {OriginOptions.map((origin) => (
                <MenuItemStyled
                  key={origin.value}
                  value={origin.value}
                  onClick={() => {
                    setFieldValue('buySide', { ...values.buySide, origin: origin.value });
                    setFieldError('buySide.origin', '');
                  }}
                >
                  {origin.text}
                </MenuItemStyled>
              ))}
            </OrderEntrySelect>
          </OrderEntryFormRow>)}

          {isFuturesContract && (<OrderEntryFormRow>
            <OrderEntryFormName>
              CTI:
            </OrderEntryFormName>
            <ContentWithTooltipIcon tooltipText={<CTITooltip/>}>
              <OrderEntrySelect
                value={values.buySide.cti}
                IconComponent={ExpandMoreIcon}
                MenuProps={{ MenuListProps: { disablePadding: true } }}
                disableUnderline
              >
                {CTIOptions.map((cti) => (
                  <MenuItemStyled
                    key={cti}
                    value={cti}
                    onClick={() => {
                      setFieldValue('buySide', { ...values.buySide, cti });
                      setFieldError('buySide.cti', '');
                    }}
                  >
                    {cti}
                  </MenuItemStyled>
                ))}
              </OrderEntrySelect>
            </ContentWithTooltipIcon>
          </OrderEntryFormRow>)}

          <OrderEntryFormRow>
            <OrderEntryFormName>
              Cust Acct Ref:
            </OrderEntryFormName>
            <OrderEntryText
              placeholder={market.type !== 'FUT' ? '(Optional)' : undefined}
              value={values.buySide.customerAccountRef}
              onChange={(e) => {
                setFieldValue('buySide', { ...values.buySide, customerAccountRef: e.target.value });
              }} />
          </OrderEntryFormRow>

          {isFuturesContract && (<OrderEntryFormRow>
            <OrderEntryFormName>
              Clear Mem Id:
            </OrderEntryFormName>
            <ContentWithTooltipIcon tooltipText={CLEARING_MEMBER_ID_TOOLTIP}>
              <OrderEntryText
                value={values.buySide.clearingMemberId}
                onChange={(e) => {
                  setFieldValue('buySide', { ...values.buySide, clearingMemberId: e.target.value });
                }} />
            </ContentWithTooltipIcon>
          </OrderEntryFormRow>)}

          {isFuturesContract && (<OrderEntryFormRow>
            <OrderEntryFormName>
              Exch Mem Id:
            </OrderEntryFormName>
            <ContentWithTooltipIcon tooltipText={EXCHANGE_MEMBER_ID_TOOLTIP}>
              <OrderEntryText
                value={values.buySide.exchangeMemberId}
                onChange={(e) => {
                  setFieldValue('buySide', { ...values.buySide, exchangeMemberId: e.target.value });
                }} />
            </ContentWithTooltipIcon>
          </OrderEntryFormRow>)}

          <div> Seller Information</div>

          <OrderEntryFormRow>
            <OrderEntryFormName>
              Account:
            </OrderEntryFormName>
            <OrderEntryText
              title={errors.sellSide?.accountLabel}
              errorfield={errors.sellSide?.accountLabel}
              InputProps={{ disableUnderline: true }}
              value={values.sellSide.accountLabel}
              onChange={(e) => {
                setFieldValue('sellSide', { ...values.sellSide, accountLabel: e.target.value });
                setFieldError('sellSide.accountLabel', '');
              }} />
          </OrderEntryFormRow>

          <OrderEntryFormRow>
            <OrderEntryFormName>
              Client Ord Id:
            </OrderEntryFormName>
            <OrderEntryText
              title={errors.sellSide?.clOrdId}
              errorfield={errors.sellSide?.clOrdId}
              InputProps={{ disableUnderline: true }}
              value={values.sellSide.clOrdId}
              onFocus={() => {
                if (!values.sellSide.clOrdId) {
                  setFieldValue('sellSide', { ...values.sellSide, clOrdId: correlationId() });
                }
              }}
              onChange={(e) => {
                setFieldValue('sellSide', { ...values.sellSide, clOrdId: e.target.value });
                setFieldError('sellSide.clOrdId', '');
              }} />
          </OrderEntryFormRow>

          {isFuturesContract && (<OrderEntryFormRow>
            <OrderEntryFormName>
              Origin:
            </OrderEntryFormName>
            <OrderEntrySelect
              value={values.sellSide.origin}
              IconComponent={ExpandMoreIcon}
              MenuProps={{ MenuListProps: { disablePadding: true } }}
              disableUnderline
            >
              {OriginOptions.map((origin) => (
                <MenuItemStyled
                  key={origin.value}
                  value={origin.value}
                  onClick={() => {
                    setFieldValue('sellSide', { ...values.sellSide, origin: origin.value });
                    setFieldError('sellSide.origin', '');
                  }}
                >
                  {origin.text}
                </MenuItemStyled>
              ))}
            </OrderEntrySelect>
          </OrderEntryFormRow>)}

          {isFuturesContract && (<OrderEntryFormRow>
            <OrderEntryFormName>
              CTI:
            </OrderEntryFormName>
            <ContentWithTooltipIcon tooltipText={<CTITooltip/>}>
              <OrderEntrySelect
                value={values.sellSide.cti}
                IconComponent={ExpandMoreIcon}
                MenuProps={{ MenuListProps: { disablePadding: true } }}
                disableUnderline
              >
                {CTIOptions.map((cti) => (
                  <MenuItemStyled
                    key={cti}
                    value={cti}
                    onClick={() => {
                      setFieldValue('sellSide', { ...values.sellSide, cti });
                      setFieldError('sellSide.cti', '');
                    }}
                  >
                    {cti}
                  </MenuItemStyled>
                ))}
              </OrderEntrySelect>
            </ContentWithTooltipIcon>
          </OrderEntryFormRow>)}

          <OrderEntryFormRow>
            <OrderEntryFormName>
              Cust Acct Ref:
            </OrderEntryFormName>
            <OrderEntryText
              placeholder={market.type !== 'FUT' ? '(Optional)' : undefined}
              value={values.sellSide.customerAccountRef}
              onChange={(e) => {
                setFieldValue('sellSide', { ...values.sellSide, customerAccountRef: e.target.value });
              }} />
          </OrderEntryFormRow>

          {isFuturesContract && (<OrderEntryFormRow>
            <OrderEntryFormName>
              Clear Mem Id:
            </OrderEntryFormName>
            <ContentWithTooltipIcon tooltipText={CLEARING_MEMBER_ID_TOOLTIP}>
              <OrderEntryText
                value={values.sellSide.clearingMemberId}
                onChange={(e) => {
                  setFieldValue('sellSide', { ...values.sellSide, clearingMemberId: e.target.value });
                }} />
            </ContentWithTooltipIcon>
          </OrderEntryFormRow>)}

          {isFuturesContract && (<OrderEntryFormRow>
            <OrderEntryFormName>
              Exch Mem Id:
            </OrderEntryFormName>
            <ContentWithTooltipIcon tooltipText={EXCHANGE_MEMBER_ID_TOOLTIP}>
              <OrderEntryText
                value={values.sellSide.exchangeMemberId}
                onChange={(e) => {
                  setFieldValue('sellSide', { ...values.sellSide, exchangeMemberId: e.target.value });
                }} />
            </ContentWithTooltipIcon>
          </OrderEntryFormRow>)}

        </OrderEntryFormRowsWrapper>

        <OrderEntryButtonsWrapper>
          <OrderEntryClearButton type='button' onClick={(e) => {
            e.preventDefault();
            resetForm();
          }}>Clear</OrderEntryClearButton>
          <OrderEntrySubmitButton
            direction={Direction.Buy}
            type='button'
            disabled={formDisabled}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}>
            {reviewOrder ? 'Review & Submit' : 'Submit'}
          </OrderEntrySubmitButton>
        </OrderEntryButtonsWrapper>
      </BlockOrderEntryFormWrapper>
      {submitButtonAlreadyClicked
        && <OrderEntryOrderReviewPopup
          showPopup={setSubmitButtonAlreadyClicked}
          handleSubmit={() => handleSubmit()}
          isBlock={true}
          symbol={market.symbol}
        />}
    </>)
  );
};
