import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import styled from 'styled-components/macro';
import { PrimaryDivButton, StatusCircle } from '../../shared/style/styled';
import { Config } from '../../config/config';
import { MarketSelectorWrapper } from './MarketSelector/MarketSelector.styled';

export const NAVBAR_HEIGHT = Config.BANNER_TEXT !== 'none' ? 81 : 49;

export const NavBarWrapper = styled.div`
  display: flex;
  height: 49px;
  box-sizing: border-box;
  justify-content: space-between;
  border-bottom: 0.5px solid ${({ theme }) => theme.palette.primary.primary1};
`;

export const NavBarLeftWrapper = styled.div`
  display: grid;
  grid-template-columns: 80px auto auto auto;
  grid-column-gap: 2px;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 10px;
    margin-top: -10px;
  }
`;

export const NavBarRightWrapper = styled.div`
  display: grid;
  grid-template-columns: 105px 60px 40px;
  grid-column-gap: 15px;
  align-items: center;
  justify-content: center;
`;

export const NavBarLoggedOutRightWrapper = styled.div`
  display: grid;
  grid-template-columns: 130px;
  margin-right: 15px;
  grid-column-gap: 15px;
  align-items: center;
  justify-content: center;
`;

export const MemberPortalWrapper = styled(PrimaryDivButton)`
  display: flex;
  max-width: 105px;
  background-color: ${({ theme }) => theme.palette.accent.accentPrimary};
  color: ${({ theme }) => theme.palette.primary.primary};
  font-size: 11px;
  max-height: 26px;
  min-height: 26px;
  font-weight: 600;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :active {
    opacity: 0.7;
  }

  svg {
    display: flex;
  }
`;

export const LoginButtonWrapper = styled(PrimaryDivButton)`
  display: flex;
  max-width: 130px;
  background-color: transparent;
  border: solid 1px ${({ theme }) => theme.palette.accent.accentPrimary};
  color: ${({ theme }) => theme.palette.secondary.secondary};
  font-size: 12.5px;
  max-height: 26px;
  min-height: 26px;
  font-weight: 600;
  border-radius: 0px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 3px 5px;
  :active {
    opacity: 0.7;
  }

  svg {
    display: flex;
  }
`;

export const AvatarWrapper = styled(PrimaryDivButton)`
  display: flex;
  position: relative;
  grid-template-columns: 26px 5px;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-right: 40px;
  cursor: pointer;

  #avatar {
    width: 24px;
    height: 24px;
    font-size: 13px;
    background-color: ${({ theme }) => theme.palette.primary.primary5};
    color: ${({ theme }) => theme.palette.secondary.secondary};
  }

  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.palette.secondary.secondary};
  }

  :active {
    opacity: 0.7;
  }
`;

export const NavBarSecondRowWrapper = styled(NavBarWrapper)`
  justify-content: flex-start;

  ${MarketSelectorWrapper} {
    width: 33%;
    margin-right: 2px;

    #market-selector-form {
      min-width: 0;
      width: 100%;
    }
  }
`;

export const MenuConnectionStatusWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.secondary1};
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 11px;
  padding-left: 16px;
  color: ${({ theme }) => theme.palette.secondary.secondary5};
  border-top: ${({ theme }) => `1px solid ${theme.palette.primary.primary1}`};
`;

export const MenuNameAndEmail = styled.div`
  font-size: 11px;
  font-weight: 550;
  color: ${({ theme }) => theme.palette.primary.primary};
`;

export const MenuNameAndEmailWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.secondary1};
  padding: 10px 10px 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.primary5};

  ${MenuNameAndEmail} {
    padding-bottom: 5px;
  }
`;

export const AvatarStatusOverlay = styled(StatusCircle)`
  position: absolute;
  bottom: -2px;
  left: -4px;
  border: 1px solid ${({ theme }) => theme.palette.primary.primary};
`;

export const UserMenuItemStyled = styled(MenuItem)`
  background-color: ${({ theme }) => theme.palette.secondary.secondary1};
  color: ${({ theme }) => theme.palette.primary.primary};
  font-size: 11px;
  font-weight: 550;
  :hover {
    background-color: ${({ theme }) => theme.palette.secondary.secondary3};
  }
`;

export const UserMenuListItemTextStyled = styled(ListItemText)`
  font-family: Roboto;
  color: ${({ theme }) => theme.palette.primary.primary};
  font-size: 11px;
  font-weight: 550;
  margin-left: -20px;

  .MuiListItemText-primary {
    font-size: 11px;
  }
`;

export const UserMenuListItemIconStyled = styled(ListItemIcon)`
  font-size: 11px;
  color: ${({ theme }) => theme.palette.primary.primary};
  font-weight: 550;

  svg {
    height: 20px;
    width: 20px;
  }
`;
