import React, { useEffect, useState } from 'react';
import { GridApi } from 'ag-grid-community';
import { Grid } from '../../../shared/Grid/Grid';
import { openOrdersColDefs } from '../colDefs/openOrdersColDefs';
import { useOpenOrders } from '../services/openOrders/openOrdersService';
import { ExecutionReport } from '../services/openOrders/openOrdersReducer';
import {
  PriceSelectType,
  useSelectedPrice,
} from '../../../shared/services/selectedPriceService';

interface Props {
  isFilterHidden: boolean;
  changeNumber: (numberOfFilter: number) => void;
  gridAPI: React.MutableRefObject<GridApi | undefined>;
}

export const OpenOrdersGrid: React.FunctionComponent<Props> = (props) => {
  const openOrders = useOpenOrders();

  const selectedPrice = useSelectedPrice();
  const gridApi = props.gridAPI.current;

  const [isFilterHidden, setIsFilterHidden] = useState<boolean>(
    props.isFilterHidden,
  );

  useEffect(() => {
    if (
      gridApi
      && selectedPrice
      && selectedPrice.type === PriceSelectType.FILTER
    ) {
      const priceFilter = gridApi.getFilterInstance('price');
      priceFilter.setModel({
        type: 'equals',
        filter: selectedPrice.price.toString(),
      });
      const contractFilter = gridApi.getFilterInstance('contract');
      contractFilter.setModel({
        type: 'equals',
        filter: selectedPrice.market?.symbol,
      });

      gridApi.onFilterChanged();
      setIsFilterHidden(false);
    }
  }, [selectedPrice, gridApi]);

  useEffect(() => {
    setIsFilterHidden(props.isFilterHidden);
  }, [props.isFilterHidden]);

  return (
    <Grid
      columnDefs={openOrdersColDefs}
      rowData={openOrders?.valueSeq().toArray()}
      getRowNodeId={(row: ExecutionReport) => row.orderID}
      suppressCellSelection={true}
      isFilterHidden={isFilterHidden}
      onFilterChanged={(event) => props.changeNumber(Object.keys(event.api.getFilterModel()).length)
      }
      gridApiRef={props.gridAPI}
    ></Grid>
  );
};
