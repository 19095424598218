import { formatDateFromMilliseconds } from '../../../../../../shared/formatters';
import { GridColDef } from '../../../../../../shared/Grid/GridColDef';
import { settlementStatusFormatter } from '../../../helpers/settlementStatusFormatter';
import { SettlementRow } from '../../../types';

export const COL_SETTLEMENT_DETAILS = 'settlementDetails';

export const columnsWithoutActions:GridColDef<SettlementRow>[] = [
  {
    headerName: 'Timestamp',
    colId: 'timestamp',
    resizable: true,
    valueGetter: (params) => params.data.time,
    valueFormatter: (params) => params.value && formatDateFromMilliseconds(params.value),
    sortable: true,
    sort: 'desc',
  },
  {
    headerName: 'From Account',
    colId: 'fromAccount',
    resizable: true,
    sortable: true,
    valueGetter: (params) => params.data.from_account_label,
  },
  {
    headerName: 'To Account',
    colId: 'toAccount',
    resizable: true,
    sortable: true,
    valueGetter: (params) => params.data.to_account_label,
  },
  {
    headerName: 'Settlement Details',
    colId: COL_SETTLEMENT_DETAILS,
    resizable: true,
    cellRenderer: 'detailsExpanderCellRenderer',
    valueGetter: (params) => params.data,
  },
  {
    headerName: 'Status',
    colId: 'status',
    resizable: true,
    sortable: true,
    valueFormatter: (params) => settlementStatusFormatter(params.data),
    valueGetter: (params) => params.data.status,
  },
  {
    headerName: 'Comments',
    colId: 'comment',
    resizable: true,
    valueGetter: (params) => params.data.text,
  },
  {
    headerName: 'Submitter',
    colId: 'submitter',
    resizable: true,
    sortable: true,
    valueGetter: (params) => params.data.submitter,
  },
  {
    headerName: 'Validator',
    colId: 'validator',
    resizable: true,
    sortable: true,
    valueGetter: (params) => params.data.to_account_validator || params.data.from_account_validator || '',
  },
  {
    headerName: 'Id',
    colId: 'id',
    resizable: true,
    valueGetter: (params) => params.data.id,
  },
];

export const columnsWithActions:GridColDef<SettlementRow>[] = [
  {
    headerName: 'Actions',
    colId: 'actions',
    width: 90,
    valueGetter: (params) => params.data,
    cellStyle: { paddingLeft: '7px' },
    cellRenderer: 'actionCellRenderer',
  },
  ...columnsWithoutActions,
];
