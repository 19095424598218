import { AuthStatus } from '../services/authStatusService';
// eslint-disable-next-line import/no-cycle
import { ConnectionStatus } from '../websocket/connectionStatus';

export const isWebsocketOpen = (status: ConnectionStatus) => (
  status === ConnectionStatus.AUTHENTICATED || status === ConnectionStatus.OPEN
);

export const isWebsocketReady = (connectionStatus: ConnectionStatus, authStatus: AuthStatus) => (
  authStatus === AuthStatus.AUTHENTICATED && connectionStatus === ConnectionStatus.AUTHENTICATED
) || (
  authStatus === AuthStatus.UNAUTHENTICATED && connectionStatus === ConnectionStatus.OPEN
);
