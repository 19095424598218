import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export const FilterSearchPredicateInput = styled(TextField)`
  background-color: ${({ theme }) => theme.palette.primary.primary1};
  display: flex;
  input {
    color: ${({ theme }) => theme.palette.secondary.secondary};
    font-size: 11px;
    padding-left: 10px;
  }
`;
