import { RowHeightParams } from '../../../../../../../../../types/agGrid.d';
import { SettlementRow } from '../../../../../../types';
import { SETTLEMENT_ROW_HEIGHT } from '../SettlementDetailsPanel.styled';
import { isDetailsNode } from './isDetailsNode';

export const getRowHeight = (params: RowHeightParams<SettlementRow>) => {
  if (isDetailsNode(params.node)) {
    return params.data.settlement.length * SETTLEMENT_ROW_HEIGHT;
  }
  return undefined;
};
