import React from 'react';
import { OrderBook } from './OrderBook';
import { OrderBookTop } from './OrderBookTop';

interface Props {
  isTopView?: boolean;
}

export const OrderBookContainer: React.FC<Props> = ({ isTopView }) => (isTopView ? <OrderBookTop />
  : <OrderBook />);
