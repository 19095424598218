import { FileCopy } from '@material-ui/icons';
import React from 'react';
import bigInt from 'big-integer';
import { WarningIcon } from '../../../../icons/WarningIcon';
import { copyToClipboard } from '../../../../shared/helperFunctions/copyToClipboard';
import { InstructionResponse } from '../../services/algos/InstructionResponse';
import {
  OrderEntryCloseButton,
  OrderEntryCorrelation,
  OrderEntryFailureReason,
  OrderEntryFormTransitionsWrapper,
  OrderEntrySummary,
  OrderEntryTransitionTitle,
} from '../styled';

interface Props {
  latestEntryMessage: InstructionResponse;
  setIsOverlayActive: (isOverlayActive: boolean) => void;
}

export const AlgoOrderEntryUnsuccessful: React.FunctionComponent<Props> = (props) => {
  const { latestEntryMessage } = props;
  return (<OrderEntryFormTransitionsWrapper>
    <OrderEntryTransitionTitle>
      {<WarningIcon />}
      {' ORDER REJECTED '}
    </OrderEntryTransitionTitle>
    <hr />
    <OrderEntrySummary>
      {'Unable to place order:'}
    </OrderEntrySummary>
    <OrderEntryFailureReason>
      {(latestEntryMessage.error) || (latestEntryMessage.message) || 'Invalid Request'}
    </OrderEntryFailureReason>
    {latestEntryMessage?.responseID && <OrderEntryCorrelation>
      {bigInt(latestEntryMessage.responseID).toString()}
      <FileCopy onClick={() => copyToClipboard(
        bigInt(latestEntryMessage.responseID)!.toString(),
      )} />
    </OrderEntryCorrelation>}
    <hr />
    <OrderEntryCloseButton
      onClick={() => props.setIsOverlayActive(false)}
    >
      CLOSE
      </OrderEntryCloseButton>

  </OrderEntryFormTransitionsWrapper>);
};
