import { shareLatest } from '@react-rxjs/core';
import { createListener } from '@react-rxjs/utils';
import { merge, Observable, Subject } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  scan,
  startWith,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Config } from '../../../config/config';
import { CURRENCYPREFIX } from '../../../shared/formatters';
import { getFetchClearing$ } from '../../../shared/services/fetchService';
import { subscribeToMessageStream$ } from '../../../shared/websocket/transport';
import {
  AccountNumberMap,
  accountState$,
  emarketAccountMap$,
} from '../../../shared/services/accountsService';
import { executionReportWithDateAndAccountNumber$ } from '../../orderGridsTabPanel/services/openOrders/openOrdersService';
import { isOrderStatusExecutionReport } from '../../orderGridsTabPanel/services/openOrders/openOrdersReducer';

const COLLATERAL_INQUIRY_ACK = 'CollateralInquiryAck';
const COLLATERAL_REPORT = 'CollateralReport';

export interface RestBalanceInfo {
  [assetType: string]: {
    value: number;
    closingPrice: number;
  };
}

interface AvailableBalance {
  availableBalance: number;
  availableBalanceCurrency: string;
}

interface TotalBalance {
  totalBalance: number;
  totalBalanceCurrency: string;
}

interface UnsettledPnl {
  unsettledPnL: number;
  unsettledPnLCurrency: string;
}

interface CollateralInquiryAckMessage {
  type: typeof COLLATERAL_INQUIRY_ACK;
  correlation: string;
  transactionTime: string;
  account: string;
  availableBalanceData: AvailableBalance[];
  totalBalanceData: TotalBalance[];
  unsettledPnlData: UnsettledPnl[];
}

interface CollateralReportMessage {
  type: typeof COLLATERAL_REPORT;
  correlation: string;
  transactionTime: string;
  account: string;
  availableBalanceData: AvailableBalance[];
  totalBalanceData: TotalBalance[];
  unsettledPnlData: UnsettledPnl[];
}

export interface BalanceInfo {
  [assetType: string]: number;
}

export interface RestAccountBalance {
  [accountNumber: string]: RestBalanceInfo;
}
export interface AccountBalance {
  [accountNumber: string]: BalanceInfo;
}

const initialBalances$ = (subscribeToMessageStream$(
  COLLATERAL_INQUIRY_ACK,
) as unknown) as Observable<CollateralInquiryAckMessage>;

const balanceUpdates$ = (subscribeToMessageStream$(
  COLLATERAL_REPORT,
) as unknown) as Observable<CollateralReportMessage>;

export const [
  showBalanceBackground$,
  setShowBalanceBackground,
] = createListener<boolean>();

export const emptyAccountBalance: AccountBalance = {};
export const emptyRestAccountBalance: RestAccountBalance = {};

interface AccountBalanceResponse {
  account_id: string;
  balances: {
    asset_type: string;
    total_excess_deficit: string;
    closing_price: string;
  }[];
}

const convertAssetType = (assetType: string): string => (Config.APP_ENV !== 'PRODUCTION' && assetType[0] === CURRENCYPREFIX
  ? (assetType.slice(1) as string)
  : (assetType as string));

export const balanceResponseToMap = (
  msg: AccountBalanceResponse,
  accountMap: AccountNumberMap,
): RestAccountBalance => ({
  [accountMap.get(msg.account_id) || msg.account_id]: msg.balances.reduce(
    (acc, val) => {
      const newBalances = { ...acc };
      if (val.asset_type) {
        const assetType = convertAssetType(val.asset_type);
        const balance = newBalances[assetType];
        if (balance === undefined) {
          newBalances[assetType] = {
            value: Number(val.total_excess_deficit),
            closingPrice: Number(val.closing_price),
          };
        } else {
          newBalances[assetType] = {
            value: balance.value += Number(val.total_excess_deficit),
            closingPrice: Number(val.closing_price),
          };
        }
      }
      return newBalances;
    },
    {} as RestBalanceInfo,
  ),
});

export const convertRestBalanceClosingPrice = (
  response: RestAccountBalance,
) => {
  const accountBalance = {} as AccountBalance;
  Object.keys(response).forEach((account) => {
    const symbolValue = {} as BalanceInfo;
    Object.keys(response[account]).forEach((symbol) => {
      symbolValue[symbol] = response[account][symbol].closingPrice;
    });
    accountBalance[account] = symbolValue;
  });
  return accountBalance;
};

export const balanceRequest$ = new Subject<string>();

export const requestBalance = (accountId: string) => {
  balanceRequest$.next(accountId);
};

export const getBalancesWhenAccounts$ = accountState$.pipe(
  tap((accounts) => {
    accounts.accounts.forEach((account) => requestBalance(account.account_id));
  }),
);

const balanceCall$ = balanceRequest$.pipe(
  mergeMap((accountId) => getFetchClearing$({
    restCallName: 'balances',
    body: { account_id: accountId },
  })),
  shareLatest(),
);

export const balanceInfoRest$ = balanceCall$.pipe(
  filter((fetchResponse) => fetchResponse.error === false),
  withLatestFrom(emarketAccountMap$),
  map(([fetchResponse, accountMap]) => balanceResponseToMap(fetchResponse.response, accountMap)),
  scan((acc, val) => ({ ...acc, ...val }), emptyRestAccountBalance),
  startWith(emptyRestAccountBalance),
  shareLatest(),
);

export const balanceErrorRest$ = balanceCall$.pipe(
  filter((fetchResponse) => fetchResponse.error === true),
  map((fetchResponse) => fetchResponse.error),
  shareLatest(),
);

export const accountsBalances$ = merge(
  initialBalances$,
  balanceUpdates$,
  executionReportWithDateAndAccountNumber$,
).pipe(
  scan((accAccountBalance, currentCollateralMessage) => {
    const balances = currentCollateralMessage.availableBalanceData.reduce(
      (acc, val) => {
        const excludeFromBalance = currentCollateralMessage.type === 'ExecutionReport' && isOrderStatusExecutionReport(currentCollateralMessage);
        return excludeFromBalance
          ? acc
          : ({
            ...acc,
            [convertAssetType(val.availableBalanceCurrency)]: val.availableBalance,
          });
      },
      {} as BalanceInfo,
    );
    const newBalances = {
      ...accAccountBalance,
      [currentCollateralMessage.account]: {
        ...accAccountBalance[currentCollateralMessage.account],
        ...balances,
      },
    };
    return newBalances;
  }, {} as AccountBalance),
  startWith({} as AccountBalance),
);
