interface ApiRequestBase {
  isComplete: boolean;
}
interface ApiRequestPending extends ApiRequestBase {
  result?: never;
}

export interface ApiRequestComplete<T> extends ApiRequestBase {
  result: T;
}

export type ApiRequest<T> = ApiRequestPending | ApiRequestComplete<T>;

interface ApiBaseResponse {
  error: boolean;
  isComplete: boolean;
}

export interface ApiResponsePending extends ApiBaseResponse {
  isComplete: false;
  error: false;
  errorMessage?: never;
  response?: never;
}
export interface ApiResponseSuccess<T> extends ApiBaseResponse {
  error: false;
  errorMessage?: never;
  isComplete: true;
  response: T;
}

export interface ApiResponseFailure extends ApiBaseResponse {
  error: true;
  errorMessage: string;
  isComplete: true;
  response?: never;
}

export type ApiResponseComplete<T> = ApiResponseSuccess<T> | ApiResponseFailure;
export type ApiResponse<T> = ApiResponsePending | ApiResponseComplete<T>;

// Request DTOs
interface SettlementAssetAllocation {
  asset: string;
  amount: number;
}

export interface SettlementRequest {
  from_account_label: string;
  to_account_label: string;
  text: string;
  settlement: SettlementAssetAllocation[];
}

// Response DTOs
export interface SettlementResponse {
  from_account_label: string;
  id: string;
  report_date: string;
  settlement: Settlement[];
  status: SettlementStatus;
  submitter: string;
  text: string;
  time: string;
  to_account_label: string;
  to_account_validator? : string;
  from_account_validator? : string;
}
export interface Settlement {
  amount: string;
  asset_type: string;
}

export enum SettlementStatus {
  ALLEGED = 'alleged',
  CLEARED = 'cleared',
  REJECTED = 'rejected',
  ADMIN_CXL = 'admin_cxl',
  CONFIRMED_TO = 'confirmed_to',
  CONFIRMED_FROM = 'confirmed_from',
}

// **** UI Types
export enum String {
  EMPTY = '',
}

export type ValueOrEmptyString<T> = T | String.EMPTY;
export interface SettlementAsset {
  symbol: string;
  displayName: string;
}

export interface SettlementRow extends SettlementResponse {}

export interface SettlementRows {
  settlements: SettlementRow[] | undefined;
}

export enum SettlementConfirmationType {
  APPROVAL,
  REJECTION,
}
export interface SettlementConfirmationAction {
  settlement: SettlementRow, type: SettlementConfirmationType
}

export interface SettlementConfirmationRequest {
  account: string;
  settlement_request_id: string;
  type: SettlementConfirmationType;
}
