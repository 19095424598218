import {
  addSpaceToCapitol,
  formatDateFromMilliseconds,
  formatNumber,
  getExecutionOrderDateMilliseconds,
  removeUnderscore,
} from '../../../shared/formatters';
// import { normalDateComparator } from '../../../shared/comparators';
import { GridColDef } from '../../../shared/Grid/GridColDef';
import { OrderResponse } from '../../orderEntry/services/trade/sendEntry';
import { DirectionCell } from '../gridComponents/DirectionCell';

export const orderHistoryColDefs = [
  {
    headerName: 'TIME',
    colId: 'time',
    valueGetter: (params) => Number(params.data.historyID),
    valueFormatter: (params) => {
      if (
        params === undefined
        || params?.data === undefined
        || params?.value === undefined
      ) return '';

      if (params.value) {
        const dateInMilliseconds = getExecutionOrderDateMilliseconds(
          (params.data.type === 'OrderReject'
            ? params.data.rejectTime
            : params.data.transactTime)!,
        );
        const isNaN = Number.isNaN(dateInMilliseconds);
        return isNaN ? '' : formatDateFromMilliseconds(dateInMilliseconds);
      }
      return '';
    },
    width: 150,
    filter: 'agTextColumnFilter',
    sortable: true,
    sort: 'desc',
  } as GridColDef<OrderResponse>,
  {
    headerName: 'STATUS',
    colId: 'status',
    valueGetter: (params) => params.data.ordStatus,
    valueFormatter: (params) => params.value && removeUnderscore(params.value),
    width: 80,
    filter: 'agTextColumnFilter',
  } as GridColDef<OrderResponse>,
  {
    headerName: 'ORDER ID',
    colId: 'orderId',
    valueGetter: (params) => params.data.orderID || 'N/A',
    width: 110,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<OrderResponse>,
  {
    headerName: 'CONTRACT',
    colId: 'contract',
    valueGetter: (params) => params.data.symbol || 'N/A',
    width: 80,
    filter: 'agTextColumnFilter',
  } as GridColDef<OrderResponse>,
  {
    headerName: 'QTY WORKING',
    colId: 'qtyWorking',
    valueGetter: (params) => params.data.leavesQty || 'N/A',
    valueFormatter: (params) => params.value && formatNumber(params.value),
    cellClass: 'ag-grid-number-cell',
    width: 80,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<OrderResponse>,
  {
    headerName: 'SIDE',
    colId: 'side',
    valueGetter: (params) => params.data.side,
    width: 60,
    cellRendererFramework: DirectionCell,
    sortable: false,
    filter: 'agTextColumnFilter',
    filterValueGetter: (params) => params.data.side,
  } as GridColDef<OrderResponse>,
  {
    headerName: 'PRICE',
    colId: 'price',
    valueGetter: (params) => params.data.price || 'N/A',
    valueFormatter: (params) => params.value && formatNumber(params.value),
    cellClass: 'ag-grid-number-cell',
    width: 70,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<OrderResponse>,
  {
    headerName: 'AMOUNT',
    colId: 'cashOrderQty',
    valueGetter: (params) => params.data.cashOrderQty || 'N/A',
    valueFormatter: (params) => params.value && formatNumber(params.value),
    cellClass: 'ag-grid-number-cell',
    width: 70,
    hide: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<OrderResponse>,
  {
    headerName: 'TRADE QTY',
    colId: 'tradeQty',
    valueGetter: (params) => params.data.lastQty || 'N/A',
    valueFormatter: (params) => params.value && formatNumber(params.value),
    cellClass: 'ag-grid-number-cell',
    width: 80,
    hide: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<OrderResponse>,
  {
    headerName: 'TRADE PRICE',
    colId: 'tradePrice',
    valueGetter: (params) => params.data.lastPrice || 'N/A',
    valueFormatter: (params) => params.value && formatNumber(params.value),
    cellClass: 'ag-grid-number-cell',
    width: 80,
    hide: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<OrderResponse>,
  {
    headerName: 'CLORDID',
    colId: 'clOrdId',
    valueGetter: (params) => params.data.clOrdID || 'N/A',
    width: 190,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<OrderResponse>,
  {
    headerName: 'TYPE',
    colId: 'type',
    valueGetter: (params) => params.data.ordType || 'N/A',
    valueFormatter: (params) => params.value && removeUnderscore(params.value),
    width: 80,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<OrderResponse>,
  {
    headerName: 'ACCOUNT',
    colId: 'account',
    valueGetter: (params) => params.data.accountNumber || 'N/A',
    width: 80,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<OrderResponse>,
  {
    headerName: 'MESSAGE',
    colId: 'account',
    valueGetter: (params) => (params.data.type === 'OrderReject'
      ? params.data.message
      : params.data.text),
    width: 150,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<OrderResponse>,
  {
    headerName: 'STOP PRICE',
    colId: 'stopPrice',
    valueGetter: (params) => params.data.stopPrice || 'N/A',
    valueFormatter: (params) => params.value && formatNumber(params.value),
    cellClass: 'ag-grid-number-cell',
    width: 80,
    hide: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<OrderResponse>,
  {
    headerName: 'FEES',
    colId: 'fees',
    valueGetter: (params) => {
      if (params.data.type === 'ExecutionReport') {
        return params.data.execType === 'TRADE'
          ? params.data.commission
          : params.data.commCalculated;
      }
      return params.data.commCalculated || 'N/A';
    },
    valueFormatter: (params) => params.value && formatNumber(params.value),
    cellClass: 'ag-grid-number-cell',
    width: 60,
    hide: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<OrderResponse>,
  {
    headerName: 'EXP TIME',
    colId: 'expirationTime',
    valueGetter: (params) => (params.data.timeInForce === 'Day'
      ? 'Session'
      : params.data.timeInForce) || 'N/A',
    valueFormatter: (params) => params.value && addSpaceToCapitol(params.value),
    width: 80,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<OrderResponse>,
];
