import { bind, shareLatest } from '@react-rxjs/core';
import { createListener } from '@react-rxjs/utils';
import {
  defer,
  from,
  merge,
  Observable,
} from 'rxjs';
import {
  delay,
  filter,
  map,
  mergeMap,
  repeatWhen,
  scan,
  switchMap,
  take,
  takeWhile,
} from 'rxjs/operators';

import { getFetchClearing$ } from '../../../../shared/services/fetchService';
import { BlockTradeEntryResponse } from '../../../orderEntry/services/block/blockTradeEntryService';
import {
  BlockTradeRecord,
  blockTradeReducer,
  createInitialBlockTradeMap,
} from './blockTradesReducer';
import { Config } from '../../../../config/config';

export interface BlockTradesResponse {
  response: BlockTradeRecord[],
  error?: boolean;
}

const isBlockTrades = (
  input: any,
): input is BlockTradeRecord[] => {
  if (input.length > 0) return input[0].request_id !== undefined;
  return true;
};

// creates an observable that fetches block trades, extracting the array of trades
// if a string is provided, creates a filter for that request_id to go with the request
const getBlockTrades$ = ((requestId : string | null) => getFetchClearing$({
  restCallName: 'block_trade_requests',
  body: requestId ? {
    filter: [{
      attr: 'request_id',
      op: 'eq',
      value: requestId,
    }],
  } : '',
}).pipe(
  map((fetchResponse) => {
    if (fetchResponse.error || !isBlockTrades(fetchResponse.response.block_trade_requests)) {
      return {
        response: [],
        error: true,
      } as BlockTradesResponse;
    }
    return {
      response: fetchResponse.response.block_trade_requests,
      error: fetchResponse.error,
    };
  }),
));

// initial data fetch for all current block trade history
export const historicalBlockTradeCall$ = defer(
  () => getBlockTrades$(null),
).pipe(shareLatest());

const historicalBlockTradeData$ = historicalBlockTradeCall$.pipe(
  filter((block) => block.error === false),
  map((data) => data.response),
);

const historicalBlockTradeError$ = historicalBlockTradeCall$.pipe(
  filter((block) => block.error === true),
);

const [blockTradeRequest$, requestBlockTradeData] = createListener<string>();

export { requestBlockTradeData };

const blockTradeRequestCall$: Observable<BlockTradesResponse> = blockTradeRequest$.pipe(
  mergeMap((request_id) => getBlockTrades$(request_id)),
  shareLatest(),
);

const retryAcceptedTrade$ = (requestId: string) => getBlockTrades$(requestId).pipe(
  repeatWhen((obs) => obs.pipe(
    scan((acc) => acc + 1, 0),
    takeWhile((n) => n < Config.CLEARED_BLOCK_TRADES_MAX_RETRY),
    delay(Config.CLEARED_BLOCK_TRADES_RETRY_MS),
  )),
  map((r) => r.response.filter((t:BlockTradeEntryResponse) => t.state === 'cleared')),
  take(1),
);

const successfulBlockTradeRequestCall$ = blockTradeRequestCall$.pipe(
  filter((block) => block.error === false),
);

const blockTradeRequestError$ = blockTradeRequestCall$.pipe(
  filter((block) => block.error === true),
);

export const blockTradeError$ = merge(
  historicalBlockTradeError$,
  blockTradeRequestError$,
);

const clearedBlockTrades$ = successfulBlockTradeRequestCall$.pipe(
  map((r) => r.response.filter((t) => t.state === 'cleared')),
  filter((r) => r.length > 0),
);

const acceptedRetryBlockTrades$ = successfulBlockTradeRequestCall$.pipe(
  map((r) => r.response.filter((t) => t.state === 'accepted')),
  filter((r) => r.length > 0),
  delay(Config.CLEARED_BLOCK_TRADES_RETRY_MS),
  switchMap((r) => from(r).pipe(
    mergeMap(({ request_id }) => retryAcceptedTrade$(request_id)),
  )),
);

const allBlockTrades$: Observable<
BlockTradeRecord[]
> = successfulBlockTradeRequestCall$.pipe(
  map((data) => data.response),
);

export const [useBlockTrades, blockTradeState$] = bind(
  merge(historicalBlockTradeData$, allBlockTrades$, acceptedRetryBlockTrades$).pipe(
    scan(blockTradeReducer, createInitialBlockTradeMap()),
  ),
  createInitialBlockTradeMap(),
);

export const recentBlockTrade$ = merge(
  clearedBlockTrades$,
  acceptedRetryBlockTrades$,
).pipe(shareLatest());
