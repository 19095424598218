import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  getAccountLabel,
  UserAccountRaw,
} from '../../services/accountsService';
import { UserPermissions } from '../../services/userPermissionsService';
import { MenuItemStyled } from '../../style/styled';
import {
  AccountSelectorAutocomplete,
  AccountSelectorDropdownMenu,
} from './AccountSelector.styled';
import { InputStyled } from '../../../components/orderEntry/styled';
import { Nullable } from '../../types';

export interface AccountSelectorProps {
  userPermissions: UserPermissions;
  userAccounts: UserAccountRaw[];
  selectedAccount: Nullable<UserAccountRaw>;
  errorfield?: string;
  onAccountSelected: (account: Nullable<UserAccountRaw>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

const noop = () => {};

export const AccountSelector: React.FC<AccountSelectorProps> = ({
  userPermissions,
  userAccounts,
  selectedAccount,
  onAccountSelected,
  onBlur = noop,
  onFocus = noop,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');

  return (
    <AccountSelectorAutocomplete
      options={userAccounts}
      popupIcon={<ExpandMoreIcon />}
      PaperComponent={AccountSelectorDropdownMenu}
      openOnFocus
      getOptionSelected={(option) => option.account_number === selectedAccount?.account_number}
      onChange={(_, accountOption) => onAccountSelected(accountOption)}
      onInputChange={(_, v) => setSearchValue(v)}
      inputValue={searchValue}
      onFocus={() => {
        setSearchValue('');
        onFocus();
      }}
      onBlur={() => {
        setSearchValue(selectedAccount?.account_number || '');
        onBlur();
      }}
      value={selectedAccount}
      renderInput={(AutocompleteRenderInputParams) => {
        const {
          InputProps,
          InputLabelProps,
          ...rest
        } = AutocompleteRenderInputParams;
        return (
          <InputStyled
            {...rest}
            {...InputProps}
            placeholder={'Search...'}
            disableUnderline
          />
        );
      }}
      filterOptions={(options, state) => {
        const { inputValue } = state;
        if (!inputValue) {
          return options;
        }
        return options.filter((op) => getAccountLabel(
          userPermissions.spotPermissions,
          userPermissions.futuresPermissions,
          op.account_number,
            op?.name,
        )
          .toLowerCase()
          .includes(inputValue.toLowerCase()));
      }}
      getOptionLabel={(accountOption) => accountOption.account_number}
      renderOption={(accountOption) => (
        <MenuItemStyled
          key={accountOption.account_number}
          selected={
            accountOption.account_number === selectedAccount?.account_number
          }
        >
          {getAccountLabel(
            userPermissions.spotPermissions,
            userPermissions.futuresPermissions,
            accountOption.account_number,
            accountOption.name,
          )}
        </MenuItemStyled>
      )}
    />
  );
};
