import { tap, filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { algoSend, subscribeToAlgoMessageStream$ } from './transport';
import { correlationId } from '../helperFunctions/correlationId';
import { algoConnectionStatus$, setConnectionStatus } from './algoConnectionStatus';
import { getToken } from '../websocket/token';
import { ConnectionStatus, connectionStatusState$ } from '../websocket/connectionStatus';

export const algoLogin = () => {
  const token = getToken();
  algoSend({
    correlation: correlationId(),
    type: 'AuthenticationRequest',
    token,
  });
};

export const algoLoginWhenConnected$ = combineLatest([algoConnectionStatus$,
  connectionStatusState$])
  .pipe(
    tap(([algoStatus, status]) => {
      if (algoStatus === ConnectionStatus.OPEN && status === ConnectionStatus.AUTHENTICATED) {
        algoLogin();
      }
    }),
  );

interface AuthenticationResultMessage {
  type: 'AuthenticationResult',
  sucess: boolean,
}

export const algoUpdateStatusWhenAuthenticated$ = subscribeToAlgoMessageStream$('AuthenticationResult').pipe(
  filter((msg: any): msg is AuthenticationResultMessage => msg.success),
  tap(() => {
    setConnectionStatus(ConnectionStatus.AUTHENTICATED);
  }),
);
