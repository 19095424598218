import styled from 'styled-components/macro';

export const ThemedCheckbox = styled.input`
  -webkit-appearance: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.primary.primary1};
  border: 1px solid ${({ theme }) => theme.palette.primary.primary5};
  width: 12px;
  height: 12px;
  display: inline-block;
  position: relative;
  :active {
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
  }
  :focus {
    outline: none;
  }
  :disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  :checked {
    background-color: ${({ theme }) => theme.palette.accent.accentPrimary};
    border: 1px solid ${({ theme }) => theme.palette.accent.accentPrimary};
    color: ${({ theme }) => theme.palette.secondary.secondary1};
    :after {
      content: url('tick.svg');
      position: absolute;
      top: -5px;
      left: -6px;
      color: ${({ theme }) => theme.palette.primary.primary1};
      svg {
        color : black;
      }
    }
    :active {
      box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
    }
  }
`;
