import { bind } from '@react-rxjs/core';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Direction } from '../../../shared/Direction';
import { correlationId } from '../../../shared/helperFunctions/correlationId';
import { withSubscribe } from '../../../shared/helperFunctions/withSubscribe';
import { selectedMarket$ } from '../../../shared/services/selectedMarketService';
import { userPermissions$ } from '../../../shared/services/userPermissionsService';
import { userPrefsState$ } from '../../navbar/services/userPrefsService';
import { orderBook$ } from '../../orderBook/services/orderBookService';
import { selectedAccount$ } from '../../../shared/services/accountsService';
import {
  OrderEntryFormState,
  OrderResponse,
  submitOrderEntry,
} from '../services/trade/sendEntry';
import { OrderEntryFormComponent } from './OrderEntryFormComponent';
import { validateOrderEntryForm } from './orderEntryValidation';

interface Props {
  isOverlayActive: boolean;
  isSelected: boolean;
  setIsOrderLoading: (isOrderLoading: boolean) => void;
  setisOverlayActive: (overlayActive: boolean) => void;
  setLastUsedCorrelation: (correlation: string) => void;
  lastUsedCorrelation: string;
  latestEntryResponse: OrderResponse | null;
  spotPermissions: string[];
  futuresPermissions: string[];
}

const state$ = combineLatest([
  selectedAccount$,
  selectedMarket$,
  orderBook$,
  userPermissions$,
  userPrefsState$,
]).pipe(
  map(([selectedAccount, market, book, userPermissions, userPrefs]) => ({
    selectedAccount,
    market,
    book,
    userPermissions,
    userPrefs,
  })),
);

const [useOrderEntryFormState, orderEntryFormState$] = bind(state$);

const OrderEntryFormCom: React.FunctionComponent<Props> = (props) => {
  const {
    selectedAccount,
    market,
    book,
    userPermissions,
    userPrefs,
  } = useOrderEntryFormState();
  const [submitButtonAlreadyClicked, setSubmitButtonAlreadyClicked] = useState(
    false,
  );
  const [formNeedsToBeReset, setFormNeedsToBeReset] = useState(false);

  return market ? (
    <Formik<OrderEntryFormState>
      initialValues={{
        direction: Direction.Buy,
        account: selectedAccount!,
        orderType: 'LIMIT',
        timeInForce: 'GoodTillCancel',
        postOnly: 'N',
      }}
      validate={(values) => validateOrderEntryForm(values, market, userPermissions)
      }
      onSubmit={(values) => {
        if (values && market) {
          if (userPrefs.reviewOrder && !submitButtonAlreadyClicked) {
            setSubmitButtonAlreadyClicked(true);
          } else {
            const correlation = correlationId();
            submitOrderEntry({
              entry: values,
              product: market,
              correlation,
              userPrefs,
            });
            props.setLastUsedCorrelation(correlation);
            props.setIsOrderLoading(true);
            props.setisOverlayActive(true);
            setSubmitButtonAlreadyClicked(false);
            setFormNeedsToBeReset(true);
          }
        }
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => (
        <OrderEntryFormComponent
          formikProps={formikProps}
          isSelected={props.isSelected}
          setSubmitButtonAlreadyClicked={setSubmitButtonAlreadyClicked}
          submitButtonAlreadyClicked={submitButtonAlreadyClicked}
          market={market}
          book={book}
          isOverlayActive={props.isOverlayActive}
          lastUsedCorrelation={props.lastUsedCorrelation}
          latestEntryResponse={props.latestEntryResponse}
          setFormNeedsToBeReset={setFormNeedsToBeReset}
          formNeedsToBeReset={formNeedsToBeReset}
          spotPermissions={props.spotPermissions}
          futuresPermissions={props.futuresPermissions}
        />
      )}
    </Formik>
  ) : (
    <div />
  );
};

export const OrderEntryForm = withSubscribe(
  OrderEntryFormCom,
  orderEntryFormState$,
);
