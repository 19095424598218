import { bind } from '@react-rxjs/core';
import { createListener, mergeWithKey } from '@react-rxjs/utils';
import {
  combineLatest,
  defer,
  EMPTY,
  Observable,
  timer,
} from 'rxjs';
import {
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  scan,
  tap,
} from 'rxjs/operators';

import { batchUpdates } from '../../../batchUpdates';
import { Direction } from '../../../shared/Direction';
import { correlationId } from '../../../shared/helperFunctions/correlationId';
import {
  formatToUtcDateTime,
  getYesterdaysDate,
  toDateFromUtc,
} from '../../../shared/helperFunctions/dateFunctions';
import { withAuth } from '../../../shared/services/authStatusService';
import { getFetchHistory$ } from '../../../shared/services/fetchService';
import { send } from '../../../shared/websocket/transport';
import { MarketDataRestResponse } from '../../tradeHistory/tradeHistoryServiceHelpers';
import { incrementalUpdatesBySymbol$ } from './incrementalTradeMessages';
import { selectedSecurities$ } from './watchlistSelection';

export interface Trade {
  symbol: string;
  direction: Direction;
  price: number;
  quantity: number;
  time: Date;
}

const VOLUME_REFRESH_TIMER = 30000;

const getUpdates$ = (symbol: string): Observable<Trade[]> => defer(() => {
  const correlationForSymbol = correlationId();
  send({
    correlation: correlationForSymbol,
    type: 'MarketDataSubscribe',
    symbol,
    tradeOnly: true,
  });
  return incrementalUpdatesBySymbol$.pipe(
    exhaustMap((messageMap) => messageMap[symbol] || EMPTY),
    // from this point, observable emitting values for current symbol only
    filter((messageForSymbol) => messageForSymbol.correlation === correlationForSymbol),
    map((x) => x.trades.map((entry) => ({
      symbol,
      direction:
            entry.tickerType === 'PAID' ? Direction.Buy : Direction.Sell,
      price: entry.price,
      quantity: entry.size,
      time: toDateFromUtc(entry.transactTime),
    }))),
  );
});

const trimByDate = (trades: Trade[]) => {
  const minDate = new Date().getTime() - 24 * 60 * 60 * 1000;
  let nToRemove = 0;
  // eslint-disable-next-line
  for (let i = trades.length - 1; i > -1; i--) {
    if (trades[i].time.getTime() >= minDate) break;
    // eslint-disable-next-line
    nToRemove--;
  }
  return nToRemove === 0 ? trades : trades.slice(0, nToRemove);
};

const reconcileTrades = (tradesBeforeRest: Trade[], restTrades: Trade[]) => {
  if (restTrades.length === 0) return tradesBeforeRest;

  const mostRecentRestTime = restTrades[0].time.getTime();
  const nMissing = tradesBeforeRest.findIndex(
    (trade) => trade.time.getTime() >= mostRecentRestTime,
  );
  return nMissing > 0
    ? [...tradesBeforeRest.slice(0, nMissing), ...restTrades]
    : restTrades;
};

type SymbolErrorStates = {
  [key: string]: boolean
};

const [watchlistErrorStatus$, setWatchlistErrorStatus] = createListener<SymbolErrorStates>();

const getRestTradeHistory$ = (symbol: string) => {
  const params = new URLSearchParams({
    startDate: formatToUtcDateTime(getYesterdaysDate()),
    endDate: formatToUtcDateTime(new Date()),
    recordsPerPage: '10000000', // waiting for backend to be optimized
    symbol,
  });

  return getFetchHistory$({
    restCallName: 'getTrades',
    params,
  }).pipe(
    tap((fetchResponse) => setWatchlistErrorStatus({ [symbol]: fetchResponse.error })),
    map((fetchResponse) => {
      if (fetchResponse.error) {
        return [];
      }
      return (fetchResponse.response as MarketDataRestResponse).payload.map((entry) => ({
        symbol,
        direction: entry.tickerType === 'PAID' ? Direction.Buy : Direction.Sell,
        price: entry.price,
        quantity: entry.amount,
        time: toDateFromUtc(entry.transactTime),
      }));
    }),
  );
};

export const [, getTradeHistory$] = bind((symbol: string) => mergeWithKey({
  message: getUpdates$(symbol),
  restResponse: getRestTradeHistory$(symbol),
  time: timer(VOLUME_REFRESH_TIMER, VOLUME_REFRESH_TIMER),
}).pipe(
  withAuth(),
  scan<{type: string; payload: Trade[] | number}, Trade[]>((previous, event) => {
    const last24hourData = trimByDate(previous);
    switch (event.type) {
      case 'message':
        return [...event.payload as Trade[], ...last24hourData];
      case 'restResponse':
        return reconcileTrades(previous, event.payload as Trade[]);
      default:
        return last24hourData;
    }
  }, []),
  distinctUntilChanged(),
  batchUpdates(),
));

export const [useLatestPrice] = bind(
  (symbol: string) => getTradeHistory$(symbol).pipe(
    map((trades) => trades[0]?.price ?? null),
    distinctUntilChanged(),
  ),
  null,
);

export const [use24HourVolume] = bind(
  (symbol: string) => getTradeHistory$(symbol).pipe(
    map((trades) => trades.reduce((acc, trade) => acc + trade.quantity, 0)),
  ),
  0,
);

export const [use24HourChange] = bind(
  (symbol: string) => getTradeHistory$(symbol).pipe(
    map((trades) => (trades.length
      ? ((trades[0].price - trades[trades.length - 1].price) * 100)
            / trades[trades.length - 1].price
      : 0)),
  ),
  0,
);

const EMPTY_SYMBOL_ERROR_MAP: SymbolErrorStates = {};

const watchlistErrorMap$ = watchlistErrorStatus$.pipe(
  scan(
    (symbolToErrorMap, symbolErrorState) => ({ ...symbolToErrorMap, ...symbolErrorState }),
    EMPTY_SYMBOL_ERROR_MAP,
  ),
);

export const [useWatchlistError] = bind<boolean>(
  combineLatest([selectedSecurities$, watchlistErrorMap$]).pipe(
    map(([selectedSecurities, watchlistErrorMap]) => {
      const errorStates = selectedSecurities.reduce(
        (currentErrorMap, selectedSecurity) => {
          let symbolErrorState = false;
          if (watchlistErrorMap[selectedSecurity.symbol]) {
            symbolErrorState = watchlistErrorMap[selectedSecurity.symbol];
          } else if (selectedSecurity.symbol in watchlistErrorMap) {
            symbolErrorState = watchlistErrorMap[selectedSecurity.symbol];
          }
          return { ...currentErrorMap, [selectedSecurity.symbol]: symbolErrorState };
        }, EMPTY_SYMBOL_ERROR_MAP,
      );
      // if any of the symbols are in error state, then Watchlist shows error
      return Object.values(errorStates).some((error) => error);
    }),
  ), false,
);
