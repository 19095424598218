import React from 'react';
import { bind } from '@react-rxjs/core';
import Decimal from 'decimal.js';

import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { formatNumber } from '../../shared/formatters';
import { getPriceValueColor } from '../../shared/helperFunctions/getPriceChangeColor';
import {
  balanceErrorRest$,
  balanceInfoRest$,
  emptyRestAccountBalance,
} from './services/balanceService';
import { totalComputedBalanceForAccount$ } from './services/totalBalanceService';
import { BalanceTotalBalanceRow, BalanceTotalPercent } from './styled';
import { selectedAccount$ } from '../../shared/services/accountsService';
import { withSubscribe } from '../../shared/helperFunctions/withSubscribe';

const [useBalanceTotalWidgetState, balanceTotalWidgetState$] = bind(
  combineLatest([
    totalComputedBalanceForAccount$,
    balanceErrorRest$.pipe(startWith(false)),
    balanceInfoRest$.pipe(startWith(emptyRestAccountBalance)),
    selectedAccount$,
  ]).pipe(
    map(
      ([totalComputedBalance, balanceError, restBalance, selectedAccount]) => ({
        totalComputedBalance,
        balanceError,
        restBalance,
        selectedAccount: selectedAccount?.emarket_account_number,
      }),
    ),
  ),
);

const BalanceTotalWidgetComponent = () => {
  const {
    totalComputedBalance,
    balanceError,
    restBalance,
    selectedAccount,
  } = useBalanceTotalWidgetState();
  // make sure we show balance when rest
  const restBalanceArrived = selectedAccount && restBalance[selectedAccount];
  const totalBalance = selectedAccount && totalComputedBalance[selectedAccount];
  const percent = totalBalance
    && Decimal.div(
      Decimal.sub(
        totalBalance.currentBalance,
        totalBalance.oldBalance,
      ).toNumber(),
      totalBalance.oldBalance,
    ).toNumber() * 100;

  return totalComputedBalance
    && totalBalance
    && !balanceError
    && restBalanceArrived ? (
    <BalanceTotalBalanceRow>
      <div> {`$${formatNumber(totalBalance.currentBalance, 2)}`}</div>
      {percent ? (
        <BalanceTotalPercent color={getPriceValueColor(percent)}>
          {`(${formatNumber(percent, 2)}%)`}
        </BalanceTotalPercent>
      ) : (
        '0.00%'
      )}
    </BalanceTotalBalanceRow>
    ) : null;
};

export const BalanceTotalWidget = withSubscribe(
  BalanceTotalWidgetComponent,
  balanceTotalWidgetState$,
);
