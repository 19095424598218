import { bind } from '@react-rxjs/core';
import { mergeWithKey } from '@react-rxjs/utils';
import { scan, map } from 'rxjs/operators';
import { all24HourTrades$ } from '../../components/tradeHistory/tradeHistoryService';
import { ProductValue } from '../ProductValue';
import { selectedMarketState$ } from './selectedMarketService';

export const latestTradePrice$ = all24HourTrades$.pipe(
  map((history) => history.trades[0]?.price),
);

interface MarketWithPrice {
  latestPrice?: number;
  selectedMarket?: ProductValue;
}

type MarketPriceEvent =
  | {
    type: 'newMarket';
    payload: ProductValue;
  }
  | {
    type: 'newLatestPrice';
    payload: number;
  };

export const marketAndPriceReducer = (
  prevValue: MarketWithPrice,
  event: MarketPriceEvent,
): MarketWithPrice => {
  // when a new market is selected, we clear the latest price
  // so it isn't reflecting the previous symbol
  if (event.type === 'newMarket') {
    return {
      selectedMarket: event.payload,
      latestPrice: undefined,
    };
  }

  return {
    selectedMarket: prevValue.selectedMarket,
    latestPrice: event.payload,
  };
};

export const initialMarketAndPrice: MarketWithPrice = {
  selectedMarket: undefined,
  latestPrice: undefined,
};

const selectedMarketWithLatestPrice$ = mergeWithKey({
  newMarket: selectedMarketState$,
  newLatestPrice: latestTradePrice$,
}).pipe(scan(marketAndPriceReducer, initialMarketAndPrice));

const [useMarketAndPrice] = bind(
  selectedMarketWithLatestPrice$,
  initialMarketAndPrice,
);

export { useMarketAndPrice };
