import { Subject } from 'rxjs';
import { bind } from '@react-rxjs/core';
import { map } from 'rxjs/operators';
import { createListener } from '@react-rxjs/utils';

export const [showUserPrefs$, setShowUserPrefs] = createListener<boolean>();

const areUserPrefsSetCheck = (
  traderId?: string,
) => Boolean(traderId);

export interface UserPrefsState {
  reviewOrder: boolean;
  locationCountry?: string;
  locationState?: string;
  nickname?: string;
  customerAccountRef?: string;
  positionAccountRef?: string;
  traderId?: string;
}

const initialSettings: UserPrefsState = {
  reviewOrder: window.localStorage.getItem('reviewOrder') !== 'false',
  locationCountry: window.localStorage.getItem('locationCountry') || '',
  locationState: window.localStorage.getItem('locationState') || '',
  nickname: window.localStorage.getItem('nickname') || '',
  traderId: window.localStorage.getItem('traderId') || '',
};

const userPrefsRequest$ = new Subject<UserPrefsState>();
export const setNewUserPrefs = (value: UserPrefsState) => {
  window.localStorage.setItem('reviewOrder', value.reviewOrder.toString());
  window.localStorage.setItem('locationCountry', value.locationCountry || '');
  window.localStorage.setItem('locationState', value.locationState || '');
  window.localStorage.setItem('nickname', value.nickname || '');
  window.localStorage.setItem(
    'customerAccountRef',
    value.customerAccountRef || '',
  );
  window.localStorage.setItem(
    'positionAccountRef',
    value.positionAccountRef || '',
  );
  window.localStorage.setItem('traderId', value.traderId || '');
  userPrefsRequest$.next(value);
};

export const getUserPrefs = (): UserPrefsState => ({
  reviewOrder: window.localStorage.getItem('reviewOrder') !== 'false',
  locationCountry: window.localStorage.getItem('locationCountry') || '',
  locationState: window.localStorage.getItem('locationState') || '',
  nickname: window.localStorage.getItem('nickname') || '',
  customerAccountRef: window.localStorage.getItem('customerAccountRef') || '',
  positionAccountRef: window.localStorage.getItem('positionAccountRef') || '',
  traderId: window.localStorage.getItem('traderId') || '',
});

export const [useUserPrefs, userPrefsState$] = bind(
  userPrefsRequest$,
  initialSettings,
);

export const [useAreUserPrefsSet, areUserPrefsSet$] = bind(
  userPrefsState$.pipe(
    map((userPrefs) => areUserPrefsSetCheck(
      userPrefs.traderId,
    )),
  ),
  areUserPrefsSetCheck(
    initialSettings.traderId,
  ),
);

export const [useReviewOrder, isReviewOrder$] = bind(
  userPrefsState$.pipe(map((userPrefs) => userPrefs.reviewOrder)),
  initialSettings.reviewOrder,
);
userPrefsState$.subscribe();
areUserPrefsSet$.subscribe();
isReviewOrder$.subscribe();
