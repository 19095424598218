import {
  addSpaceToCapitol,
  formatDateFromMilliseconds,
  formatNumber,
} from '../../../shared/formatters';
import { ExecutionReport } from '../services/openOrders/openOrdersReducer';
import { GridColDef } from '../../../shared/Grid/GridColDef';
import { sortByMilliseconds } from '../../../shared/comparators';
import { DirectionCell } from '../gridComponents/DirectionCell';

export const algoOrdersColDefs = [
  {
    headerName: 'TIME',
    colId: 'time',
    valueGetter: (params) => params.data.timeInMilliseconds,
    valueFormatter: (params) => params.value && formatDateFromMilliseconds(params.value),
    comparator: sortByMilliseconds,
    width: 150,
    filter: 'agTextColumnFilter',
    sort: 'desc',
    sortable: true,
  } as GridColDef<ExecutionReport>,
  {
    headerName: 'CONTRACT',
    colId: 'contract',
    valueGetter: (params) => params.data.symbol,
    width: 79,
    filter: 'agTextColumnFilter',
  } as GridColDef<ExecutionReport>,
  {
    headerName: 'QTY WORKING',
    colId: 'qtyWorking',
    valueGetter: (params) => params.data.leavesQty,
    valueFormatter: (params) => params.value && formatNumber(params.value),
    cellClass: 'ag-grid-number-cell',
    width: 90,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<ExecutionReport>,
  {
    headerName: 'SIDE',
    colId: 'side',
    valueGetter: (params) => params.data.side,
    width: 60,
    cellRendererFramework: DirectionCell,
    cellClass: 'ag-grid-no-padding-cell',
    sortable: false,
    filter: 'agTextColumnFilter',
    filterValueGetter: (params) => params.data.side,
  } as GridColDef<ExecutionReport>,
  {
    headerName: 'PRICE',
    colId: 'price',
    valueGetter: (params) => params.data.price,
    valueFormatter: (params) => params.value && formatNumber(params.value),
    cellClass: 'ag-grid-number-cell',
    width: 100,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<ExecutionReport>,
  {
    headerName: 'QTY FILLED',
    colId: 'qtyFilled',
    valueGetter: (params) => params.data.cumQty,
    valueFormatter: (params) => params.value && formatNumber(params.value),
    cellClass: 'ag-grid-number-cell',
    width: 70,
    hide: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<ExecutionReport>,
  {
    headerName: 'CLORDID',
    colId: 'clOrdId',
    valueGetter: (params) => params.data.clOrdID,
    width: 190,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<ExecutionReport>,
  {
    headerName: 'ACCOUNT',
    colId: 'account',
    valueGetter: (params) => params.data.accountNumber,
    width: 90,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<ExecutionReport>,
  {
    headerName: 'FEES',
    colId: 'fees',
    valueGetter: (params) => params.data.commCalculated,
    valueFormatter: (params) => params.value && formatNumber(params.value),
    cellClass: 'ag-grid-number-cell',
    width: 70,
    hide: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<ExecutionReport>,
  {
    headerName: 'EXP TIME',
    colId: 'expirationTime',
    valueGetter: (params) => (params.data.timeInForce === 'Day' ? 'Session' : params.data.timeInForce),
    valueFormatter: (params) => params.value && addSpaceToCapitol(params.value),
    width: 70,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<ExecutionReport>,
  {
    headerName: 'ORDER ID',
    colId: 'orderId',
    valueGetter: (params) => params.data.orderID,
    width: 130,
    hide: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<ExecutionReport>,
];
