import React, { memo, useCallback } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import styled from 'styled-components/macro';

import { Panel } from '../../../shared/components/Panel';
import { WarningPanel } from '../../../shared/components/WarningMessage';
import { useWatchlistError } from '../services/tradeHistory';
import { setSelectedSymbols, useSelectedSecurities } from '../services/watchlistSelection';
import { WatchListEditList } from './WatchListEdit';
import { Header } from './WatchListHeader';
import { WatchlistRow } from './WatchListRow';

const WatchListPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  height: calc(100% - 2px);
`;

const GridWrapper = styled.div`
  overflow: auto;
`;

export const Watchlist: React.FC = memo(() => {
  const selectedSecurities = useSelectedSecurities();
  const error = useWatchlistError();

  const handleDragEnd = useCallback(
    ({ destination, source }: DropResult) => {
      if (!destination) {
        return;
      }

      const selectedSymbols = selectedSecurities.map(
        (security) => security.symbol,
      );
      selectedSymbols.splice(
        destination.index,
        0,
        selectedSymbols.splice(source.index, 1)[0],
      );

      setSelectedSymbols(new Set(selectedSymbols));
    },
    [selectedSecurities],
  );

  return (
    <WatchListPanel>
      <WatchListEditList />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="watchlist-container">
          {(droppable) => (
            <GridWrapper {...droppable.droppableProps} ref={droppable.innerRef}>
              <Header />
              {selectedSecurities.map((security, index) => (
                <Draggable
                  index={index}
                  draggableId={security.symbol}
                  key={security.symbol}
                >
                  {(draggable) => (
                    <WatchlistRow market={security} draggable={draggable} />
                  )}
                </Draggable>
              ))}
              {droppable.placeholder}
            </GridWrapper>
          )}
        </Droppable>
      </DragDropContext>
      {error && (<WarningPanel message="Watchlist symbol load error(s)" />)}
    </WatchListPanel>
  );
});
