import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { bind } from '@react-rxjs/core';
import Decimal from 'decimal.js';
import { FormikProps } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import styled from 'styled-components';
import {
  ContentWithTooltip,
  ContentWithTooltipIcon,
} from '../../../shared/components/ContentWithTooltip';
import {
  NumberInput,
  QuantityOptions,
} from '../../../shared/components/NumberInput';
import { ThemedCheckbox } from '../../../shared/components/ThemedCheckbox';
import { WarningPanel } from '../../../shared/components/WarningMessage';
import { Direction } from '../../../shared/Direction';
import { getDecimalScale } from '../../../shared/formatters';
import { withSubscribe } from '../../../shared/helperFunctions/withSubscribe';
import { ProductValue } from '../../../shared/ProductValue';
import {
  AuthStatus,
  authStatusState$,
} from '../../../shared/services/authStatusService';
import {
  mainPriceState$,
  PriceSelectType,
  setSelectedPrice,
} from '../../../shared/services/selectedPriceService';
import { MenuItemStyled } from '../../../shared/style/styled';
import { LoginButton } from '../../navbar/LoginButton';
import {
  areUserPrefsSet$,
  isReviewOrder$,
} from '../../navbar/services/userPrefsService';
import { OrderBookTotals } from '../../orderBook/services/common';
import { DisconnectedOverlay } from '../popups/DisconnectedOverlay';
import { OrderEntryOrderReviewPopup } from '../popups/OrderEntryOrderReviewPopup';
import {
  accountsResponse$,
  getAccountLabel,
  isAccountError$,
  selectedAccount$,
  setSelectedAccount,
} from '../../../shared/services/accountsService';
import {
  FUT,
  OrderEntryFormState,
  OrderResponse,
  OrderType,
} from '../services/trade/sendEntry';
import {
  ConvertAmountOrderEntryFormName,
  InputStyled,
  OrderBlockedMessageWrapper,
  OrderEntryAutocomplete,
  OrderEntryButtonsWrapper,
  OrderEntryClearButton,
  OrderEntryDirection,
  OrderEntryDirectionRow,
  OrderEntryFormConvertInput,
  OrderEntryFormCurrency,
  OrderEntryFormLimitInput,
  OrderEntryFormName,
  OrderEntryFormQuantitiyInput,
  OrderEntryFormRow,
  OrderEntryFormRowsWrapper,
  OrderEntryFormWrapper,
  OrderEntrySelect,
  OrderEntrySubmitButton,
  PaperStyled,
} from '../styled';

import { OrderEntryDateField } from './OrderEntryDateField';
import {
  AdvancedOrderEntryPanel,
  AdvancedOrderEntryPanelExpander,
  ExpanderIcon,
} from './OrderEntryFormComponent.styled';
import { OrderEntryFutureValidation } from '../popups/OrderEntryFutureValidation';

const AccountDropdownMenu = styled(PaperStyled)`
  width: fit-content;
`;

const TIME_IN_FORCE_TOOLTIP = 'Order will remain active for the specified time in force.';
const POST_ONLY_TOOLTIP = 'The order is only entered if it will not immediately execute with another resting order in the market.';
const getOrderTypeTooltip = (orderType: OrderType) => {
  if (orderType === 'LIMIT') {
    return 'An order to buy or sell at a specific price (Limit Price) or better.';
  }
  if (orderType === 'STOP_LIMIT') {
    return 'A Limit Order that is entered into the market if a trade occurs at a price or better than the stop price.';
  }
  if (orderType === 'MARKET') {
    return (
      <p>
        An order to buy or sell at the best available price. This order type
        does not guarantee a full execution but it does guarantee the best
        possible price at the time of execution. Click{' '}
        <a
          href="https://www.erisx.com/product/trading/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>{' '}
        for additional details.
      </p>
    );
  }
  return '';
};
const getConvertTooltip = (symbol: string) => (
  <>
    {symbol} amount will be converted to a limit order with the{' '}
    <span>highlighted parameters</span>.
  </>
);

const orderTypeOptions = [
  { name: 'Limit', value: 'LIMIT' },
  { name: 'Stop Limit', value: 'STOP_LIMIT' },
  { name: 'Market', value: 'MARKET' },
];

const IMMEDIATE_OR_CANCEL = 'ImmediateOrCancel';
const GOOD_TILL_CANCEL = 'GoodTillCancel';

const timeInForceOptions = [
  { name: 'Session', value: 'Day' },
  { name: 'Good Till Cancel', value: GOOD_TILL_CANCEL },
  { name: 'Immediate Or Cancel', value: IMMEDIATE_OR_CANCEL },
  { name: 'Fill Or Kill', value: 'FillOrKill' },
  { name: 'Good Till Date', value: 'GoodTillDate' },
];
export const directions = [
  { name: Direction.Buy, value: Direction.Buy },
  { name: Direction.Sell, value: Direction.Sell },
];
export const postOnlyOptions = [
  { name: 'No', value: 'N' },
  { name: 'Yes', value: 'Y' },
];

export const getButtonText = (
  reviewOrder: boolean,
  buttonAlreadyClicked: boolean,
  side: Direction,
) => {
  const direction = side === Direction.Buy ? 'Buy' : 'Sell';
  if (reviewOrder && !buttonAlreadyClicked) {
    return `Review & ${direction}`;
  }
  return `Place ${direction} Order`;
};

const calcConvertValue = (
  usdValue: number | undefined,
  bestPrice: number | undefined,
  minTradeVol: number,
  roundLot: number,
) => {
  if (!bestPrice || !usdValue || !roundLot) return undefined;
  const decimals = 1 / roundLot;
  const convertValue = new Decimal(usdValue).div(new Decimal(bestPrice));
  const roundedConvert = convertValue
    .add(Number.EPSILON)
    .times(decimals)
    .floor()
    .div(decimals)
    .toNumber();
  return roundedConvert > minTradeVol ? roundedConvert : 0;
};

const calcAmountBasedOnQty = (
  newQty: number | undefined,
  limitPrice: number | undefined,
  minTradeVol: number,
  roundLot: number,
) => {
  if (!limitPrice || !newQty || !roundLot) return undefined;
  const decimals = 1 / roundLot;
  const convertValue = new Decimal(newQty).times(new Decimal(limitPrice));
  const roundedConvert = convertValue
    .add(Number.EPSILON)
    .times(decimals)
    .floor()
    .div(decimals)
    .toNumber();
  return roundedConvert > minTradeVol * limitPrice ? roundedConvert : 0;
};
interface Props {
  book?: OrderBookTotals;
  isOverlayActive: boolean;
  isSelected: boolean;
  formikProps: FormikProps<OrderEntryFormState>;
  market: ProductValue;
  submitButtonAlreadyClicked: boolean;
  setSubmitButtonAlreadyClicked: (clicked: boolean) => void;
  lastUsedCorrelation: string;
  latestEntryResponse: OrderResponse | null;
  setFormNeedsToBeReset: (reset: boolean) => void;
  formNeedsToBeReset: boolean;
  spotPermissions: string[];
  futuresPermissions: string[];
}

const accountState$ = combineLatest([
  isAccountError$,
  accountsResponse$,
  selectedAccount$,
]).pipe(
  map(([isAccountError, accountsResponse, selectedAccount]) => ({
    isAccountError,
    accountsResponse,
    selectedAccount,
  })),
);

const [useOrderFormState, orderFormState$] = bind(
  combineLatest([
    isReviewOrder$,
    areUserPrefsSet$,
    mainPriceState$,
    accountState$,
    authStatusState$,
  ]).pipe(
    map(
      ([
        reviewOrder,
        areUserPrefsSet,
        selectedPrice,
        { isAccountError, accountsResponse, selectedAccount },
        authStatus,
      ]) => ({
        reviewOrder,
        areUserPrefsSet,
        selectedPrice,
        isAccountError,
        accountsResponse,
        selectedAccount,
        authStatus,
      }),
    ),
  ),
);

const OrderEntryForm: React.FunctionComponent<Props> = (props) => {
  const {
    book,
    market,
    submitButtonAlreadyClicked,
    setSubmitButtonAlreadyClicked,
    isSelected,
  } = props;
  const {
    values,
    setFieldValue,
    setFieldError,
    errors,
    resetForm,
    handleSubmit,
    touched,
    setFieldTouched,
  } = props.formikProps;

  const {
    reviewOrder,
    areUserPrefsSet,
    selectedPrice,
    isAccountError: failedAccounts,
    accountsResponse,
    selectedAccount,
    authStatus,
  } = useOrderFormState();
  const { account_number: accountNumber } = values.account || {};
  const invalidAccounts = Boolean(!accountsResponse);

  const accountOptions = useMemo(
    () => accountsResponse?.accounts
      .filter((accounts) => accounts.fix_ids?.length)
      .map((account) => account),
    [accountsResponse],
  ) || [];

  const isOrderType = (orderType: OrderType) => values.orderType === orderType;

  const getQtyLabel = () => (isOrderType('MARKET') && values.direction === Direction.Buy
    ? 'Amount:'
    : 'Quantity:');

  useEffect(() => {
    if (
      selectedPrice
      && isSelected
      && selectedPrice.type === PriceSelectType.PRICE
    ) {
      setFieldValue('limitPrice', selectedPrice.price);
      setSelectedPrice(undefined);
    }
  }, [selectedPrice, setFieldValue, isSelected]);

  useEffect(() => {
    if (
      props.formNeedsToBeReset
      && props.latestEntryResponse?.correlation === props.lastUsedCorrelation
    ) {
      if (props.latestEntryResponse?.ordStatus === 'NEW') {
        resetForm({
          values: {
            ...values,
            stopPrice: undefined,
            limitPrice: undefined,
            quantity: undefined,
          },
        });
      }
      props.setFormNeedsToBeReset(false);
    }
  }, [
    props,
    props.lastUsedCorrelation,
    props.latestEntryResponse,
    resetForm,
    values,
  ]);

  useEffect(() => {
    if (!selectedAccount && accountOptions.length) {
      setSelectedAccount(accountOptions[0]);
      setAccountInput(accountOptions[0].account_number);
      setFieldValue('account', accountOptions[0]);
    }
  }, [accountOptions, selectedAccount, setFieldValue]);

  const [convert, setConvert] = useState(false);
  const [accountInput, setAccountInput] = useState('');
  const [convertValue, setConvertValue] = useState<number | undefined>(
    undefined,
  );
  const [lastTouched, setLastTouched] = useState('');
  const symbol = market.symbol.split('/')[1];
  const [isAdvancedPanelOpen, setIsAdvancedPanelOpen] = useState<boolean>(false);

  return (
    <>
      <OrderEntryFormWrapper
        isOverlayActive={
          props.isOverlayActive || invalidAccounts || !accountOptions.length
        }
      >
        <OrderEntryDirectionRow>
          {directions.map((direction) => (
            <OrderEntryDirection
              key={direction.value}
              onClick={() => setFieldValue('direction', direction.value)}
              selected={values.direction === direction.value}
              direction={values.direction}
            >
              {direction.name}
            </OrderEntryDirection>
          ))}
        </OrderEntryDirectionRow>
        <OrderEntryFormRowsWrapper>
          <OrderEntryFormRow>
            <OrderEntryFormName>Account:</OrderEntryFormName>
            <ContentWithTooltip
              openOnError
              tooltipText={errors.account?.account_number || ''}
            >
              <OrderEntryAutocomplete
                options={accountOptions}
                disableClearable
                popupIcon={<ExpandMoreIcon />}
                PaperComponent={AccountDropdownMenu}
                openOnFocus
                getOptionSelected={(option) => option.account_number === accountNumber
                }
                onChange={(_, accountOption) => {
                  setFieldValue('account', accountOption);
                  setSelectedAccount(accountOption);
                  setFieldError('account', '');
                }}
                onInputChange={(_, value) => setAccountInput(value)}
                inputValue={accountInput}
                onFocus={() => setAccountInput('')}
                onBlur={() => setAccountInput(selectedAccount!.account_number)}
                value={values.account || null}
                renderInput={(AutocompleteRenderInputParams) => {
                  const {
                    InputProps,
                    InputLabelProps,
                    ...rest
                  } = AutocompleteRenderInputParams;
                  return (
                    <InputStyled
                      {...rest}
                      {...InputProps}
                      placeholder={'Search...'}
                      disableUnderline
                    />
                  );
                }}
                filterOptions={(options, state) => {
                  const { inputValue } = state;
                  if (!inputValue) {
                    return options;
                  }
                  return options.filter((op) => getAccountLabel(
                    props.spotPermissions,
                    props.futuresPermissions,
                    op.account_number,
                    op?.name,
                  )
                    .toLowerCase()
                    .includes(inputValue.toLowerCase()));
                }}
                getOptionLabel={(accountOption) => accountOption.account_number}
                renderOption={(accountOption) => (
                  <MenuItemStyled
                    key={accountOption.account_number}
                    selected={accountOption.account_number === accountNumber}
                  >
                    {getAccountLabel(
                      props.spotPermissions,
                      props.futuresPermissions,
                      accountOption.account_number,
                      accountOption.name,
                    )}
                  </MenuItemStyled>
                )}
              />
            </ContentWithTooltip>
          </OrderEntryFormRow>
          <OrderEntryFormRow>
            <OrderEntryFormName>Order type:</OrderEntryFormName>
            <ContentWithTooltipIcon
              interactive
              tooltipText={getOrderTypeTooltip(values.orderType)}
            >
              <OrderEntrySelect
                value={values.orderType}
                IconComponent={ExpandMoreIcon}
                MenuProps={{ MenuListProps: { disablePadding: true } }}
              >
                {orderTypeOptions.map((type) => (
                  <MenuItemStyled
                    key={type.value}
                    value={type.value}
                    onClick={() => {
                      switch (type.value) {
                        case 'MARKET':
                          setFieldValue('timeInForce', IMMEDIATE_OR_CANCEL);
                          setConvertValue(undefined);
                          setConvert(false);
                          setFieldValue('postOnly', 'N');
                          setFieldValue('quantity', undefined);
                          setFieldValue('limitPrice', undefined);
                          setFieldValue('stopPrice', undefined);
                          break;
                        case 'LIMIT':
                        case 'STOP_LIMIT':
                        default:
                          setFieldValue('timeInForce', GOOD_TILL_CANCEL);
                          break;
                      }
                      setFieldValue('orderType', type.value);
                      setFieldError('orderType', '');
                    }}
                  >
                    {type.name}
                  </MenuItemStyled>
                ))}
              </OrderEntrySelect>
            </ContentWithTooltipIcon>
          </OrderEntryFormRow>
          {values.orderType === 'STOP_LIMIT' && (
            <>
              {' '}
              <OrderEntryFormRow>
                <OrderEntryFormName>Stop Price:</OrderEntryFormName>
                <NumberInput
                  error={errors.stopPrice}
                  decimalScale={getDecimalScale(market?.minPriceIncrement)}
                  value={values.stopPrice}
                  {...QuantityOptions}
                  onValueChange={(val) => {
                    setFieldError('stopPrice', '');
                    setFieldValue('stopPrice', val.floatValue);
                  }}
                />
              </OrderEntryFormRow>{' '}
            </>
          )}
          <OrderEntryFormRow>
            <OrderEntryFormName>{getQtyLabel()}</OrderEntryFormName>
            <OrderEntryFormQuantitiyInput
              controlled={convert && !touched.quantity}
              fullSize={market.type !== 'SPOT'}
            >
              <NumberInput
                error={errors.quantity}
                decimalScale={getDecimalScale(market?.roundLot)}
                value={values.quantity}
                onFocus={() => setLastTouched('quantity')}
                name="quantity"
                {...QuantityOptions}
                onChange={() => {
                  setFieldTouched('quantity', true);
                  setLastTouched('quantity');
                }}
                onValueChange={(val) => {
                  setFieldError('quantity', '');
                  setFieldValue('quantity', val.floatValue);
                  if (lastTouched === 'quantity') {
                    const newConvertValue = calcAmountBasedOnQty(
                      val.floatValue,
                      values.limitPrice,
                      market?.minTradeVol,
                      market?.roundLot,
                    );
                    setConvertValue(newConvertValue);
                  }
                }}
              />
              {market.type === 'SPOT' && (
                <OrderEntryFormCurrency>
                  {isOrderType('MARKET') && values.direction === Direction.Buy
                    ? symbol
                    : market.currency}
                </OrderEntryFormCurrency>
              )}
            </OrderEntryFormQuantitiyInput>
          </OrderEntryFormRow>
          {values.orderType !== 'MARKET' && (
            <OrderEntryFormRow>
              <OrderEntryFormName>Limit Price:</OrderEntryFormName>
              <OrderEntryFormLimitInput
                controlled={convert && !touched.limitPrice}
              >
                <NumberInput
                  error={errors.limitPrice}
                  decimalScale={getDecimalScale(market?.minPriceIncrement)}
                  value={values.limitPrice}
                  {...QuantityOptions}
                  onChange={() => {
                    setFieldTouched('limitPrice', true);
                    setLastTouched('limitPrice');
                  }}
                  onValueChange={(val) => {
                    setFieldError('limitPrice', '');
                    setFieldValue('limitPrice', val.floatValue);

                    if (convert) {
                      if (!convertValue) return;
                      const bestPrice = values.direction === Direction.Sell
                        ? book?.bestBid
                        : book?.bestOffer;
                      const price = convertValue || bestPrice;
                      const quantity = calcConvertValue(
                        price,
                        val.floatValue,
                        market?.minTradeVol,
                        market?.roundLot,
                      );
                      setFieldValue('quantity', quantity);
                    }
                  }}
                />
              </OrderEntryFormLimitInput>
            </OrderEntryFormRow>
          )}
          {market.type === 'SPOT' && convert && (
            <OrderEntryFormRow>
              <ConvertAmountOrderEntryFormName>
                {symbol} Amount:
              </ConvertAmountOrderEntryFormName>
              <NumberInput
                decimalScale={getDecimalScale(market?.roundLot)}
                value={convertValue}
                {...QuantityOptions}
                onChange={() => setLastTouched('convertValue')}
                onValueChange={(val) => {
                  const bestPrice = values.direction === Direction.Sell
                    ? book?.bestBid
                    : book?.bestOffer;

                  const price = values.limitPrice
                    ? values.limitPrice
                    : bestPrice;
                  const quantity = calcConvertValue(
                    val.floatValue,
                    price,
                    market?.minTradeVol,
                    market?.roundLot,
                  );
                  if (lastTouched !== 'quantity') {
                    setFieldValue('quantity', quantity);
                    if (!values.limitPrice) {
                      setFieldValue('limitPrice', bestPrice);
                    }
                  }
                  setFieldTouched('quantity', false);
                  setFieldTouched('limitPrice', false);
                  setConvertValue(val.floatValue);
                }}
              />
            </OrderEntryFormRow>
          )}

          <AdvancedOrderEntryPanelExpander>
            Advanced
            <ExpanderIcon
              expanded={isAdvancedPanelOpen}
              onClick={() => setIsAdvancedPanelOpen((o) => !o)}
            />
          </AdvancedOrderEntryPanelExpander>

          <AdvancedOrderEntryPanel open={isAdvancedPanelOpen}>
            {market.type === 'SPOT' && !isOrderType('MARKET') && (
              <OrderEntryFormRow>
                <OrderEntryFormName />
                <ContentWithTooltipIcon tooltipText={getConvertTooltip(symbol)}>
                  <OrderEntryFormConvertInput>
                    <ThemedCheckbox
                      type="checkbox"
                      checked={convert}
                      onChange={(e) => setConvert(e.target.checked)}
                    />
                    <OrderEntryFormCurrency>
                      Convert an amount
                    </OrderEntryFormCurrency>
                  </OrderEntryFormConvertInput>
                </ContentWithTooltipIcon>
              </OrderEntryFormRow>
            )}
            <OrderEntryFormRow>
              <OrderEntryFormName>Time in force:</OrderEntryFormName>
              <ContentWithTooltipIcon tooltipText={TIME_IN_FORCE_TOOLTIP}>
                <OrderEntrySelect
                  value={values.timeInForce}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{ MenuListProps: { disablePadding: true } }}
                  disableUnderline
                  disabled={isOrderType('MARKET')}
                >
                  {timeInForceOptions.map((type) => (
                    <MenuItemStyled
                      key={type.value}
                      value={type.value}
                      onClick={() => {
                        setFieldValue('timeInForce', type.value);
                        setFieldValue('goodTillDate', undefined);
                        setFieldError('goodTillDate', '');
                      }}
                    >
                      {type.name}
                    </MenuItemStyled>
                  ))}
                </OrderEntrySelect>
              </ContentWithTooltipIcon>
            </OrderEntryFormRow>
            {values.timeInForce === 'GoodTillDate' && (
              <>
                {' '}
                <OrderEntryFormRow>
                  <OrderEntryFormName>Good Till Date:</OrderEntryFormName>
                  <OrderEntryDateField
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    error={errors.goodTillDate}
                  />
                </OrderEntryFormRow>{' '}
              </>
            )}
            {!isOrderType('MARKET') && (
              <OrderEntryFormRow>
                <OrderEntryFormName>Post only:</OrderEntryFormName>
                <ContentWithTooltipIcon tooltipText={POST_ONLY_TOOLTIP}>
                  <ThemedCheckbox
                    type="checkbox"
                    checked={values.postOnly === 'Y'}
                    onChange={(e) => setFieldValue('postOnly', e.target.checked ? 'Y' : 'N')
                    }
                  />
                </ContentWithTooltipIcon>
              </OrderEntryFormRow>
            )}
          </AdvancedOrderEntryPanel>
        </OrderEntryFormRowsWrapper>
        {market.type === FUT && !areUserPrefsSet && (
          <OrderEntryFutureValidation />
        )}
        <OrderEntryButtonsWrapper>
          <OrderEntryClearButton
            type="button"
            onClick={(e) => {
              e.preventDefault();
              resetForm();
              setConvertValue(undefined);
            }}
          >
            Clear
          </OrderEntryClearButton>
          <OrderEntrySubmitButton
            type="button"
            direction={values.direction}
            disabled={
              (!isOrderType('MARKET') && !values.limitPrice)
              || !values.quantity
              || (market.type === FUT && !areUserPrefsSet)
            }
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {getButtonText(
              reviewOrder,
              submitButtonAlreadyClicked,
              values.direction,
            )}
          </OrderEntrySubmitButton>
        </OrderEntryButtonsWrapper>
      </OrderEntryFormWrapper>
      {submitButtonAlreadyClicked && (
        <OrderEntryOrderReviewPopup
          showPopup={setSubmitButtonAlreadyClicked}
          handleSubmit={() => handleSubmit()}
          orderValues={values}
          symbol={market.symbol}
          quoteCurrency={symbol}
          baseCurrency={market.currency}
        />
      )}
      {!invalidAccounts
        && !accountOptions.length
        && authStatus === AuthStatus.AUTHENTICATED && (
          <OrderBlockedMessageWrapper>
            No Accounts are available for trading
          </OrderBlockedMessageWrapper>
      )}
      {failedAccounts && authStatus === AuthStatus.AUTHENTICATED && (
        <OrderBlockedMessageWrapper>
          <WarningPanel message="Accounts could not be loaded" />
        </OrderBlockedMessageWrapper>
      )}
      {authStatus !== AuthStatus.AUTHENTICATED ? (
        <OrderBlockedMessageWrapper>
          <LoginButton />
        </OrderBlockedMessageWrapper>
      ) : (
        <DisconnectedOverlay type="trade" />
      )}
    </>
  );
};

export const OrderEntryFormComponent = withSubscribe(
  OrderEntryForm,
  orderFormState$,
);
