import React from 'react';
import styled from 'styled-components/macro';
import { Direction } from '../../../shared/Direction';
import {
  addSpaceToCapitol as addSpaceToCapital,
  formatNumber,
} from '../../../shared/formatters';
import { useSelectedMarket } from '../../../shared/services/selectedMarketService';
import { usePermissions } from '../../../shared/services/userPermissionsService';
import { AlgoOrderEntryFormState } from '../services/algos/algoSendEntry';
import { OrderEntryFormState } from '../services/trade/sendEntry';
import { OrderEntrySubmitButton } from '../styled';
import {
  OrderEntryCloseButton,
  OrderEntrySummary,
} from '../transitionScreens/styled';
import { canSubmitForMarket, marketLabel } from '../validationFunctions';

interface Props {
  showPopup: (show: boolean) => void;
  handleSubmit: () => void;
  orderValues?: OrderEntryFormState;
  symbol: string;
  isBlock?: boolean;
  isAlgo?: boolean;
  algoValues?: AlgoOrderEntryFormState;
  quoteCurrency?: string;
  baseCurrency?: string;
}

export const OrderEntryOrderReviewPopupWrapper = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.palette.primary.primary1};
  bottom: 0;
  width: 100%;
`;

const OrderReviewPopupTitle = styled.div`
  display: flex;
  justify-content: center;
  font-size: 13px;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  font-weight: 600;
  word-break: break-word;
`;

const OrderReviewPopupWrapper = styled.div<{ block?: boolean }>`
  display: block;
  align-items: center;
  justify-content: center;
  padding: ${({ block }) => (block ? '10px' : '20px')} 10px;

  ${OrderEntrySummary} {
    margin: 12px;
    word-break: break-word;
  }
  ${OrderEntryCloseButton} {
    margin-top: -1px;
  }

  ${OrderReviewPopupTitle} {
    margin-bottom: ${({ block }) => block && '4px'};
  }
`;

const OrderReviewPopupButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 125px 125px;
  align-items: center;
  justify-content: center;
`;

const getButtonText = (
  block?: boolean,
  algos?: boolean,
  values?: OrderEntryFormState,
  algoValues?: AlgoOrderEntryFormState,
) => {
  if (algos) {
    return `Place ${
      algoValues?.direction === Direction.Buy ? 'Buy' : 'Sell'
    } Order`;
  }
  if (block) {
    return 'Place Order';
  }

  return `Place ${values?.direction === Direction.Buy ? 'Buy' : 'Sell'} Order`;
};

export const OrderEntryOrderReviewPopup = ({
  showPopup,
  handleSubmit,
  orderValues,
  symbol,
  isBlock,
  isAlgo,
  algoValues,
  quoteCurrency,
  baseCurrency,
}: Props) => {
  const permissions = usePermissions();
  const market = useSelectedMarket();
  const account = isAlgo
    ? algoValues?.account.account_number
    : orderValues?.account.account_number;
  const canSubmit = isBlock
    || (permissions && market && account
      ? canSubmitForMarket(permissions!, market!, account!)
      : false);

  const isMarketOrder = orderValues?.orderType === 'MARKET';

  const getMarketOrderText = () => `
    New Market Order to ${orderValues?.direction} 
    ${formatNumber(orderValues?.quantity)} 
    ${orderValues?.direction === 'BUY' ? quoteCurrency : baseCurrency} 
    in ${symbol} in ${orderValues?.account.account_number}`;

  const getOrderText = () => `
    New Order to ${orderValues?.direction} 
        ${formatNumber(orderValues?.quantity)} 
        ${symbol} @ 
        ${formatNumber(orderValues?.limitPrice)} 
        ${orderValues?.orderType} 
        ${formatNumber(orderValues?.stopPrice)}
        ${addSpaceToCapital(orderValues?.timeInForce!)} 
        ${orderValues?.postOnly === 'Y' ? 'Post Only' : ''} 
        in ${orderValues?.account.account_number}
  `;

  const getText = () => (isMarketOrder ? getMarketOrderText() : getOrderText());

  return (
    <OrderEntryOrderReviewPopupWrapper>
      <OrderReviewPopupWrapper block={isBlock}>
        <OrderReviewPopupTitle>CONFIRM YOUR ORDER</OrderReviewPopupTitle>
        {!canSubmit && (
          <OrderEntrySummary>
            {`Account ${account} is not permissioned to trade ${marketLabel(
              market,
            )} Products. `}
            {'Contact market.operations@erisx.com for more information'}
          </OrderEntrySummary>
        )}
        {canSubmit && !isBlock && !isAlgo && orderValues && (
          <OrderEntrySummary>
            {getText()}
          </OrderEntrySummary>
        )}
        {canSubmit && isAlgo && algoValues && (
          <OrderEntrySummary>
            {`New ${
              algoValues?.orderType === 'PEGGED' ? 'Pegged' : 'Order on Fill'
            } Order to ${algoValues.direction} 
        ${formatNumber(algoValues.quantity)} 
        ${symbol} with Offset 
        ${formatNumber(algoValues.offset)}
        and limit price 
        ${formatNumber(algoValues.limitPrice)} 
        ${algoValues.postOnly === 'Y' ? 'Post Only' : ''} 
        in ${algoValues.account.account_number}`}
          </OrderEntrySummary>
        )}
        <OrderEntrySummary>
          Please be advised that you may not cancel a trade once confirmed as executed.
        </OrderEntrySummary>
        <OrderReviewPopupButtonsWrapper>
          <OrderEntryCloseButton onClick={() => showPopup(false)}>
            CANCEL
          </OrderEntryCloseButton>
          <OrderEntrySubmitButton
            disabled={!canSubmit}
            // block trade by default will show BUY colour
            direction={
              isBlock
                ? Direction.Buy
                : isAlgo
                  ? algoValues?.direction!
                  : orderValues?.direction!
            }
            type="button"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {getButtonText(isBlock, isAlgo, orderValues, algoValues)}
          </OrderEntrySubmitButton>
        </OrderReviewPopupButtonsWrapper>
      </OrderReviewPopupWrapper>
    </OrderEntryOrderReviewPopupWrapper>
  );
};
