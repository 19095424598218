import React from 'react';
import {
  ConnectionStatus,
  useConnectionStatus,
} from '../../../shared/websocket/connectionStatus';
import { OrderBlockedMessageWrapper, AlgoNoFutureText } from '../styled';
import { useAlgoConnectionStatus } from '../../../shared/algoWebsocket/algoConnectionStatus';

const isConnected = (
  type: 'trade' | 'algo',
  tradeStatus: ConnectionStatus,
  algoStatus: ConnectionStatus,
) => {
  if (type === 'trade') {
    return tradeStatus === ConnectionStatus.AUTHENTICATED;
  }
  return algoStatus === ConnectionStatus.AUTHENTICATED;
};

interface Props {
  type: 'trade' | 'algo';
}

export const DisconnectedOverlay: React.FC<Props> = ({ type }) => {
  const tradeConnectionStatus = useConnectionStatus();
  const algoConnectionStatus = useAlgoConnectionStatus();

  return !isConnected(type, tradeConnectionStatus, algoConnectionStatus) ? (
    <OrderBlockedMessageWrapper>
      <AlgoNoFutureText>
        Websocket connection is closed. Please try again later.
      </AlgoNoFutureText>
    </OrderBlockedMessageWrapper>
  ) : null;
};
