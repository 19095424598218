import React from 'react';
import { TabPanel, TabParams } from '../tabs/TabPanel';
import {
  SettingButton, ActiveFilterButton, ActiveNumberLabel, FilterButton, NumberLabel, ConfigWrapper,
} from './styled';
import { FilterIcon } from '../../icons/FilterIcon';
import { SettingIcon } from '../../icons/SettingIcon';
import { ActiveFilterIcon } from '../../icons/ActiveFilterIcon';
import { CancelAllCell } from './gridComponents/CancelAllCell';
import { useMobileCheck } from '../../shared/hooks/useMobileCheck';

interface Props {
  tabs: TabParams[];
  switchTab: (input: string | undefined) => void,
  settingPanelHidden: boolean,
  filterHidden: boolean,
  filterNumber: number | undefined,
  onSettingClick: () => void,
  onFilterClick: () => void,
  isLeft: boolean,
}

export const GridTabPanel: React.FC<Props> = ({
  tabs, switchTab, settingPanelHidden, filterHidden, filterNumber,
  onSettingClick, onFilterClick, isLeft,
}) => {
  const isMobile = useMobileCheck();

  const gridFilterAndSettingsL = (
    <ConfigWrapper>
      <CancelAllCell />
      {filterNumber && filterHidden
        ? (
          <ActiveFilterButton
            onClick={onFilterClick}>
            <ActiveNumberLabel>{filterNumber}</ActiveNumberLabel>
            <ActiveFilterIcon isFilterHidden={filterHidden} />
          </ActiveFilterButton>
        )
        : (
          <FilterButton
            onClick={onFilterClick}>
            <NumberLabel>{filterNumber && filterNumber > 0 ? filterNumber : undefined}</NumberLabel>
            <FilterIcon isFilterHidden={filterHidden} />
          </FilterButton>
        )}
      <SettingButton onClick={onSettingClick}>
        <SettingIcon panelHidden={settingPanelHidden} />
      </SettingButton>
    </ConfigWrapper>
  );

  const gridFilterAndSettings = (
    <ConfigWrapper>
      {filterNumber && filterHidden
        ? (
          <ActiveFilterButton
            onClick={onFilterClick}>
            <ActiveNumberLabel>{filterNumber}</ActiveNumberLabel>
            <ActiveFilterIcon isFilterHidden={filterHidden} />
          </ActiveFilterButton>
        )
        : (
          <FilterButton
            onClick={onFilterClick}>
            <NumberLabel>{filterNumber && filterNumber > 0 ? filterNumber : undefined}</NumberLabel>
            <FilterIcon isFilterHidden={filterHidden} />
          </FilterButton>
        )}
      <SettingButton onClick={onSettingClick}>
        <SettingIcon panelHidden={settingPanelHidden} />
      </SettingButton>
    </ConfigWrapper>
  );

  return (
    <TabPanel
      tabs={tabs}
      switchTab={switchTab}
      headerContent={isLeft ? gridFilterAndSettingsL : gridFilterAndSettings}
      dropdownOptions={isMobile}
    />
  );
};
