import { sortByTimeString } from '../../../shared/comparators';
import { formatTradeTime } from '../../../shared/formatters';
import { GridColDef } from '../../../shared/Grid/GridColDef';
import { errorMapping } from '../../orderEntry/services/block/blockTradeEntryService';
import { RefreshCell } from '../gridComponents/RefreshCell';
import { BlockTradeRecord } from '../services/blockTrades/blockTradesReducer';

export const blockTradesColDefs = [
  {
    headerName: '',
    cellRendererFramework: RefreshCell,
    colId: 'actions',
    width: 30,
    cellClass: 'ag-grid-no-padding-cell',
    sortable: false,
    floatingFilter: false,
    valueGetter: (params) => params.data,
  } as GridColDef<BlockTradeRecord>,
  {
    headerName: 'TIME',
    colId: 'time',
    valueGetter: (params) => params.data.submitted_time,
    valueFormatter: (params) => params.value && formatTradeTime(params.value),
    comparator: sortByTimeString,
    width: 150,
    filter: 'agTextColumnFilter',
    sort: 'desc',
    sortable: true,
  } as GridColDef<BlockTradeRecord>,
  {
    headerName: 'STATE',
    colId: 'state',
    valueGetter: (params) => params.data.state,
    width: 62,
    filter: 'agTextColumnFilter',
  } as GridColDef<BlockTradeRecord>,
  {
    headerName: 'CONTRACT',
    colId: 'contract',
    valueGetter: (params) => params.data.contract_symbol,
    width: 79,
    filter: 'agTextColumnFilter',
  } as GridColDef<BlockTradeRecord>,
  {
    headerName: 'QTY',
    colId: 'qty',
    valueGetter: (params) => params.data.qty,
    width: 80,
    cellClass: 'ag-grid-number-cell',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<BlockTradeRecord>,
  {
    headerName: 'PRICE',
    colId: 'price',
    valueGetter: (params) => params.data.px,
    width: 80,
    cellClass: 'ag-grid-number-cell',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<BlockTradeRecord>,
  {
    headerName: 'CREATOR',
    colId: 'creator',
    valueGetter: (params) => params.data.created_by,
    width: 200,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<BlockTradeRecord>,
  {
    headerName: 'NEGOTIATED TIME',
    colId: 'negotiatedTime',
    valueGetter: (params) => params.data.negotiated_time,
    valueFormatter: (params) => params.value && formatTradeTime(params.value),
    width: 130,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<BlockTradeRecord>,
  {
    headerName: 'B.ACC ID',
    colId: 'b.accId',
    valueGetter: (params) => params.data?.buy_side?.account_label,
    width: 90,
    filter: 'agTextColumnFilter',
  } as GridColDef<BlockTradeRecord>,
  {
    headerName: 'B.SUB ID',
    colId: 'b.subId',
    valueGetter: (params) => params.data?.buy_side?.sender_sub_id,
    width: 80,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<BlockTradeRecord>,
  {
    headerName: 'B.CLORDID',
    colId: 'b.clOrdId',
    valueGetter: (params) => params.data?.buy_side?.cl_ord_id,
    width: 80,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<BlockTradeRecord>,
  {
    headerName: 'B.CUST ACCT REF',
    colId: 'b.custAccRef',
    valueGetter: (params) => params.data?.buy_side?.customer_account_ref,
    width: 100,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<BlockTradeRecord>,
  {
    headerName: 'S.ACC ID',
    colId: 's.accId',
    valueGetter: (params) => params.data?.sell_side?.account_label,
    width: 90,
    filter: 'agTextColumnFilter',
  } as GridColDef<BlockTradeRecord>,
  {
    headerName: 'S.SUB ID',
    colId: 's.subId',
    valueGetter: (params) => params.data?.sell_side?.sender_sub_id,
    width: 80,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<BlockTradeRecord>,
  {
    headerName: 'S.CLORDID',
    colId: 's.clOrdId',
    valueGetter: (params) => params.data?.sell_side?.cl_ord_id,
    width: 80,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<BlockTradeRecord>,
  {
    headerName: 'S.CUST ACCT REF',
    colId: 's.custAcctRef',
    valueGetter: (params) => params.data?.sell_side?.customer_account_ref,
    width: 100,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<BlockTradeRecord>,
  {
    headerName: 'REJECT REASON',
    colId: 'rejectReason',
    hide: true,
    valueGetter: (params) => (
      (params.data.state === 'rejected' && params.data.error_code)
        ? errorMapping[params.data.error_code]
        : 'N/A'),
    width: 200,
    filter: 'agTextColumnFilter',
  } as GridColDef<BlockTradeRecord>,
  {
    headerName: 'REQUEST ID',
    colId: 'requestId',
    valueGetter: (params) => params.data.request_id,
    width: 300,
    filter: 'agTextColumnFilter',
  } as GridColDef<BlockTradeRecord>,
];
