import React from 'react';
import styled from 'styled-components/macro';
import { OrderEntryCancelButton } from '../../orderEntry/styled';
import { OrderEntrySummary, OrderEntryCloseButton } from '../../orderEntry/transitionScreens/styled';

interface Props {
  close: () => void;
  handleCancel: () => void;
}

export const CancelConfirmWrapper = styled.div`
    border-radius: 3px;
    background-color: ${({ theme }) => theme.palette.primary.primary1};
    padding: 4px 5px;
    z-index: 100000;
    min-width: 100px;
    min-height: 100px;
    border: 1px solid ${({ theme }) => theme.palette.secondary.secondary};
`;

const CancelConfirmPopupWrapper = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;

  ${OrderEntrySummary}{
    margin: 12px;
    word-break: break-word;
  }
`;

const CancelConfirmPopupButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px 125px;
  grid-column-gap: 15px;
  align-items: center;
  justify-content: center;

  ${OrderEntryCloseButton}{
    display: flex;
    padding: 5px;
    margin-top: -1px;
    margin-bottom: 0px;
  }
`;

const CancelConfirmPopupTitle = styled.div`
  display: flex;
  justify-content: center;
  font-size: 13px;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  font-weight: 600;
  word-break: break-word;
`;

export const CancelConfirmation: React.FunctionComponent<Props> = (props) => (
  <CancelConfirmWrapper>
    <CancelConfirmPopupWrapper>
      <CancelConfirmPopupTitle>
        CONFIRM CANCELLATION
  </CancelConfirmPopupTitle>
      <OrderEntrySummary>
        {'Please confirm you would like to cancel this order.'}
      </OrderEntrySummary>
      <CancelConfirmPopupButtonsWrapper>
        <OrderEntryCloseButton
          onClick={() => props.close()}
        >
          CLOSE
  </OrderEntryCloseButton>
        <OrderEntryCancelButton
          type='button'
          onClick={(e) => {
            e.preventDefault();
            props.handleCancel();
          }}>
          {'Cancel Order'}
        </OrderEntryCancelButton>
      </CancelConfirmPopupButtonsWrapper>

    </CancelConfirmPopupWrapper>
  </CancelConfirmWrapper>
);
