import { Map } from 'immutable';
import { Direction } from '../../../../shared/Direction';
import { ObservableType } from '../../../../shared/helperFunctions/typeHelpers';
import { ActionTypes, ExecutionType, OrderStatus } from './constants';
// eslint-disable-next-line
import { MarketOrderActions$ } from './marketOrderExecutionSummaryService';

export interface MarketOrderExecutionSummary {
  amount: number;
  clOrdID: string;
  fees: number;
  isComplete: boolean;
  price: number;
  quantity: number,
  side: Direction;
  status: string;
  symbol: string;
  commCurrency: string;
}

export type MarketOrderAction = ObservableType<MarketOrderActions$>;
export type MarketOrderSummaryState = Map<string, MarketOrderExecutionSummary>;

export const INITIAL_STATE: MarketOrderSummaryState = Map();

export const marketOrderSummaryReducer = (
  state: MarketOrderSummaryState,
  action: MarketOrderAction,
): MarketOrderSummaryState => {
  switch (action.type) {
    case ActionTypes.CREATE: {
      const { payload: { clOrdID, side, symbol } } = action;
      return state.set(
        clOrdID,
        {
          clOrdID,
          commCurrency: '',
          isComplete: false,
          symbol,
          quantity: 0,
          amount: 0,
          price: 0,
          fees: 0,
          status: '',
          side,
        },
      );
    }

    case ActionTypes.UPDATE: {
      const {
        payload: {
          clOrdID, execType, ordStatus: status, cumQty, lastPrice,
          lastQty, commission, commCurrency, avgPrice,
        },
      } = action;

      const report = state.get(clOrdID);
      if (!report) {
        return state;
      }

      const isComplete = status === OrderStatus.FILLED || execType === ExecutionType.CANCELLED;

      switch (execType) {
        case ExecutionType.TRADE:
          return state.set(
            clOrdID,
            {
              ...report,
              quantity: cumQty,
              amount: report.amount + (lastQty * lastPrice),
              price: avgPrice,
              fees: report.fees + commission,
              commCurrency,
              status,
              isComplete,
            },
          );

        case ExecutionType.CANCELLED:
        default:
          return state.set(clOrdID, { ...report, isComplete });
      }
    }
    case ActionTypes.REMOVE:
      return state.has(action.payload) ? state.delete(action.payload) : state;
    default:
      return state;
  }
};
