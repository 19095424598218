import React from 'react';

export const CrossIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
  className?: string;
}> = ({ height = 20, width = 20, className = '' }) => (
  <svg width={width} height={height} className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.667 8.667h-4v4H7.333v-4h-4V7.333h4v-4h1.334v4h4v1.334z" fill="currentColor"/>
  </svg>
);
