import React from 'react';
import { GridContainer } from './styled';

export const GridHeader = () => (
  <GridContainer>
    <span>SYMBOL</span>
    <span>BID</span>
    <span>OFFER</span>
    <span>MAX BID QUANTITY</span>
    <span>MAX OFFER QUANTITY</span>
    <span>TRADE</span>
  </GridContainer>
);
