import { PositionRow } from '../services/positions/positionsReducer';
import { GridColDef } from '../../../shared/Grid/GridColDef';
import { formatNumber } from '../../../shared/formatters';

const cellStyle = {
  type: 'rightAligned',
  cellStyle: {
    paddingRight: 0,
  },
};

export const positionsColDefs:GridColDef<PositionRow>[] = [
  //   comparator: (pos1, pos2, node1, node2) => sortPositionRows(node1.data, node2.data),
  {
    colId: 'accountName',
    rowGroup: true,
    hide: true,
    suppressFiltersToolPanel: true,
    suppressColumnsToolPanel: true,
    valueGetter: (params) => params.data.account,
  } as GridColDef<PositionRow>,
  {
    colId: 'productName',
    rowGroup: true,
    hide: true,
    suppressFiltersToolPanel: true,
    suppressColumnsToolPanel: true,
    valueGetter: (params) => params.data.product,
  } as GridColDef<PositionRow>,
  {
    colId: 'contractName',
    hide: true,
    suppressFiltersToolPanel: true,
    suppressColumnsToolPanel: true,
    valueGetter: (params) => params.data.contract,
  } as GridColDef<PositionRow>,
  {
    colId: 'totalLong',
    width: 95,
    headerName: 'TOTAL LONG',
    cellClass: 'ag-grid-number-cell',
    ...cellStyle,
    valueGetter: (params) => (params.data.netPosition > 0 ? params.data.netPosition : 0),
    aggFunc: 'sum',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<PositionRow>,
  {
    colId: 'totalShort',
    width: 95,
    headerName: 'TOTAL SHORT',
    cellClass: 'ag-grid-number-cell',
    ...cellStyle,
    valueGetter: (params) => (params.data.netPosition < 0 ? Math.abs(params.data.netPosition) : 0),
    aggFunc: 'sum',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<PositionRow>,
  {
    hide: true,
    suppressFiltersToolPanel: true,
    suppressColumnsToolPanel: true,
    colId: 'unrealisedPnl',
    width: 110,
    headerName: 'UNREALISED PNL',
    cellClass: 'ag-grid-number-cell',
    ...cellStyle,
    valueGetter: (params) => params.data.unrealisedPnl,
    valueFormatter: (params) => params.value && formatNumber(params.value),
    aggFunc: 'sum',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<PositionRow>,
];
