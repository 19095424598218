import React from 'react';
import { Panel } from '../../shared/components/Panel';
import { useSelectedMarket } from '../../shared/services/selectedMarketService';
import { Chart } from './Chart';

export const ChartContainer: React.FC = () => {
  const market = useSelectedMarket();
  return <Panel> {
    market ? <Chart market={market}/> : null
  }</Panel>;
};
