import { bind, shareLatest } from '@react-rxjs/core';
import { createListener } from '@react-rxjs/utils';
import { Map } from 'immutable';
import { Observable, Subject } from 'rxjs';
import {
  filter,
  map,
  scan,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import { getFetchClearing$ } from './fetchService';
import {
  ConnectionStatus,
  connectionStatusState$,
} from '../websocket/connectionStatus';
import { getToken } from '../websocket/token';

const token$ = new Subject<string>();

export interface UserAccountRaw {
  account_id: string;
  account_number: string;
  fix_ids: string[];
  origin?: number;
  cti?: number;
  member_users?: string[];
  emarket_account_number: string;
  name?: string;
}

export interface UserAccountResponse {
  accounts: UserAccountRaw[];
}

const [account$, setSelectedAccount] = createListener<UserAccountRaw>();
export const [useSelectedAccount, selectedAccount$] = bind(account$.pipe(startWith(undefined)));
export { setSelectedAccount };

export const getAccounts = () => token$.next(getToken());

export const accountCall$ = token$.pipe(
  switchMap(() => getFetchClearing$({ restCallName: 'accounts' })),
);

export const accountState$: Observable<UserAccountResponse> = accountCall$.pipe(
  filter((fetchResponse) => fetchResponse.error === false),
  map((fetchResponse) => fetchResponse.response as UserAccountResponse),
  shareLatest(),
);

export const accountErrorRest$ = accountCall$.pipe(
  filter((fetchResponse) => fetchResponse.error === true),
  map((fetchResponse) => fetchResponse.error),
  shareLatest(),
);

export type AccountNumberMap = Map<string, string>;
export const initialAccountNumberMap = Map<string, string>();

export const accountMapReducer = (
  exisitingMap: AccountNumberMap,
  accountsResponse: UserAccountResponse,
): AccountNumberMap => exisitingMap.withMutations(
  (accountMap) => accountsResponse.accounts.forEach((account) => {
    accountMap.set(account.account_id, account.account_number);
      account.fix_ids?.forEach((fix) => accountMap.set(fix, account.account_number));
  }),
);

export const accountMap$: Observable<AccountNumberMap> = accountState$.pipe(
  scan(accountMapReducer, initialAccountNumberMap),
  startWith(initialAccountNumberMap),
  shareLatest(),
);

export const emarketAccountMapReducer = (
  exisitingMap: AccountNumberMap,
  accountsResponse: UserAccountResponse,
): AccountNumberMap => exisitingMap.withMutations(
  (accountMap) => accountsResponse.accounts.forEach((account) => {
    accountMap.set(account.account_id, account.emarket_account_number);
      account.fix_ids?.forEach((fix) => accountMap.set(fix, account.emarket_account_number));
  }),
);

export const emarketAccountMap$: Observable<AccountNumberMap> = accountState$.pipe(
  scan(emarketAccountMapReducer, initialAccountNumberMap),
  startWith(initialAccountNumberMap),
  shareLatest(),
);

export const getAccountsWhenAuthenticated$ = connectionStatusState$.pipe(
  filter((status) => status === ConnectionStatus.AUTHENTICATED),
  tap(() => {
    getAccounts();
  }),
);

export const getFixId = (account: UserAccountRaw) => (account && account.fix_ids ? account.fix_ids[0] : '');

export const [useAccounts, accountsResponse$] = bind(accountState$, null);
export const [useAccountErrors, isAccountError$] = bind(accountErrorRest$, false);

const accountTypeLabel = (
  account_number: string,
  spotPermissions: string[],
  futuresPermissions: string[],
) => {
  const isSpot = spotPermissions.includes(account_number);
  const isFutures = futuresPermissions.includes(account_number);
  if (isSpot && isFutures) return 'Spot&Futures';
  if (isSpot) return 'Spot';
  if (isFutures) return 'Futures';
  return '';
};

export const getAccountLabel = (
  spotPermissions: string[],
  futuresPermissions: string[],
  accNumber: string,
  accName?: string,
) => `${accName} (${accNumber}) ${accountTypeLabel(accNumber, spotPermissions, futuresPermissions)}`;
