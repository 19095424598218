import React, { useState, useRef, useEffect } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useSubscribe } from '@react-rxjs/core';
import { GridApi } from 'ag-grid-community';
import { GridWrapper, RightTabPanel, LeftTabPanel } from './styled';

import { OpenOrdersGrid } from './gridComponents/OpenOrdersGrid';
import { PositionsGrid } from './gridComponents/PositionsGrid/PositionsGrid';
import { TradesGrid } from './gridComponents/TradesGrid';
import { GridTabPanel } from './GridTabPanel';
import { getMassOrdersWhenAccounts$ } from './services/openOrders/openOrdersService';
import { getMassAlgoOrdersWhenAccounts$ } from './services/algoOrders/algoOrdersService';
import { OrderHistoryGrid } from './gridComponents/OrderHistoryGrid';
import { BlockTradesGrid } from './gridComponents/BlockTradesGrid';
import { AlgoInstructionsGrid } from './gridComponents/AlgoInstructionsGrid';
import { AlgoOrdersGrid } from './gridComponents/AlgoOrdersGrid';
import { usePermissions } from '../../shared/services/userPermissionsService';

interface Props {}

export const LargeOrdersGridsTabPanel: React.FunctionComponent<Props> = () => {
  useSubscribe(getMassOrdersWhenAccounts$);
  useSubscribe(getMassAlgoOrdersWhenAccounts$);
  const permissions = usePermissions();
  const [hiddenFilter, setHiddenFilter] = useState<boolean>(true);
  const [filterNumber, setFilterNumber] = useState<number>();
  const [hiddenFilterL, setHiddenFilterL] = useState<boolean>(true);
  const [filterNumberL, setFilterNumberL] = useState<number>();
  const [openOrderFilterNum, setopenOrderFilterNum] = useState<number>();
  const [orderHistoryFilterNum, setorderHistoryFilterNum] = useState<number>();
  const [positionsFilterNum, setpositionsFilterNum] = useState<number>();
  const [tradesFilterNum, settradesFilterNum] = useState<number>();
  const [blockFilterNum, setblockFilterNum] = useState<number>();
  const [algoInsFilterNum, setAlgoInsFilterNum] = useState<number>();
  const [algoOrdFilterNum, setAlgoOrdFilterNum] = useState<number>();
  const gridApi1 = useRef<GridApi>();
  const gridApi2 = useRef<GridApi>();
  const gridApi3 = useRef<GridApi>();
  const gridApi4 = useRef<GridApi>();
  const gridApi5 = useRef<GridApi>();
  const gridApi6 = useRef<GridApi>();
  const gridApi7 = useRef<GridApi>();
  const [isPanelHidden, setIsPanelHidden] = useState<boolean>(true);
  const [isPanelHiddenL, setIsPanelHiddenL] = useState<boolean>(true);
  const [currentTab, setCurrentTab] = useState<string>();
  const [currentTabL, setCurrentTabL] = useState<string>();

  const handleSettingL = () => {
    let localApi;
    switch (currentTabL) {
      case 'openOrders':
        localApi = gridApi1.current;
        break;
      case 'orderHistory':
        localApi = gridApi4.current;
        break;
      case 'blockTrades':
        localApi = gridApi5.current;
        break;
      case 'algoInstructions':
        localApi = gridApi6.current;
        break;
      case 'algoOrders':
        localApi = gridApi7.current;
        break;
      default:
        localApi = gridApi1.current;
        break;
    }
    setIsPanelHiddenL((current) => !current);
    if (isPanelHiddenL && localApi) {
      localApi.openToolPanel('columns');
    } else if (localApi) {
      localApi.closeToolPanel();
    }
  };

  const handleSetting = () => {
    let localApi;
    switch (currentTab) {
      case 'trades':
        localApi = gridApi2.current;
        break;
      case 'openPositions':
        localApi = gridApi3.current;
        break;
      default:
        localApi = gridApi2.current;
        break;
    }
    setIsPanelHidden((current) => !current);
    if (isPanelHidden && localApi) {
      localApi.openToolPanel('columns');
    } else if (localApi) {
      localApi.closeToolPanel();
    }
  };

  useEffect(() => {
    let localApi;
    switch (currentTabL) {
      case 'openOrders':
        setFilterNumberL(openOrderFilterNum);
        localApi = gridApi1.current;
        break;
      case 'orderHistory':
        setFilterNumberL(orderHistoryFilterNum);
        localApi = gridApi4.current;
        break;
      case 'blockTrades':
        setFilterNumberL(blockFilterNum);
        localApi = gridApi5.current;
        break;
      case 'algoInstructions':
        setFilterNumberL(algoInsFilterNum);
        localApi = gridApi6.current;
        break;
      case 'algoOrders':
        setFilterNumberL(algoOrdFilterNum);
        localApi = gridApi7.current;
        break;
      default:
        setFilterNumberL(openOrderFilterNum);
        localApi = gridApi1.current;
        break;
    }
    const currentPanelStatus = localApi ? localApi.getOpenedToolPanel() : null;
    if (currentPanelStatus !== null) {
      setIsPanelHiddenL(false);
    } else {
      setIsPanelHiddenL(true);
    }
    // eslint-disable-next-line
  }, [currentTabL]);

  useEffect(() => {
    let localApi;
    switch (currentTab) {
      case 'trades':
        setFilterNumber(tradesFilterNum);
        localApi = gridApi2.current;
        break;
      case 'openPositions':
        setFilterNumber(positionsFilterNum);
        localApi = gridApi3.current;
        break;
      default:
        setFilterNumber(openOrderFilterNum);
        localApi = gridApi2.current;
        break;
    }
    const currentPanelStatus = localApi ? localApi.getOpenedToolPanel() : null;
    if (currentPanelStatus !== null) {
      setIsPanelHidden(false);
    } else {
      setIsPanelHidden(true);
    }
    // eslint-disable-next-line
  }, [currentTab]);

  const handleOpenOrders = (numberOfFilter: number) => {
    setopenOrderFilterNum(numberOfFilter);
    setFilterNumberL(numberOfFilter);
  };

  const handleTrades = (numberOfFilter: number) => {
    settradesFilterNum(numberOfFilter);
    setFilterNumber(numberOfFilter);
  };

  const handleOpenPositions = (numberOfFilter: number) => {
    setpositionsFilterNum(numberOfFilter);
    setFilterNumber(numberOfFilter);
  };

  const handleHistory = (numberOfFilter: number) => {
    setorderHistoryFilterNum(numberOfFilter);
    setFilterNumberL(numberOfFilter);
  };

  const handleBlock = (numberOfFilter: number) => {
    setblockFilterNum(numberOfFilter);
    setFilterNumberL(numberOfFilter);
  };

  const handleAlgoIns = (numberOfFilter: number) => {
    setAlgoInsFilterNum(numberOfFilter);
    setFilterNumberL(numberOfFilter);
  };

  const handleAlgoOrd = (numberOfFilter: number) => {
    setAlgoOrdFilterNum(numberOfFilter);
    setFilterNumberL(numberOfFilter);
  };

  return (
    <GridWrapper>
      <LeftTabPanel>
        <GridTabPanel
          tabs={[
            {
              title: 'Open Orders',
              tabId: 'openOrders',
              content: (
                <OpenOrdersGrid
                  isFilterHidden={hiddenFilterL}
                  changeNumber={(numberOfFilter: number) => handleOpenOrders(numberOfFilter)
                  }
                  gridAPI={gridApi1}
                />
              ),
            },
            {
              title: 'Order History',
              tabId: 'orderHistory',
              content: (
                <OrderHistoryGrid
                  isFilterHidden={hiddenFilterL}
                  changeNumber={(numberOfFilter: number) => handleHistory(numberOfFilter)
                  }
                  gridAPI={gridApi4}
                />
              ),
            },
            ...(!permissions?.error && permissions?.blockTrades
              ? [
                {
                  title: 'Block Trade Requests',
                  tabId: 'blockTrades',
                  content: (
                      <BlockTradesGrid
                        isFilterHidden={hiddenFilterL}
                        changeNumber={(numberOfFilter: number) => handleBlock(numberOfFilter)
                        }
                        gridAPI={gridApi5}
                      />
                  ),
                },
              ]
              : []),
            {
              title: 'Algo Instructions',
              tabId: 'algoInstructions',
              content: (
                <AlgoInstructionsGrid
                  isFilterHidden={hiddenFilterL}
                  changeNumber={(numberOfFilter: number) => handleAlgoIns(numberOfFilter)
                  }
                  gridAPI={gridApi6}
                />
              ),
            },
            {
              title: 'Algo Orders',
              tabId: 'algoOrders',
              content: (
                <AlgoOrdersGrid
                  isFilterHidden={hiddenFilterL}
                  changeNumber={(numberOfFilter: number) => handleAlgoOrd(numberOfFilter)
                  }
                  gridAPI={gridApi7}
                />
              ),
            },
          ]}
          switchTab={setCurrentTabL}
          onSettingClick={() => handleSettingL()}
          onFilterClick={() => setHiddenFilterL((current) => !current)}
          filterNumber={filterNumberL}
          settingPanelHidden={isPanelHiddenL}
          filterHidden={hiddenFilterL}
          isLeft
        />
      </LeftTabPanel>

      <RightTabPanel>
        <GridTabPanel
          tabs={[
            {
              title: 'Trades',
              tabId: 'trades',
              content: (
                <TradesGrid
                  isFilterHidden={hiddenFilter}
                  changeNumber={(numberOfFilter: number) => handleTrades(numberOfFilter)
                  }
                  gridAPI={gridApi2}
                />
              ),
            },
            ...(permissions?.error
            || (permissions?.futuresPermissions?.length ?? 0) > 0
              ? [
                {
                  title: 'Open Positions',
                  tabId: 'openPositions',
                  content: (
                      <PositionsGrid
                        isFilterHidden={hiddenFilter}
                        changeNumber={
                          (numberOfFilter: number) => handleOpenPositions(numberOfFilter)
                        }
                        gridAPI={gridApi3}
                      />
                  ),
                },
              ]
              : []),
          ]}
          switchTab={setCurrentTab}
          onSettingClick={() => handleSetting()}
          onFilterClick={() => setHiddenFilter((current) => !current)}
          filterNumber={filterNumber}
          settingPanelHidden={isPanelHidden}
          filterHidden={hiddenFilter}
          isLeft={false}
        />
      </RightTabPanel>
    </GridWrapper>
  );
};
