import { bind } from '@react-rxjs/core';
import { of, Subject } from 'rxjs';
import {
  catchError, filter, map, switchMap, tap, skip,
} from 'rxjs/operators';

import { getUserPermissions$ } from './getUserPermissions$';
import {
  ConnectionStatus,
  connectionStatusState$,
} from '../websocket/connectionStatus';
import { setNewUserPrefs, getUserPrefs } from '../../components/navbar/services/userPrefsService';

export const userPermissionsSubject$ = new Subject<any>();

export interface UserPermissions {
  error: boolean;
  spotPermissions: string[];
  futuresPermissions: string[];
  blockTrades: boolean;
  userId: string;
  apiKeyPermission: string[];
}

interface RestResponse {
  user?: {
    api_key_permissions: string[];
    trading_permissions: {
      spot: string[];
      futures: string[];
    };
    user_id: string;
  };
  error?: string;
}

const failedLookupResponse: UserPermissions = {
  error: true,
  blockTrades: false,
  spotPermissions: [],
  futuresPermissions: [],
  userId: '',
  apiKeyPermission: [],
};
export const [usePermissions, userPermissions$] = bind(
  userPermissionsSubject$.pipe(
    switchMap(() => getUserPermissions$().pipe(
      map((resp: RestResponse) => {
        if (resp.error) {
          return failedLookupResponse;
        }
        const currentPrefs = getUserPrefs();
        setNewUserPrefs({
          ...currentPrefs,
          nickname: resp.user?.user_id || '',
        });
        return {
          error: false,
          blockTrades:
              (resp.user?.api_key_permissions ?? []).findIndex(
                (permission) => permission === 'submit_block_trade',
              ) > -1,
          spotPermissions: resp.user?.trading_permissions.spot,
          futuresPermissions: resp.user?.trading_permissions.futures,
          userId: resp.user?.user_id,
          apiKeyPermission: resp.user?.api_key_permissions ?? [],
        } as UserPermissions;
      }),
      catchError(() => of(failedLookupResponse)),
    )),
  ),
  undefined,
);

export const userPermissionsState$ = userPermissions$.pipe(skip(1));

export const getUserPermissions = () => {
  userPermissionsSubject$.next();
};

export const getUserPermissionsWhenAuthenticated$ = connectionStatusState$.pipe(
  filter((status) => status === ConnectionStatus.AUTHENTICATED),
  tap(() => {
    getUserPermissions();
  }),
);

export enum ApiPermission {
  SETTLEMENT_SERVICE = 'settlement_service',
}

export const useHasPermission = () => {
  const permissions = usePermissions();
  return (permission: ApiPermission) => (
    permissions ? permissions.apiKeyPermission.includes(permission) : false
  );
};
