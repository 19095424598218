import React from 'react';
import { OuterContainer, InnerContainer } from './AccountManagement.styled';
import { SettlementsListContainer } from './settlements';
import { AccountManagementViewType } from './types';

interface AccountManagementProps {
  view: AccountManagementViewType;
}

export const AccountManagement:React.FC<AccountManagementProps> = ({ view }) => {
  let viewComponent;

  switch (view) {
    case AccountManagementViewType.SETTLEMENTS:
    default:
      viewComponent = <SettlementsListContainer />;
  }

  return (
    <OuterContainer>
        <InnerContainer>
            {viewComponent}
        </InnerContainer>
    </OuterContainer>
  );
};
