import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TitleWrapper } from '../style/styled';
import { Panel } from './Panel';
import {
  PriceHeader, QuantityHeader, TimeHeader, QuantityCell, PriceCell, TimeCell,
} from '../../components/tradeHistory/styled';
import {
  MyQty as OrderBookMyQty,
  MyQtyHeader as OrderBookMyQtyHeader,
  Price as OrderBookPrice,
  PriceHeader as OrderbookPriceHeader,
  Quantity as OrderBookQuantity,
  QuantityHeader as OrderBookQuantityHeader,
} from '../../components/orderBook/styles';

const StyledAccordion = styled(Accordion)`
    &.expandedAccordion {
        margin: 0 0 2px 0;
    }
    margin-bottom: 2px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.secondary.main};
    box-shadow: none;
    @media (max-width: 480px) {
        .MuiIconButton-label{
            -ms-transform: rotate(180deg); /* IE 9 */
            transform: rotate(180deg);
        }
    }
    
`;

// We set min-height to 0 in both collapsed and expanded cases
// to override default behavior making MUI accordions too large
const StyledSummary = styled(AccordionSummary)`
    display: flex;
    background-color: ${({ theme }) => theme.palette.primary.primary1};
    color: ${({ theme }) => theme.palette.secondary.main};
    font-size: 11px;
    font-weight: 550;
    height: 34px;
    min-height: 0px;

    &.expandedSummary {
        min-height: 0px;
    }

    .summaryContent {
        margin: 0;
        justify-content: space-between;
    }

    .expandIcon {
        padding: 0 8px;
    }

    svg {
        width: 16px;
        height: 16px;
        color: ${({ theme }) => theme.palette.secondary.secondary};
    }
`;

// for the content, replace styling in order book and trading history widgets
// to fit new location
const StyledDetails = styled(AccordionDetails)`
    background-color: ${({ theme }) => theme.palette.primary.main};

    ${TitleWrapper} {
        display: none;
    }

    ${Panel} {
        border: none;
        max-height: 350px;
        height: inherit;
    }

    /* Trade History overrides */

    ${QuantityCell} {
        width: 56px;
    }

    ${PriceCell} {
        width: 56px;
    }

    ${TimeCell} {
        width: 86px;
    }

    ${PriceHeader} {
        width: 76px;
    }

    ${QuantityHeader} {
        width: 52px;
    }

    ${TimeHeader} {
        width: 75px;
    }

    /* Order Book overrides */

    ${OrderBookMyQtyHeader}, ${OrderBookMyQty} {
        width: 48px;
    }

    ${OrderBookPrice} {
        width: 80px;
    }

    ${OrderbookPriceHeader} {
        width: 75px;
    }

    ${OrderBookQuantity} {
        width: 56px;
    }

    ${OrderBookQuantityHeader} {
        width: 58px;
    }
`;

const StyledCollapseText = styled.div`
    font-weight: bold;
`;

const StyledExpandIcon = styled(ExpandMoreIcon)`
    color: ${({ theme }) => theme.palette.secondary.main};
`;

interface Props {
  collapsedText: string;
  defaultExpanded?: boolean;
  rightContent?: React.ReactNode;
  // optional params that give you control over opening and closing the accordion in react
  // if not provided, will simply open and close whenever clicked
  isOpen?: boolean;
  onChange?: () => void;
}

export const AccordionWidget: React.FC<Props> = ({
  children, collapsedText, defaultExpanded, isOpen, onChange, rightContent,
}) => (
    <StyledAccordion expanded={isOpen} onChange={onChange} defaultExpanded={defaultExpanded} classes={{ root: 'accordionRoot', expanded: 'expandedAccordion' }}>
        <StyledSummary classes={{ expandIcon: 'expandIcon', content: 'summaryContent', expanded: 'expandedSummary' }} expandIcon={<StyledExpandIcon />}>
            <StyledCollapseText>{collapsedText}</StyledCollapseText>
            <div>{rightContent}</div>
        </StyledSummary>
        <StyledDetails>
            {children}
        </StyledDetails>
    </StyledAccordion>
);
