import { webSocket } from 'rxjs/webSocket';
import { Config } from '../../config/config';
import { ConnectionStatus } from '../websocket/connectionStatus';
import { Message } from '../websocket/types';
import { setConnectionStatus } from './algoConnectionStatus';

export const algoWebSocketSubject = webSocket<Message>({
  url: Config.ALGO_WEBSOCKET_URL,
  openObserver: {
    next: () => {
      setConnectionStatus(ConnectionStatus.OPEN);
    },
  },
  closeObserver: {
    next: () => {
      setConnectionStatus(ConnectionStatus.CLOSED);
    },
  },
});
