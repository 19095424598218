import Decimal from 'decimal.js';
import { Map } from 'immutable';
import { getTradeDate, getExecutionOrderDateMilliseconds } from '../../../../shared/formatters';
import { ExecutionReport } from '../openOrders/openOrdersReducer';

export interface HistoricalTradeResponse {
  trades: Trade[];
}

export interface NewTradeResponse {
  response: HistoricalTradeResponse,
  error: boolean;
}

export interface TradeRow {
  time: string;
  contract: string;
  side: string;
  qty: number;
  price: number;
  aggressor: string;
  fees: number;
  feeCurrency: string;
  tradeID: string;
  orderID: string;
  clOrdID: string;
  account: string;
  timeInMilliseconds?: number;
  type:string;
}

export interface Trade {
  account_id: string;
  aggressor: string;
  asset_type: string;
  clearing_fee: string;
  client_order_id: string;
  contract_symbol: string;
  customer_account_ref?: string;
  description: string;
  exchange_fee: string;
  fee_type: string;
  fix_id: string;
  notional: string;
  product_code: string;
  product_suffix: string;
  px: string;
  px_type: string;
  qty: string;
  qty_type: string;
  record_type: string;
  side: string;
  state: string;
  tcr_id: string;
  time: string;
  total_amount: string;
  trade_id: string;
  trade_report_id: string;
  trade_type: string;
  accountName: string;
}

export const convertTradeToTradeRow = (trade: Trade) => ({
  time: trade.time,
  contract: trade.contract_symbol,
  side: trade.side,
  qty: Math.abs(Number(trade.qty)),
  price: Number(trade.px),
  aggressor: trade.aggressor,
  fees: Decimal.add(trade.exchange_fee, trade.clearing_fee).toNumber() * -1,
  feeCurrency: trade.fee_type,
  tradeID: trade.trade_id,
  orderID: trade.tcr_id,
  clOrdID: trade.client_order_id,
  account: trade.accountName,
  timeInMilliseconds: getTradeDate(trade.time).getTime(),
  type: trade.trade_type,
});

export const convertExecutionReportToTradeRow = (executionReport: ExecutionReport) => ({
  time: executionReport.transactTime,
  contract: executionReport.symbol,
  side: executionReport.side,
  qty: executionReport.lastQty,
  price: executionReport.lastPrice,
  aggressor: executionReport.matchingType === 'TAKER_IS_NOT_AGGRESSOR' ? 'N' : 'Y',
  fees: executionReport.commission,
  feeCurrency: executionReport.commCurrency,
  tradeID: executionReport.execID,
  orderID: executionReport.orderID,
  clOrdID: executionReport.clOrdID,
  account: executionReport.accountNumber,
  timeInMilliseconds: getExecutionOrderDateMilliseconds(executionReport.transactTime),
  type: 'REGULAR',
});

export type TradeMap = Map<string, TradeRow>;
export const initialTradeMap: TradeMap = Map<string, TradeRow>();

export const fillsOrderReducer = (existingOpenOrders: TradeMap,
  orderUpdate: ExecutionReport): TradeMap => {
  if (orderUpdate.execType === 'TRADE') {
    return existingOpenOrders
      .withMutations((map) => map
        .set(orderUpdate.execID, convertExecutionReportToTradeRow(orderUpdate)));
  }
  return existingOpenOrders.withMutations((map) => map);
};
