import { bind } from '@react-rxjs/core';
import React, { useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import styled, { DefaultTheme } from 'styled-components';
import { DownArrowIcon } from '../../icons/DownArrowIcon';
import { UpArrowIcon } from '../../icons/UpArrowIcon';
import { Direction } from '../../shared/Direction';
import { formatNumber } from '../../shared/formatters';
import { isWebsocketOpen } from '../../shared/helperFunctions/connectionStatusUtils';
import { withSubscribe } from '../../shared/helperFunctions/withSubscribe';
import { SecurityStatusEnum } from '../../shared/services/securityStatus';
import { ConnectionStatus } from '../../shared/websocket/connectionStatus';
import { getTradeHistorySubscription$ } from '../tradeHistory/tradeHistoryService';
import { MidPriceDetails, midPriceState$ } from './services/midPriceService';
import { midHeight } from './styles';

// number of milliseconds to highlight for when a new trade happens
const HIGHLIGHT_TIMEOUT = 200;

const getBackgroundColour = (
  theme: DefaultTheme,
  highlight: boolean,
  direction?: Direction | null,
) => {
  if (highlight && direction === Direction.Buy) {
    return theme.palette.primary.primary1;
  }
  if (highlight && direction === Direction.Sell) {
    return theme.palette.trading.tradingSell3;
  }
  return theme.palette.primary.main;
};

const Panel = styled.div<{
  highlight: boolean;
  direction?: Direction | null;
}>`
  display: flex;
  height: ${midHeight}px;
  line-height: ${midHeight}px;
  background-color: ${({ theme, direction, highlight }) => getBackgroundColour(theme, highlight, direction)};
`;

const Content = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
`;

const Price = styled.div`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-family: Roboto Mono;
`;

const PriceText = styled.div`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-family: Roboto;
  padding-right: 10px;
`;

const StatusMessage = styled.div<{ isWebsocketMessage?: boolean }>`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-family: Roboto Mono;
  font-size: ${(isWebsocketMessage) => (isWebsocketMessage ? '14px' : '16px')};
`;

const UpArrow = styled(UpArrowIcon)`
  margin-top: auto;
  margin-bottom: auto;
  color: ${({ theme }) => theme.palette.trading.tradingBuy};
`;

const DownArrow = styled(DownArrowIcon)`
  margin-top: auto;
  margin-bottom: auto;
  color: ${({ theme }) => theme.palette.trading.tradingSell};
`;

interface Props {
  securityStatus: SecurityStatusEnum;
  connectionStatus: ConnectionStatus;
  disabled: boolean;
}

const directions = {
  up: <UpArrow />,
  down: <DownArrow />,
  none: null,
};

const getDirectionFromMidprice = (midPrice: MidPriceDetails) => directions[midPrice.direction];

const getDirectionFromTrade = (direction?: Direction | null) => {
  if (direction === Direction.Buy) {
    return <UpArrow />;
  }
  if (direction === Direction.Sell) {
    return <DownArrow />;
  }
  return null;
};

const getMarketStatusMessage = (
  status: SecurityStatusEnum,
  connectionStatus: ConnectionStatus,
) => {
  if (!isWebsocketOpen(connectionStatus)) {
    return (
      <StatusMessage isWebsocketMessage>
        Websocket is not Connected
      </StatusMessage>
    );
  }
  switch (status) {
    case SecurityStatusEnum.CLOSED:
    case SecurityStatusEnum.HALTED:
      return <StatusMessage>Market is Closed</StatusMessage>;
    default:
      return null;
  }
};

const [useMidPriceState, midPrice$] = bind(
  combineLatest([
    getTradeHistorySubscription$().pipe(startWith(null)),
    midPriceState$,
  ]).pipe(map(([tradeHistory, midPrice]) => ({ tradeHistory, midPrice }))),
);

const MidPriceComponent: React.FC<Props> = ({
  securityStatus,
  connectionStatus,
  disabled,
}) => {
  const [showLatestTrade, setShowLatestTrade] = useState(false);
  const [highlight, setHighlight] = useState(false);
  const { tradeHistory, midPrice } = useMidPriceState();
  const latestTrade = tradeHistory?.trades?.[0];
  const midPriceDirection = midPrice
    ? getDirectionFromMidprice(midPrice)
    : null;
  const latestTradeDirection = showLatestTrade
    ? getDirectionFromTrade(latestTrade?.direction)
    : null;
  const midPriceContent = (
    <>
      <PriceText>Mid-Price</PriceText>
      <Price>{midPrice && formatNumber(midPrice.price)}</Price>
      {midPriceDirection}
    </>
  );
  const tradeContent = (
    <>
      <PriceText>Last Trade Price</PriceText>
      <Price>{formatNumber(latestTrade?.price)}</Price>
      {latestTradeDirection}
    </>
  );

  let body: JSX.Element | null = midPriceContent;
  if (disabled) {
    body = getMarketStatusMessage(securityStatus, connectionStatus);
  } else if (showLatestTrade) {
    body = tradeContent;
  }

  useEffect(() => {
    if (tradeHistory) {
      setShowLatestTrade(true);
      setHighlight(true);
      const highlightId = setTimeout(() => {
        setHighlight(false);
      }, HIGHLIGHT_TIMEOUT);
      return () => {
        clearTimeout(highlightId);
      };
    }
    return () => {};
  }, [tradeHistory]);

  useEffect(() => {
    if (midPrice) {
      setShowLatestTrade(false);
    }
  }, [midPrice]);

  return (
    <Panel
      highlight={highlight && showLatestTrade}
      direction={latestTrade?.direction}
    >
      <Content>{body}</Content>
    </Panel>
  );
};

export const MidPrice = withSubscribe(MidPriceComponent, midPrice$);
