import styled, { DefaultTheme } from 'styled-components';
import { Panel } from '../../shared/components/Panel';
import { Direction } from '../../shared/Direction';

export const quantityWidth = 66;
export const priceWidth = 70;
export const barWidth = 30;
export const midHeight = 30;

const headerHeight = 28;

export const OrderBookPanel = styled(Panel)`
  height: calc(100% - 2px);
`;

export const TableHeaders = styled.div`
  display: flex;
  height: ${headerHeight}px;
  line-height: ${headerHeight}px;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.primary1};
`;

export const Body = styled.div`
  height: calc(100% - ${headerHeight * 2}px);
  overflow: hidden;
`;

export const PriceHeader = styled.div`
  width: ${priceWidth}px;
  text-align: right;
`;

export const QuantityHeader = styled.div`
  width: ${quantityWidth}px;
  text-align: right;
`;

export const MyQtyHeader = styled.div`
  width: ${quantityWidth}px;
  text-align: right;
`;

export const BarHeader = styled.div`
  width: ${barWidth}px;
`;

export const Book = styled.div`
  overflow: auto;
  border-top: ${({ theme }) => `1px solid ${theme.palette.primary.primary1}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.primary.primary1}`};
  height: calc(50% - ${midHeight / 2}px);
  min-height: 22px;
  overflow-y: scroll;
  &:not(:hover) {
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border: 0;
      box-shadow: none;
    }
  }
`;

export const OfferBook = styled(Book)`
  display: flex;
  flex-direction: column-reverse;
`;

export const Row = styled.div`
  display: flex;
  height: 22px;
  min-height: 22px;
  line-height: 22px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  font-family: Roboto Mono;
  font-size: 11px;
`;

export const buyColor = (disabled: boolean, theme: DefaultTheme) => (disabled
  ? theme.palette.trading.tradingBuy1
  : theme.palette.trading.tradingBuy);

export const sellColor = (disabled: boolean, theme: DefaultTheme) => (disabled
  ? theme.palette.trading.tradingSell1
  : theme.palette.trading.tradingSell);

export const Price = styled.div<{ direction: Direction; disabled: boolean }>`
  width: ${priceWidth}px;
  text-align: right;
  color: ${({ direction, theme, disabled }) => (direction === Direction.Buy
    ? buyColor(disabled, theme)
    : sellColor(disabled, theme))};
  font-weight: bold;
`;

export const Quantity = styled.div<{
  disabled: boolean;
}>`
  width: ${quantityWidth}px;
  text-align: right;
  color: ${({ theme, disabled }) => (disabled
    ? theme.palette.secondary.secondary3
    : theme.palette.secondary.main)};
`;

export const MyQty = styled.div`
  width: ${quantityWidth}px;
  text-align: right;
  color: ${({ theme }) => theme.palette.accent.accentPrimary};
  font-weight: bold;
`;

// righthand Bar styles
export const BarContainer = styled.div`
  display: flex;
  width: ${barWidth}px;
  height: 20px;
  padding-top: 1px;
  padding-bottom: 1px;
  justify-content: flex-end;
`;

export const BarFill = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.palette.primary.primary2};
`;
