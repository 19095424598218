import React from 'react';
import styled from 'styled-components/macro';
import { Subscription } from 'rxjs';
import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import { submitOrderCancel } from '../services/openOrders/cancelEntry';
import { ExecutionReport } from '../services/openOrders/openOrdersReducer';
import { CancelConfirmation } from './CancelConfirmation';
import { OverlayPopup, Position } from '../../../shared/components/Overlay';
import { handleMouseclickPosition } from '../../../shared/helperFunctions/handleMouseclickPosition';
import { PrimaryButton } from '../../../shared/style/styled';
import { ConnectionStatus, connectionStatusState$ } from '../../../shared/websocket/connectionStatus';
import { ContentWithTooltip } from '../../../shared/components/ContentWithTooltip';

const CancelCellWrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    margin-left: 3px;
`;

export const BackDrop = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 8;
`;

export const Menu = styled.div`
    border-radius: 2px;
    background-color: ${({ theme }) => theme.palette.primary.primary4};
    padding: 4px 5px;
    font-size: 10px;
    z-index: 9;
    min-width: 100px;
    min-height: 100px;

`;

const CancelConfirmButton = styled(PrimaryButton)`
  margin-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 11px;
  background-color: ${({ theme }) => theme.palette.primary.primary5};
  color: ${({ theme }) => theme.palette.secondary.secondary};
  border: none;
  outline: none;
  cursor: pointer;
  opacity: .8;
  :hover {
    opacity: 1
  }
  :focus {
    opacity: 1
  }
  :active {
    opacity: .7
  }

  :disabled {
    pointer-events: none;
    opacity: .5;
  }
`;

interface CancelCellProps extends ICellRendererParams {
  value: ExecutionReport;
  data: ExecutionReport;
}

interface CancelCellState {
  params: CancelCellProps,
  connectionStatus?: ConnectionStatus;
  connectionStatusSubscription?: Subscription;
  position?: Position;
}

export class CancelCell extends React.Component<CancelCellProps, CancelCellState>
  implements ICellRenderer {
  constructor(props: CancelCellProps) {
    super(props);
    this.state = {
      params: props,
    };
  }

  componentDidMount() {
    const subscription = connectionStatusState$.subscribe((status) => {
      this.setState({ connectionStatus: status });
    });

    this.setState({ connectionStatusSubscription: subscription });
  }

  componentWillUnmount() {
    this.state.connectionStatusSubscription?.unsubscribe();
  }

  public render() {
    const disableButton = this.state.connectionStatus !== ConnectionStatus.AUTHENTICATED;
    return (
      this.props.value ? <CancelCellWrapper>
        {
          <ContentWithTooltip tooltipText={disableButton ? 'Websocket connection is closed. Please try again later.' : ''}>
            <span>
              <CancelConfirmButton
                disabled={disableButton}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ position: handleMouseclickPosition(e) });
                }}
              >
                Cancel
              </CancelConfirmButton>
            </span>
          </ContentWithTooltip>
        }
        {this.state.position && (
          <OverlayPopup
            position={this.state.position!}
            onClose={() => {
              this.setState({ position: undefined });
            }}
          >
            {this.state.position && (
              <CancelConfirmation
                close={() => this.setState({ position: undefined })}
                handleCancel={() => submitOrderCancel(this.state.params.data)}
              />
            )}
          </OverlayPopup>)}
      </CancelCellWrapper> : null);
  }

  public refresh(params: ICellRendererParams): boolean {
    this.setState({
      params,
    });
    return true;
  }
}
