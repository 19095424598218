import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Direction } from '../../shared/Direction';
import {
  Row,
  PriceCell,
  QuantityCell,
  TimeCell,
  UpArrow,
  DownArrow,
  IconWrapper,
} from './styled';
import { logger } from '../../shared/logger';
import {
  formatPrice,
  formatQuantity,
  findNonZero,
  findZero,
} from '../../shared/formatters';
import { ProductValue } from '../../shared/ProductValue';
import { TH_QTY_ROUNDLOT } from './constants';

const highlightTimeout = 200;

const formatTradeTime = (date: Date) => {
  try {
    return format(date, 'HH:mm:ss');
  } catch (e) {
    logger.debug(e);
  }
  return '';
};

interface Props {
  price: number;
  quantity: number;
  time: Date;
  direction: Direction | null;
  market: ProductValue | undefined;
}

export const TradeHistoryRow: React.FC<Props> = React.memo(
  ({
    price, quantity, time, direction, market,
  }) => {
    const [highlightRow, setHighlightRow] = useState<boolean>(true);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setHighlightRow(false);
      }, highlightTimeout);

      return () => {
        clearTimeout(timeoutId);
      };
    }, []);

    const DirectionIcon = direction === Direction.Buy ? <UpArrow /> : <DownArrow />;
    return (
      <Row
        direction={direction}
        highlightRow={highlightRow}
        data-testid="trade-history-row"
      >
        <IconWrapper direction={direction}>{DirectionIcon}</IconWrapper>
        <PriceCell direction={direction}>
          {formatPrice(price, market!.minPriceIncrement)}
        </PriceCell>
        <QuantityCell>
          <span>{findNonZero(formatQuantity(quantity, TH_QTY_ROUNDLOT))}</span>
          <span style={{ color: 'grey' }}>
            {findZero(formatQuantity(quantity, TH_QTY_ROUNDLOT))}
          </span>
        </QuantityCell>
        <TimeCell>{formatTradeTime(time)}</TimeCell>
      </Row>
    );
  },
);
