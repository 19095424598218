import { FileCopy } from '@material-ui/icons';
import React from 'react';
import bigInt from 'big-integer';
import { SuccessIcon } from '../../../../icons/SuccessIcon';
import { copyToClipboard } from '../../../../shared/helperFunctions/copyToClipboard';
import { InstructionResponse } from '../../services/algos/InstructionResponse';

import {
  OrderEntryCloseButton,
  OrderEntryCorrelation,
  OrderEntryFormTransitionsWrapper,
  OrderEntryTransitionTitle,
} from '../styled';

interface Props {
  latestEntryMessage: InstructionResponse;
  setIsOverlayActive: (isOverlayActive: boolean) => void;
}

export const AlgoOrderEntrySuccess: React.FunctionComponent<Props> = (props) => {
  const { latestEntryMessage } = props;
  return (<OrderEntryFormTransitionsWrapper>
    <OrderEntryTransitionTitle><SuccessIcon /> {' ALGO SUBMITTED:'}</OrderEntryTransitionTitle>
    {latestEntryMessage?.responseID && <OrderEntryCorrelation>
      {bigInt(latestEntryMessage.responseID).toString()}
      <FileCopy onClick={() => copyToClipboard(
        bigInt(latestEntryMessage.responseID)!.toString(),
      )} />
    </OrderEntryCorrelation>}
    <hr />
    <OrderEntryCloseButton
      onClick={() => {
        props.setIsOverlayActive(false);
      }}
    >
      CLOSE
      </OrderEntryCloseButton>
  </OrderEntryFormTransitionsWrapper>);
};
