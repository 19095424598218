import {
  pairwise,
  map,
  withLatestFrom,
  startWith,
  distinctUntilChanged,
} from 'rxjs/operators';
import Decimal from 'decimal.js';
import { orderBookState$ } from './orderBookService';
import { selectedMarketState$ } from '../../../shared/services/selectedMarketService';
import { OrderBookTotals } from './common';

export type PriceChangeDirection = 'up' | 'down' | 'none';

export interface MidPriceDetails {
  price: number;
  direction: PriceChangeDirection;
}

const RoundHalfEven = Decimal.clone({ rounding: Decimal.ROUND_HALF_EVEN });

export const roundMid = (mid: number, priceIncrement: number) => {
  const increments = new Decimal(mid).dividedBy(new Decimal(priceIncrement));
  return RoundHalfEven.round(increments).times(priceIncrement).toNumber();
};

export const getDirection = (
  oldPrice: number | undefined,
  newPrice: number | undefined,
): PriceChangeDirection => {
  if (newPrice) {
    if (oldPrice) {
      return newPrice >= oldPrice ? 'up' : 'down';
    }
  }
  return 'none';
};

export const calcMid = (book: OrderBookTotals): number | undefined => {
  if (book.bestBid && book.bestOffer) {
    return new Decimal(book.bestBid)
      .add(new Decimal(book.bestOffer))
      .div(new Decimal(2))
      .toNumber();
  }
  return undefined;
};

export const midPriceState$ = orderBookState$.pipe(
  map((book) => calcMid(book)),
  distinctUntilChanged(),
  withLatestFrom(selectedMarketState$),
  map(([mid, selectedMarket]) => {
    if (mid) {
      return roundMid(mid, selectedMarket.minPriceIncrement);
    }
    return undefined;
  }),
  startWith(undefined),
  pairwise(),
  map(
    ([oldMid, newMid]): MidPriceDetails => {
      const direction = getDirection(oldMid, newMid);
      return {
        price: newMid as number,
        direction,
      };
    },
  ),
);
