import { Subscription } from 'rxjs';
import {
  OnReadyCallback,
  SearchSymbolsCallback,
  ResolutionString,
  LibrarySymbolInfo,
  ResolveCallback,
  ErrorCallback,
  HistoryCallback,
  SeriesFormat,
  Timezone,
  SubscribeBarsCallback,
  Nominal,
  Bar,
} from '../../../charting_library/charting_library';
import { formatToUtcDateTime, toDateFromUtc } from '../../../shared/helperFunctions/dateFunctions';
import { AuthStatus, authStatusState$ } from '../../../shared/services/authStatusService';
import { getRestUrl } from '../../../shared/helperFunctions/getRestUrl';
import { getCandleHistory } from './getCandleHistory';
import { getCandleSubscription } from './candleDataService';
import {
  candleGraphRange,
  candleGraphVisibleRange,
  toGranularity,
  getPriceScale,
  calcCandlesDateRange,
} from './chartHelperFunctions';
import { selectedMarketState$ } from '../../../shared/services/selectedMarketService';
import { safeAdd } from '../../../shared/mathFunctions';
import { ProductValue } from '../../../shared/ProductValue';

let DATA_STATUS: LibrarySymbolInfo['data_status'];

const configurationData = {
  supported_resolutions: ['1', '5', '15', '60', '1D'] as ResolutionString[],
  exchanges: [],
  symbols_types: [],
};

let mostRecent: Bar | undefined;
let range: { from: number, to: number } | undefined;
let lastTradeDate: Date | undefined;
let candleSubscription: Subscription;
let cachedBars: Bar[];
let cacheStartDate: Date;
let currentSecurity: ProductValue | undefined;
let currentAuthStatus: AuthStatus | undefined;
selectedMarketState$.subscribe((security) => {
  currentSecurity = security;
});

authStatusState$.subscribe((authStatus) => {
  currentAuthStatus = authStatus;
});

const getBarsForRange = (bars: Bar[],
  start: number,
  end: number): Bar[] => bars
  .filter((bar) => (bar.time > (start * 1000) && bar.time <= (end * 1000)));

export const chartDataApi = {
  onReady: (callback: OnReadyCallback): void => {
    setTimeout(() => {
      callback(configurationData);
    }, 0);
  },

  /* eslint-disable @typescript-eslint/no-unused-vars */
  searchSymbols: (userInput: string,
    exchange: string,
    symbolType: string,
    onResult: SearchSymbolsCallback): void => {
    // searchbar is hidden
    throw new Error('method not implemented');
  },
  /* eslint-enable */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  resolveSymbol: (symbolName: string,
    onResolve: ResolveCallback,
    onError: ErrorCallback): void => {
    setTimeout(() => {
      if (currentSecurity) {
        const symbolInfo = {
          name: currentSecurity.symbol,
          has_empty_bars: true,
          full_name: '',
          format: 'price' as SeriesFormat,
          listed_exchange: '',
          description: currentSecurity.symbol,
          type: '',
          session: '24x7',
          timezone: 'Etc/UTC' as Timezone,
          exchange: '',
          minmov: 1,
          pricescale: getPriceScale(currentSecurity.minPriceIncrement),
          has_intraday: true,
          has_weekly_and_monthly: false,
          supported_resolutions: configurationData.supported_resolutions,
          data_status: 'streaming' as typeof DATA_STATUS,
        };
        onResolve(symbolInfo);
      }
    }, 0);
  },
  /* eslint-enable */

  getBars: async (
    symbolInfo: LibrarySymbolInfo,
    resolution: Nominal<string, 'ResolutionString'>,
    from: number,
    to: number,
    onResult: HistoryCallback,
    onError: ErrorCallback,
    isFirstCall: boolean) => {
    const requestStart = new Date(from * 1000);
    const end = new Date(to * 1000);

    if (isFirstCall) {
      const granularity = toGranularity(resolution);
      const searchStart = calcCandlesDateRange(end, granularity, candleGraphRange);
      const visibleRangeStart = calcCandlesDateRange(end,
        granularity,
        candleGraphVisibleRange).getTime();
      cacheStartDate = searchStart;
      const params = new URLSearchParams({
        startDate: formatToUtcDateTime(searchStart),
        endDate: formatToUtcDateTime(end),
        symbol: symbolInfo.name,
        interval: (granularity * 60).toString(),
        recordsPerPage: candleGraphRange.toString(),
        partialBar: 'Y',
      });
      const url = `${getRestUrl('HISTORY_REST_API_URL', currentAuthStatus as AuthStatus)}ohlc?${params.toString()}`;
      const resp = await getCandleHistory(url);
      lastTradeDate = resp.lastTradeTime && toDateFromUtc(resp.lastTradeTime);
      const bars = resp.hasError ? [] : resp.payload.map((bar: Bar) => ({
        time: bar.time * 1000,
        low: bar.low,
        high: bar.high,
        open: bar.open,
        close: bar.close,
        volume: bar.volume,
      })).sort((a: Bar, b: Bar) => (a.time < b.time ? -1 : 1)) as Bar[];
      if (bars.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        mostRecent = bars[bars.length - 1];
        range = {
          from: ((visibleRangeStart - (1000 * 60 * granularity)) / 1000),
          to: (mostRecent?.time / 1000),
        };
      } else {
        range = undefined;
        mostRecent = undefined;
      }
      cachedBars = bars;
      onResult(
        getBarsForRange(cachedBars, from, to),
        { noData: cachedBars.length === 0 },
      );
      return;
    }

    if (requestStart.getTime() < cacheStartDate.getTime()) {
      onResult(
        [],
        { noData: true },
      );
      return;
    }
    onResult(
      getBarsForRange(cachedBars, from, to),
      { noData: false },
    );
  },

  /* eslint-disable @typescript-eslint/no-unused-vars */
  subscribeBars: (symbolInfo: LibrarySymbolInfo,
    resolution: Nominal<string, 'ResolutionString'>,
    onTick: SubscribeBarsCallback,
    listenerGuid: string,
    onResetCacheNeededCallback: () => void): void => {
    const granularity = toGranularity(resolution);
    candleSubscription = getCandleSubscription(symbolInfo.name, granularity, lastTradeDate)
      .subscribe((newCandle) => {
        if (mostRecent) {
          if (mostRecent.time === newCandle.time) {
            const newBar = {
              time: newCandle.time,
              open: mostRecent.open,
              close: newCandle.close,
              high: newCandle.high > mostRecent.high ? newCandle.high : mostRecent.high,
              low: newCandle.low < mostRecent.low ? newCandle.low : mostRecent.low,
              volume: safeAdd(mostRecent.volume || 0, newCandle.volume || 0),
            } as Bar;
            mostRecent = newBar;
            onTick(newBar);
            return;
          }
        }
        const newBar = {
          time: newCandle.time,
          open: newCandle.open,
          close: newCandle.close,
          high: newCandle.high,
          low: newCandle.low,
          volume: newCandle.volume || 0,
        } as Bar;
        mostRecent = newBar;
        onTick(newBar);
      });
  },
  /* eslint-enable */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  unsubscribeBars: (listenerGuid: string): void => {
    mostRecent = undefined;
    range = undefined;
    lastTradeDate = undefined;
    cachedBars = [];
    if (candleSubscription) {
      candleSubscription.unsubscribe();
    }
  },
  /* eslint-enable */
  getCandlesRange: () => range,
};
