import styled from 'styled-components';

export const OuterContainer = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: rgba(23, 46, 58, 0.5);
`;

export const InnerContainer = styled.div`
    display: flex;
    width: 95vw;
    background-color: #fff;
    border-radius: 4px;
    height: 65vh;

    @media screen and (max-width: 768px) {
        height: 98vh;
        width: 98vw;
    }
`;

export const ViewHeader = styled.h1`
    padding-bottom: 25px;
`;

export const LinkButton = styled.button`
  color: ${({ theme }) => theme.palette.accent.accentPrimary};
  cursor: pointer;
  user-select: none;
  width: fit-content;
  background: transparent;
  text-decoration: underline;
  outline: none;
  border: none;
  font-size: 11px;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  &:hover {
    filter: brightness(1.3);
  }
  &:active {
    color: ${({ theme }) => theme.palette.accent.accentPrimary};
  }
  transform: filter 120ms ease-in;
  padding-top: 5px;
`;

export const DimmableContentWrapper = styled.div<{busy: boolean}>`
  opacity: ${({ busy }) => (busy ? '0.5' : 1)};
  transition: opacity 120ms ease-in;
  align-self: stretch;
`;
