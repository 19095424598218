import { MenuItem } from '@material-ui/core';
import styled from 'styled-components';

export const FilterButtonsContainer = styled.ul`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const FilterButton = styled(MenuItem)<{ $isSelected: boolean }>`
  display: flex;
  justify-content: center;
  width: 50px;
  padding: 5px 5px;
  background-color: ${({ theme, $isSelected }) => ($isSelected
    ? theme.palette.accent.accentPrimary
    : theme.palette.primary.primary2)};
  color: ${({ theme, $isSelected }) => ($isSelected
    ? theme.palette.primary.primary1
    : theme.palette.secondary.secondary)};
  font-size: 11px;
  font-style: ${({ disabled }) => disabled && 'italic'};
  :hover {
    background-color: ${({ theme, $isSelected }) => ($isSelected
    ? theme.palette.accent.accentPrimary2
    : theme.palette.primary.primary4)};
  }
`;
