import React from 'react';
import { AgGridReactProps, AgGridReact } from 'ag-grid-react';
import {
  ColumnApi, GridApi, ColDef, SideBarDef,
} from 'ag-grid-community';
import * as GridEnterprise from 'ag-grid-enterprise';
import { NoRowsOverlay } from './NoRowOverlay';
import { AgGridWrapper, WrapperProp } from './styled';
import 'ag-grid-community/dist/styles/ag-grid.css';

GridEnterprise.LicenseManager.setLicenseKey('CompanyName=Eris Exchange, LLC,LicensedApplication=ErisX,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=3,AssetReference=AG-016292,ExpiryDate=29_June_2022_[v2]_MTY1NjQ1NzIwMDAwMA==806c39220a04bb35a5f377d15ff3947e');

export const defaultColDef: ColDef = {
  sortable: true,
  resizable: true,
  unSortIcon: true,
  sortingOrder: ['desc', 'asc'],
  floatingFilter: true,
};

const getContextMenuItems = (watchlist?: boolean) => {
  if (watchlist) {
    return [
      'csvExport',
      'excelExport',
      'resetColumns',
    ];
  }
  return [
    'copy',
    'copyWithHeaders',
    'csvExport',
    'excelExport',
    'resetColumns',
  ];
};

interface Props extends AgGridReactProps {
  title?: string;
  columnApiRef?: React.MutableRefObject<ColumnApi | undefined>;
  gridApiRef?: React.MutableRefObject<GridApi | undefined>;
  noRowsMessage?: string;
  loadingMessage?: string;
  watchlist?: boolean;
}

const AgGridWrapperLo = ({ children, ...props }: WrapperProp) => (
  <AgGridWrapper {...props}>{children}</AgGridWrapper>
);

const sideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
    },
  ],
  defaultToolPanel: '',
} as SideBarDef;

export const BaseGrid: React.FC<Props> = ({
  noRowsMessage,
  loadingMessage,
  gridApiRef,
  watchlist,
  ...gridProps
}) => (
    <AgGridReact
      onGridReady={(params) => {
        if (gridApiRef) {
          // eslint-disable-next-line no-param-reassign
          gridApiRef.current = params.api;
        }
      }}
      sideBar={sideBarDef}
      suppressDragLeaveHidesColumns={true}
      defaultColDef={defaultColDef}
      immutableData={true}
      getContextMenuItems={() => getContextMenuItems(watchlist)}
      enableRangeSelection={true}
      {...gridProps}
      columnDefs={gridProps.columnDefs}
      frameworkComponents={{
        ...gridProps.frameworkComponents,
      }}
      overlayLoadingTemplate={NoRowsOverlay(loadingMessage)}
      overlayNoRowsTemplate={NoRowsOverlay(noRowsMessage)}
    />
);

export const Grid: React.FC<Props & { isFilterHidden: boolean;}> = ({
  isFilterHidden,
  ...props
}) => (
  <AgGridWrapperLo isFilterHidden={isFilterHidden}>
    <BaseGrid {...props} />
  </AgGridWrapperLo>
);
