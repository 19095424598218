import React from 'react';
import styled from 'styled-components/macro';
import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import { BlockTradeRecord } from '../services/blockTrades/blockTradesReducer';
import { Position } from '../../../shared/components/Overlay';
import { PrimaryButton } from '../../../shared/style/styled';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { requestBlockTradeData } from '../services/blockTrades/blockTradesService';

const RefreshCellWrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    margin-left: 3px;
`;

const RefreshConfirmButton = styled(PrimaryButton)`
  margin-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 11px;
  background-color: transparent;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  border: none;
  outline: none;
  cursor: pointer;
`;

interface RefreshCellProps extends ICellRendererParams {
  value: BlockTradeRecord;
  data: BlockTradeRecord;
}

interface RefreshCellState {
  params: RefreshCellProps,
  position?: Position;
}

export class RefreshCell extends React.Component<RefreshCellProps, RefreshCellState>
  implements ICellRenderer {
  constructor(props: RefreshCellProps) {
    super(props);
    this.state = {
      params: props,
    };
  }

  public render() {
    return (
      this.state.params.data.state === 'pending' || this.state.params.data.state === 'accepted'
        ? <RefreshCellWrapper>
        {<RefreshConfirmButton
          onClick={() => requestBlockTradeData(this.state.params.data.request_id)}>
            <RefreshIcon/>
         </RefreshConfirmButton>}
      </RefreshCellWrapper> : null);
  }

  public refresh(params: ICellRendererParams): boolean {
    this.setState({
      params,
    });
    return true;
  }
}
