// these are descending so that we can make statements like
// "currentBreakpoint >= FIRST_BREAKPOINT" to represent being at or above the first breakpoint
export const WIDEST_BREAKPOINT = 4;
export const FIRST_BREAKPOINT = 3;
export const SECOND_BREAKPOINT = 2;
export const MOBILE_BREAKPOINT = 1;

const FIRST_BREAKPOINT_WIDTH = 1043;
const SECOND_BREAKPOINT_WIDTH = 810;
const MOBILE_BREAKPOINT_WIDTH = 480;

export const getBreakpointNumber = (width : number): number => {
  if (width > FIRST_BREAKPOINT_WIDTH) {
    return WIDEST_BREAKPOINT;
  } if (width > SECOND_BREAKPOINT_WIDTH) {
    return FIRST_BREAKPOINT;
  } if (width > MOBILE_BREAKPOINT_WIDTH) {
    return SECOND_BREAKPOINT;
  }
  return MOBILE_BREAKPOINT;
};
