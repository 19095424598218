import styled from 'styled-components/macro';
import { Direction } from '../../shared/Direction';
import { PrimaryDivButton } from '../../shared/style/styled';
import { DownArrow, UpArrow } from '../tradeHistory/styled';

export const TradeNotificationContent = styled.div`
    max-height: 40px;
    display: grid;
    grid-template-rows: 16px auto;
    align-items: center;
    justify-content: left;
    margin-top: -5px;
`;

export const TradeNotificationTitle = styled.div`
   color: ${({ theme }) => theme.palette.secondary.secondary};
   font-family: Roboto;
   font-size: 10px;
`;

export const TradeNotificationWrapper = styled.div`
display: grid;
grid-template-columns: auto;
align-items: center;
`;

export const TradeNotificationValue = styled.div`
    margin-top: 4px;
 svg {
     margin-bottom: -2px;
 }
`;

export const NotificationDropdownWrapper = styled.div`
min-width: 310px;
max-width: 350px;
display: grid;
grid-template-rows: 30px repeat(25px, auto);
background-color: ${({ theme }) => theme.palette.primary.primary5};
max-height: 300px;
overflow: auto;
`;

export const NotificationDropdownTitle = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
min-height: 30px;
padding: 0px 10px;
border-bottom: 1px solid ${({ theme }) => theme.palette.secondary.secondary5};
`;

export const NotificationDropdownRow = styled.div<{ direction: Direction }>`
padding-left: 10px;
display: grid;
grid-template-columns: 225px auto 15px;
grid-column-gap: 7px;
border-left: 5px solid ${({ theme, direction }) => (direction === Direction.Buy ? theme.palette.trading.tradingBuy : theme.palette.trading.tradingSell)};
border-bottom: 1px solid ${({ theme }) => theme.palette.secondary.secondary5};
min-height: 25px;
color: ${({ theme }) => theme.palette.secondary.secondary};

 ${UpArrow}{
     margin-bottom: -10px
 }
 ${DownArrow}{
     margin-bottom: -10px
 }
`;

export const NotificationIconBadge = styled.div`
    background-color: ${({ theme }) => (theme.palette.accent.accentPositive)};
    color: ${({ theme }) => theme.palette.primary.primary};
    position: relative;
    max-height: 14px;
    min-height:14px;
    max-width: 14px;
    min-width:14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 10px;
`;

export const NotificationIconWrapper = styled(PrimaryDivButton) <{ selected: boolean }>`
    background-color: ${({ theme, selected }) => (selected ? theme.palette.primary.primary5 : theme.palette.primary.primary)};
    max-height: 47px;
    max-width: 50px;
    min-height: 47px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    ${NotificationIconBadge}{
        margin-left: -12px;
        margin-top: -10px;
    }
    
    svg {
        cursor: pointer;  
    }
`;

export const NotificationDropdownTitleName = styled.div`
   color: ${({ theme }) => theme.palette.secondary.secondary};
   font-size: 11px;
   text-align: center;
`;

export const NotificationDropdownTitleButton = styled.div`
   color: ${({ theme }) => theme.palette.secondary.secondary2};
   font-size:11px;
   cursor: pointer;
   text-align: center;
   opacity: .7;
   :hover {
        opacity: 1;
    }
`;

export const NotificationDropdownRowContent = styled.div`
font-family: Roboto Mono;
font-size: 12px;
display: flex;
align-items: center;
svg {
    margin-bottom: -3px;
}

#copy {
    width: 14px;
    height: 14px;
    cursor: pointer;
    :active {
        opacity: .1;
    }
    margin-right: 6px;
}
`;

export const NotificationStatusRow = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
`;

export const NotificationStatusRowLeft = styled.div`
`;

export const NotificationStatusRowRight = styled.div`
`;

export const NotificationDropdownRowDate = styled.div`
font-family: Roboto Mono;
font-size: 11px;
color: ${({ theme }) => theme.palette.secondary.secondary5};
display: flex;
align-items: center;
padding-right: 10px;
`;

export const NotificationDropdownCloseIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: -5px;
    svg {
   fill: ${({ theme }) => theme.palette.secondary.secondary2};
   opacity: .7;
   :hover {
    opacity: 1;
   }
 }
`;
