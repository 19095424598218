import { FileCopy } from '@material-ui/icons';
import React from 'react';
import { WarningIcon } from '../../../../icons/WarningIcon';
import { copyToClipboard } from '../../../../shared/helperFunctions/copyToClipboard';
import { BlockTradeEntryResponse, errorMapping } from '../../services/block/blockTradeEntryService';
import {
  OrderEntryCloseButton,
  OrderEntryCorrelation,
  OrderEntryFailureReason,
  OrderEntryFormTransitionsWrapper,
  OrderEntrySummary,
  OrderEntryTransitionTitle,
} from '../styled';

interface Props {
  latestEntryMessage: BlockTradeEntryResponse;
  setIsOverlayActive: (isOverlayActive: boolean) => void;
}

export const BlockOrderEntryUnsuccessful: React.FunctionComponent<Props> = (props) => {
  const { latestEntryMessage } = props;
  return (<OrderEntryFormTransitionsWrapper>
    <OrderEntryTransitionTitle>
      {<WarningIcon />}
      {latestEntryMessage.restError ? ' REQUEST FAILED ' : ' ORDER REJECTED '}
    </OrderEntryTransitionTitle>
    <hr />
    <OrderEntrySummary>
      {'Unable to place order:'}
    </OrderEntrySummary>
    {latestEntryMessage.restError ? (
      <OrderEntryFailureReason>Request Failed, please try again.</OrderEntryFailureReason>
    ) : (
      <>
        <OrderEntryFailureReason>
          {(latestEntryMessage.error?.error_code && errorMapping[latestEntryMessage.error?.error_code]) || 'Invalid Request'}
        </OrderEntryFailureReason>
        <OrderEntryCorrelation>
          {latestEntryMessage.error?.request_id}
          <FileCopy onClick={() => copyToClipboard(latestEntryMessage.request_id!)} />
        </OrderEntryCorrelation>
      </>
    )}
    <hr />
    <OrderEntryCloseButton
      onClick={() => props.setIsOverlayActive(false)}
    >
      CLOSE
      </OrderEntryCloseButton>

  </OrderEntryFormTransitionsWrapper>);
};
