import styled from 'styled-components';
import { NumberInput } from '../../../../../shared/components/NumberInput';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 5px;
    justify-content: space-between;
`;

export const Amount = styled(NumberInput)`
    box-sizing: border-box;
    height: 25px;
    font-family: Roboto;
    font-size: 11px;
    color: ${({ theme }) => theme.palette.secondary.secondary}
`;
