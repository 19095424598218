import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { AgGridWrapper } from '../../../../shared/Grid/styled';
import TreeOpen from './assets/tree-open.svg';
import TreeClosed from './assets/tree-closed.svg';

export const PositionsGridStyleWrapper = styled((props: PropsWithChildren<{ isFilterHidden: boolean}>) => <AgGridWrapper {...props}/>)`
    /* CONTAINER */

    .ag-center-cols-container {
        padding-left: 0;
        padding-right: 10px;
    }


    /* CELLS  */

    .ag-cell-focus,
    .ag-cell-no-focus {
        border: 0 !important;

    }
    .ag-cell:focus {
        border: 0 !important;
        outline: none;
    }

    .ag-cell-range-selected {
        background: inherit;
        border: 0 !important;
        outline: none;
    }


    /* ROWS */

    .ag-row {
        font-size: 11px;
        font-family: Roboto Mono;
        color: ${({ theme }) => theme.palette.secondary.secondary};
        background-color: ${({ theme }) => theme.palette.primary.primary};
        font-weight: 800;
    }

    .ag-row-odd {
        background-color: ${({ theme }) => theme.palette.primary.primary};
    }

    .ag-row-level-0 {
        border-bottom: 1px solid ${({ theme }) => theme.palette.primary.primary1};
    }

    .ag-row-group-indent-0 {
        padding: 10px;
    }

    .ag-row-group-indent-1 {
        padding-left: 30px;
    }

    .ag-row-level-2 {
        background-color: ${({ theme }) => theme.palette.primary.primary1};
        font-weight: 200;
        border-bottom: 1px solid ${({ theme }) => theme.palette.primary.primary};
        box-sizing: border-box;
    }

    .ag-row-group-indent-2 {
        padding-left: 50px;
    }

    .ag-row-group {
        align-items: center;
    }

    .ag-group-expanded, .ag-group-contracted {
        background-color: ${({ theme }) => theme.palette.primary.primary1};
        text-indent: 0;
    }

    .ag-group-value {
        font-family: Roboto;
    }


    /* ICONS */

    .ag-header-icon {
        padding-right: 5px
    }

    .ag-icon.ag-icon-tree-open, .ag-icon.ag-icon-tree-closed {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 10px 10px;
        height: 10px;
        width: 10px;
        padding: 3px;
        cursor: pointer;
    }

    .ag-icon.ag-icon-tree-open{
        background: url('${TreeOpen}') no-repeat;
    }

    .ag-icon.ag-icon-tree-closed{
        background: url('${TreeClosed}') no-repeat;
    }
`;

export const UnrealisedPnl = styled.span<{ value: number}>`
    color: ${({ value }) => (value > 0
    ? 'green' : (value < 0 ? 'red' : 'grey'))}
`;
