import React, { memo, useRef } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import styled, { useTheme } from 'styled-components';
import { MoreVert } from '@material-ui/icons';

import { Direction } from '../../../shared/Direction';
import { formatPrice, formatQuantity } from '../../../shared/formatters';
import { getPriceValueColor } from '../../../shared/helperFunctions/getPriceChangeColor';
import { useFlash } from '../../../shared/hooks/useFlash';
import { requestMarketData } from '../../../shared/services/selectedMarketService';
import { FUT } from '../../orderEntry/services/trade/sendEntry';
import { Security } from '../../../shared/services/getSecurities$';
import {
  useTopOfTheBookBid,
  useTopOfTheBookOffer,
  TopBook,
} from '../services/latestOrders';
import {
  useLatestPrice,
  use24HourChange,
  use24HourVolume,
} from '../services/tradeHistory';

const OrderEntryNA = styled.div<{
  w?: number;
  a?: 'flex-start' | 'center' | 'flex-end'
}>`
  display: flex;
  flex: 0 0 ${({ w }) => (w || '80')}px;
  align-items: center;
  justify-content: ${({ a }) => a || 'flex-end'};
  margin-left: 2px;
  height: 100%;
  padding-right: 10px;
`;

const OrderEntryCellWrapper = styled.div<{
  w?:number ;
  a?: 'flex-start' | 'center' | 'flex-end'
}>`
  display: flex;
  flex-direction: column;
  flex: 0 0 ${({ w }) => (w || '80')}px;
  align-items: ${({ a }) => a || 'flex-end'};
  justify-content: center;
  padding-left: 2px;
  height: 100%;
  padding-right: 10px;
`;

const OrderEntryPrice = styled.div<{ direction: Direction }>`
  height: 15px;
  display: flex;
  color: ${({ direction, theme }) => (direction === Direction.Buy
    ? theme.palette.trading.tradingBuy
    : theme.palette.trading.tradingSell)};
  align-items: center;
  justify-content: flex-end;
  margin-left: 2px;
`;

const OrderEntryQuantity = styled.div`
  height: 15px;
  display: flex;
  font-size: 9px;
  align-items: center;
  justify-content: flex-end;
  margin-left: 2px;
`;

const Handle = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  cursor: grab;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.accent.accentPrimary};
`;

const needsQtyFlash = (prev: [number] | null, [value]: [number]) => !!prev && value !== 0;

const TopOfTheBookCell: React.FC<{
  direction: Direction;
  entry: NonNullable<TopBook>;
  market: Security;
  priceOnly?: Boolean;
  w?: number;
  a?: 'flex-start' | 'center' | 'flex-end';
}> = ({
  direction, entry, market, priceOnly, w, a,
}) => {
  const theme = useTheme();
  const wrapperRef = useRef<HTMLDivElement>(null);
  useFlash(
    wrapperRef,
    {
      backgroundColor:
        direction === Direction.Buy
          ? theme.palette.primary.primary2
          : theme.palette.trading.tradingSell3,
    },
    [entry.price],
    needsQtyFlash,
  );
  const quantityRef = useRef<HTMLDivElement>(null);
  useFlash(
    quantityRef,
    {
      color:
        direction === Direction.Buy
          ? theme.palette.trading.tradingBuy
          : theme.palette.trading.tradingSell,
    },
    [entry.quantity],
    needsQtyFlash,
  );
  return (
    <OrderEntryCellWrapper ref={wrapperRef} w={w} a={a}>
      <OrderEntryPrice direction={direction} >
        {formatPrice(entry.price, market.minPriceIncrement)}
      </OrderEntryPrice>
      {!priceOnly && <OrderEntryQuantity ref={quantityRef}>
        {formatQuantity(entry.quantity, market.roundLot)}
      </OrderEntryQuantity>}
    </OrderEntryCellWrapper>
  );
};

export const BidCell: React.FC<{
  market: Security,
  priceOnly?: boolean,
  w?: number,
  a?: 'flex-start' | 'center' | 'flex-end',
}> = ({
  market, priceOnly, w, a,
}) => {
  const bid = useTopOfTheBookBid(market.symbol);
  return bid ? (
    <TopOfTheBookCell
      direction={Direction.Buy}
      entry={bid}
      market={market}
      priceOnly={priceOnly}
      w={w}
      a={a}
    />
  ) : (
    <OrderEntryNA w={w} a={a}>--:--</OrderEntryNA>
  );
};

export const OfferCell: React.FC<{
  market: Security,
  priceOnly?: boolean,
  w?: number,
  a?: 'flex-start' | 'center' | 'flex-end',
}> = ({
  market, priceOnly, w, a,
}) => {
  const offer = useTopOfTheBookOffer(market.symbol);
  return offer ? (
    <TopOfTheBookCell
      direction={Direction.Sell}
      entry={offer}
      market={market}
      priceOnly={priceOnly}
      w={w}
      a={a}
    />
  ) : (
    <OrderEntryNA
    w={w}
    a={a}
    >--:--
    </OrderEntryNA>
  );
};

const LastCellWrapper = styled.div`
  flex: 0 0 80px;
  text-align: right;
`;
const LastCell: React.FC<{ market: Security }> = ({ market }) => {
  const lastPrice = useLatestPrice(market.symbol);
  return (
    <LastCellWrapper>
      {lastPrice === null
        ? '---'
        : formatPrice(lastPrice, market.minPriceIncrement || 0.0001)}
    </LastCellWrapper>
  );
};

const ChangeCellWrapper = styled.div<{ value: number }>`
  flex: 0 0 65px;
  text-align: right;
  color: ${({ value }) => getPriceValueColor(value)};
`;
const ChangeCell: React.FC<{ symbol: string }> = ({ symbol }) => {
  const value = use24HourChange(symbol);
  return (
    <ChangeCellWrapper value={value}>{value.toFixed(2)}%</ChangeCellWrapper>
  );
};

const VolumeCellWrapper = styled.div`
  flex: 0 0 90px;
  text-align: right;
`;
const VolumeCell: React.FC<{ market: Security }> = ({ market }) => {
  const twentyFourHourVol = use24HourVolume(market.symbol);
  const multiplier = market.securityType !== 'FUT' && market.contractMultiplier
    ? market.contractMultiplier
    : 1;
  return (
    <VolumeCellWrapper>
      {formatQuantity(twentyFourHourVol * multiplier, 0.01)}
    </VolumeCellWrapper>
  );
};

const Container = styled.div`
  display: flex;
  width: 580px;
  flex-direction: row;
  align-items: center;
  height: 35px;
  font-family: 'Roboto Mono';
  font-size: 12px;
  margin-left: 5px;
  &: nth-child(odd) {
    background-color: rgb(12, 55, 90);
  }
  &: nth-child(even) {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const SymbolCell = styled.div`
  flex: 0 0 120px;
  text-align: center;
  height: 35px;
  position: sticky;
  left: 0;
  flex-basis: 120px;
  background-color: inherit;
`;

const SymbolText = styled.span`
  height: 35px;
  line-height: 35px;
`;

const Space = styled.div`
  flex: 0 0 12px;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const WatchlistRow: React.FC<{
  market: Security;
  draggable: DraggableProvided;
}> = memo(
  ({ market, draggable: { draggableProps, innerRef, dragHandleProps } }) => (
    <Container {...draggableProps} ref={innerRef}>
      <SymbolCell>
        <Handle {...dragHandleProps}>
          <MoreVert />
        </Handle>
        <SymbolText
          style={{ cursor: 'pointer' }}
          onClick={() => requestMarketData({
            ...(market.securityType === FUT
              ? { type: FUT, productCode: market.productCode }
              : { type: 'SPOT' }),
            symbol: market.symbol,
            value: market.symbol,
            minTradeVol: market.minTradeVol,
            maxTradeVol: market.maxTradeVol,
            roundLot: market.roundLot,
            minPriceIncrement: market.minPriceIncrement,
            currency: market.currency,
            contractMultiplier: market.contractMultiplier,
          })
          }
        >
          {market.symbol}
        </SymbolText>
      </SymbolCell>
      <Space />
      <BidCell market={market} />
      <OfferCell market={market} />
      <LastCell market={market} />
      <ChangeCell symbol={market.symbol} />
      <VolumeCell market={market} />
    </Container>
  ),
);
