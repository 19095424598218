import styled, { DefaultTheme } from 'styled-components';
import { CrossIcon } from '../../../../icons/CrossIcon';

export type ColorTheme = 'primary' | 'secondary' | 'danger';

const getCssColorsForTheme = (theme: DefaultTheme, colorTheme: ColorTheme) => {
  const { palette } = theme;
  return {
    primary: `
            background-color: ${palette.accent.accentPrimary};
            color: ${palette.primary.primary};
            `,
    secondary: `
            background-color: ${palette.primary.primary1};
            color: ${palette.secondary.secondary};
            `,
    danger: `
            background-color: ${palette.accent.accentNegative1};
            color: ${palette.secondary.secondary};
        `,
  }[colorTheme];
};

export const ButtonStyled = styled.button<{ colorTheme?: ColorTheme, disabled?: boolean }>`
${({ theme, colorTheme = 'primary' }) => getCssColorsForTheme(theme, colorTheme)};
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  height: 25px;
  justify-content: center;
  font-size: 11px;
  border: none;
  cursor: pointer;
  transition: all 150ms ease-in;


  :focus {
    outline: none;
    box-shadow: none;
  }

  :hover {
    filter: ${({ disabled = false }) => (disabled ? 'none' : 'brightness(1.3)')};
  }

`;

export const DisabledIcon = styled(CrossIcon)`
  background-color: ${({ theme }) => theme.palette.primary.primary1};
  color: ${({ theme }) => theme.palette.secondary.secondary};
  align-self: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transform: rotate(45deg);
`;
