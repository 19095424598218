import React from 'react';
import { WarningIcon } from '../../icons/WarningIcon';
import { BannerBody, Mono, TextLabel } from './styled';

interface Props {
  text: string;
}

export const EnvBanner: React.FC<Props> = ({
  text,
}) => (
      <BannerBody>
          <WarningIcon />
          <Mono className="mono"><TextLabel>{text}</TextLabel></Mono>
        </BannerBody>
);
