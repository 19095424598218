import { bind } from '@react-rxjs/core';
import { Subject } from 'rxjs';
import { ProductValue } from '../ProductValue';

export enum PriceSelectType {
  FILTER = 'filter',
  PRICE = 'price',
}

export interface PriceSelect {
  price: number;
  type: PriceSelectType;
  market?: ProductValue;
}

const selectedPriceSubject$ = new Subject<PriceSelect>();

export const selectedPriceState$ = selectedPriceSubject$.asObservable();

export const setSelectedPrice = (selected?: PriceSelect) => {
  selectedPriceSubject$.next(selected);
};

export const [useSelectedPrice, mainPriceState$] = bind(
  selectedPriceState$,
  undefined,
);
