import React from 'react';

export const AlertIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
}> = ({ height = 20, width = 20 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path fill="#FF274B" d="M14.702 2.567l8.83 15.203c.832 1.433.345 3.27-1.088 4.101-.458.266-.977.406-1.507.406H3.278c-1.657 0-3-1.343-3-3 0-.53.14-1.049.406-1.507l8.83-15.203c.832-1.433 2.668-1.92 4.1-1.088.451.262.826.637 1.088 1.088z" />
      <path fill="#FFF" fillRule="nonzero" d="M12.108 17c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1s-1-.448-1-1V18c0-.552.448-1 1-1zm0-11c.552 0 1 .448 1 1v7.5c0 .552-.448 1-1 1s-1-.448-1-1V7c0-.552.448-1 1-1z" />
    </g>
  </svg>
);
