import React from 'react';

export const UpArrowIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
  className?: string;
}> = ({ height = 24, width = 24, className = '' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
          <path d="M0 0H24V24H0z"/>
          <path fill="currentColor" d="M11.894 7.477l3.238 3.294c.186.223.262.651.048.9-.212.246-.621.24-.834-.006L12.072 9.35v6.343c0 .341-.255.618-.571.618-.316 0-.571-.277-.571-.618V9.349l-2.274 2.316c-.196.211-.62.25-.834.007-.213-.244-.145-.687.048-.901l3.238-3.294c.11-.113.241-.168.393-.167.136.005.294.066.393.167z"/>
      </g>
</svg>
);
