import { map, scan } from 'rxjs/operators';
import { bind } from '@react-rxjs/core';
import { Map } from 'immutable';
import { defer } from 'rxjs';
import { subscribeToMessageStream$ } from '../websocket/transport';
import { SecurityStatusEnum } from './securityStatus';
import { Security } from './getSecurities$';

export interface SecurityStatusResponse {
  type: 'SecurityStatus';
  correlation: string;
  securityTradingStatus: string;
  security: Security;
}

type SecurityStatusMap = Map<string, SecurityStatusEnum>;

const initialSecurityStatusMap = Map<string, SecurityStatusEnum>();

const getSecurityEnumFromMessage = (msg: SecurityStatusResponse) => {
  switch (msg.securityTradingStatus) {
    case 'NOT_AVAILABLE_FOR_TRADING_END_OF_SESSION':
      return SecurityStatusEnum.CLOSED;
    case 'TRADING_HALT':
      return SecurityStatusEnum.HALTED;
    default:
      return SecurityStatusEnum.OPEN;
  }
};

const securityStatusReducer = (
  acc: SecurityStatusMap,
  response: SecurityStatusResponse,
) => acc.withMutations((m) => m.set(
  response.security.symbol,
  getSecurityEnumFromMessage(response),
));

export const [useSecurityStatus, securityStatusState$] = bind<
SecurityStatusMap
>(
  defer(() => subscribeToMessageStream$('SecurityStatus').pipe(
    map((msg) => msg as SecurityStatusResponse),
    scan(securityStatusReducer, initialSecurityStatusMap),
  )),
  initialSecurityStatusMap,
);
