import styled from 'styled-components/macro';
import { PrimaryDivButton } from '../../../shared/style/styled';

export const OrderEntryFormTransitionsWrapper = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.palette.primary.primary1};
  bottom: 0;
  width: 100%;

  hr {
    border-color: ${({ theme }) => theme.palette.primary.primary};
    width: 75%;
    opacity: .3;
  }
`;

export const OrderEntryTransitionTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  font-size: 13px;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  min-width: 250px;
  font-weight: bold;
  margin-bottom: 5px;

  svg {
    padding-right: 5px;
  }
`;

export const OrderEntrySuccessId = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  font-size: 10px;
  align-items: center;
  justify-content: center;
  margin-top: -18px;
  margin-bottom: 15px;
  word-break: break-word;
  padding: 0px 10px;
`;

export const OrderEntryCorrelation = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  font-size: 10px;
  align-items: center;
  justify-content: center;
  word-break: break-word;
  padding: 0px 10px;

  svg {
    height: 12px; 
    cursor: pointer;
    :active {
      opacity: .3;
    }
  }
`;

export const OrderEntrySummary = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.secondary.secondary2};
  font-size: 11px;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
`;

export const OrderEntryFailureReason = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  background-color: ${({ theme }) => theme.palette.primary.primary};
  font-size: 11px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  min-width: 190px;
  max-width:190px;
  min-height: 54px;
  max-height: 54px;
  margin-left: 30px;
  margin-bottom: 5px;
  text-align: center;
  word-break: break-word;
`;

export const OrderEntryCloseButton = styled(PrimaryDivButton)`
  display: flex;
  color: ${({ theme }) => theme.palette.secondary.secondary1};
  background-color: ${({ theme }) => theme.palette.primary.primary1};
  font-size: 11px;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 15px;
  font-weight: bold;
  cursor: pointer;
  height: 21px;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  :active {
    opacity: .3;
  }
`;
