import React from 'react';

export const BitcoinCashIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
}> = ({ height = 20, width = 20 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF">
        <g>
          <g>
            <path d="M12 22.956c6.041 0 10.956-4.914 10.956-10.956S18.041 1.044 12 1.044C5.959 1.044 1.044 5.958 1.044 12S5.959 22.956 12 22.956zM12 0c6.617 0 12 5.383 12 12s-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0zm0 19.826c4.315 0 7.826-3.51 7.826-7.826 0-4.315-3.51-7.826-7.826-7.826-4.315 0-7.826 3.51-7.826 7.826 0 4.315 3.51 7.826 7.826 7.826zM12 3.13c4.89 0 8.87 3.98 8.87 8.87 0 4.89-3.98 8.87-8.87 8.87-4.89 0-8.87-3.98-8.87-8.87 0-4.89 3.98-8.87 8.87-8.87zm1.947 7.251c.02-.304-.088-.604-.297-.826-.209-.223-.5-.35-.806-.35h-2.79c-.002 0-.005.002-.005.005v2.2c0 .004.003.006.006.006H12.8c.591 0 1.11-.445 1.148-1.034zm0 3.38c.02-.305-.088-.604-.297-.827-.209-.223-.5-.35-.806-.35h-2.79c-.002 0-.005.002-.005.005v2.2c0 .004.003.006.006.006H12.8c.591 0 1.11-.445 1.148-1.034zm.422-1.757l.158.172c.513.558.71 1.338.522 2.072-.185.735-.729 1.327-1.445 1.574l-.172.06c-.002 0-.004.002-.004.004v.338c0 .323-.261.584-.584.584-.322 0-.584-.261-.584-.584v-.256c0-.002-.002-.005-.005-.005H10.9c-.003 0-.005.003-.005.005v.256c0 .322-.261.584-.584.584-.322 0-.584-.262-.584-.584v-.256l-.001-.003-.004-.002h-.25c-.326 0-.59-.264-.59-.59V8.627c0-.325.264-.589.59-.589h.25c.003 0 .005-.002.005-.005v-.256c0-.322.261-.583.584-.583.322 0 .584.261.584.583v.256c0 .003.002.005.005.005h1.356c.003 0 .005-.002.005-.005v-.256c0-.322.261-.584.584-.584.323 0 .584.262.584.584v.338c0 .002.001.004.003.005l.172.06c.717.246 1.26.838 1.446 1.573.187.735-.01 1.514-.522 2.072l-.158.172c-.002.002-.002.006 0 .008z" transform="translate(-4 -40) translate(0 36) rotate(-20 25.343 2.657)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
