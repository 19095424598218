import { useLayoutEffect, useRef } from 'react';

const T = () => true;
export const useFlash = <
  A extends Array<any>,
  OT extends {
    [K in keyof A]: A[K];
  }
>(
    htmlDomRef: React.RefObject<HTMLElement | undefined>,
    styles: Partial<CSSStyleDeclaration>,
    deps: OT,
    fn: (prev: OT | null, current: OT) => boolean = T,
  ): void => {
  const vm = useRef<{ cleanup?:() => void; prev: OT | null }>({
    prev: null,
  });

  useLayoutEffect(() => {
    let timeoutId: number | undefined;
    try {
      if (htmlDomRef.current && fn(vm.current.prev, deps) && !vm.current.cleanup) {
        const prev: any = {};
        Object.entries(styles).forEach(([key, value]) => {
          prev[key] = htmlDomRef.current!.style[key as any];
          // eslint-disable-next-line
          htmlDomRef.current!.style[key as any] = value;
        });
        vm.current.cleanup = () => {
          Object.keys(prev).forEach((key) => {
            // eslint-disable-next-line
            htmlDomRef.current!.style[key as any] = prev[key];
          });
          vm.current.cleanup = undefined;
        };
        timeoutId = window.setTimeout(vm.current.cleanup, 200);
      }
    } finally {
      vm.current.prev = deps;
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = undefined;
      }
      if (vm.current.cleanup) {
        vm.current.cleanup();
        // eslint-disable-next-line
        vm.current.cleanup = undefined;
      }
    };
  }, deps); // eslint-disable-line
};
