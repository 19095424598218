import React from 'react';

export const DownArrowIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
  className?: string;
}> = ({ height = 24, width = 24, className = '' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z"/>
        <path fill="currentColor" d="M11.106 16.142l-3.238-3.294c-.186-.223-.262-.651-.048-.9.212-.246.621-.239.834.006l2.274 2.316V7.927c0-.34.255-.617.571-.617.316 0 .571.276.571.617v6.343l2.274-2.316c.196-.211.62-.25.834-.007.213.244.145.687-.048.901l-3.238 3.294c-.11.113-.241.168-.393.168-.136-.006-.294-.067-.393-.168z"/>
      </g>
    </svg>
);
