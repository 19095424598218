import { Observable } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';
import { getToken } from './token';
import { send, subscribeToMessageStream$ } from './transport';
import { correlationId } from '../helperFunctions/correlationId';
import { connectionStatus$, ConnectionStatus, setConnectionStatus } from './connectionStatus';
import { AuthStatus, authStatusState$, setAuthStatus } from '../services/authStatusService';
import { clearAllAuthLocalStorage } from '../services/auth0Service';

export const login = () => {
  const token = getToken();
  send({
    correlation: correlationId(),
    type: 'AuthenticationRequest',
    token,
  });
};

export const loginWhenConnected$ = connectionStatus$.pipe(
  withLatestFrom(authStatusState$),
  tap(([status, authStatus]) => {
    if (status === ConnectionStatus.OPEN && authStatus !== AuthStatus.NOT_DETERMINED) {
      login();
    }
  }),
);

interface AuthenticationResultMessage {
  type: 'AuthenticationResult',
  correlation: string,
  success: boolean,
}

export const updateStatusWhenAuthenticated$ = subscribeToMessageStream$('AuthenticationResult').pipe(
  tap((msg:any) => {
    if (msg.success) {
      setConnectionStatus(ConnectionStatus.AUTHENTICATED);
    } else {
      clearAllAuthLocalStorage();
      setAuthStatus(AuthStatus.UNAUTHENTICATED);
      setConnectionStatus(ConnectionStatus.OPEN);
    }
  }),
) as Observable<AuthenticationResultMessage>;
