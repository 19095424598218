import React from 'react';
import {
  AddNewSettlement,
  ValidatedSettlementFormState,
} from './AddNewSettlement';
import {
  createSettlement,
  useCreateSettlementResponseFailure,
  useIsPending,
} from './AddNewSettlementService';
import { SettlementAsset } from '../types';

interface AddNewSettlementContainerProps {
  onClose: () => void;
}

const availableAssets: SettlementAsset[] = [
  { symbol: 'USD', displayName: 'USD' },
  { symbol: 'BTC', displayName: 'BTC' },
  { symbol: 'ETH', displayName: 'ETH' },
  { symbol: 'BCH', displayName: 'BCH' },
  { symbol: 'LTC', displayName: 'LTC' },
  { symbol: 'USDC', displayName: 'USDC' },
];

export const AddNewSettlementContainer: React.FC<AddNewSettlementContainerProps> = ({
  onClose,
}) => {
  const response = useCreateSettlementResponseFailure();
  const isPending = useIsPending();

  const onCreate = (formValues: ValidatedSettlementFormState) => {
    createSettlement(formValues);
  };

  return (
    <AddNewSettlement
      assets={availableAssets}
      errorText={response?.errorMessage}
      busy={isPending}
      onCreate={onCreate}
      onClose={onClose}
    />
  );
};
