import React, { useRef } from 'react';
import { useTheme } from 'styled-components';

import { Direction } from '../../shared/Direction';
import {
  findNonZero,
  findZero, formatPrice,
  formatQuantity,
} from '../../shared/formatters';
import { useFlash } from '../../shared/hooks/useFlash';
import { ProductValue } from '../../shared/ProductValue';
import {
  PriceSelectType,
  setSelectedPrice,
} from '../../shared/services/selectedPriceService';
import { OB_QTY_ROUNDLOT } from './constants';
import { OrderBookEntry } from './services/common';
import {
  BarContainer,
  BarFill,
  buyColor,
  MyQty,
  Row,
  Price,
  Quantity,
  sellColor,
} from './styles';

interface Props {
  market: ProductValue;
  direction: Direction;
  orderBookEntry: OrderBookEntry;
  userQty?: number;
  total: number;
  showOnlyPriceAndQuantity?: boolean;
  disabled: boolean;
}

const needsRowFlash = (prev: [number] | null,
  [value]: [number]) => (!prev || !prev[0]) && value !== 0;

const needsQtyFlash = (prev: [number] | null, [value]: [number]) => !!prev && value !== 0;

const OrderBookRow: React.FC<Props> = React.memo(
  ({
    direction,
    orderBookEntry,
    total,
    userQty,
    market,
    showOnlyPriceAndQuantity,
    disabled,
  }) => {
    const { price, amount } = orderBookEntry;
    const fillPercentage = (amount / total) * 100;

    const theme = useTheme();

    const rowRef = useRef<HTMLDivElement>(null);
    useFlash(
      rowRef,
      {
        backgroundColor:
          direction === Direction.Buy
            ? theme.palette.primary.primary1
            : theme.palette.trading.tradingSell3,
      },
      [amount],
      needsRowFlash,
    );

    const quantityRef = useRef<HTMLDivElement>(null);
    useFlash(
      quantityRef,
      {
        color:
          direction === Direction.Buy
            ? buyColor(disabled, theme)
            : sellColor(disabled, theme),
      },
      [amount],
      needsQtyFlash,
    );

    return (
      <Row ref={rowRef}>
        {!showOnlyPriceAndQuantity && (
          <MyQty
            onClick={() => {
              if (userQty) {
                setSelectedPrice({
                  price,
                  type: PriceSelectType.FILTER,
                  market,
                });
              }
            }}
          >
            {(userQty && formatQuantity(userQty, market.roundLot)) || '-'}
          </MyQty>
        )}
        <Price
          direction={direction}
          disabled={disabled}
          onClick={() => {
            setSelectedPrice({
              price,
              type: PriceSelectType.PRICE,
            });
          }}
        >
          {formatPrice(price, market.minPriceIncrement)}
        </Price>
        <Quantity ref={quantityRef} disabled={disabled}>
          <span>
            {findNonZero(formatQuantity(amount, OB_QTY_ROUNDLOT))}
          </span>
          <span style={{ color: 'grey' }}>
            {findZero(formatQuantity(amount, OB_QTY_ROUNDLOT))}
          </span>
        </Quantity>
        {!showOnlyPriceAndQuantity && (
          <BarContainer>
            <BarFill style={{ width: `${fillPercentage}%` }} />
          </BarContainer>
        )}
      </Row>
    );
  },
);

export { OrderBookRow };
