import React from 'react';
import styled from 'styled-components/macro';
import { bind } from '@react-rxjs/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderBookRow } from './OrderBookRow';
import { OrderBookEntry, OrderBookTotals } from './services/common';
import {
  OrderBookPanel,
  TableHeaders,
  PriceHeader,
  QuantityHeader,
  Row,
  Price,
  Quantity,
} from './styles';
import { TitleWrapper } from '../../shared/style/styled';
import { Direction } from '../../shared/Direction';
import { orderBook$ } from './services/orderBookService';
import { withSubscribe } from '../../shared/helperFunctions/withSubscribe';
import { securityStatusState$ } from '../../shared/services/securityStatusService';
import { connectionStatusState$ } from '../../shared/websocket/connectionStatus';
import { SecurityStatusEnum } from '../../shared/services/securityStatus';
import { isWebsocketOpen } from '../../shared/helperFunctions/connectionStatusUtils';

const OrderBookTopPanel = styled(OrderBookPanel)`
  min-height: 110px;
  position: relative;
  bottom: 6px;
  && ${TitleWrapper} {
    display: block;
  }
  border-bottom: 0;
`;

// styled div for one side of trades, either buy or sell
const SidePanelWrapper = styled.div<{ reverseColumns: boolean }>`
  border: 1px solid ${({ theme }) => theme.palette.primary.primary1};
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 8px 0;
  padding-left: 0;
  width: 50%;
  
  ${TableHeaders} {
    line-height: normal;
    width: 100%;
    height: auto;
    justify-content: space-between;
    padding: 2px 0;
    margin-bottom: 12px;
    font-size: 12px;
    flex-direction: ${({ reverseColumns }) => (reverseColumns ? 'row-reverse' : 'row')}
  }

  ${PriceHeader}, ${QuantityHeader}, ${Price}, ${Quantity} {
    text-align: left;
    padding-left: 16px;
  }

  ${Row} {
    justify-content: space-between;
    width: 100%;
    flex-direction: ${({ reverseColumns }) => (reverseColumns ? 'row-reverse' : 'row')}
  }
`;

// surrounds the header and data rows, below the title
const RowsWrapper = styled.div`
  width: 100%;
`;

// surrounds both buy and sell boxes
const SidePanelsWrapper = styled.div`
  display: flex;
  min-height: 76px;
`;

interface SidePanelProps {
  title: string;
  entry?: OrderBookEntry;
  book: OrderBookTotals;
  direction: Direction;
  disabled: boolean;
}

const SidePanel: React.FC<SidePanelProps> = ({
  title,
  entry,
  book,
  direction,
  disabled,
}) => (
  <SidePanelWrapper reverseColumns={direction === Direction.Buy}>
    <RowsWrapper>
      <TableHeaders>
        <PriceHeader>{title} Price</PriceHeader>
        <QuantityHeader>{title} Qty</QuantityHeader>
      </TableHeaders>
      {entry && (
        <OrderBookRow
          key={entry.price.toString()}
          showOnlyPriceAndQuantity
          market={book.market}
          direction={direction}
          orderBookEntry={entry}
          total={book.offerTotal}
          disabled={disabled}
        />
      )}
    </RowsWrapper>
  </SidePanelWrapper>
);

const [useOrderBookState, orderBookState$] = bind(
  combineLatest([
    orderBook$,
    securityStatusState$,
    connectionStatusState$,
  ]).pipe(
    map(([book, securityStatusMap, connectionStatus]) => {
      const currentMarketSymbol = book?.market?.symbol;
      const currentMarketStatus = securityStatusMap.get(
        currentMarketSymbol || '',
      );
      const securityStatus = currentMarketStatus || (SecurityStatusEnum.OPEN as SecurityStatusEnum);
      const disabled = !isWebsocketOpen(connectionStatus)
        || securityStatus !== SecurityStatusEnum.OPEN;
      return {
        book,
        disabled,
      };
    }),
  ),
);

const OrderBookTopComponent = () => {
  const { book, disabled } = useOrderBookState();
  return (
    <OrderBookTopPanel>
      <TitleWrapper>Order Book - Top of Book</TitleWrapper>
      <SidePanelsWrapper>
        {book && (
          <SidePanel
            title="Bid"
            entry={book.bids?.[0]}
            book={book}
            direction={Direction.Buy}
            disabled={disabled}
          />
        )}
        {book && (
          <SidePanel
            title="Ask"
            entry={book.offers?.[0]}
            book={book}
            direction={Direction.Sell}
            disabled={disabled}
          />
        )}
      </SidePanelsWrapper>
    </OrderBookTopPanel>
  );
};

export const OrderBookTop = withSubscribe(
  OrderBookTopComponent,
  orderBookState$,
);
