import { useHistory } from 'react-router-dom';
import { checkAuth0 } from './shared/services/auth0Service';

const Login = () => {
  const history = useHistory();
  checkAuth0();
  history.push('/');
  return null;
};

export { Login };
