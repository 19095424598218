import { Map } from 'immutable';

import { errorMapping } from '../../../orderEntry/services/block/blockTradeEntryService';

export interface BlockTradeRecord {
  request_id: string;
  contract_symbol: string;
  qty: string;
  px: string;
  trade_date: string;
  negotiated_time: string;
  submitted_time: string;
  created_by: string;
  state: string;
  buy_side?: OneSideProp;
  sell_side?: OneSideProp;
  error_code?: keyof typeof errorMapping;
  user_side: string;
}

interface OneSideProp {
  account_label: string;
  side: string;
  customer_account_ref: string;
  cl_ord_id: string;
  sender_sub_id: string;
}

export type BlockTradeMap = Map<string, BlockTradeRecord>;

export const createInitialBlockTradeMap = () => Map<string, BlockTradeRecord>();

export const blockTradeReducer = (
  currentBlockTrades: BlockTradeMap,
  newBlockTrades: BlockTradeRecord[],
) => currentBlockTrades
  .withMutations((map) => {
    newBlockTrades.forEach((blockTrade) => map.set(blockTrade.request_id, blockTrade));
  });
