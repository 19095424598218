import { Select } from '@material-ui/core';
import styled from 'styled-components/macro';
import { PrimaryDivButton } from '../../shared/style/styled';

export const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

export const TabHeader = styled.div<{ width: string }>`
  display: flex;
  flex-direction: row;
  width: ${({ width }) => width};
  overflow-x : auto;
  ::-webkit-scrollbar {
    height: 10px;
  }

  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.palette.secondary.secondary};
    padding-top: 3px;
  }
`;

interface TabButtonProps {
  selected: boolean;
}

export const TabButton = styled(PrimaryDivButton) <TabButtonProps>`
  padding: 9px 7px;
  margin-right: 2px;
  border-radius: 4px 4px 0 0;
  color: ${({ selected, theme }) => (selected
    ? theme.palette.secondary.secondary
    : theme.palette.secondary.secondary2)};
  background-color: ${({ theme }) => theme.palette.primary};
  border-bottom: 2px solid ${({ theme, selected }) => (selected ? theme.palette.accent.accentPositive : 'transparent')};
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  transition: all 100ms;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
`;

export const ButtonLabel = styled.label`
  position: relative;
  top: 5px;
`;

export const ContentSection = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const ContentWrapper = styled.div<{ isSelected: boolean }>`
  display: ${({ isSelected }) => (isSelected ? 'block' : 'none')};
  z-index: ${({ isSelected }) => (isSelected ? '1' : '-1')};
  background-color: ${({ theme }) => theme.palette.primary.primary};
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TabSelectDropdown = styled(Select)`
  display: grid;
  grid-template-columns: 110px 20px;
  align-items: center;
  justify-content: center;
  background-color:  ${({ theme }) => theme.palette.primary.primary};
  max-height: 26px;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  font-size: 11px;
  margin: 6px 0px 3px 10px;
  padding-left: 10px;
  font-weight: 550;
  border-bottom: ${({ theme }) => (`1px solid ${theme.palette.accent.accentPositive}`)};

  :active {
    border-bottom: none;
  }
`;
