import React from 'react';
import styled from 'styled-components';
import { CrossIcon } from '../../../icons/CrossIcon';

interface DismissButtonStyleProps {
  dark?: boolean;
}

const Container = styled.a<DismissButtonStyleProps>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${({ theme, dark = false }) => (dark ? theme.palette.primary.primary2 : theme.palette.secondary.secondary)};
  color: ${({ theme, dark = false }) => (dark ? theme.palette.secondary.secondary : theme.palette.primary.primary2)};
  transform: rotate(45deg);
  cursor: pointer;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
  transition: opacity 150ms ease-in;
  > svg {
    transform: translateY(1px) translateX(1px);
  }
`;

interface DismissButtonProps {
  onClick: () => void;
}

export const DismissButton:React.FC<
DismissButtonProps & DismissButtonStyleProps
> = ({ onClick, dark }) => (
    <Container onClick={onClick} dark={dark} aria-label="dismiss">
        <CrossIcon width={24} height={24}/>
    </Container>
);
