import styled from 'styled-components';
import DefaultWarningIcon from '@material-ui/icons/Warning';

export const ErrorMessageContainer = styled.div`
  font-size: 12px;
  border-radius: 4px;
  align-items: center;
  height: 28px;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  padding: 0 8px;
  justify-content: flex-start;
  display: flex;
  background-color: ${({ theme }) => theme.palette.error.main};
`;

export const WarningIcon = styled(DefaultWarningIcon)`
  width: 16px;
  height: 16px;
  padding-right: 8px;
`;
