import { bind } from '@react-rxjs/core';
import { format } from 'date-fns';
import { defer, merge, Subject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import {
  algoSend,
  subscribeToAlgoMessageStream$,
} from '../../../../shared/algoWebsocket/transport';
import { Direction } from '../../../../shared/Direction';
import { ProductValue } from '../../../../shared/ProductValue';
import { getFixId, UserAccountRaw } from '../../../../shared/services/accountsService';
import { YesOrNo } from '../trade/sendEntry';
import {
  AllInstructionResponse,
  convertMessageToInstructionResponse,
} from './InstructionResponse';

export type AlgoOrderType = 'PEGGED' | 'ORDER_ON_FILL';
export type OrderEntryMessageType =
  | 'ExecutionReport'
  | 'OrderReject'
  | 'BusinessMessageRejectMessage'
  | 'ERROR_MESSAGE';

export interface AlgoOrderEntryFormState {
  account: UserAccountRaw;
  orderType: AlgoOrderType;
  limitPrice?: number;
  quantity?: number;
  offset?: number;
  direction: Direction;
  iterations: number;
  postOnly: YesOrNo;
  cancelOnDisconnect: YesOrNo
}

export interface AlgoOrderEntrySubject {
  product: ProductValue;
  entry: AlgoOrderEntryFormState;
  correlation: string;
}

interface AlgoOrderEntryRequest {
  correlation: string;
  type: 'NewPegInstruction' | 'NewOofInstruction';
  currency: string;
  side: Direction;
  symbol: string;
  orderQty: number;
  transactTime: string;
  limitPrice?: number;
  price?: number;
  pegOffset?: number;
  offset?: number;
  partyID: string;
  iterations: number;
  postOnly: YesOrNo;
  cancelOnDisconnect: YesOrNo;
}

export const convertAlgoEntryToRequest = (
  entry: AlgoOrderEntryFormState,
  selectedMarket: ProductValue,
  correlation: string,
): AlgoOrderEntryRequest => ({
  correlation,
  type:
      entry.orderType === 'PEGGED' ? 'NewPegInstruction' : 'NewOofInstruction',
  currency: selectedMarket.currency,
  side: entry.direction,
  symbol: selectedMarket.symbol,
  transactTime: format(Date.now(), 'yyyyMMdd-hh:mm:ss.SSS'),
  orderQty: entry.quantity!,
  [entry.orderType === 'PEGGED' ? 'limitPrice' : 'price']: entry.limitPrice,
  partyID: getFixId(entry.account),
  iterations: entry.iterations,
  postOnly: entry.postOnly,
  cancelOnDisconnect: entry.cancelOnDisconnect,
  [entry.orderType === 'PEGGED' ? 'pegOffset' : 'offset']: entry.offset!,
});
export const algoOrderEntry$ = new Subject<AlgoOrderEntrySubject>();

export const submitAlgoOrderEntry = (algoOrderEntry: AlgoOrderEntrySubject) => {
  algoOrderEntry$.next(algoOrderEntry);
};

export const sendAlgoOrderEntry$ = (
  entry: AlgoOrderEntryFormState,
  product: ProductValue,
  correlation: string,
) => defer(() => {
  const orderRequest = convertAlgoEntryToRequest(entry, product, correlation);
  algoSend(orderRequest);

  return merge(
    subscribeToAlgoMessageStream$('PegInstructionResponse').pipe(
      filter(
        (msg: any): msg is AllInstructionResponse => msg.pegInstructionStatus === 'NEW',
      ),
    ),
    subscribeToAlgoMessageStream$('OofInstructionResponse').pipe(
      filter(
        (msg: any): msg is AllInstructionResponse => msg.oofInstructionStatus === 'NEW',
      ),
    ),
    subscribeToAlgoMessageStream$('ERROR_MESSAGE'),
  ).pipe(
    filter((msg: any) => msg.correlation === orderRequest.correlation),
    map((response) => convertMessageToInstructionResponse(response)),
  );
});

export const [useAlgoEntryResponse, algoOrderEntryState$] = bind(
  algoOrderEntry$.pipe(
    switchMap((algoOrderEntry) => sendAlgoOrderEntry$(
      algoOrderEntry.entry,
      algoOrderEntry.product,
      algoOrderEntry.correlation,
    )),
  ),
  null,
);
