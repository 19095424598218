import { Map } from 'immutable';
import { Direction } from '../../../../shared/Direction';
import { OrderType, TimeInForce, YesOrNo } from '../../../orderEntry/services/trade/sendEntry';

export interface ExecutionReport {
  correlation: string;
  type: 'ExecutionReport';
  account: string;
  avgPrice: number;
  orderID: string;
  execID: string;
  clOrdID: string;
  ordStatus: string;
  symbol: string;
  side: Direction;
  orderQty: number;
  ordType: OrderType;
  price: number;
  stopPrice: number;
  currency: string;
  leavesQty: number;
  cumQty: number;
  transactTime: string;
  commCalculated: number;
  timeInForce: TimeInForce;
  partyIDs: string[];
  postOnly: YesOrNo;
  execType: string;
  lastQty: number;
  lastPrice: number;
  matchingType: string;
  commission: number;
  commCurrency: string;
  productCode: string;
  accountNumber: string;
  origClOrdID: string;
  availableBalanceData: {
    availableBalance: number;
    availableBalanceCurrency: string;
  }[];
  timeInMilliseconds: number;
  cashOrderQty?: number;
  targetLocationId?: string;
  targetSubId?: string;
}

export interface OrderCancelReject {
  account: string;
  clOrdID: string;
  correlation: string;
  cxlRejReason: string;
  cxlRejResponseTo: string;
  ordStatus: string;
  orderID: string;
  origClOrdID: string;
  senderLocationId?: string;
  senderSubId?: string;
  text: string;
  transactTime: string;
  type: 'OrderCancelReject';
}

export const isOrderStatusExecutionReport = (executionReport: ExecutionReport) => executionReport.execType === 'ORDER_STATUS';

export type OpenOrderMap = Map<string, ExecutionReport>;

export const initialOpenOrderMap: OpenOrderMap = Map<string, ExecutionReport>();

type OpenOrderEvent = {
  type: 'newExecutionReport',
  payload: ExecutionReport,
} | {
  type: 'newMassOrderRequest',
  payload: string,
};

export const openOrdersReducer = (
  existingOpenOrders: OpenOrderMap,
  event: OpenOrderEvent,
) => {
  if (event.type === 'newMassOrderRequest') {
    return existingOpenOrders.withMutations((map) => map.clear());
  }
  const orderUpdate = event.payload;
  if (orderUpdate.leavesQty === 0 || orderUpdate.ordStatus === 'CANCELED') {
    return existingOpenOrders.withMutations((map) => map.delete(orderUpdate.orderID));
  }
  return existingOpenOrders.withMutations((map) => map.set(orderUpdate.orderID, orderUpdate));
};
