import React from 'react';

export const SettingIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
  panelHidden?: boolean;
}> = ({ height = 24, width = 24, panelHidden }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" className='settingIcon'>
        <g fill="none" fillRule="evenodd">
            <g fill={panelHidden ? '#FFF' : '#00EBB9'}>
                <path d="M11.91 9.564c-1.327 0-2.4 1.072-2.4 2.4 0 1.327 1.073 2.4 2.4 2.4 1.328 0 2.4-1.073 2.4-2.4 0-1.328-1.072-2.4-2.4-2.4zm0 4.018c-.89 0-1.618-.727-1.618-1.618 0-.891.728-1.619 1.619-1.619.89 0 1.618.728 1.618 1.619 0 .89-.728 1.618-1.618 1.618zm5.728-.327l-.6-.382c-.31-.2-.491-.528-.491-.891v-.018c0-.364.182-.71.49-.891l.6-.382c.183-.11.237-.346.128-.527l-1.364-2.346c-.109-.182-.327-.254-.509-.145l-.6.29c-.145.073-.309.11-.472.11-.2 0-.382-.055-.564-.146l-.018-.018c-.327-.182-.527-.509-.546-.873l-.036-.672c-.018-.2-.182-.364-.382-.364h-2.727c-.2 0-.382.164-.382.364l-.036.672c-.018.364-.218.691-.546.873l-.018.018c-.182.11-.364.146-.564.146-.163 0-.327-.037-.472-.11l-.6-.308c-.182-.091-.418-.037-.51.145l-1.363 2.345c-.11.182-.055.4.127.528l.6.382c.31.2.491.527.491.89v.019c0 .363-.182.709-.49.89l-.6.382c-.183.11-.237.346-.128.528l1.364 2.345c.109.182.327.236.509.146l.6-.31c.145-.072.309-.109.472-.109.2 0 .382.055.564.146l.018.018c.328.182.528.51.546.873l.036.672c.018.2.182.364.382.364h2.709c.2 0 .382-.164.382-.364l.036-.672c.018-.364.218-.691.546-.873l.036-.018c.182-.11.364-.146.564-.146.163 0 .327.037.472.11l.6.309c.182.09.419.036.51-.146l1.363-2.327c.11-.182.055-.418-.127-.527zm-1.71 2.163l-.272-.145c-.255-.128-.545-.2-.836-.2-.328 0-.655.09-.946.254l-.018.018c-.564.328-.91.891-.927 1.51l-.018.309h-1.982l-.018-.291c-.037-.637-.382-1.2-.928-1.51l-.018-.018c-.29-.163-.618-.254-.945-.254-.291 0-.582.073-.837.2l-.29.127-.982-1.709.29-.182c.528-.345.855-.927.855-1.545v-.018c0-.637-.31-1.219-.855-1.546l-.29-.182.981-1.709.273.146c.255.127.546.2.836.2.328 0 .655-.091.946-.255l.018-.018c.564-.327.91-.89.927-1.51l.019-.308h1.963l.018.309c.037.636.382 1.2.928 1.509l.018.018c.29.164.618.255.945.255.291 0 .582-.073.837-.2l.272-.146.982 1.71-.29.181c-.528.346-.855.927-.855 1.546v.018c0 .636.309 1.218.854 1.545l.291.182-.945 1.71z" />
            </g>
        </g>
    </svg>
);
