import styled from 'styled-components';
import { FormLabel } from '@material-ui/core';
import { LinkButton } from '../../AccountManagement.styled';
import { Button } from '../../components/Button/Button';

export const Container = styled.div`
  min-width: 350px;
`;

export const Label = styled(FormLabel)`
  color: ${({ theme }) => theme.palette.secondary.secondary};
  padding-bottom: 5px;
  font-size: 11px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 60px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.primary2};
`;

export const Title = styled.h1`
  font-size: 19px;
`;

export const SubTitle = styled.h2`
  font-size: 10px;
  text-transform: uppercase;
  padding-bottom: 3px;
  padding-top: 7px;
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
`;

export const AddNewAllocationButton = styled(LinkButton)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.accent.accentPrimary};
  text-decoration: none;
  font-weight: 500;
  > svg {
    padding-right: 5px;
  }
  padding-bottom: 10px;
`;

export const SendButton = styled(Button)`
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 10px;
  height: 35px;
  border-radius: 3px;
  align-self: stretch;
  pointer-events: ${({ colorTheme }) => (colorTheme === 'secondary' ? 'none' : 'inherit')};
`;

export const Footer = styled.div`
  display: flex;
  padding: 10px 0 5px 0;
  flex-direction: column;
  align-items: center;
`;

export const SettlementAllocationLabels = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

export const SettlmentAllocations = styled.div`
  padding: 15px 0 8px 0;
  margin-bottom: 7px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.primary1};
`;
