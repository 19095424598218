import { Subscribe } from '@react-rxjs/core';
import React, { ComponentType, ReactNode } from 'react';
import { Observable } from 'rxjs';

export function withSubscribe<T>(
  Component: ComponentType<T>,
  source$: Observable<any>,
  FallbackComponent?: NonNullable<ReactNode> | null,
) {
  const Fallback = FallbackComponent || <>loading...</>;

  return (props: T) => (
    <Subscribe source$={source$} fallback={Fallback}>
      <Component {...props} />
    </Subscribe>
  );
}
