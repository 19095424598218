import React from 'react';

export const CloseIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
}> = ({ height = 26, width = 26 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" version="1.1">
    <g fill="none" fillRule="evenodd">
      <g>
        <path d="M0 0H24V24H0z" />
        <path fill="#FFF" d="M16.003 7.627c.206.185.224.5.04.707l-3.037 3.39 3.036 3.392c.164.183.168.453.023.64l-.062.066c-.205.184-.522.167-.706-.039l-2.963-3.309-2.961 3.31c-.185.205-.5.222-.707.038-.205-.184-.223-.5-.039-.706l3.036-3.392-3.036-3.39c-.163-.183-.168-.453-.022-.64l.061-.067c.206-.184.522-.166.707.04l2.962 3.308 2.962-3.309c.184-.205.5-.223.706-.039z" />
      </g>
    </g>
  </svg>
);
