import DateFnsUtils from '@date-io/date-fns';
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { bind, useSubscribe } from '@react-rxjs/core';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ThemeProvider } from 'styled-components';
import {
  AccountManagement, AccountManagementViewType,
  accountManagementView$, shouldShowAccountManagementView,
} from './components/accountManagement';
import {
  setShowBalanceBackground,
  showBalanceBackground$,
} from './components/balances/services/balanceService';
import { BalanceMobileBackground } from './components/balances/styled';
import { DesktopLayout } from './components/layouts/DesktopLayout';
import { MobileLayout } from './components/layouts/MobileLayout';
import { getUserInfoWhenAuthenticated$ } from './components/navbar/services/userInfoService';
import { showUserPrefs$ } from './components/navbar/services/userPrefsService';
import { UserPrefsModal } from './components/navbar/UserPrefsModal';
import { useUnreadNotifications } from './components/notifications/notificationsService';
import {
  getAccountsWhenAuthenticated$,
} from './shared/services/accountsService';
import { initGA, pageView as pageViewTracking } from './components/tracking';
import { MODAL_PORTAL_NAME } from './shared/components/Modal';
import { Portal } from './shared/components/Portal';
import { formatPrice } from './shared/formatters';
import { getPageTitle } from './shared/helperFunctions/windowUtils';
import { withSubscribe } from './shared/helperFunctions/withSubscribe';
import { useMobileCheck } from './shared/hooks/useMobileCheck';
import { useWindowSize } from './shared/hooks/useWindowSize';
import {
  startSessionCheck,
  stopSessionCheck,
  userLogout,
} from './shared/services/auth0Service';
import { useMarketAndPrice } from './shared/services/latestDataService';
import { getUserPermissionsWhenAuthenticated$ } from './shared/services/userPermissionsService';
import { GlobalScrollbarStyle } from './shared/style/Scrollbars';
import { GlobalStyle } from './shared/style/styled';
import { themePalette } from './shared/style/theme';

const muiTheme = createMuiTheme(themePalette);

const [useHomeState, homeState$] = bind(
  combineLatest([
    showUserPrefs$.pipe(startWith(false)),
    showBalanceBackground$.pipe(startWith(false)),
    accountManagementView$.pipe(startWith(AccountManagementViewType.HIDDEN)),
  ]).pipe(
    map(([showUserPrefs, showBalanceBackground, accountManagementView]) => ({
      showUserPrefs,
      showBalanceBackground,
      accountManagementView,
    })),
  ),
);

const HomeComponent: React.FC = () => {
  const { showUserPrefs, showBalanceBackground, accountManagementView } = useHomeState();
  const [toastDismissTimeoutId, setToastDismissTimeoutId] = useState<number>();

  const { height } = useWindowSize();
  const isMobile = useMobileCheck();
  const prevIsMobile = useRef(isMobile);

  const { selectedMarket, latestPrice } = useMarketAndPrice();
  const symbol = selectedMarket?.symbol || '';
  const latestPriceString = selectedMarket && latestPrice
    ? formatPrice(latestPrice, selectedMarket.minPriceIncrement)
    : '';

  const unreadNotifications = useUnreadNotifications();
  const unreadNotificationLength = unreadNotifications.count();

  useSubscribe(getAccountsWhenAuthenticated$);
  useSubscribe(getUserInfoWhenAuthenticated$);
  useSubscribe(getUserPermissionsWhenAuthenticated$);

  useEffect(() => {
    initGA('UA-126730371-1');
    pageViewTracking();

    startSessionCheck();
    return stopSessionCheck;
  }, []);

  useEffect(() => {
    /* refreshes the page when user switches from desktop to mobile (+ vice versa)
       this cleans up streams when switching between views which caused weird behaviour  */
    if (isMobile !== prevIsMobile.current) {
      window.location.reload();
    }
  }, [isMobile]);

  const onBlur = () => {
    setToastDismissTimeoutId(
      setTimeout(() => {
        if (document.hidden) {
          toast.dismiss();
        }
      }, 0),
    );
  };

  // if the user leaves the trading UI tab,
  // clear all toasts so they don't dismiss when the user enters the tab again
  // the notification will still be visible in the notification dropdown
  useEffect(() => {
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  useEffect(
    () => () => {
      clearTimeout(toastDismissTimeoutId);
    },
    [toastDismissTimeoutId],
  );

  useEffect(() => {
    document.title = getPageTitle(
      symbol,
      latestPriceString,
      unreadNotificationLength,
    );
  }, [symbol, latestPriceString, unreadNotificationLength]);

  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider theme={themePalette}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <>
              <Portal name={MODAL_PORTAL_NAME}/>
              {showUserPrefs && <UserPrefsModal />}
              {showBalanceBackground && (
                <BalanceMobileBackground
                  onClick={() => setShowBalanceBackground(false)}
                />
              )}
              {isMobile ? (
                <MobileLayout
                  isBalanceOpen={showBalanceBackground}
                  logUserOut={userLogout}
                />
              ) : (
                <DesktopLayout
                  logUserOut={userLogout}
                />
              )})

              {shouldShowAccountManagementView(accountManagementView) && (
                <AccountManagement view={accountManagementView} />)
              }
              <GlobalScrollbarStyle />
              <GlobalStyle windowHeight={height} isMobile={isMobile} />
            </>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

const Home = withSubscribe(HomeComponent, homeState$);

export { Home };
