import { Subject, EMPTY } from 'rxjs';
import { bind } from '@react-rxjs/core';
import {
  switchMap, catchError, filter, tap, skip,
} from 'rxjs/operators';
import { fetchUserInfo$ } from './getUserInfo';
import {
  connectionStatusState$,
  ConnectionStatus,
} from '../../../shared/websocket/connectionStatus';

interface UserInfoResponse {
  nickname: string;
  picture: string;
  name: string;
  email: string;
}

export const userInfo$ = new Subject<any>();
// eslint-disable-next-line
const [useUserInfo, userInfoState_$] = bind<UserInfoResponse | null>(
  userInfo$.pipe(
    switchMap(fetchUserInfo$),
    catchError(() => EMPTY),
  ),
  null,
);

export { useUserInfo };
// eslint-disable-next-line
export const userInfoState$ = userInfoState_$.pipe(skip(1));

export const getUserInfo = () => {
  userInfo$.next();
};

export const getUserInfoWhenAuthenticated$ = connectionStatusState$.pipe(
  filter((status) => status === ConnectionStatus.AUTHENTICATED),
  tap(() => {
    getUserInfo();
  }),
);
