import React, { useMemo } from 'react';
import { combineLatest } from 'rxjs';
import { withSubscribe } from '../../helperFunctions/withSubscribe';
import { accountsResponse$, useAccounts } from '../../services/accountsService';
import {
  usePermissions,
  userPermissions$,
} from '../../services/userPermissionsService';
import { AccountSelector, AccountSelectorProps } from './AccountSelector';

export const AccountSelectorContainer: React.FC<Omit<
AccountSelectorProps,
'userPermissions' | 'userAccounts'
>> = withSubscribe(
  ({
    selectedAccount, onAccountSelected, onFocus, onBlur,
  }) => {
    const accountsResponse = useAccounts();
    const userPermissions = usePermissions();

    const accountOptions = useMemo(() => accountsResponse?.accounts.map((account) => account), [
      accountsResponse,
    ]) || [];

    return (
      <AccountSelector
        userPermissions={userPermissions}
        userAccounts={accountOptions}
        selectedAccount={selectedAccount}
        onAccountSelected={onAccountSelected}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    );
  },
  combineLatest([accountsResponse$, userPermissions$]),
  <></>,
);
