import React from 'react';
import { formatPrice } from '../../../shared/formatters';
import {
  TwentyFourHourRow, TwentyFourHourTitle, TwentyFourHourValue, TwentyFourHourWrapper,
} from './styled';

interface Props {
  volume: string;
  color: string;
  percent: number;
  currency: string;
  latestPrice?: number;
  minPriceIncrement: number;
  marketType?: 'FUT' | 'SPOT'
}

export const TwentyFourHour: React.FunctionComponent<Props> = ({
  volume, color, percent, currency, latestPrice, minPriceIncrement, marketType,
}) => (
  <TwentyFourHourWrapper>
    <TwentyFourHourRow>
      <TwentyFourHourTitle>
        Last Trade Price
      </TwentyFourHourTitle>
      <TwentyFourHourValue>
        {latestPrice ? formatPrice(latestPrice, minPriceIncrement) : '-'}
      </TwentyFourHourValue>
    </TwentyFourHourRow>
    <TwentyFourHourRow>
      <TwentyFourHourTitle>
        24h Price &Delta;
      </TwentyFourHourTitle>
      <TwentyFourHourValue color={color}>
        {`${percent.toFixed(2)}%`}
      </TwentyFourHourValue>
    </TwentyFourHourRow>
    <TwentyFourHourRow>
      <TwentyFourHourTitle>
        24h Volume
      </TwentyFourHourTitle>
      <TwentyFourHourValue>
        {`${volume} ${marketType === 'SPOT' ? currency : ''}`}
      </TwentyFourHourValue>
    </TwentyFourHourRow>
  </TwentyFourHourWrapper>
);
