import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { useSubscribe } from '@react-rxjs/core';

import { ErrorBoundary } from './Error';
import { Home } from './Home';
import { Login } from './Login';
import {
  loginWhenConnected$,
  updateStatusWhenAuthenticated$,
} from './shared/websocket/login';
import {
  algoLoginWhenConnected$,
  algoUpdateStatusWhenAuthenticated$,
} from './shared/algoWebsocket/algoLogin';
import { authorizeUser$ } from './shared/services/authorize';
import { initDatadog } from './shared/datadog';

const App: React.FC = () => {
  useSubscribe(loginWhenConnected$);
  useSubscribe(updateStatusWhenAuthenticated$);
  useSubscribe(algoLoginWhenConnected$);
  useSubscribe(algoUpdateStatusWhenAuthenticated$);
  useSubscribe(authorizeUser$);

  initDatadog();

  return (
    <BrowserRouter>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <ErrorBoundary>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export { App };
