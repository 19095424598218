import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../../shared/components/Modal';
import { MarketOrderExecutionSummaryComponent, MarketOrderExecutionSummaryComponentProps } from './MarketOrderExecutionSummaryComponent';
import { isFilled, isPartiallyFilled } from './services/helpers';
import { CloseIcon } from '../../../icons/CloseIcon';
import { ErisXLogo } from '../../../shared/assets/ErisXLogo';
import { DismissButton } from '../../../shared/components/DismissButton/DismissButton';

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  display: flex;
  flex: 1;
  justify-content: right;
`;

const HeaderBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  padding: 15px 0 0 0
`;

const Component:React.FC<MarketOrderExecutionSummaryComponentProps> = (
  (props) => (
    <Modal content={<MarketOrderExecutionSummaryComponent {...props}/>}
      renderHeader={() => {
        const { onClose, summary } = props;
        return (
        <Header>
          <TopBar>
            <DismissButton dark onClick={onClose}><CloseIcon/></DismissButton>
          </TopBar>
          <HeaderBody>
            <ErisXLogo/>
            <Title>{isFilled(summary.status) ? 'Order Executed' : isPartiallyFilled(summary.status) ? 'Order Partially Executed' : 'Order Not Executed'}</Title>
          </HeaderBody>
        </Header>);
      }}
    />
  )
);

export { Component as MarketOrderExecutionSummaryModal };
