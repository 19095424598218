import { mergeMap } from 'rxjs/operators';
import { fromFetch } from 'rxjs/fetch';
import { Config } from '../../config/config';
import { getToken } from '../websocket/token';

export const getUserPermissions$ = () => fromFetch(`${Config.CLEARING_REST_API_URL}v1/user`, {
  method: 'GET',
  headers: { authorization: `Bearer ${getToken()}` },
  mode: 'cors',
  credentials: 'include',
}).pipe(
  mergeMap((response) => response.json()),
);
