import React from 'react';

export const EthereumIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
}> = ({ height = 20, width = 20 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF">
        <g>
          <path d="M12 22.956c6.041 0 10.956-4.914 10.956-10.956S18.041 1.044 12 1.044C5.959 1.044 1.044 5.958 1.044 12S5.959 22.956 12 22.956zM12 0c6.617 0 12 5.383 12 12s-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0zm0 19.826c4.315 0 7.826-3.51 7.826-7.826 0-4.315-3.51-7.826-7.826-7.826-4.315 0-7.826 3.51-7.826 7.826 0 4.315 3.51 7.826 7.826 7.826zM12 3.13c4.89 0 8.87 3.98 8.87 8.87 0 4.891-3.98 8.87-8.87 8.87-4.89 0-8.87-3.979-8.87-8.87 0-4.89 3.98-8.87 8.87-8.87zm0 7.1l-1.702 1.42L12 13.35l1.702-1.702L12 10.23zm0-3.558l-1.41 3.751 1.168-.972c.14-.116.342-.116.481 0l1.168.972L12 6.672zm-2.595 5.112c-.006-.012-.013-.023-.017-.036-.016-.044-.024-.09-.023-.138v-.003c0-.006.004-.01.004-.016.002-.034.008-.068.02-.1l2.259-6.025c.055-.147.195-.244.352-.244.158 0 .298.097.353.244l2.26 6.025c.01.033.017.066.019.1 0 .006.004.01.004.016v.003c0 .047-.007.094-.024.139-.004.012-.012.023-.017.035-.015.031-.034.06-.056.086-.006.006-.007.014-.013.02l-2.26 2.26c-.07.07-.166.11-.266.11s-.196-.04-.266-.11l-2.26-2.26c-.006-.006-.007-.014-.013-.02-.022-.026-.041-.055-.056-.086zM12 17.512l1.39-2.98-1.124 1.124c-.07.07-.166.11-.266.11s-.196-.04-.266-.11l-1.125-1.124L12 17.512zm2.852-5.067c.15.105.202.302.125.468l-2.636 5.648c-.062.133-.195.217-.341.217-.146 0-.28-.084-.341-.217l-2.636-5.648c-.077-.166-.024-.363.125-.468.15-.104.353-.087.482.042l2.37 2.37 2.37-2.37c.13-.129.332-.146.482-.042z" transform="translate(-4 -76) translate(4 76)" />
        </g>
      </g>
    </g>
  </svg>
);
