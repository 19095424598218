import { createListener } from '@react-rxjs/utils';
import { AccountManagementViewType } from '../types';

export const shouldShowAccountManagementView = (
  view: AccountManagementViewType,
) => view !== AccountManagementViewType.HIDDEN;

export const [
  accountManagementView$,
  showAccountManagementView,
] = createListener<AccountManagementViewType>();
