import { Direction } from '../../../../shared/Direction';
import { YesOrNo } from '../trade/sendEntry';

export enum InstructionStatus {
  NEW = 'NEW',
  CANCELED = 'CANCELED',
  COMPLETE = 'COMPLETE',
  TRIGGERED = 'TRIGGERED'
}

export interface InstructionResponse {
  type: 'PegInstructionResponse' | 'ERROR_MESSAGE' | 'OofInstructionResponse';
  correlation: string;
  side: Direction;
  transactTime: string;
  symbol: string;
  orderQty: number;
  senderSubId: string;
  senderSubLocation: string;
  accountType: number;
  custOrderCapacity: number;
  customerAccountRef: string;
  postOnly: YesOrNo;
  offset: number;
  price: number;
  currency: string;
  partyID: string;
  responseID: number;
  instructionStatus: InstructionStatus;
  message: string;
  error?: string;
  accountNumber?: string;
  timeInMilliseconds?: number;
  orderID?: string;
  oofIterations?: number;
  oofIterationsCounter?: number;
  cancelOnDisconnect: YesOrNo
}

export interface AllInstructionResponse {
  type: 'PegInstructionResponse' | 'ERROR_MESSAGE' | 'OofInstructionResponse';
  correlation: string;
  side: Direction;
  transactTime: string;
  symbol: string;
  orderQty: number;
  senderSubId: string;
  senderSubLocation: string;
  accountType: number;
  custOrderCapacity: number;
  customerAccountRef: string;
  postOnly: YesOrNo;
  offset: number;
  pegOffset: number;
  limitPrice: number;
  price: number;
  currency: string;
  partyID: string;
  oofID: number;
  pegID: number;
  oofInstructionStatus: InstructionStatus;
  pegInstructionStatus: InstructionStatus;
  orderID?: string;
  oofIterations?: number;
  oofIterationsCounter?: number;
  message: string;
  error?: string;
  accountNumber?: string;
  timeInMilliseconds?: number;
  cancelOnDisconnect: YesOrNo
}

export const convertMessageToInstructionResponse = (response: AllInstructionResponse)
: InstructionResponse => {
  const isOrderOnFill = response.type === 'OofInstructionResponse';

  if (isOrderOnFill) {
    const { oofID: responseID, oofInstructionStatus: instructionStatus, ...remaining } = response;
    return {
      ...remaining,
      responseID,
      instructionStatus,
    };
  }

  const {
    pegID: responseID,
    pegInstructionStatus: instructionStatus,
    pegOffset: offset,
    limitPrice: price,
    ...remaining
  } = response;

  return {
    ...remaining,
    responseID,
    instructionStatus,
    offset,
    price,
  };
};
