import React from 'react';
import { SuccessIcon } from '../../../../icons/SuccessIcon';
import { OrderResponse } from '../../services/trade/sendEntry';
import {
  OrderEntryCloseButton,
  OrderEntryFormTransitionsWrapper,
  OrderEntrySuccessId,
  OrderEntrySummary,
  OrderEntryTransitionTitle,
} from '../styled';

interface Props {
  latestEntryMessage: OrderResponse;
  setIsOverlayActive: (isOverlayActive: boolean) => void;
}

export const OrderEntrySuccess: React.FunctionComponent<Props> = (props) => {
  const { latestEntryMessage } = props;
  const isMarketOrder = latestEntryMessage.ordType === 'MARKET';
  const isBuyOrder = latestEntryMessage.side === 'BUY';

  const getMarketOrderSummary = () => `Summary of order: ${latestEntryMessage.side}
    ${isBuyOrder ? latestEntryMessage.cashOrderQty : latestEntryMessage.orderQty}
    ${isBuyOrder ? latestEntryMessage.commCurrency : latestEntryMessage.currency}
    in ${latestEntryMessage.symbol}`;

  const getSummaryText = () => (isMarketOrder
    ? getMarketOrderSummary()
    : `Summary of order: ${latestEntryMessage.side} ${latestEntryMessage.orderQty} ${latestEntryMessage.symbol} @ ${latestEntryMessage.price}`);

  return (
  <OrderEntryFormTransitionsWrapper>
    <OrderEntryTransitionTitle><SuccessIcon /> {' ORDER SUBMITTED:'}</OrderEntryTransitionTitle>
    <OrderEntrySuccessId>{latestEntryMessage.clOrdID}</OrderEntrySuccessId>
    <hr />
    <OrderEntrySummary>
      {getSummaryText()}
    </OrderEntrySummary>
    <hr />
    <OrderEntryCloseButton
      onClick={() => {
        props.setIsOverlayActive(false);
      }}
    >
      CLOSE
      </OrderEntryCloseButton>
  </OrderEntryFormTransitionsWrapper>
  );
};
