import React from 'react';
import { Config } from '../../config/config';
import { useMobileCheck } from '../hooks/useMobileCheck';
import { Feature } from './constants';

const parseFeatures = (config: string) => new Set(config.split(',').map((_) => _.trim().toLowerCase()));

const disabledMobileFeatures = () => parseFeatures(Config.DISABLED_FEATURES_MOBILE || '');

export const useFeatureEnabled = (key: Feature) => {
  const isMobile = useMobileCheck();
  const disabled = isMobile && disabledMobileFeatures().has(key);
  return !disabled;
};

export const FeatureToggle: React.FC<{ feature: Feature }> = ({
  feature,
  children,
}) => {
  const featureEnabled = useFeatureEnabled(feature);
  return featureEnabled ? <>{children}</> : null;
};
