import { createGlobalStyle } from 'styled-components/macro';

export const GlobalScrollbarStyle = createGlobalStyle`
    ::-webkit-scrollbar {
        width: 16px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.palette.primary.primary1};
        background-clip: padding-box;
        border: 1px solid transparent;
        box-shadow: 0 0 0 0.5px inset ${({ theme }) => theme.palette.primary.primary2};
    }

    ::-webkit-scrollbar-track {
         background-color: transparent;
    }

    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    ::-webkit-scrollbar-button:single-button {
        background-color: transparent;
        display: block;
        border-style: solid;
    }
    ::-webkit-scrollbar-button:single-button:vertical {
        height: 10px;
        width: 16px;
    }
    ::-webkit-scrollbar-button:single-button:horizontal {
        height: 16px;
        width: 10px;
    }

    /* Up */
    ::-webkit-scrollbar-button:single-button:vertical:decrement {
        border-width: 0 8px 8px 8px;
        border-color: transparent transparent ${({ theme }) => theme.palette.primary.primary1} transparent;
    }
    ::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
        border-color: transparent transparent ${({ theme }) => theme.palette.primary.primary4} transparent;
    }

    /* Down */
    ::-webkit-scrollbar-button:single-button:vertical:increment {
        border-width: 8px 8px 0 8px;
        border-color: ${({ theme }) => theme.palette.primary.primary1} transparent transparent transparent;
    }
    ::-webkit-scrollbar-button:vertical:single-button:increment:hover {
        border-color: ${({ theme }) => theme.palette.primary.primary4} transparent transparent transparent;
    }

    /* Left */
    ::-webkit-scrollbar-button:single-button:horizontal:decrement {
        border-width: 8px 8px 8px 0;
        border-color: transparent ${({ theme }) => theme.palette.primary.primary1} transparent transparent;
    }
    ::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
        border-color: transparent ${({ theme }) => theme.palette.primary.primary4} transparent transparent;
    }

    /* Right */
    ::-webkit-scrollbar-button:single-button:horizontal:increment {
        border-width: 8px 0 8px 8px;
        border-color: transparent transparent transparent ${({ theme }) => theme.palette.primary.primary1};
    }
    ::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
        border-color: transparent transparent transparent ${({ theme }) => theme.palette.primary.primary4};
    }

   .ag-details-row ::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.palette.primary.primary2};
    }
`;
