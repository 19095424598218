import React from 'react';

import { WarningPanel } from '../../shared/components/WarningMessage';
import { ProductValue } from '../../shared/ProductValue';
import { TitleWrapper } from '../../shared/style/styled';
import {
  Body,
  PriceHeader,
  QuantityHeader,
  Table,
  TableHeaders,
  TimeHeader,
  TradeHistoryPanel,
} from './styled';
import { TradeHistoryRow } from './TradeHistoryRow';
import { TradeHistoryDetail } from './types';

interface Props {
  rows: TradeHistoryDetail[]
  market?: ProductValue,
  error: boolean,
}

export const TradeHistory: React.FC<Props> = ({ rows, error, market }) => (
  <TradeHistoryPanel>
    <TitleWrapper>Trade History</TitleWrapper>
    <Table showError={error}>
      <TableHeaders>
        <PriceHeader>Price</PriceHeader>
        <QuantityHeader>Qty</QuantityHeader>
        <TimeHeader>Time</TimeHeader>
      </TableHeaders>
      <Body>
        {rows.map((row) => <TradeHistoryRow
          key={row.id}
          price={row.price}
          quantity={row.quantity}
          time={row.time}
          direction={row.direction}
          market={market}
        />)}
      </Body>
    </Table>
    {error && (<WarningPanel message="Trade History could not be loaded" />)}
  </TradeHistoryPanel>
);
