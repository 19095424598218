import {
  Input,
  Paper,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import styled, { DefaultTheme } from 'styled-components/macro';
import { Direction } from '../../shared/Direction';
import { PrimaryButton, Select } from '../../shared/style/styled';

export const OrderEntryWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  margin-bottom: 10px;
  position: relative;
`;

export const OrderEntryDirectionRow = styled.div`
  display: grid;
  grid-template-columns: 110px 110px;
  margin-bottom: 12px;
`;

export const OrderEntrySelect = styled(Select)`
  display: grid;
  grid-template-columns: 105px 40px;
  padding-left: 20px;
`;

export const OrderEntryAutocomplete: typeof Autocomplete = styled(Autocomplete)`
  background-color: ${({ theme }) => theme.palette.primary.primary1};
  max-height: 25px;
  :hover {
    border-color: transparent;
    box-shadow: ${({ theme }) => `0 2px 0 0 ${theme.palette.accent.accentPositive}`};
  }
  :active {
    border-color: transparent;
    box-shadow: ${({ theme }) => `0 2px 0 0 ${theme.palette.accent.accentPositive}`};
  }
`;

export const InputStyled = styled(Input)`
  color: ${({ theme }) => theme.palette.secondary.secondary};
  font-size: 11px;
  font-weight: 550;
  padding-left: 10px;
`;

export const PaperStyled = styled(Paper)`
  .MuiAutocomplete-option {
    padding: 0;
  }

  .MuiMenuItem-root {
    width: 100%;
  }

  .MuiAutocomplete-listbox {
    padding: 0;
  }
`;

export const getDirectionBackground = (
  selected: boolean,
  direction: Direction,
  theme: DefaultTheme,
) => {
  if (selected) {
    return direction === Direction.Buy
      ? theme.palette.primary.primary1
      : theme.palette.trading.tradingSell3;
  }
  return theme.palette.primary.primary;
};

export const OrderEntryDirection = styled.div<{
  selected: boolean;
  direction: Direction;
}>`
  display: flex;
  border-bottom: ${({ theme, selected, direction }) => selected
    && `1px solid ${
      direction === Direction.Buy
        ? theme.palette.trading.tradingBuy
        : theme.palette.trading.tradingSell
    }`};
  align-items: center;
  justify-content: center;
  color: ${({ theme, selected }) => (selected
    ? theme.palette.secondary.secondary
    : theme.palette.secondary.secondary2)};
  background-color: ${({ theme, direction, selected }) => getDirectionBackground(selected, direction, theme)};
  min-height: 25px;
  font-size: 11px;
  border-radius: 2px;
  font-weight: 500;
  cursor: pointer;
  :hover {
    color: ${({ theme, selected }) => !selected && theme.palette.secondary.secondary};
  }
`;

export const OrderEntryDirections = styled.div<{ selected: boolean }>`
  position: absolute;
  background-color: red;
  color: white;
  text-align: center;
  bottom: 0;
  width: 100%;
`;

export const OrderEntryFormRowsWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(25px, 7);
  grid-row-gap: 5px;
  align-items: center;
  justify-content: center;
`;

export const OrderEntryFormRow = styled.div`
  display: grid;
  grid-template-columns: 82px 140px;
  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.palette.secondary.secondary};
    padding-top: 3px;
  }

  .cancelOnDisconnect {
    line-height: 15px;
  }
`;

export const OrderEntryFormName = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  text-align: right;
  padding-right: 10px;
  justify-content: center;
  vertical-align: middle;
  line-height: 25px;
`;

export const ConvertAmountOrderEntryFormName = styled(OrderEntryFormName)`
  white-space: pre;
`;

export const OrderEntryFormWrapper = styled.div<{ isOverlayActive: boolean }>`
  background-color: ${({ theme }) => theme.palette.primary.primary};
  display: grid;
  grid-template-rows: 33px auto;
  align-items: center;
  justify-content: center;
  opacity: ${({ isOverlayActive }) => isOverlayActive && 0.3};
  pointer-events: ${({ isOverlayActive }) => isOverlayActive && 'none'};
  margin-top: 8px;
`;

export const BlockOrderEntryFormWrapper = styled(OrderEntryFormWrapper)<{
  isOverlayActive: boolean;
}>`
  grid-template-rows: auto;
`;

export const OrderEntryFormLimitInput = styled.div<{ controlled: boolean }>`
  color: ${({ theme, controlled }) => (controlled
    ? theme.palette.accent.accentPrimary
    : theme.palette.secondary.main)};
`;

export const OrderEntryFormQuantitiyInput = styled.div<{
  controlled: boolean;
  fullSize?: boolean;
}>`
  display: grid;
  color: ${({ theme, controlled }) => (controlled
    ? theme.palette.accent.accentPrimary
    : theme.palette.secondary.main)};
  ${({ fullSize }) => (fullSize ? '' : 'grid-template-columns: 110px 30px;')}
  align-items: center;
  justify-content: center;
`;

export const OrderEntryFormConvertInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const OrderEntryFormCurrency = styled.div`
  display: flex;
  font-size: 11px;
  color: ${({ theme }) => theme.palette.secondary.secondary3};
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  white-space: nowrap;
`;

export const OrderEntryButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
`;

export const OrderEntryButton = styled(PrimaryButton)`
  align-items: center;
  padding: 0;
  height: 25px;
  justify-content: center;
  max-width: 45px;
  font-size: 11px;
  border: none;
  cursor: pointer;
  margin-right: 5px;
  :focus {
    outline: none;
    box-shadow: none;
    filter: ${({ disabled }) => disabled && 'brightness(1.2)'};
  }
`;

export const OrderEntryClearButton = styled(OrderEntryButton)`
  background-color: ${({ theme }) => theme.palette.primary.primary1};
  color: ${({ theme }) => theme.palette.secondary.secondary};
  width: 45px;
`;

export const OrderEntrySubmitButton = styled(OrderEntryButton)<{
  disabled?: boolean;
  direction: Direction;
}>`
  background-color: ${({ theme, direction }) => (direction === Direction.Buy
    ? theme.palette.trading.tradingBuy
    : theme.palette.trading.tradingSell)};
  min-width: 104px;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  opacity: ${({ disabled, direction }) => (direction === Direction.Sell && !disabled ? 0.8 : disabled && 0.6)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  :hover {
    filter: ${({ disabled }) => !disabled && 'brightness(1.15)'};
  }
  :focus {
    filter: ${({ disabled }) => !disabled && 'brightness(1.15)'};
  }
  :active {
    opacity: 0.7;
  }
`;

export const OrderEntryCancelButton = styled(OrderEntryButton)<{
  disabled?: boolean;
}>`
  background-color: ${({ theme }) => theme.palette.accent.accentPrimary};
  color: ${({ theme }) => theme.palette.primary.primary};
  min-width: 100px;
  opacity: ${({ disabled }) => disabled && 0.4};
`;

export const BlockEntryDate = styled(KeyboardDateTimePicker)<{
  errorfield?: string;
}>`
  input {
    color: ${({ theme }) => theme.palette.secondary.secondary};
    background-color: ${({ theme }) => theme.palette.primary.primary1};
    font-size: 10px;
    font-weight: 550;
    padding-left: 10px;
  }

  .MuiInput-underline:before,
  .MuiInput-underline:hover:before {
    border-bottom: none;
  }

  button {
    padding: 0;
    padding-right: 6px;
    position: relative;
    bottom: 2px;

    svg {
      color: ${({ theme }) => theme.palette.accent.accentPositive};
    }
  }

  :hover {
    border-bottom: ${({ theme, errorfield }) => !errorfield && `2px solid ${theme.palette.accent.accentPositive}`};
  }
  border: ${({ errorfield, theme }) => (errorfield ? `1px solid ${theme.palette.trading.tradingSell}` : 'none')};
  background-color: ${({ theme }) => theme.palette.primary.primary1};
`;

export const OrderEntryDate = styled(KeyboardDatePicker)<{
  errorfield?: string;
}>`
  input {
    color: ${({ theme }) => theme.palette.secondary.secondary};
    background-color: ${({ theme }) => theme.palette.primary.primary1};
    font-size: 11px;
    font-weight: 550;
    padding-left: 10px;
  }

  .MuiInput-underline:before,
  .MuiInput-underline:hover:before {
    border-bottom: none;
  }

  button {
    padding: 0;
    padding-right: 6px;
    position: relative;
    bottom: 2px;

    svg {
      color: ${({ theme }) => theme.palette.accent.accentPositive};
    }
  }

  :hover {
    border-bottom: ${({ theme, errorfield }) => !errorfield && `2px solid ${theme.palette.accent.accentPositive}`};
  }
  border: ${({ errorfield, theme }) => (errorfield ? `1px solid ${theme.palette.trading.tradingSell}` : 'none')};
  background-color: ${({ theme }) => theme.palette.primary.primary1};
`;

export const OrderEntryText = styled(TextField)<{ errorfield?: string }>`
  input {
    color: ${({ theme }) => theme.palette.secondary.secondary};
    background-color: ${({ theme }) => theme.palette.primary.primary1};
    font-size: 11px;
    font-weight: 550;
    padding-left: 10px;
    border: ${({ errorfield, theme }) => (errorfield ? `1px solid ${theme.palette.trading.tradingSell}` : 'none')};
    :hover {
      border-bottom: ${({ theme, errorfield }) => !errorfield && `2px solid ${theme.palette.accent.accentPositive}`};
    }
    :focus {
      border-bottom: ${({ theme, errorfield }) => !errorfield && `2px solid ${theme.palette.accent.accentPositive}`};
    }
  }
`;

export const OrderBlockedMessageWrapper = styled.div`
  position: absolute;
  font-size: 14px;
  padding: 20px 0;
  display: flex;
  text-align: center;
  word-break: break-word;
  background-color: ${({ theme }) => theme.palette.primary.primary1};
  color: ${({ theme }) => theme.palette.secondary.secondary};
  bottom: 0;
  width: 100%;
  justify-content: center;
`;

export const AlgoNoFutureText = styled.div`
  width: 90%;
`;
