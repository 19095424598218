import { bind } from '@react-rxjs/core';
import { createListener } from '@react-rxjs/utils';
import {
  ColumnResizedEvent,
  GridReadyEvent,
  BodyScrollEvent,
} from 'ag-grid-community';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { COL_SETTLEMENT_DETAILS } from '../../settlementsColDefs';

const [hScrollPosition$, setHScrollPosition] = createListener<number>();
const [columnLeft$, setColumnLeft] = createListener<number>();
export const [useDetailsPanelOffset, d1$] = bind(columnLeft$, 0);

export const onGridResize = ({
  columnApi,
}: ColumnResizedEvent | GridReadyEvent) => {
  const left = columnApi.getColumn(COL_SETTLEMENT_DETAILS).getLeft();
  setColumnLeft(left);
};

export const onGridScroll = ({ left }: BodyScrollEvent) => {
  setHScrollPosition(left);
};

export const combinedLeftPosition$ = combineLatest([
  hScrollPosition$,
  columnLeft$,
]).pipe(
  map(([hScrollPos, columnLeftPos]) => columnLeftPos - hScrollPos),
);

export const [useLeftPosition, leftPosition$] = bind(combinedLeftPosition$);
leftPosition$.subscribe();
