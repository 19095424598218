import { bind } from '@react-rxjs/core';
import { createListener } from '@react-rxjs/utils';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  sortFutureSecurities,
  sortSpotSecurities,
} from '../../../../shared/comparators';
import { ProductValue } from '../../../../shared/ProductValue';
import { FUT } from '../../../orderEntry/services/trade/sendEntry';
import { securityListState$ } from '../../../../shared/services/securitiesService';
import { GroupedProductValue } from '../types';
import { Security } from '../../../../shared/services/getSecurities$';

export enum ProductFilter {
  NONE = 'All',
  FUTURE = 'Futures',
  SPOT = 'Spot',
}

export const isSpotFilter = (f: ProductFilter) => f === ProductFilter.SPOT;
export const isFutureFilter = (f: ProductFilter) => f === ProductFilter.FUTURE;
export const isNoFilter = (f: ProductFilter) => f === ProductFilter.NONE;
export const isFutureSecurity = (f: Security) => f.securityType === FUT;
export const isValidFutureSecurity = (f: Security) => f.securityType === FUT && f.productCode;

export const [
  selectedProduct$,
  setSelectedProduct,
] = createListener<ProductValue | null>();

export const [
  selectedContract$,
  setSelectedContract,
] = createListener<ProductValue | null>();

export const allSecuritiesState$ = securityListState$.pipe(
  map(({ securities }) => securities),
);

export const futuresListState$ = securityListState$.pipe(
  map(({ securities }) => {
    const futures = securities.filter(
      ({ securityType }) => securityType === FUT,
    );
    const transformedFutures = futures
      .filter(
        (prdA, i, arr) => arr.findIndex((prdB) => prdA.productCode === prdB.productCode) === i,
      )
      .sort(sortFutureSecurities)
      .map((future) => ({
        type: FUT,
        symbol: future.productCode,
        value: future.productCode,
        minTradeVol: future.minTradeVol,
        maxTradeVol: future.maxTradeVol,
        roundLot: future.roundLot,
        minPriceIncrement: future.minPriceIncrement,
        contractMultiplier: future.contractMultiplier,
      })) as ProductValue[];
    return transformedFutures;
  }),
);

export const spotsListState$ = securityListState$.pipe(
  map(({ securities }) => {
    const spots = securities.filter(({ securityType }) => securityType !== FUT);
    const transformedSpots = spots.map((spot) => ({
      type: 'SPOT',
      symbol: spot.symbol,
      value: spot.symbol,
      minTradeVol: spot.minTradeVol,
      maxTradeVol: spot.maxTradeVol,
      roundLot: spot.roundLot,
      minPriceIncrement: spot.minPriceIncrement,
      currency: spot.currency,
      contractMultiplier: spot.contractMultiplier,
    })) as ProductValue[];
    return transformedSpots.reduce((acc, next) => {
      const quoteCurrency = next.symbol.split('/')[1];
      const productValues = acc[quoteCurrency];
      if (productValues) {
        acc[quoteCurrency] = [...productValues, next].sort(sortSpotSecurities);
        return acc;
      }
      acc[quoteCurrency] = [next];
      return acc;
    }, {} as GroupedProductValue);
  }),
);

export const [
  selectedProductFilter$,
  setSelectedProductFilter,
] = createListener<ProductFilter>();

export const [useSelectedProductFilter] = bind(
  selectedProductFilter$,
  ProductFilter.FUTURE,
);

export const [
  productFilterPredicate$,
  setProductFilterPredicate,
] = createListener<string>();

export const createFilteredSpotsState$ = (
  spots$: Observable<GroupedProductValue>,
  productFilter$: Observable<string>,
) => combineLatest([
  spots$,
  productFilter$.pipe(startWith('')),
]).pipe(
  map(([spots, predicate]) => Object.keys(spots).reduce<GroupedProductValue>((acc, key) => {
    const filteredSpots = spots[key].filter(
      ({ symbol }) => !predicate || symbol.toLowerCase().includes(predicate.toLowerCase()),
    );

    return filteredSpots.length > 0
      ? {
        ...acc,
        [key]: filteredSpots,
      }
      : acc;
  }, {})),
);

export const [useFilteredSpots, filteredSpotsState$] = bind(
  createFilteredSpotsState$(spotsListState$, productFilterPredicate$),
  {},
);

export const createFilteredFuturesListState$ = (
  futuresList$: Observable<ProductValue[]>,
  productFilter$: Observable<string>,
) => combineLatest([
  futuresList$,
  productFilter$.pipe(startWith('')),
]).pipe(
  map(([futures, predicate]) => futures.filter((filter) => (
    !predicate
    || (filter.symbol && filter.symbol.toLowerCase().includes(predicate.toLowerCase()))
  ))),
);

export const [useFilteredFuturesList, filteredFuturesList$] = bind(
  createFilteredFuturesListState$(futuresListState$, productFilterPredicate$),
  [],
);
