import React, { PropsWithChildren } from 'react';
import { FilterButton, FilterButtonsContainer } from './FilterButton.styled';

interface FilterButtonProps<T> {
  options: T[];
  currentSelection: T;
  onFilterSelect: (filter: T) => void;
  disabledOptions?: T[];
}

export const FilterButtons = <T extends string>({
  currentSelection,
  onFilterSelect,
  disabledOptions = [],
  options,
}: PropsWithChildren<FilterButtonProps<T>>) => (
  <FilterButtonsContainer onClick={(e) => { e.stopPropagation(); }}>
    {options.map((filter) => (
      <FilterButton
        key={filter}
        onClick={() => onFilterSelect(filter) }
        $isSelected={filter === currentSelection}
        disabled={disabledOptions.includes(filter)}
      >
        {filter}
      </FilterButton>
    ))}
  </FilterButtonsContainer>
  );
