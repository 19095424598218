import React, { useEffect, useState } from 'react';

import {
  AuthStatus,
  useAuthStatus,
} from '../../shared/services/authStatusService';
import { usePermissions } from '../../shared/services/userPermissionsService';
import {
  ConnectionStatus,
  useConnectionStatus,
} from '../../shared/websocket/connectionStatus';
import { requestBlockTradeData } from '../orderGridsTabPanel/services/blockTrades/blockTradesService';
import { TabPanel } from '../tabs/TabPanel';
import { BlockEntryForm } from './blockTradeOrderEntry/BlockEntryForm';
import { useAlgoEntryResponse } from './services/algos/algoSendEntry';
import { useBlockTradeResponse } from './services/block/blockTradeEntryService';
import { useOrderEntryResponse } from './services/trade/sendEntry';
import { OrderEntryWrapper } from './styled';
import { MarketOrderExecutionSummaryModal } from './marketOrderExecutionSummary/MarketOrderExecutionSummaryModal';
import { OrderEntryForm } from './tradeOrderEntry/OrderEntryForm';
import { AlgoOrderEntryTransitionScreens } from './transitionScreens/algo/AlgoOrderEntryTransitionScreens';
import { BlockOrderEntryTransitionScreens } from './transitionScreens/block/BlockOrderEntryTransitionScreens';
import { OrderEntryTransitionScreens } from './transitionScreens/trade/OrderEntryTransitionScreens';
import {
  onRemoveMarketOrder,
  useMarketOrderSummary,
} from './marketOrderExecutionSummary/services/marketOrderExecutionSummaryService';

export const OrderEntry = () => {
  const permissions = usePermissions();
  const latestEntryResponse = useOrderEntryResponse();
  const latestBlockEntryResponse = useBlockTradeResponse();
  const latestAlgoEntryResponse = useAlgoEntryResponse();
  const authStatus = useAuthStatus();
  const connectionStatus = useConnectionStatus();
  const [tabSelected, setTabSelected] = useState<string | undefined>(
    'orderEntry',
  );
  const [isOrderLoading, setIsOrderLoading] = useState(false);
  const [isOverlayActive, setIsOverlayActive] = useState(
    authStatus !== AuthStatus.AUTHENTICATED,
  );
  const [lastUsedCorrelation, setLastUsedCorrelation] = useState('');

  const marketOrderSummary = useMarketOrderSummary();

  useEffect(() => {
    setIsOverlayActive(authStatus !== AuthStatus.AUTHENTICATED);
  }, [authStatus]);

  useEffect(() => {
    if (
      (latestEntryResponse?.correlation === lastUsedCorrelation
        || latestBlockEntryResponse?.correlation === lastUsedCorrelation
        || latestAlgoEntryResponse?.correlation === lastUsedCorrelation)
      && isOrderLoading
    ) {
      setIsOrderLoading(false);
      if (
        latestBlockEntryResponse?.state === 'accepted'
        && latestBlockEntryResponse.request_id
      ) {
        requestBlockTradeData(latestBlockEntryResponse.request_id);
      } else if (latestBlockEntryResponse?.error?.request_id) {
        requestBlockTradeData(latestBlockEntryResponse.error.request_id);
      }
    }
  }, [
    latestEntryResponse,
    isOrderLoading,
    setIsOrderLoading,
    lastUsedCorrelation,
    latestBlockEntryResponse,
    latestAlgoEntryResponse,
  ]);

  return (
    <OrderEntryWrapper>
      {
        <TabPanel
          switchTab={setTabSelected}
          tabs={[
            {
              title: 'Order Entry',
              tabId: 'orderEntry',
              content: (
                <OrderEntryForm
                  isSelected={tabSelected === 'orderEntry'}
                  setIsOrderLoading={setIsOrderLoading}
                  setisOverlayActive={setIsOverlayActive}
                  setLastUsedCorrelation={setLastUsedCorrelation}
                  isOverlayActive={
                    isOverlayActive
                    || connectionStatus !== ConnectionStatus.AUTHENTICATED
                  }
                  lastUsedCorrelation={lastUsedCorrelation}
                  latestEntryResponse={latestEntryResponse}
                  spotPermissions={permissions?.spotPermissions}
                  futuresPermissions={permissions?.futuresPermissions}
                />
              ),
            },
            ...(!permissions?.error && permissions?.blockTrades
              ? [
                {
                  title: 'Block Entry',
                  tabId: 'blockEntry',
                  content: (
                      <BlockEntryForm
                        isSelected={tabSelected === 'blockEntry'}
                        setIsOrderLoading={setIsOrderLoading}
                        setisOverlayActive={setIsOverlayActive}
                        setLastUsedCorrelation={setLastUsedCorrelation}
                        isOverlayActive={isOverlayActive}
                        lastUsedCorrelation={lastUsedCorrelation}
                        latestEntryResponse={latestBlockEntryResponse}
                      />
                  ),
                },
              ]
              : []),
          ]}
        />
      }
      {isOverlayActive
        && tabSelected === 'orderEntry'
        && (latestEntryResponse?.correlation === lastUsedCorrelation
          || isOrderLoading) && (
          <OrderEntryTransitionScreens
            setIsOverlayActive={setIsOverlayActive}
            isOrderLoading={isOrderLoading}
            latestEntryResponse={latestEntryResponse}
          />
      )}
      {isOverlayActive
        && tabSelected === 'blockEntry'
        && (latestBlockEntryResponse?.correlation === lastUsedCorrelation
          || isOrderLoading) && (
          <BlockOrderEntryTransitionScreens
            setIsOverlayActive={setIsOverlayActive}
            isOrderLoading={isOrderLoading}
            latestEntryResponse={latestBlockEntryResponse}
          />
      )}
      {isOverlayActive
        && tabSelected === 'algoEntry'
        && (latestAlgoEntryResponse?.correlation === lastUsedCorrelation
          || isOrderLoading) && (
          <AlgoOrderEntryTransitionScreens
            setIsOverlayActive={setIsOverlayActive}
            isOrderLoading={isOrderLoading}
            latestEntryResponse={latestAlgoEntryResponse}
          />
      )}
      {marketOrderSummary && (
        <MarketOrderExecutionSummaryModal
          summary={marketOrderSummary}
          onClose={() => onRemoveMarketOrder(marketOrderSummary.clOrdID)}
        />
      )}
    </OrderEntryWrapper>
  );
};
