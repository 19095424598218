import styled from 'styled-components';
import ArrowRight from '@material-ui/icons/ArrowRight';

export const Container = styled.span`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const ExpanderIcon = styled(ArrowRight)<{open: boolean}>`
    transform: rotate(${({ open = false }) => (open ? '90' : '0')}deg);
`;

export const ExpanderLabel = styled.span`
    color: ${({ theme }) => theme.palette.accent.accentPositive};
`;
