import { ICellRendererParams } from 'ag-grid-community';
import React, { useState } from 'react';
import { Container, ExpanderIcon, ExpanderLabel } from './DetailsExpander.styled';

export const DetailsExpander:React.FC<ICellRendererParams> = (props) => {
  const { node } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(node.expanded);

  return (
      <>
        <Container onClick={() => {
          node.setExpanded.apply(node, [!isExpanded]);
          setIsExpanded((e) => !e);
        }}>
          <ExpanderIcon open={isExpanded}/>
          <ExpanderLabel>{isExpanded ? 'Hide' : 'View'}</ExpanderLabel>
        </Container>
      </>
  );
};
