import ReactGA from 'react-ga';
import { Config } from '../../config/config';

const removeKeyAndSecret = (url: string) => {
  const urlSplit = url.split(/[?&| ]+/);
  const paramsSplit = (params: string) => params.split('=');
  let newUrl = '?';
  /* eslint-disable no-plusplus */
  for (let i = 0; i < urlSplit.length; i++) {
    const params = paramsSplit(urlSplit[i]);
    if (params[0] !== 'apiKey' && params[0] !== 'secret' && urlSplit[i].length) {
      newUrl += urlSplit[i];
    }
  }
  return newUrl;
};

export const initGA = (trackingID: string) => {
  if (Config.APP_ENV === 'PRODUCTION') {
    ReactGA.initialize(trackingID);
  }
};

export const pageView = () => {
  if (Config.APP_ENV === 'PRODUCTION') {
    ReactGA.pageview(window.location.pathname
      + removeKeyAndSecret(window.location.search));
  }
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const trackEvent = (category: string, action: string, label: string) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
