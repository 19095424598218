import React, { useState } from 'react';
import { Formik } from 'formik';

import { correlationId } from '../../../shared/helperFunctions/correlationId';
import { useSelectedMarket } from '../../../shared/services/selectedMarketService';
import { useReviewOrder } from '../../navbar/services/userPrefsService';
import {
  BlockEntryFormState,
  BlockTradeEntryResponse,
  submitBlockEntry,
} from '../services/block/blockTradeEntryService';
import { BlockOrderEntryFormComponent, getEmptyBlockSide } from './BlockOrderEntryFormComponent';
import { validateBlockOrderEntryForm } from './blockOrderEntryValidation';

interface Props {
  isOverlayActive: boolean;
  isSelected: boolean;
  setIsOrderLoading: (isOrderLoading: boolean) => void;
  setisOverlayActive: (overlayActive: boolean) => void;
  setLastUsedCorrelation: (correlation: string) => void;
  lastUsedCorrelation: string;
  latestEntryResponse: BlockTradeEntryResponse | null;
}

export const BlockEntryForm: React.FunctionComponent<Props> = (props) => {
  const market = useSelectedMarket();
  const reviewOrder = useReviewOrder();
  const [submitButtonAlreadyClicked, setSubmitButtonAlreadyClicked] = useState(false);
  const [formNeedsToBeReset, setFormNeedsToBeReset] = useState(false);

  return (
    (market) ? <Formik<BlockEntryFormState>
      validate={(values) => validateBlockOrderEntryForm(values)}
      initialValues={{
        price: undefined,
        quantity: undefined,
        negotiatedTime: undefined,
        senderSubId: '',
        sellSide: getEmptyBlockSide(),
        buySide: getEmptyBlockSide(),
      }}
      onSubmit={(values) => {
        if (values && market) {
          if (reviewOrder && !submitButtonAlreadyClicked) {
            setSubmitButtonAlreadyClicked(true);
          } else {
            const correlation = correlationId();
            submitBlockEntry({ entry: values, product: market, correlation });
            props.setLastUsedCorrelation(correlation);
            props.setIsOrderLoading(true);
            props.setisOverlayActive(true);
            setSubmitButtonAlreadyClicked(false);
            setFormNeedsToBeReset(true);
          }
        }
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => (
        <BlockOrderEntryFormComponent
          isSelected={props.isSelected}
          formikProps={formikProps}
          setSubmitButtonAlreadyClicked={setSubmitButtonAlreadyClicked}
          submitButtonAlreadyClicked={submitButtonAlreadyClicked}
          market={market}
          isOverlayActive={props.isOverlayActive}
          lastUsedCorrelation={props.lastUsedCorrelation}
          latestEntryResponse={props.latestEntryResponse}
          setFormNeedsToBeReset={setFormNeedsToBeReset}
          formNeedsToBeReset={formNeedsToBeReset}
        />
      )}
    </Formik > : <div />

  );
};
