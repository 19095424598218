import { FormikErrors } from 'formik';
import Decimal from 'decimal.js';

import { ProductValue } from '../../../shared/ProductValue';
import { OrderEntryFormState } from '../services/trade/sendEntry';
import { Direction } from '../../../shared/Direction';
import { UserPermissions } from '../../../shared/services/userPermissionsService';
import { canSubmitForMarket, marketLabel } from '../validationFunctions';
import { formatNumber, getDecimalScale } from '../../../shared/formatters';

export const getModuloOfPrice = (price: number, increment: number) => {
  const decimalScale = (getDecimalScale(increment) || 1);
  const power = Decimal.pow(10, decimalScale).toNumber();
  const priceMultiple = Decimal.mul(price, power).toNumber();
  const incrementMultiple = Decimal.mul(increment, power).toNumber();
  return !(priceMultiple % incrementMultiple);
};

export const validateOrderEntryForm = (values: OrderEntryFormState,
  market: ProductValue,
  userPermissions?: UserPermissions): FormikErrors<OrderEntryFormState> => {
  const errors: FormikErrors<OrderEntryFormState> = {};

  if (values.orderType !== 'MARKET' && !values.limitPrice) {
    errors.limitPrice = 'Limit price is required.';
  }

  if (!values.quantity) {
    errors.quantity = 'Quantity is required.';
  }

  if (!values.stopPrice && values.orderType === 'STOP_LIMIT') {
    errors.stopPrice = 'Stop price is required when order type is STOP LIMIT';
  }

  if (values.quantity && values.quantity < market.minTradeVol) {
    errors.quantity = `Quantity value should be greater than or equal to ${formatNumber(market.minTradeVol)}`;
  }

  if (values.quantity && values.quantity > market.maxTradeVol) {
    if (values.orderType === 'MARKET') {
      if (values.direction === Direction.Sell) {
        errors.quantity = `Quantity value should be less than or equal to ${formatNumber(market.maxTradeVol)}`;
      }
    } else {
      errors.quantity = `Quantity value should be less than or equal to ${formatNumber(market.maxTradeVol)}`;
    }
  }

  if (values.limitPrice && !getModuloOfPrice(values.limitPrice, market.minPriceIncrement)) {
    errors.limitPrice = `Limit price value should be an increment of ${market.minPriceIncrement}`;
  }

  if (values.stopPrice && !getModuloOfPrice(values.stopPrice, market.minPriceIncrement)) {
    errors.stopPrice = `Stop price value should be an increment of ${market.minPriceIncrement}`;
  }

  if (values.stopPrice && values.limitPrice && values.orderType === 'STOP_LIMIT') {
    if (values.stopPrice > values.limitPrice && values.direction === Direction.Buy) {
      errors.stopPrice = 'Stop price value should be less than limit price when direction BUY';
    } else if (values.stopPrice < values.limitPrice && values.direction === Direction.Sell) {
      errors.stopPrice = 'Stop price value should be greater than limit price when direction SELL';
    }
  }

  if (values.timeInForce === 'GoodTillDate' && !values.goodTillDate) {
    errors.goodTillDate = 'Expiry date is required when time in force is Good Till Date';
  }

  if (!(userPermissions && market && values.account
    && canSubmitForMarket(userPermissions, market, values.account.account_number))) {
    errors.account = {};
    errors.account.account_number = `Account ${values.account.account_number} is not permissioned to trade ${marketLabel(market)} Products. `;
  }

  return errors;
};
