import React, { useCallback } from 'react';
import { Modal } from '../../../../shared/components/Modal';
import { withSubscribe } from '../../../../shared/helperFunctions/withSubscribe';
import { accountsResponse$, useAccounts } from '../../../../shared/services/accountsService';

import { showAccountManagementView } from '../../services/accountManagementService';
import { AccountManagementViewType } from '../../types';
import { AddNewSettlementContainer } from '../AddNewSettlement';
import {
  useAddSettlementModalVisible,
  useSettlements,
  setAddSettlementModalVisible,
  refreshSettlements,
  useSettlementIsLoading,
  useSettlementsResponseFailure,
} from '../services/settlementsService';
import { SettlementConfirmationContainer } from '../SettlementConfirmation';
import { useSettlementConfirmationAction } from '../SettlementConfirmation/SettlementConfirmationService';

import { SettlementsList } from './SettlementsList';
import { LoadingInlay } from './SettlementsList.styled';

export const SettlementsListContainer: React.FC = withSubscribe(() => {
  useAccounts();
  const addSettlementModalVisible = useAddSettlementModalVisible();
  const settlementConfirmationAction = useSettlementConfirmationAction();
  const { settlements } = useSettlements();
  const isLoading = useSettlementIsLoading();
  const failedResponse = useSettlementsResponseFailure();

  const onClose = useCallback(
    () => showAccountManagementView(AccountManagementViewType.HIDDEN),
    [],
  );

  return (
    <>
      <SettlementsList
        isLoading={isLoading}
        settlements={settlements}
        errorMessage={failedResponse?.errorMessage}
        onClose={onClose}
        onRefresh={refreshSettlements}
        onAddNewSettlement={() => setAddSettlementModalVisible(true)}
      />

      {settlementConfirmationAction && (
        <Modal
          noPadding
          renderHeader={() => null}
          content={
            <SettlementConfirmationContainer
              settlementConfirmationAction={settlementConfirmationAction}
            />
          }
        />
      )}
      {addSettlementModalVisible && (
        <Modal
          renderHeader={() => null}
          content={
            <AddNewSettlementContainer
              onClose={() => setAddSettlementModalVisible(false)}
            />
          }
        />
      )}
    </>
  );
}, accountsResponse$, <LoadingInlay>Loading settlement instructions...</LoadingInlay>);
