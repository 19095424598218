import React from 'react';
import { useSubscribe } from '@react-rxjs/core';
import { GridApi } from 'ag-grid-community';
import { Grid } from '../../../shared/Grid/Grid';
import { orderHistoryColDefs } from '../colDefs/orderHistoryColDefs';
import {
  getHistoryWhenAccounts$,
  useOrderHistory,
} from '../services/orderHistory/orderHistoryService';
import { OrderResponse } from '../../orderEntry/services/trade/sendEntry';

interface Props {
  isFilterHidden: boolean;
  changeNumber: (numberOfFilter: number) => void;
  gridAPI: React.MutableRefObject<GridApi | undefined>;
}

export const OrderHistoryGrid: React.FunctionComponent<Props> = (props) => {
  useSubscribe(getHistoryWhenAccounts$);
  const orderHistory = useOrderHistory();

  return (
    <Grid
      columnDefs={orderHistoryColDefs}
      rowData={orderHistory?.valueSeq().toArray()}
      getRowNodeId={(row: OrderResponse) => row.historyID!}
      suppressCellSelection={true}
      isFilterHidden={props.isFilterHidden}
      onFilterChanged={(event) => props.changeNumber(Object.keys(event.api.getFilterModel()).length)
      }
      gridApiRef={props.gridAPI}
    ></Grid>
  );
};
