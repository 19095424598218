import { shareLatest } from '@react-rxjs/core';
import { mergeWithKey } from '@react-rxjs/utils';
import { defer, merge } from 'rxjs';
import {
  filter,
  map,
  scan,
  startWith,
  withLatestFrom,
} from 'rxjs/operators';
import { algoWebsocketConnectionNumber$ } from '../../../../shared/algoWebsocket/algoConnectionStatus';
import { subscribeToAlgoMessageStream$ } from '../../../../shared/algoWebsocket/transport';
import { getExecutionOrderDateMilliseconds } from '../../../../shared/formatters';
import {
  AllInstructionResponse,
  convertMessageToInstructionResponse,
  InstructionResponse,
} from '../../../orderEntry/services/algos/InstructionResponse';
import { accountMap$ } from '../../../../shared/services/accountsService';
import {
  algoInstructionReducer,
  initialAlgoInstructionMap,
} from './algoInstructionsReducer';

const algoInstructionCall$ = defer(() => merge(
  subscribeToAlgoMessageStream$('PegInstructionResponse'),
  subscribeToAlgoMessageStream$('OofInstructionResponse'),
)).pipe(shareLatest());

export const pegInstructionResponse$ = algoInstructionCall$.pipe(
  filter((msg: any):
    msg is AllInstructionResponse => msg.type === 'PegInstructionResponse' && Boolean(msg.pegID)),
  map((response) => convertMessageToInstructionResponse(response)),
);

export const oofInstructionResponse$ = algoInstructionCall$.pipe(
  filter((msg: any):
    msg is AllInstructionResponse => msg.type === 'OofInstructionResponse' && Boolean(msg.oofID)),
  map((response) => convertMessageToInstructionResponse(response)),
);

export const algoInstructionResponse$ = merge(pegInstructionResponse$,
  oofInstructionResponse$).pipe(
  map((originalResponse) => ({
    ...originalResponse,
    timeInMilliseconds:
        getExecutionOrderDateMilliseconds(originalResponse.transactTime),
  })),
  withLatestFrom(accountMap$),
  map(([algoInsResponse, accountMap]) => (
    {
      ...algoInsResponse,
      accountNumber:
          accountMap.get(algoInsResponse.partyID) || algoInsResponse.partyID,
    } as InstructionResponse)),
  shareLatest(),
);

export const algoInstructionState$ = mergeWithKey({
  newAlgoInstruction: algoInstructionResponse$,
  newAlgoConnection: algoWebsocketConnectionNumber$,
}).pipe(
  scan(algoInstructionReducer, initialAlgoInstructionMap),
  startWith(initialAlgoInstructionMap),
  shareLatest(),
);
