import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export const TextInput = styled(TextField)<{ errorfield?: string }>`
    display: flex;
    background-color: ${({ theme }) => theme.palette.primary.primary1};
    input, textarea {
        font-size: 11px;
        color: ${({ theme }) => theme.palette.secondary.secondary};
        padding-left: 10px;
    }

    border: ${({ errorfield, theme }) => (errorfield ? `1px solid ${theme.palette.trading.tradingSell}` : 'none')};

    & .MuiInput-root {
        &, &.Mui-disabled {
            &:before {
                border-color:  transparent;
            }
        }

        &:hover, &:active, &.Mui-focused {
            &:not(.Mui-disabled):before {
                border-bottom:  2px solid ${({ theme, errorfield }) => (errorfield ? 'transparent' : theme.palette.accent.accentPositive)};
            }
        }

        &:after {
            transform: scaleX(0);
        }
    }

`;
