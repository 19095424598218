import React from 'react';

export const NotificationIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
}> = ({ height = 24, width = 24 }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" className='notificationIcon'>
        <g fill="none" fillRule="evenodd">
            <g>
                <path d="M0 0H24V24H0z" />
                <path fill="#FFF" d="M6.668 6.923h5.058c.279 0 .505-.226.505-.505 0-.28-.226-.505-.505-.505H6.668c-.835 0-1.514.68-1.514 1.514v9.905c0 .835.68 1.514 1.514 1.514h9.905c.835 0 1.514-.68 1.514-1.514v-5.058c0-.279-.226-.505-.505-.505s-.505.226-.505.505v5.058c0 .278-.226.505-.504.505H6.668c-.278 0-.505-.227-.505-.505V7.427c0-.278.227-.504.505-.504z" />
                <path fill="#FFF" d="M16.3 5.154c-1.404 0-2.546 1.142-2.546 2.546s1.142 2.546 2.546 2.546 2.546-1.142 2.546-2.546-1.142-2.546-2.546-2.546zm0 4.083c-.847 0-1.537-.69-1.537-1.537s.69-1.537 1.537-1.537 1.537.69 1.537 1.537c0 .848-.69 1.537-1.537 1.537z" />
            </g>
        </g>
    </svg>
);
