import { getToken } from '../../../shared/websocket/token';

export async function getCandleHistory(url: string): Promise<any> {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: { authorization: `Bearer ${getToken()}` },
      mode: 'cors',
      credentials: 'include',
    });
    return response.json();
  } catch (error) {
    return {
      hasError: true,
    };
  }
}
