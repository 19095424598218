import { formatDateFromMilliseconds, formatNumber } from '../../../shared/formatters';
import { TradeRow } from '../services/fills/fillsReducer';
import { GridColDef } from '../../../shared/Grid/GridColDef';
import { sortByMilliseconds } from '../../../shared/comparators';
import { DirectionCell } from '../gridComponents/DirectionCell';

export const tradeColDefs = [
  {
    headerName: 'TIME',
    colId: 'time',
    valueGetter: (params) => params.data.timeInMilliseconds,
    valueFormatter: (params) => params.value && formatDateFromMilliseconds(params.value),
    comparator: sortByMilliseconds,
    width: 150,
    filter: 'agTextColumnFilter',
    sort: 'desc',
    sortable: true,
  } as GridColDef<TradeRow>,
  {
    headerName: 'CONTRACT',
    colId: 'contract',
    valueGetter: (params) => params.data.contract,
    width: 79,
    filter: 'agTextColumnFilter',
  } as GridColDef<TradeRow>,
  {
    headerName: 'TYPE',
    colId: 'type',
    valueGetter: (params) => params.data.type,
    width: 79,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<TradeRow>,
  {
    headerName: 'SIDE',
    colId: 'side',
    valueGetter: (params) => params.data.side,
    width: 60,
    cellRendererFramework: DirectionCell,
    cellClass: 'ag-grid-no-padding-cell',
    sortable: false,
    filterValueGetter: (params) => params.data.side,
    filter: 'agTextColumnFilter',
  } as GridColDef<TradeRow>,
  {
    headerName: 'QTY',
    colId: 'qtyWorking',
    valueGetter: (params) => params.data.qty,
    valueFormatter: (params) => params.value && formatNumber(params.value),
    cellClass: 'ag-grid-number-cell',
    width: 60,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<TradeRow>,
  {
    headerName: 'PRICE',
    colId: 'price',
    valueGetter: (params) => params.data.price,
    valueFormatter: (params) => params.value && formatNumber(params.value),
    cellClass: 'ag-grid-number-cell',
    width: 70,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<TradeRow>,
  {
    headerName: 'AGGRESSOR',
    colId: 'aggressor',
    valueGetter: (params) => params.data.aggressor,
    width: 70,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<TradeRow>,
  {
    headerName: 'FEES',
    colId: 'fees',
    valueGetter: (params) => `${formatNumber(params.data.fees)} ${params.data.feeCurrency}`,
    cellClass: 'ag-grid-number-cell',
    width: 120,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<TradeRow>,
  {
    headerName: 'TRADE ID',
    colId: 'tradeId',
    valueGetter: (params) => params.data.tradeID,
    width: 120,
    hide: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<TradeRow>,
  {
    headerName: 'ORDER ID',
    colId: 'orderId',
    valueGetter: (params) => params.data.orderID,
    width: 110,
    hide: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<TradeRow>,
  {
    headerName: 'CLORDID',
    colId: 'clOrdId',
    valueGetter: (params) => params.data.clOrdID,
    width: 190,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<TradeRow>,
  {
    headerName: 'ACCOUNT',
    colId: 'account',
    valueGetter: (params) => params.data.account,
    width: 80,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<TradeRow>,
];
