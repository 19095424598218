import React from 'react';

export const FilterIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
  isFilterHidden?: boolean;
}> = ({ height = 24, width = 24, isFilterHidden }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0H24V24H0z" />
            <path fill={isFilterHidden ? '#FFF' : '#00EBB9'} d="M14 14.667c.367 0 .667.3.667.666 0 .367-.3.667-.667.667h-4c-.367 0-.667-.3-.667-.667 0-.366.3-.666.667-.666zm1.333-3.334c.367 0 .667.3.667.667 0 .367-.3.667-.667.667H8.667C8.3 12.667 8 12.367 8 12c0-.367.3-.667.667-.667zm2-3.333c.367 0 .667.3.667.667 0 .366-.3.666-.667.666H6.667c-.367 0-.667-.3-.667-.666C6 8.3 6.3 8 6.667 8z" />
        </g>
    </svg>
);
