import { split } from '@react-rxjs/utils';
import { Observable, of, race } from 'rxjs';
import {
  delay, exhaustMap, scan, share, tap,
} from 'rxjs/operators';
import { WebSocketSubject } from 'rxjs/webSocket';
import { correlationId } from '../helperFunctions/correlationId';
import { Message, MessageMap } from './types';

const securityRequest = {
  type: 'SecurityStatusRequest',
  correlation: correlationId(),
  symbol: 'BTC/USD',
};

export const reloadWhenIdle = (ms: number) => <T extends Message>(
  source$: WebSocketSubject<T>,
): Observable<T> => (ms === Infinity
    ? source$
    : new Observable<T>((observer) => {
      let latestDate = Date.now();
      const tappedSource$ = source$.pipe(
        tap(() => {
          latestDate = Date.now();
        }),
      );

      const subscription = tappedSource$.subscribe(observer);

      const heartbeat$ = race(
        of('timeout').pipe(delay(5000)),
        source$.pipe(
          split((msg) => msg.type),
          scan(
            (acc, message$) => ({ ...acc, [message$.key]: message$ }),
            {} as MessageMap,
          ),
          exhaustMap((msgMap) => msgMap.SecurityStatus),
        ),
      );

      const intervalToken = setInterval(() => {
        if (Date.now() - latestDate > ms) {
          source$.next(securityRequest as any);
          heartbeat$.subscribe((obs) => {
            if (obs === 'timeout') {
              window.location.reload();
            }
          });
        }
      }, ms / 2);

      return () => {
        clearInterval(intervalToken);
        subscription.unsubscribe();
      };
    }).pipe(share()));
