import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { shareLatest } from '@react-rxjs/core';
import { Direction } from '../../../shared/Direction';
import { safeAdd } from '../../../shared/mathFunctions';
import { selectedMarketState$ } from '../../../shared/services/selectedMarketService';
import { ExecutionReport } from '../../orderGridsTabPanel/services/openOrders/openOrdersReducer';
import { openOrderState$ } from '../../orderGridsTabPanel/services/openOrders/openOrdersService';
import { algoOrderState$ } from '../../orderGridsTabPanel/services/algoOrders/algoOrdersService';

const ordersAndMarket$ = combineLatest([selectedMarketState$, openOrderState$, algoOrderState$])
  .pipe(
    map(([selectedMarket, ordersMap, algosMap]) => (Array.from(ordersMap.values())
      .filter((order) => (order.symbol === selectedMarket.symbol))
      .concat(Array.from(algosMap.values())
        .filter((algo) => (algo.symbol === selectedMarket.symbol)))
    )),
    shareLatest(),
  );

const listHasChanged = (a: ExecutionReport[], b: ExecutionReport[]) => {
  if (a.length !== b.length) {
    return false;
  }
  const match = a.reduce((acc, v) => (acc && b.includes(v)), true);
  return match;
};

export const groupOrdersByPrice = (acc: Map<number, number>, value: ExecutionReport) => {
  const existingQty = acc.get(value.price) || 0;
  acc.set(value.price, safeAdd(value.leavesQty, existingQty || 0));
  return acc;
};

export const buyUserOpenOrders$ = ordersAndMarket$.pipe(
  map((orders) => (orders
    .filter((order) => order.side === Direction.Buy))),
  distinctUntilChanged(listHasChanged),
  map((orders) => (orders
    .reduce(groupOrdersByPrice, new Map<number, number>()))),
  shareLatest(),
);

export const sellUserOpenOrders$ = ordersAndMarket$.pipe(
  map((orders) => (orders
    .filter((order) => order.side === Direction.Sell))),
  distinctUntilChanged(listHasChanged),
  map((orders) => (orders
    .reduce(groupOrdersByPrice, new Map<number, number>()))),
  shareLatest(),
);
