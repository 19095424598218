import React from 'react';
import { merge, Subject } from 'rxjs';
import { Map } from 'immutable';
import bigInt from 'big-integer';
import {
  filter, map, scan, withLatestFrom,
} from 'rxjs/operators';
import { bind, shareLatest } from '@react-rxjs/core';
import { toast } from 'react-toastify';
import { getExecutionOrderDateMilliseconds } from '../../shared/formatters';
import { ExecutionReport, OrderCancelReject } from '../orderGridsTabPanel/services/openOrders/openOrdersReducer';
import { InstructionResponse } from '../orderEntry/services/algos/InstructionResponse';
import { newTradeState$ } from '../orderGridsTabPanel/services/fills/fillsService';
import { algoInstructionResponse$ } from '../orderGridsTabPanel/services/algoInstructions/algoInstructionsService';
import { executionReportWithDateAndAccountNumber$, newOrderCancelRejects$ } from '../orderGridsTabPanel/services/openOrders/openOrdersService';
import { NotificationPopup } from './NotificationPopup';
import { CloseIcon } from '../../icons/CloseIcon';
import { NewNotification, NotificationType } from './NewNotification';
import { algoStatusCorrelations$ } from '../orderGridsTabPanel/services/algoOrders/algoOrdersService';
import { otcTradeResponse$ } from '../otcStreams/service';
import { Status, TradeResponse } from '../otcStreams/types';
import { Direction } from '../../shared/Direction';

export type NotificationMap = Map<string, NewNotification>;
export const initialNotificationMap: NotificationMap = Map<
string,
NewNotification
>();

export const convertExecutionReportToTradeNotification = (
  executionReport: ExecutionReport,
): NewNotification => ({
  quantity: executionReport.lastQty,
  direction: executionReport.side,
  symbol: executionReport.symbol,
  price: executionReport.lastPrice,
  clicked: false,
  notificationID: executionReport.execID,
  timeStamp: getExecutionOrderDateMilliseconds(executionReport.transactTime),
  status: executionReport.ordStatus,
  type:
    executionReport.execType === 'TRADE'
      ? NotificationType.TRADE
      : NotificationType.ORDER_CANCEL,
});

export const convertInstructionResponseToTradeNotification = (
  instructionResponse: InstructionResponse,
): NewNotification => ({
  quantity: instructionResponse.orderQty,
  direction: instructionResponse.side,
  symbol: instructionResponse.symbol,
  price: instructionResponse.price,
  clicked: false,
  notificationID: bigInt(instructionResponse.responseID).toString(),
  timeStamp: getExecutionOrderDateMilliseconds(
    instructionResponse.transactTime,
  ),
  status: instructionResponse.instructionStatus,
  type: NotificationType.INSTRUCTION,
});

export const convertOTCTradeToNotification = ({
  status,
  quantity: responseQuantity,
  tradeRequest,
  tradeId,
  unitPrice,
  tradeTime,
}: TradeResponse): NewNotification => ({
  quantity:
    status === Status.FILLED
      ? responseQuantity.quantity
      : tradeRequest.quantity.quantity,
  direction:
    tradeRequest.counterpartyAction === Direction.Buy
      ? Direction.Sell
      : Direction.Buy,
  symbol:
    status === Status.FILLED
      ? unitPrice.ticker
      : undefined,
  price: status === Status.FILLED ? unitPrice.price : undefined,
  clicked: false,
  notificationID:
    status === Status.FILLED ? tradeId : tradeRequest.counterpartyRequestId,
  timeStamp: status === Status.FILLED ? tradeTime : Date.now(),
  status,
  type:
    status === Status.FILLED
      ? NotificationType.OTC_TRADE
      : NotificationType.OTC_REJECTED,
});

export const convertOrderCancelRejectToTradeNotification = (
  orderCancelReject: OrderCancelReject,
): NewNotification => ({
  quantity: 0, // Does not exist in payload, placeholder value
  direction: Direction.Buy, // Does not exist in payload, placeholder value
  clicked: false,
  notificationID: `${orderCancelReject.orderID} - ${orderCancelReject.text} - ${orderCancelReject.transactTime}`,
  timeStamp: getExecutionOrderDateMilliseconds(orderCancelReject.transactTime),
  status: orderCancelReject.ordStatus,
  type: NotificationType.ORDER_CANCEL_REJECT,
});

const notification$ = new Subject<NewNotification>();

export const updateNotification = (
  notification: NewNotification,
) => notification$.next(notification);

const updatedNotification$ = notification$.pipe((notification) => notification);

const tradeNotification$ = newTradeState$.pipe(
  map((trade) => convertExecutionReportToTradeNotification(trade)),
);

const algoNotification$ = algoInstructionResponse$.pipe(
  withLatestFrom(algoStatusCorrelations$),
  filter(
    ([algo, correlations]) => (algo.instructionStatus === 'CANCELED'
        || algo.instructionStatus === 'TRIGGERED'
        || algo.instructionStatus === 'COMPLETE')
      && !correlations.includes(algo.correlation),
  ),
  map(([algo]) => convertInstructionResponseToTradeNotification(algo)),
);

const canceledOrders$ = executionReportWithDateAndAccountNumber$.pipe(
  filter((executionReport) => executionReport.execType === 'CANCELED'),
  map((trade) => convertExecutionReportToTradeNotification(trade)),
  shareLatest(),
);

const otcTrades$ = otcTradeResponse$.pipe(
  map((trade) => convertOTCTradeToNotification(trade!)),
);

const orderCancelRejects$ = newOrderCancelRejects$.pipe(
  map((reject) => convertOrderCancelRejectToTradeNotification(reject)),
);

export const allNotificationsReducer = (
  allNotifications: NotificationMap,
  newNotification: NewNotification,
): NotificationMap => {
  const oldNotification = allNotifications.get(newNotification.notificationID);
  if (!oldNotification || oldNotification.status !== newNotification.status) {
    // only create a toast if the user is on the trade UI tab
    // if not, the notification will still show in the notification dropdown
    if (!document.hidden) {
      const isOtcTrade = newNotification.type === NotificationType.OTC_TRADE
        || newNotification.type === NotificationType.OTC_REJECTED;
      const userOtcDirection = newNotification.direction === Direction.Buy
        ? Direction.Sell
        : Direction.Buy;
      toast.info(<NotificationPopup newNotification={newNotification} />, {
        autoClose: 4000,
        className: `trade-toaster trade-toaster-${
          isOtcTrade
            ? userOtcDirection.toLowerCase()
            : newNotification.direction.toLowerCase()
        }`,
        closeButton: <CloseIcon />,
        onClick: () => updateNotification({ ...newNotification, clicked: true }),
      });
    }
    return allNotifications.set(
      newNotification.notificationID,
      newNotification,
    );
  }
  if (oldNotification && newNotification.clicked) {
    return allNotifications.set(
      newNotification.notificationID,
      newNotification,
    );
  }

  return allNotifications;
};

export const [useUnreadNotifications] = bind(
  merge(
    updatedNotification$,
    tradeNotification$,
    algoNotification$,
    canceledOrders$,
    otcTrades$,
    orderCancelRejects$,
  )
    .pipe(scan(allNotificationsReducer, initialNotificationMap))
    .pipe(
      map((notifications) => notifications.filter((notification) => !notification.clicked)),
    ),
  initialNotificationMap,
);
