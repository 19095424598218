import { Map } from 'immutable';
import { InstructionResponse, InstructionStatus } from '../../../orderEntry/services/algos/InstructionResponse';

export type AlgoInstructionMap = Map<string, InstructionResponse>;

export const initialAlgoInstructionMap: AlgoInstructionMap = Map<string, InstructionResponse>();

type AlgoInstructionEvent = {
  type: 'newAlgoInstruction'
  payload: InstructionResponse,
} | {
  type: 'newAlgoConnection',
  payload: number,
};

const getMergeUpdateIntoExisting = (
  update: InstructionResponse,
) => (existing: InstructionResponse) => {
  const mergedInstruction = {
    ...existing,
    instructionStatus: update.instructionStatus,
    message: update.message,
    // OOF iterations swap sides
    side: update.side,
    transactTime: update.transactTime,
    timeInMilliseconds: update.timeInMilliseconds,
  };
  if (update.oofIterationsCounter) {
    mergedInstruction.oofIterationsCounter = update.oofIterationsCounter;
  }
  return mergedInstruction;
};

export const algoInstructionReducer = (existingInstructions: AlgoInstructionMap,
  event: AlgoInstructionEvent): AlgoInstructionMap => {
  // when the connection number increments,
  // clear all entries from the table because they expired.
  if (event.type === 'newAlgoConnection') {
    return existingInstructions.withMutations((map) => map.clear());
  }

  const instructionUpdate = event.payload;
  const updateKey = instructionUpdate.responseID.toString();
  const isNew = instructionUpdate.instructionStatus === InstructionStatus.NEW;

  if (!isNew && existingInstructions.get(updateKey)) {
    return existingInstructions.withMutations(
      (map) => map.update(updateKey, getMergeUpdateIntoExisting(instructionUpdate)),
    );
  }

  return existingInstructions
    .withMutations((map) => map.set(updateKey, instructionUpdate));
};
