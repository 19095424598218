import {
  FormControl, InputLabel, Select,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { bind } from '@react-rxjs/core';
import { createListener } from '@react-rxjs/utils';
import React from 'react';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Config } from '../../config/config';
import { AlertIcon } from '../../icons/AlertIcon';
import {
  NumberInput,
  QuantityOptions,
} from '../../shared/components/NumberInput';
import { withSubscribe } from '../../shared/helperFunctions/withSubscribe';
import { MenuItemStyled } from '../../shared/style/styled';
import { filteredQuoteResponse$, submitRequestQuote } from './service';
import { HeaderContainer, RequestQuoteButton, RequestQuoteErrorContainer } from './styled';
import { Status } from './types';

const RequestQuoteError = ({ errorMessage }: { errorMessage: string }) => (
  <RequestQuoteErrorContainer>
    <AlertIcon width={20} height={20} />
    <p>{errorMessage}</p>
  </RequestQuoteErrorContainer>
);

const products = Config.OTC_PRODUCTS.split(',');

const [selectedProduct$, setSelectedProduct] = createListener<string>();
const [selectedCurrency$, setSelectedCurrency] = createListener<string>();
const [quantity$, setQuantity] = createListener<number>();

export const [useRequestQuoteFormState, requestQuoteFormState$] = bind(
  combineLatest([
    selectedProduct$.pipe(startWith(null)),
    selectedCurrency$.pipe(startWith(null)),
    quantity$.pipe(startWith(NaN)),
    filteredQuoteResponse$.pipe(startWith(null)),
  ]).pipe(
    map(([selectedProduct, selectedCurrency, quantity, quoteResponse]) => {
      if (!selectedProduct && !selectedCurrency) {
        // default values (first time visiting form)
        return {
          selectedProduct: products[0],
          selectedCurrency: products[0].split('/')[1],
          quantity,
          quoteResponse,
        };
      }
      if (!selectedProduct && selectedCurrency) {
        // visiting form first time and changing currency first
        return {
          selectedProduct: products[0],
          selectedCurrency,
          quantity,
          quoteResponse,
        };
      }
      if (
        (selectedProduct && !selectedCurrency)
        || (selectedProduct
          && selectedCurrency
          && !selectedProduct.split('/').includes(selectedCurrency))
      ) {
        // preselect currency when changing product for first time
        // preselect currency when changing product that does not include previous currency
        return {
          selectedProduct,
          selectedCurrency: selectedProduct.split('/')[1],
          quantity,
          quoteResponse,
        };
      }
      return {
        selectedProduct,
        selectedCurrency,
        quantity,
        quoteResponse,
      };
    }),
  ),
);

const HeaderFormComponent = () => {
  const {
    selectedProduct,
    selectedCurrency,
    quantity,
    quoteResponse,
  } = useRequestQuoteFormState();
  const currencies = selectedProduct && selectedProduct.split('/');
  const isError = quoteResponse?.status === Status.REJECTED;

  return (
    <HeaderContainer>
      <div>
        <InputLabel focused={false}>Product</InputLabel>
        <FormControl className="productForm">
          <Select
            className="productSelect"
            value={selectedProduct}
            IconComponent={ExpandMoreIcon}
            MenuProps={{ MenuListProps: { disablePadding: true } }}
            disableUnderline
          >
            {products.map((product) => (
              <MenuItemStyled
                key={product}
                value={product}
                onClick={() => setSelectedProduct(product)}
                selected={selectedProduct === product}
              >
                {product}
              </MenuItemStyled>
            ))}
          </Select>
        </FormControl>
      </div>
      <div>
        <InputLabel focused={false}>Quantity</InputLabel>
        <FormControl className="currencyForm">
          <NumberInput
            name="quantity"
            {...QuantityOptions}
            value={quantity}
            onValueChange={(e) => setQuantity(e.floatValue!)}
            decimalScale={9} // TO CONFIRM***
            className="quantity"
          ></NumberInput>
          <Select
            className="currencySelect"
            value={selectedCurrency}
            IconComponent={ExpandMoreIcon}
            MenuProps={{ MenuListProps: { disablePadding: true } }}
            disableUnderline
          >
            {currencies
              && currencies.map((currency) => (
                <MenuItemStyled
                  key={currency}
                  value={currency}
                  onClick={() => setSelectedCurrency(currency)}
                  selected={selectedCurrency === currency}
                >
                  {currency}
                </MenuItemStyled>
              ))}
          </Select>
        </FormControl>
      </div>
      <RequestQuoteButton
        variant="contained"
        onClick={() => submitRequestQuote({ selectedCurrency, selectedProduct, quantity })
        }
      >
        Request Quote
      </RequestQuoteButton>
      {isError && <RequestQuoteError errorMessage={quoteResponse!.reason!} />}
    </HeaderContainer>
  );
};

export const RequestQuoteHeaderForm = withSubscribe(
  HeaderFormComponent,
  requestQuoteFormState$,
);
