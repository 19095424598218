import { addMinutes, format } from 'date-fns';

export const getTomorrowsDate = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const getYesterdaysDate = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

export const checkIfDateIsWithin24Hours = (date: Date) => {
  const oneDayAgo = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
  return date.getTime() > oneDayAgo;
};

const utcOffset = () => new Date().getTimezoneOffset();

export const formatToUtcDateTime = (dateTime: Date) => format(addMinutes(dateTime, utcOffset()), "yyyy-MM-dd'T'HH:mm:ss");

const dateRegex = /^(\d{4})(\d{2})(\d{2})-(.*)$/;
const replaceFn = (
  _: string,
  year: string,
  month: string,
  day: string,
  rest: string,
) => `${year}-${month}-${day}T${rest}Z`;
export const toDateFromUtc = (time: string) => new Date(time.replace(dateRegex, replaceFn));
