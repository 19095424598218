import { Paper } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import styled from 'styled-components';

export const PaperStyled = styled(Paper)`
  .MuiAutocomplete-option {
    padding: 0;
  }

  .MuiMenuItem-root {
    width: 100%;
  }

  .MuiAutocomplete-listbox {
    padding: 0;
  }
`;

export const AccountSelectorDropdownMenu = styled(PaperStyled)`
  width: fit-content;
`;

export const AccountSelectorAutocomplete: typeof Autocomplete = styled(
  Autocomplete,
)`
  background-color: ${({ theme }) => theme.palette.primary.primary1};
  max-height: 25px;
  :hover {
    border-color: transparent;
    box-shadow: ${({ theme }) => `0 2px 0 0 ${theme.palette.accent.accentPositive}`};
  }
  :active {
    border-color: transparent;
    box-shadow: ${({ theme }) => `0 2px 0 0 ${theme.palette.accent.accentPositive}`};
  }

  .MuiIconButton-label {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;
