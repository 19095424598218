import { defer, Observable } from 'rxjs';
import { map, scan, startWith } from 'rxjs/operators';
import { Direction } from '../../../shared/Direction';
import { safeAdd, safeSubtract, safeMultiply } from '../../../shared/mathFunctions';
import { ProductValue } from '../../../shared/ProductValue';
import {
  OrderBookEntry, OrderBookTotals,
} from './common';
import {
  orderBookReducer, initialOrderBookMaps, OrderBookTotalMaps, MarketDataMessage,
} from './orderBookReducer';

const minBookSize = 10;

export const getOrderBook$ = (messageObservable$: Observable<MarketDataMessage>) => defer(
  () => messageObservable$.pipe(
    map((x) => x as any),
    scan(orderBookReducer, initialOrderBookMaps),
    startWith(initialOrderBookMaps),
  ),
);

export const getBestOfferBid = (bids: OrderBookEntry[], offers: OrderBookEntry[]) => {
  const bid = (bids.length
    && bids.reduce((acc, v) => ({
      bestBid: v.price >= acc.bestBid ? v.price : acc.bestBid,
      worstBid: v.price <= acc.worstBid ? v.price : acc.worstBid,
    }), { bestBid: 0, worstBid: Number.MAX_VALUE })) || undefined;

  const offer = (offers.length
    && offers.reduce((acc, v) => ({
      worstOffer: v.price >= acc.worstOffer ? v.price : acc.worstOffer,
      bestOffer: v.price <= acc.bestOffer ? v.price : acc.bestOffer,
    }), { worstOffer: 0, bestOffer: Number.MAX_VALUE })) || undefined;
  return { ...bid, ...offer };
};

const getNumberOfPads = (numberOnSide: number, bestOppositeSide?: number) => {
  if (numberOnSide === 0) {
    return bestOppositeSide ? minBookSize : 0;
  }
  return minBookSize - numberOnSide;
};

const getPadRows = (padsRequired: number,
  direction: Direction,
  startPrice:
  number, inc: number) => Array.from({ length: padsRequired }, (x, i) => i + 1).map((e) => ({
  price: direction === Direction.Buy
    ? safeSubtract(startPrice, safeMultiply(inc, e))
    : safeAdd(startPrice, safeMultiply(inc, e)),
  amount: 0,
}));

export const padWithEmptyPrices = (entries: OrderBookEntry[],
  priceIncrement: number, direction: Direction,
  bestOppositeSide?: number,
  worstCurrentSide?: number): OrderBookEntry[] => {
  const padsRequired = getNumberOfPads(entries.length, bestOppositeSide);
  if (entries.length === 0) {
    const pads = bestOppositeSide
      ? getPadRows(padsRequired, direction, bestOppositeSide, priceIncrement)
      : [];
    return [...entries, ...pads];
  }

  const startPrice = direction === Direction.Buy
    ? (worstCurrentSide || Number.MAX_VALUE)
    : (worstCurrentSide || 0);

  const pads = getPadRows(padsRequired, direction, startPrice, priceIncrement);
  return [...entries, ...pads];
};

export const orderBookTotalsFormatMap = (totals: OrderBookTotals): OrderBookTotals => ({
  ...totals,
  bids: totals.bids.sort((a, b) => {
    if (a.price === b.price) {
      return 0;
    }
    if (a.price < b.price) {
      return 1;
    }
    return -1;
  }),
  offers: totals.offers.sort((a, b) => {
    if (a.price === b.price) {
      return 0;
    }
    if (a.price > b.price) {
      return 1;
    }
    return -1;
  }),
});

export const mapToOrderBookTotals = (orderBookObservable: Observable<OrderBookTotalMaps>,
  product: ProductValue) => orderBookObservable
  .pipe(
    map((orderBook: OrderBookTotalMaps): OrderBookTotals => {
      const sortedBids = orderBook.bidsPrice.valueSeq()
        .toArray().filter((bid) => bid.amount > 0);
      const sortedOffers = orderBook.offersPrice.valueSeq()
        .toArray().filter((bid) => bid.amount > 0);

      return {
        market: product,
        bids: sortedBids,
        bidTotal: orderBook.bidTotal,
        offers: sortedOffers,
        offerTotal: orderBook.offerTotal,
        ...getBestOfferBid(sortedBids, sortedOffers),
      };
    }),
    map((orderBookTotals): OrderBookTotals => ({
      ...orderBookTotals,
      bids: padWithEmptyPrices(orderBookTotals.bids,
        orderBookTotals.market.minPriceIncrement,
        Direction.Buy,
        orderBookTotals.bestOffer,
        orderBookTotals.worstBid),
      offers: padWithEmptyPrices(orderBookTotals.offers,
        orderBookTotals.market.minPriceIncrement,
        Direction.Sell,
        orderBookTotals.bestBid,
        orderBookTotals.worstOffer),
    })),
  );
