import React from 'react';
import styled from 'styled-components';
import { ConnectionStatusWidget } from '../../shared/components/ConnectionStatusWidget';

const FOOTER_HEIGHT = 24;

const FooterWrapper = styled.div`
  height: ${FOOTER_HEIGHT}px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 11px;
  color: ${({ theme }) => theme.palette.secondary.secondary5};
  padding-right: 16px;
`;

export const DesktopFooter : React.FC = () => (
  <FooterWrapper>
    <ConnectionStatusWidget />
  </FooterWrapper>
);
