import React from 'react';

export const RefreshIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
}> = ({ height = 24, width = 24 }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" className='RefreshIcon' fill="#FFF">
        <path d="M12.6 6.4c3.093 0 5.6 2.507 5.6 5.6 0 3.093-2.507 5.6-5.6 5.6-.331 0-.6-.269-.6-.6 0-.331.269-.6.6-.6 2.43 0 4.4-1.97 4.4-4.4 0-2.43-1.97-4.4-4.4-4.4-2.43 0-4.4 1.97-4.4 4.4 0 .9.27 1.756.763 2.477l.01-.017 1.346-2.955c.115-.302.498-.45.78-.29.287.16.362.589.264.862L9.446 16.28c-.058.129-.185.24-.312.292-.142.052-.283.045-.426-.023l-4.17-1.988c-.254-.135-.47-.529-.352-.83.117-.302.53-.41.785-.28l2.762 1.321C7.258 13.94 7 12.99 7 12c0-3.093 2.507-5.6 5.6-5.6z"/>
    </svg>
);
