import { Observable } from 'rxjs';
import {
  map, switchMap, filter, withLatestFrom, sampleTime, startWith,
} from 'rxjs/operators';
import { selectedMarketRequestCorrelation$ } from '../../../shared/services/selectedMarketService';
import {
  getOrderBook$,
  mapToOrderBookTotals,
  orderBookTotalsFormatMap,
} from './orderBookServiceHelpers';
import { subscribeToMessageStream$ } from '../../../shared/websocket/transport';
import { subscribedMarketData$ } from '../../../shared/services/marketDataSubscriptionService';
import { MarketDataMessage } from './orderBookReducer';
import { batchUpdates } from '../../../batchUpdates';

const SAMPLE_PERIOD = 150;

export const getSelectedMarketOrderMessages$ = () => subscribeToMessageStream$('MarketDataIncrementalRefresh').pipe(
  withLatestFrom(selectedMarketRequestCorrelation$),
  filter(([msg, correlation]) => msg.correlation === correlation),
  map(([msg]) => msg),
) as Observable<MarketDataMessage>;

// eslint-disable-next-line
const orderBookState_$ = subscribedMarketData$.pipe(
  switchMap((ack) => mapToOrderBookTotals(
    getOrderBook$(getSelectedMarketOrderMessages$()),
    ack.product,
  )),
  map(orderBookTotalsFormatMap),
);

export const orderBook$ = orderBookState_$.pipe(
  sampleTime(SAMPLE_PERIOD),
  batchUpdates(),
  startWith(undefined),
);

export const orderBookState$ = orderBookState_$.pipe(filter((x) => !!x));
