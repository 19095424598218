import React, { useMemo, useEffect } from 'react';
import { bind, useSubscribe } from '@react-rxjs/core';
import { GridApi } from 'ag-grid-community';
import {
  getPositionsWhenAccounts$,
  historicalPositionState$,
  newPositionState$,
  positionError$,
  getPositionsOnAlgoReconnect$,
} from '../../services/positions/positionsService';
import { positionsColDefs } from '../../colDefs/positionsColDefs';
import {
  initialPositionMap,
  PositionRow,
  HistoricalPositionResponse,
} from '../../services/positions/positionsReducer';
import { BaseGrid } from '../../../../shared/Grid/Grid';
import { getWarningMessageAsHtmlString } from '../../../../shared/components/WarningMessage';
import { PositionsGridStyleWrapper } from './PositionsGrid.styled';

interface Props {
  isFilterHidden: boolean;
  changeNumber: (numberOfFilter: number) => void;
  gridAPI: React.MutableRefObject<GridApi | undefined>;
}

const [useHistoricalPositions] = bind(historicalPositionState$, undefined);
const [usePositions] = bind(newPositionState$, initialPositionMap);
const [usePositionError] = bind(positionError$, null);

export const PositionsGrid: React.FunctionComponent<Props> = (props) => {
  useSubscribe(getPositionsWhenAccounts$);
  useSubscribe(getPositionsOnAlgoReconnect$);

  const historicalPositions = useHistoricalPositions();
  const positions = usePositions();
  const positionErrors = usePositionError();

  useEffect(() => {
    const localApi = props.gridAPI.current;
    if (localApi) localApi.showNoRowsOverlay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionErrors]);

  const positionRows = useMemo(
    () => historicalPositions && positions
      .mergeWith(
        (valA, valB) => ({
          contract: valB.contract,
          product: valB.product,
          account: valB.account,
          netPosition: valB.netPosition + valA.netPosition,
          unrealisedPnl: 0,
        }),
        historicalPositions,
      )
      .valueSeq()
      .toArray(),
    [positions, historicalPositions],
  );

  const getNoRowMessage = (
    error: HistoricalPositionResponse | null,
  ) => (error?.error ? getWarningMessageAsHtmlString('Open Positions') : 'No Rows to Show');

  useEffect(() => {
  }, [positions, historicalPositions]);
  return (
    <PositionsGridStyleWrapper isFilterHidden={props.isFilterHidden}>
      <BaseGrid
        gridOptions={{
          suppressAggFuncInHeader: true,
          autoGroupColumnDef: {
            headerName: 'ACCOUNT',
            minWidth: 220,
            sort: 'asc',
            valueGetter: (params) => params.data.contract,
            cellRendererParams: {
              suppressCount: true,
              checkbox: false,
            },
          },
        }}
        sideBar={{
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
              },
            },
          ],
          defaultToolPanel: '',
        }}
        columnDefs={positionsColDefs}
        rowData={positionRows}
        getRowNodeId={(row: PositionRow) => row.account + row.contract}
        onFilterChanged={(event) => (
          props.changeNumber(Object.keys(event.api.getFilterModel()).length)
        )}
        gridApiRef={props.gridAPI}
        loadingMessage="Loading Open Positions ..."
        noRowsMessage={getNoRowMessage(positionErrors)}
      />
    </PositionsGridStyleWrapper>
  );
};
