import { bind } from '@react-rxjs/core';
import { createListener } from '@react-rxjs/utils';
import { fromFetch } from 'rxjs/fetch';
import { map, share, switchMap } from 'rxjs/operators';
import { Config } from '../../../../config/config';
import { getToken } from '../../../../shared/websocket/token';

import { ValidatedSettlementFormState } from './AddNewSettlement';
import { settlementAllocationIsNonEmpty } from './components/SettlementAllocation';

import { SettlementResponse, SettlementRequest } from '../types';
import { fetchResponseJsonHandler } from '../helpers/fetchResponseJsonHandler';
import {
  filterResponseIsComplete,
  filterResponseIsFailure,
  filterResponseIsSuccessful,
  useIsPendingResponse,
} from '../helpers/responseUtils';

const mapStateToRequest = (
  formValues: ValidatedSettlementFormState,
): SettlementRequest => ({
  to_account_label: formValues.toAccount,
  from_account_label: formValues.fromAccount.account_number,
  text: formValues.comments,
  settlement: formValues.settlements
    .filter(settlementAllocationIsNonEmpty)
    .map((a) => ({ asset: a.asset.symbol, amount: a.amount })),
});

export const [createSettlement$, createSettlement] = createListener<
ValidatedSettlementFormState
>();

export const addNewSettlement = (request: SettlementRequest) => fromFetch(`${Config.CLEARING_REST_API_URL}v1/settlement_instructions`, {
  method: 'POST',
  body: JSON.stringify(request),
  headers: {
    authorization: `Bearer ${getToken()}`,
    'Content-Type': 'application/json',
  },
  credentials: undefined,
}).pipe(fetchResponseJsonHandler<SettlementResponse>());

export const createSettlementResponse$ = createSettlement$.pipe(
  map(mapStateToRequest),
  switchMap(addNewSettlement),
  share(),
);

export const completedCreateSettlementResponse$ = createSettlementResponse$.pipe(
  filterResponseIsComplete(),
);

export const successfulCreateSettlementResponse$ = completedCreateSettlementResponse$.pipe(
  filterResponseIsSuccessful(),
);

export const [useCreateSettlementResponseFailure] = bind(
  completedCreateSettlementResponse$.pipe(filterResponseIsFailure()),
  undefined,
);

export const useIsPending = useIsPendingResponse(createSettlementResponse$);
