import React, { useMemo } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Container, Amount } from './SettlementAllocation.styled';
import { MenuItemStyled as SelectOption, Select } from '../../../../../shared/style/styled';
import { SettlementAsset, String, ValueOrEmptyString } from '../../types';

export interface SettlementAllocation {
  uid: number;
  asset: ValueOrEmptyString<SettlementAsset>;
  amount: ValueOrEmptyString<number>;
}

export interface NonEmptySettlementAllocation extends SettlementAllocation {
  asset: SettlementAsset;
  amount: number;
}
export const settlementAllocationIsNonEmpty = (
  (settlement: SettlementAllocation): settlement is NonEmptySettlementAllocation => (
    (settlement.asset !== String.EMPTY) && (settlement.amount !== String.EMPTY)
  )
);
export interface SettlementAllocationProps {
  error: boolean;
  settlement: SettlementAllocation;
  getAssets: (selectedAsset: ValueOrEmptyString<SettlementAsset>) => SettlementAsset[];
  onChange: (settlement: SettlementAllocation) => void;
  onBlur: () => void;
}

export const SettlementAllocation:React.FC<SettlementAllocationProps> = (
  {
    error, getAssets, onChange, onBlur, settlement,
  },
) => {
  const assets = useMemo(
    () => getAssets(settlement.asset),
    [getAssets, settlement.asset],
  );

  return (
    <Container>
    <Select
      onBlur={() => onBlur()}
      errorfield={error ? 'e' : undefined}
      value={settlement.asset}
      MenuProps={{ MenuListProps: { disablePadding: true } }}
      IconComponent={ExpandMoreIcon}
    >
        {assets.map((asset) => (
            <SelectOption
              key={asset.symbol}
              // @ts-ignore
              value={asset}
              onClick={() => {
                onChange({
                  ...settlement,
                  asset,
                });
              }}
            >{asset.displayName}</SelectOption>
        ))}
    </Select>

    <Amount
        onBlur={() => onBlur()}
        placeholder="Enter amount"
        allowNegative
        value={settlement.amount}
        error={error ? 'enter amount' : undefined}
        suppressErrorTooltip
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { target: { value } } = e;
          onChange({
            ...settlement,
            amount: value.length ? Number(value) : String.EMPTY,
          });
        }}
        />
    </Container>
  );
};
