import { Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  skip,
  startWith,
} from 'rxjs/operators';
import { shareLatest, bind } from '@react-rxjs/core';
import { ConnectionStatus } from '../websocket/connectionStatus';

export const algoConnectionStatus$ = new Subject<ConnectionStatus>();

export const setConnectionStatus = (status: ConnectionStatus) => {
  algoConnectionStatus$.next(status);
};

export const [useAlgoConnectionStatus, algoConnectionStatusState$] = bind(
  algoConnectionStatus$.pipe(distinctUntilChanged()),
  ConnectionStatus.CONNECTING,
);
algoConnectionStatusState$.subscribe();

// gives the current connection number, which increments whenever the
// websocket fails then successfully reconnects
export const algoWebsocketConnectionNumber$ = algoConnectionStatusState$.pipe(
  filter((status) => status === ConnectionStatus.AUTHENTICATED),
  skip(1),
  map((value, indx) => indx + 1),
  startWith(0),
  shareLatest(),
);
