import { EMPTY, Observable } from 'rxjs';
import {
  retryWhen,
  tap,
  delay,
  switchMap,
  scan,
  exhaustMap,
} from 'rxjs/operators';
import { shareLatest } from '@react-rxjs/core';
import { split } from '@react-rxjs/utils';
import { webSocketSubject } from './getWebSocketSubject';
import { ConnectionStatus, setConnectionStatus } from './connectionStatus';
import { logger } from '../logger';
import { publicWebSocketSubject } from './getPublicWebSocketSubject';
import { AuthStatus, authStatusState$ } from '../services/authStatusService';
import { logMessages } from '../services/logMessages';
import { reloadWhenIdle } from './reloadWhenIdle';
import { Message, MessageMap } from './types';

const MAX_IDLE_TIME = 30_000;

let currAuthStatus: AuthStatus;
authStatusState$.subscribe((val) => {
  currAuthStatus = val;
});

const messages$ = authStatusState$.pipe(
  switchMap((authState) => {
    if (authState === AuthStatus.NOT_DETERMINED) return EMPTY;
    return reloadWhenIdle(MAX_IDLE_TIME)(
      authState === AuthStatus.AUTHENTICATED
        ? webSocketSubject
        : publicWebSocketSubject,
    );
  }),
  retryWhen((errors) => errors.pipe(
    tap((err) => {
      logger.error('Web Socket connection error', err);
    }),
    delay(5000),
    tap(() => {
      setConnectionStatus(ConnectionStatus.CONNECTING);
    }),
  )),
  logMessages('new message'),
);

const messageStreams$: Observable<MessageMap> = messages$.pipe(
  split((msg) => msg.type),
  scan((acc, message$) => ({ ...acc, [message$.key]: message$ }), {}),
  shareLatest(),
);
messageStreams$.subscribe();

export const subscribeToMessageStream$ = <T extends Message>(
  type: string,
): Observable<T> => messageStreams$.pipe(
    exhaustMap((messageMap) => messageMap[type] || EMPTY),
  ) as Observable<T>;

export const send = (msg: any) => {
  if (currAuthStatus === AuthStatus.AUTHENTICATED) {
    logger.debug(`sending msg - ${JSON.stringify(msg)}`);
    webSocketSubject.next(msg);
  } else {
    publicWebSocketSubject.next(msg);
  }
};
