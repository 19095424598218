import React from 'react';
import { Container, IconWrapper, LoadingIcon } from './LoadingIndicator.styled';

interface LoadingIndicatorProps {
  message?: React.ReactNode;
  className?: string;
}
export const LoadingIndicator: React.FC<LoadingIndicatorProps> = (
  { message = <span>Please wait</span>, className },
) => (
  <Container className={className}>
    <IconWrapper>
      <LoadingIcon />
    </IconWrapper>
    {message}
  </Container>
);
