import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
  border-bottom: 0.5px solid rgb(102, 128, 144);
  width: calc(100% - 5px);
  min-width: 580px;
`;

const Cell = styled.div<{ w: number; a?: 'left' | 'center' | 'right' }>`
  flex: 0 0 ${({ w }) => w}px;
  text-align: ${({ a }) => a || 'right'};
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const StickyCell = styled.div<{ w: number; a?: 'left' | 'center' | 'right' }>`
  flex: 0 0 ${({ w }) => w}px;
  text-align: ${({ a }) => a || 'right'};
  height: 35px;
  position: sticky;
  left: 0px;
  flex-basis: ${({ w }) => w}px;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const SymbolText = styled.span`
  height: 35px;
  line-height: 35px;
`;

const Space = styled.div`
  flex: 0 0 12px;
`;

export const Header = () => (
  <Container>
    <StickyCell w={120} a="center">
      <SymbolText>SYMBOL</SymbolText>
    </StickyCell>
    <Space />
    <Cell w={82}>BID</Cell>
    <Cell w={92}>OFFER</Cell>
    <Cell w={92}>LAST</Cell>
    <Cell w={65}>CHANGE</Cell>
    <Cell w={90}>VOLUME</Cell>
  </Container>
);
