import { datadogRum } from '@datadog/browser-rum';
import auth0, { Auth0Result } from 'auth0-js';
import jwt from 'jsonwebtoken';
import { Config } from '../../config/config';
import { logger } from '../logger/index';
import {
  ConnectionStatus,
  setConnectionStatus,
} from '../websocket/connectionStatus';
import { AuthStatus, setAuthStatus } from './authStatusService';

const DOMAIN = Config.auth0_DOMAIN;
const { CLIENT_ID } = Config;
const SCOPE = 'email profile openid write:order read:clearing_api write:block_trade write:settlement_request';
const AUDIENCE = `${Config.EMP_ENDPOINT}/api`;
const { REDIRECT_URI } = Config;
const { LOGOUT_URL } = Config;
const AUTH_ERROR_LOGIN_REQUIRED = 'login_required';
const CHECK_SESSION_FREQUENCY_TIME = 15 * 60 * 1000; // 15 minutes
const CHECK_REFRESH_INTERVAL = 5000;
const TOKEN_EXPIRY_OFFSET = 300 * 1000; // 5 minutes

let webAuth: any = null;
let interval: any;

const initWebAuth = () => {
  const options = {
    domain: DOMAIN,
    clientID: CLIENT_ID,
    scope: SCOPE,
    redirectUri: REDIRECT_URI,
    audience: AUDIENCE,
    responseType: 'token',
  };
  webAuth = new auth0.WebAuth(options);
};

const clearLoggedInLocalStorage = () => {
  localStorage.removeItem('hasLoggedIn');
};

const clearAuth0LocalStorage = () => {
  clearLoggedInLocalStorage();
  localStorage.removeItem('access_token');
  localStorage.removeItem('lastRefreshTime');
  localStorage.removeItem('tokenExpiryTime');
};

export const clearAllAuthLocalStorage = () => {
  clearAuth0LocalStorage();
  localStorage.removeItem('apiKey');
  localStorage.removeItem('secret');
};

const setAuth0LocalStorage = (authResult: Auth0Result) => {
  const { accessToken } = authResult;
  const token = jwt.decode(authResult.accessToken!) as any;
  const currentTime = Math.floor(new Date().getTime());
  localStorage.setItem('access_token', accessToken!);
  localStorage.setItem('hasLoggedIn', 'true');
  localStorage.setItem('lastRefreshTime', currentTime.toString());
  localStorage.setItem('tokenExpiryTime', (token.exp * 1000).toString());
};

const refreshTokenAndSetConnectionStatus = () => {
  logger.debug('The network connection has been regained.');
  setConnectionStatus(ConnectionStatus.OPEN);
  refreshAuth0Token();
};

const setConnectionStatusClosed = () => setConnectionStatus(ConnectionStatus.CLOSED);

const logout = () => {
  if (webAuth === null) {
    initWebAuth();
  }
  webAuth.logout({
    clientID: CLIENT_ID,
    returnTo: LOGOUT_URL,
  });
};

export const userLogout = () => {
  clearAllAuthLocalStorage();
  if (interval) {
    clearInterval(interval);
  }
  setAuthStatus(AuthStatus.UNAUTHENTICATED);
  logout();
};

const refreshAuth0Token = () => {
  if (webAuth === null) {
    initWebAuth();
  }
  webAuth.checkSession({}, (err: any, authResult: Auth0Result) => {
    const hasLoggedIn = !!localStorage.getItem('hasLoggedIn');
    if (err) {
      datadogRum.addAction('checkSessionFailure', err);

      if (!hasLoggedIn) {
        logger.debug(
          'auth0Service: Initial refresh - auth state failure prior to successful login, ignore',
        );
        return;
      }
      logger.error(
        'auth0Service: Failed to get auth state',
        JSON.stringify(err),
      );
      if (err.error === AUTH_ERROR_LOGIN_REQUIRED) {
        userLogout();
      }
      return;
    }
    // auth0 success
    setAuth0LocalStorage(authResult);
    interval = setInterval(refreshAuth0TokenWhenNeeded, CHECK_REFRESH_INTERVAL);
  });
};

const refreshAuth0TokenWhenNeeded = () => {
  const hasLoggedIn = !!localStorage.getItem('hasLoggedIn');
  const lastRefreshTime = localStorage.getItem('lastRefreshTime');
  const tokenExpiryTime = (localStorage.getItem(
    'tokenExpiryTime',
  ) as unknown) as number;
  if (hasLoggedIn && lastRefreshTime && tokenExpiryTime) {
    const currentTime = Math.floor(new Date().getTime());
    const shouldRefresh = currentTime - Number(lastRefreshTime) > CHECK_SESSION_FREQUENCY_TIME
      || currentTime > tokenExpiryTime - TOKEN_EXPIRY_OFFSET;
    if (shouldRefresh) {
      logger.debug(
        'auth0Service: refreshing auth0 token as it has been longer than 15 minutes or access token has expired',
      );
      clearInterval(interval);
      interval = undefined;
      refreshAuth0Token();
    }
  }
};

// from Home

export const startSessionCheck = () => {
  logger.debug('auth0Service: start auth state refresh process ...');
  clearLoggedInLocalStorage();
  stopSessionCheck();
  window.addEventListener('online', refreshTokenAndSetConnectionStatus);
  window.addEventListener('offline', setConnectionStatusClosed);
  refreshAuth0Token();
};

export const stopSessionCheck = () => {
  if (interval) {
    clearInterval(interval);
  }
  interval = undefined;
  window.removeEventListener('online', refreshTokenAndSetConnectionStatus);
  window.removeEventListener('offline', setConnectionStatusClosed);
};

export const login = () => {
  window.location.href = `${Config.EMP_ENDPOINT}/login?redirectTo=${Config.TRADE_REST_API_URL}`;
};

// Flow for /login route

const authorize = (params = {}) => {
  webAuth.authorize(params);
};

export const checkAuth0 = () => {
  if (webAuth === null) {
    initWebAuth();
  }
  if (window.location.hash) {
    webAuth.parseHash({}, (err: any, authResult: any) => {
      if (err) {
        logger.debug(JSON.stringify(err));
        if (err.error === 'login_required') {
          datadogRum.addAction('parseHashFailure', err);
          login();
        } else {
          authorize();
        }
        return;
      }
      setAuth0LocalStorage(authResult);
      interval = setInterval(
        refreshAuth0TokenWhenNeeded,
        CHECK_REFRESH_INTERVAL,
      );
    });
  } else {
    authorize();
  }
};
