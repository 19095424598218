import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { CancelAllConfirmation } from './CancelAllConfirmation';
import { OverlayPopup, Position } from '../../../shared/components/Overlay';
import { handleMouseclickPositionLeft } from '../../../shared/helperFunctions/handleMouseclickPosition';
import { PrimaryButton } from '../../../shared/style/styled';
import {
  submitOrderCancelAll,
  submitBuyCancelAll,
  submitSellCancelAll,
} from '../services/openOrders/cancelEntry';
import { submitAlgoCancelAll } from '../services/algoInstructions/cancelAlgo';
import {
  ConnectionStatus,
  useConnectionStatus,
} from '../../../shared/websocket/connectionStatus';
import { ContentWithTooltip } from '../../../shared/components/ContentWithTooltip';
import { useAlgoConnectionStatus } from '../../../shared/algoWebsocket/algoConnectionStatus';

const CancelCellWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  margin-left: 3px;
  width: 90%;
  padding-right: 2px;
  @media (max-width: 480px) {
    padding-right: 30px;
  }
`;

export const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 8;
`;

export const Menu = styled.div`
  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.primary.primary4};
  padding: 4px 5px;
  font-size: 10px;
  z-index: 9;
  min-width: 100px;
  min-height: 100px;
`;

const CancelConfirmButton = styled(PrimaryButton)`
  margin-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 11px;
  background-color: ${({ theme }) => theme.palette.primary.primary5};
  color: ${({ theme }) => theme.palette.secondary.secondary};
  border: none;
  outline: none;
  cursor: pointer;
  width: 70px;
  margin-bottom: 5px;
  margin-right: 5px;
  opacity: 0.8;
  white-space: nowrap;
  :hover {
    opacity: 1;
  }
  :focus {
    opacity: 1;
  }
  :active {
    opacity: 0.7;
  }
  :disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const CancelAllCell = () => {
  const connectionStatus = useConnectionStatus();
  const algoConnectionStatus = useAlgoConnectionStatus();
  const isTradeConnected = connectionStatus === ConnectionStatus.AUTHENTICATED;
  const isAlgoConnected = algoConnectionStatus === ConnectionStatus.AUTHENTICATED;
  const [position, setPosition] = useState<Position>();

  const disableButton = !isTradeConnected && !isAlgoConnected;
  return (
    <CancelCellWrapper>
      {
        <ContentWithTooltip
          tooltipText={
            disableButton
              ? 'Websocket connection is closed. Please try again later.'
              : ''
          }
        >
          <span>
            <CancelConfirmButton
              disabled={disableButton}
              onClick={(e) => {
                e.preventDefault();
                setPosition(handleMouseclickPositionLeft(e));
              }}
            >
              Cancel All
            </CancelConfirmButton>
          </span>
        </ContentWithTooltip>
      }
      {position && (
        <OverlayPopup
          position={position!}
          onClose={() => {
            setPosition(undefined);
          }}
        >
          {position && (
            <CancelAllConfirmation
              close={() => setPosition(undefined)}
              handleCancelOrder={submitOrderCancelAll}
              handleCancelBuy={submitBuyCancelAll}
              handleCancelSell={submitSellCancelAll}
              handleCancelAlgo={submitAlgoCancelAll}
              handleClose={() => setPosition(undefined)}
              isAlgoConnected={isAlgoConnected}
              isTradeConnected={isTradeConnected}
            />
          )}
        </OverlayPopup>
      )}
    </CancelCellWrapper>
  );
};
