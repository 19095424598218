import React from 'react';
import { FilterSearchPredicateInput } from './FilterSearchPredicate.styled';

export const FilterSearchPredicate: React.FC<{
  onChange: (v: string) => void;
  hidePlaceholder?: boolean;
  value?: string;
}> = ({ onChange, value, hidePlaceholder }) => (
    <FilterSearchPredicateInput
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      value={value}
      onKeyDown={(e) => e.stopPropagation()}
      onChange={(e) => onChange(e.target.value)}
      placeholder={hidePlaceholder ? '' : 'Search'}
      InputProps={{ disableUnderline: true }}
    ></FilterSearchPredicateInput>
);
