import { SettlementRow, SettlementStatus } from '../types';

export const settlementStatusFormatter = (settlement: SettlementRow) => ({
  [SettlementStatus.ALLEGED]: 'Alleged',
  [SettlementStatus.CLEARED]: 'Cleared',
  [SettlementStatus.REJECTED]: 'Rejected',
  [SettlementStatus.ADMIN_CXL]: 'Admin Cancelled',
  [SettlementStatus.CONFIRMED_TO]: `Confirmed by ${settlement.to_account_label}`,
  [SettlementStatus.CONFIRMED_FROM]: `Confirmed by ${settlement.from_account_label}`,
})[settlement.status];
