import {
  Subject, combineLatest, Observable, EMPTY,
} from 'rxjs';
import { bind, shareLatest } from '@react-rxjs/core';
import { map, distinctUntilChanged, switchMap } from 'rxjs/operators';
import {
  connectionStatusState$,
  ConnectionStatus,
} from '../websocket/connectionStatus';

export enum AuthStatus {
  NOT_DETERMINED = 'not_determined',
  UNAUTHENTICATED = 'unauthenticated',
  AUTHENTICATED = 'authenticated',
}

export const authStatus$ = new Subject<AuthStatus>();

export const setAuthStatus = (status: AuthStatus) => {
  authStatus$.next(status);
};

export const [useAuthStatus, authStatusState$] = bind(
  authStatus$,
  AuthStatus.NOT_DETERMINED,
);

authStatusState$.subscribe();

const isAuthenticated$ = combineLatest([
  connectionStatusState$,
  authStatusState$,
]).pipe(
  map(
    ([status, currAuthStatus]) => (currAuthStatus === AuthStatus.AUTHENTICATED
        && status === ConnectionStatus.AUTHENTICATED)
      || (currAuthStatus === AuthStatus.UNAUTHENTICATED
        && status === ConnectionStatus.OPEN),
  ),
  distinctUntilChanged(),
  shareLatest(),
);

export const withAuth = () => <T>(source$: Observable<T>) => isAuthenticated$.pipe(
  switchMap((isAuth) => (isAuth ? source$ : EMPTY)),
);
