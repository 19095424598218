import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { Direction } from '../../shared/Direction';

export const GridContainer = styled.div`
display: grid;
grid-template-columns: repeat(6, 1fr);
font-family: "Roboto Mono";
font-size: 12px;
font-weight: bold;
border-bottom: 0.5px solid rgb(102, 128, 144);
width: calc(100% - 5px);
min-width: 580px;
margin-left: 5px;
margin-top: 20px;
height: 40px;

> span {
  padding: 8px 8px;
}
`;

export const OTCStreamsContainer = styled.div`
  margin-top: 26px;
  padding: 0 14px;

  .gridContainer {
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 11px;
  min-height: 37px;

  .productForm,
  .currencyForm {
    background-color: ${({ theme }) => theme.palette.primary.primary1};
  }

  .productForm {
    display: grid;
  }

  .quantity {
    min-width: 120px;
  }

  .productSelect,
  .currencySelect {
    color: ${({ theme }) => theme.palette.secondary.secondary};
    font-size: 11px;
    padding-left: 20px;
    min-height: 37px;
  }

  .currencySelect {
    border-left: 2px solid #12436b; //COLOUR NOT IN PALETTE
  }

  .currencyForm {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  svg {
    width: 18px;
    height: 18px;
    color: ${({ theme }) => theme.palette.secondary.secondary};
    padding-top: 5px;
  }

  label {
    color: ${({ theme }) => theme.palette.secondary.secondary5};
    font-size: 12px;
    padding-bottom: 1px;
  }
`;

export const RequestQuoteButton = styled(Button)`
  margin-top: 12px;
  max-height: 37px;
  min-width: 180px;
  background-color: ${({ theme }) => theme.palette.trading.tradingBuy};
  .MuiButton-label {
    color: ${({ theme }) => theme.palette.secondary.secondary};
    font-weight: 700;
    font-size: 14px;
  }
`;

export const RequestQuoteErrorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const GridRowContainer = styled.div<{ isSelected: boolean }>`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  height: 35px;
  width: calc(100% - 5px);
  min-width: 580px;
  font-family: "Roboto Mono";
  font-size: 12px;
  margin-left: 5px;
  background-color: ${({ isSelected }) => isSelected && 'rgb(12, 55, 90)'};

  .symbolCell {
    display: flex;
    align-items: center;
  }

  .cancelIcon {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.trading.tradingBuy};
  }

  .handleIcon {
    cursor: grab;
    color: ${({ theme }) => theme.palette.accent.accentPrimary};
  }

  .cell {
    margin-left: 7px;
  }

  .maxQuantity {
    display: flex;
    gap: 4px;
  }

  .currency {
    opacity: 0.5;
  }
`;

export const TradeButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
`;

export const GridRowFormContainer = styled.div`
  margin-left: 5px;
  border-top: 1px solid #0a5384;
  background-color: rgb(12, 55, 90);
  padding: 10px 15px;
  position: sticky;
  left: 5px;

  .formRowGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 10px;
    margin-top: 8px;
  }

  .priceField,
  .productField {
    display: grid;
  }

  .orderTitle {
    font-weight: bold;
    font-size: 14px;
    line-height: 16.4px;
  }

  .amountForm {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 4px;
  }

  .tradeError {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
  }

  .MuiInputBase-input.Mui-disabled {
    color: ${({ theme }) => theme.palette.secondary.secondary};
    background-color: ${({ theme }) => theme.palette.primary.primary};
    font-size: 11px;
    padding: 0 0 0 10px;
    opacity: 0.5;
    height: 37px;
  }

  label {
    color: ${({ theme }) => theme.palette.secondary.secondary5};
    font-size: 12px;
    padding-bottom: 1px;
  }

  button {
    height: 37px;
  }

  ${TradeButtonsContainer} {
    margin-top: 12px;
  }
`;

export const ValidationWrapper = styled.div`
  display: flex;
  cursor: pointer;
  margin-top: 4px;
  align-items: center;

  :hover {
    opacity: 0.7;
  }

  .text {
    font-size: 11px;
    text-decoration: underline;
    margin-left: 4px;
  }
`;

export const TradeButton = styled(Button)<{ direction: Direction }>`
  background-color: ${({ theme, direction }) => (direction === Direction.Buy
    ? theme.palette.trading.tradingBuy
    : theme.palette.trading.tradingSell)};
`;
