import React from 'react';

export const ErisXIconSmall: React.FunctionComponent<{
  width?: number;
  height?: number;
}> = ({ height = 33, width = 33 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 34 34">
    <path fill="#00EBB9" fillRule="evenodd" d="M17.462 26.846V31H5v-4.154h12.462zm11.423-7.27v4.155H5v-4.154h23.885zm0-8.307v4.154H17.462V11.27h11.423zM17.462 4v4.154H5V4h12.462z" />
  </svg>

);
