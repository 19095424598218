import { Observable } from 'rxjs';
import { unstable_batchedUpdates } from 'react-dom';

export const batchUpdates = <T>() => (
  source$: Observable<T>,
): Observable<T> => new Observable<T>((observer) => source$.subscribe(
  (v) => {
    unstable_batchedUpdates(() => {
      observer.next(v);
    });
  },
  observer.error.bind(observer),
  observer.complete.bind(observer),
));
