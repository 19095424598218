import React from 'react';
import { GridApi } from 'ag-grid-community';
import { Grid } from '../../../shared/Grid/Grid';
import { algoOrdersColDefs } from '../colDefs/algoOrdersColDefs';
import { useAlgoOrders } from '../services/algoOrders/algoOrdersService';
import { ExecutionReport } from '../services/openOrders/openOrdersReducer';

interface Props {
  isFilterHidden: boolean;
  changeNumber: (numberOfFilter: number) => void;
  gridAPI: React.MutableRefObject<GridApi | undefined>;
}

export const AlgoOrdersGrid: React.FunctionComponent<Props> = (props) => {
  const algoOrders = useAlgoOrders();
  return (
    <Grid
      columnDefs={algoOrdersColDefs}
      rowData={algoOrders?.valueSeq().toArray()}
      getRowNodeId={(row: ExecutionReport) => row.orderID}
      suppressCellSelection={true}
      isFilterHidden={props.isFilterHidden}
      onFilterChanged={(event) => props.changeNumber(Object.keys(event.api.getFilterModel()).length)
      }
      gridApiRef={props.gridAPI}
    ></Grid>
  );
};
