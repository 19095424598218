import React from 'react';
import { format } from 'date-fns';

import { TextFieldWithTooltip } from '../../../shared/components/TextFieldWithTooltip';
import { BlockEntryFormState as FormState } from '../services/block/blockTradeEntryService';
import { BlockEntryDate } from '../styled';

interface Props {
  setFieldValue: <K extends keyof FormState>(field: K, value: FormState[K]) => void;
  setFieldError: <K extends keyof FormState>(field: K, value: string) => void;
  values: FormState;
  errorField?: string;
}

export const BlockEntryDateField: React.FunctionComponent<Props> = (props) => <BlockEntryDate
  value={props.values.negotiatedTime ? format(props.values.negotiatedTime, "yyyy-MM-dd'T'HH:mm") : null}
  emptyLabel="mm/dd/yyyy --:--"
  onFocus={
    () => {
      if (!props.values.negotiatedTime) {
        props.setFieldValue('negotiatedTime', new Date());
        props.setFieldError('negotiatedTime', '');
      }
    }
  }
  onChange={(date) => {
    if (date && !Number.isNaN(date.getTime())) {
      props.setFieldValue('negotiatedTime', date || undefined);
      props.setFieldError('negotiatedTime', '');
    }
  }}
  errorfield={props.errorField}
  format="MM/dd/yyyy HH:mm"
  className="datePickerRoot"
  disablePast
  TextFieldComponent={
    (inputProps) => <TextFieldWithTooltip errorText={props.errorField} {...inputProps} />
  }
/>;
