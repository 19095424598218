import styled from 'styled-components';

export const SettlementsGridAgGridStyleWrapper = styled.div`
    height: 100%;
    width: 100%;

    /* HEADER */
    .ag-header-container.ag-header-container  {
        background-color: ${({ theme }) => theme.palette.secondary.secondary};
        text-transform: uppercase;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 13px;
    }

    .ag-header-viewport {
        background-color: ${({ theme }) => theme.palette.secondary.secondary};
        border-bottom: 1px solid ${({ theme }) => theme.palette.secondary.secondary1};
    }

    .ag-header-cell-text {
        color: ${({ theme }) => theme.palette.secondary.secondary5};
    }

    .ag-header-icon {
        color: ${({ theme }) => theme.palette.secondary.secondary5};
    }

    .ag-root-wrapper, .ag-root-wrapper-body, .ag-root, .ag-header-row, .ag-header, .ag-row.ag-row {
        border: none;
    }

    .ag-header {
        border: none;
        box-sixing: border-box;
        height: 31px;
        background-color: ${({ theme }) => theme.palette.secondary.secondary};
        color: ${({ theme }) => theme.palette.secondary.secondary5};
    }

    /* ROWS */
    .ag-row {
        border: none;
        height: 31px;
        background-color: ${({ theme }) => theme.palette.secondary.secondary};
        &.ag-row-hover {
            background-color: ${({ theme }) => theme.palette.secondary.secondary};
        }
        & .ag-react-container {
            display: flex;
            height: 100%;
            align-items: center;
        }
    }

    .ag-row-settlement-detail {
        display: flex;
        background-color: ${({ theme }) => theme.palette.secondary.secondary1};

        &.ag-row-hover {
            background-color: ${({ theme }) => theme.palette.secondary.secondary1};
        }
    }

    .ag-react-container {
        width: 100%;
    }
`;
