import { OpenInNew } from '@material-ui/icons';
import React from 'react';
import { MemberPortalWrapper } from './styled';
import { Config } from '../../config/config';

interface Props { }

export const MemberPortal: React.FunctionComponent<Props> = () => (
  <MemberPortalWrapper onClick={() => window.open(Config.EMP_ENDPOINT)}>
    <OpenInNew fontSize={'small'} />
      Member Portal
  </MemberPortalWrapper>
);
