import React from 'react';

import { BlockTradeEntryResponse } from '../../services/block/blockTradeEntryService';
import { OrderEntryFormTransitionsWrapper } from '../styled';
import { OrderEntryLoading } from '../trade/OrderEntryLoading';
import { BlockOrderEntrySuccess } from './BlockOrderEntrySuccess';
import { BlockOrderEntryUnsuccessful } from './BlockOrderEntryUnsuccessful';

interface Props {
  latestEntryResponse: BlockTradeEntryResponse | null
  isOrderLoading: boolean;
  setIsOverlayActive: (isOverlayActive: boolean) => void;
}

const getOverlay = (
  isLoading: boolean,
  latestEntryResponse: BlockTradeEntryResponse,
  setIsOverlayActive: (isOverlayActive: boolean) => void,
) => {
  if (isLoading) {
    return <OrderEntryLoading />;
  }
  if (latestEntryResponse?.state === 'accepted') {
    return <BlockOrderEntrySuccess
      latestEntryMessage={latestEntryResponse}
      setIsOverlayActive={setIsOverlayActive}
    />;
  }
  if (latestEntryResponse?.error || latestEntryResponse?.restError) {
    return <BlockOrderEntryUnsuccessful
      latestEntryMessage={latestEntryResponse}
      setIsOverlayActive={setIsOverlayActive}
    />;
  }
  return null;
};

export const BlockOrderEntryTransitionScreens: React.FunctionComponent<Props> = (props) => (
  <OrderEntryFormTransitionsWrapper>
    {props.latestEntryResponse
      && getOverlay(props.isOrderLoading, props.latestEntryResponse, props.setIsOverlayActive)}
  </OrderEntryFormTransitionsWrapper>
);
