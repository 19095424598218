import React from 'react';
import { OrderResponse } from '../../services/trade/sendEntry';
import { OrderEntryLoading } from './OrderEntryLoading';
import { OrderEntrySuccess } from './OrderEntrySuccess';
import { OrderEntryUnsuccessful } from './OrderEntryUnsuccessful';
import { OrderEntryFormTransitionsWrapper } from '../styled';

interface Props {
  latestEntryResponse: OrderResponse | null;
  isOrderLoading: boolean;
  setIsOverlayActive: (isOverlayActive: boolean) => void;
}

const getOverlay = (
  isLoading: boolean,
  latestEntryResponse: OrderResponse,
  setIsOverlayActive: (isOverlayActive: boolean) => void,
) => {
  if (isLoading) {
    return <OrderEntryLoading />;
  }
  switch (latestEntryResponse?.type) {
    case 'ExecutionReport':
      return <OrderEntrySuccess
        latestEntryMessage={latestEntryResponse}
        setIsOverlayActive={setIsOverlayActive}
      />;
    case 'BusinessMessageRejectMessage':
    case 'OrderReject':
      return <OrderEntryUnsuccessful
        latestEntryMessage={latestEntryResponse}
        type='REJECTED'
        setIsOverlayActive={setIsOverlayActive}
      />;
    case 'ERROR_MESSAGE':
      return <OrderEntryUnsuccessful
        latestEntryMessage={latestEntryResponse}
        type='FAILED'
        setIsOverlayActive={setIsOverlayActive}
      />;
    default:
      return null;
  }
};

export const OrderEntryTransitionScreens: React.FunctionComponent<Props> = (props) => (
  <OrderEntryFormTransitionsWrapper>
    {props.latestEntryResponse
      && getOverlay(props.isOrderLoading, props.latestEntryResponse, props.setIsOverlayActive)}
  </OrderEntryFormTransitionsWrapper>
);
