import React from 'react';
import styled from 'styled-components/macro';
import { AlertIcon } from '../../../icons/AlertIcon';
import { setShowUserPrefs } from '../../navbar/services/userPrefsService';

export const FutureValidationWrapper = styled.div`
  display: grid;
  grid-template-columns: 25px auto;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  cursor: pointer;
  word-break: break-word;
  padding-left: 10px;
  color: ${({ theme }) => theme.palette.accent.accentPrimary};
  :hover {
    opacity: .7;
  }
`;

const FutureValidationTextWrapper = styled.div`
  display: flex;
  font-size: 11px;
  text-decoration: underline;
  align-items: center;
  justify-content: center;
`;

export const OrderEntryFutureValidation = () => (
  <FutureValidationWrapper>
    <AlertIcon />
    <FutureValidationTextWrapper
      onClick={() => setShowUserPrefs(true)}
    >
      Click here to Set your Trader ID in the user preferences menu
    </FutureValidationTextWrapper>
  </FutureValidationWrapper>
);
