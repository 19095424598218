import { shareLatest } from '@react-rxjs/core';
import bigInt from 'big-integer';
import { defer, Subject } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import { algoSend } from '../../../../shared/algoWebsocket/transport';
import { correlationId } from '../../../../shared/helperFunctions/correlationId';
import { InstructionResponse } from '../../../orderEntry/services/algos/InstructionResponse';
import { accountState$ } from '../../../../shared/services/accountsService';

interface PegCancelRequest {
  correlation: string;
  type: 'CancelPegInstruction';
  partyID: string;
  pegID: string;
}

interface OofCancelRequest {
  correlation: string;
  type: 'CancelOofInstruction';
  partyID: string;
  oofID: string;
}

interface AlgoCancelAllRequest {
  correlation: string;
  type: 'CancelAllAlgosRequest';
  partyID: string;
}

export const convertAlgoToCancelRequest = (
  order: InstructionResponse,
): PegCancelRequest | OofCancelRequest => {
  if (order.type === 'PegInstructionResponse') {
    return {
      correlation: correlationId(),
      type: 'CancelPegInstruction',
      partyID: order.partyID,
      pegID: bigInt(order.responseID).toString(),
    };
  }
  return {
    correlation: correlationId(),
    type: 'CancelOofInstruction',
    partyID: order.partyID,
    oofID: bigInt(order.responseID).toString(),
  };
};

export const algoCancel$ = new Subject<InstructionResponse>();

export const submitAlgoCancel = (order: InstructionResponse) => {
  algoCancel$.next(order);
};

export const sendAlgoCancel$ = (order: InstructionResponse) => defer(() => {
  const cancelRequest = convertAlgoToCancelRequest(order);
  return algoSend(cancelRequest);
});

algoCancel$.pipe(
  switchMap(
    (order) => sendAlgoCancel$(order),
  ),
  shareLatest(),
).subscribe();

export const convertAlgoToCancelAllRequest = (partyId: string): AlgoCancelAllRequest => ({
  correlation: correlationId(),
  type: 'CancelAllAlgosRequest',
  partyID: partyId,
});

export const algoCancelAll$ = new Subject();

export const submitAlgoCancelAll = () => {
  algoCancelAll$.next();
};

export const sendAlgoCancelAll = (partyId: string) => {
  const cancelRequest = convertAlgoToCancelAllRequest(partyId);
  return algoSend(cancelRequest);
};

algoCancelAll$.pipe(
  withLatestFrom(accountState$),
  map(([, accountState]) => {
    accountState.accounts.forEach((account) => {
      if (account.fix_ids && account.fix_ids[0]) {
        sendAlgoCancelAll(account.fix_ids[0]);
      }
    });
  }),
  shareLatest(),
).subscribe();
