import { bind } from '@react-rxjs/core';
import React from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { merge } from 'rxjs';
import { withSubscribe } from '../../shared/helperFunctions/withSubscribe';
import { GridHeader } from './GridHeader';
import { GridRow } from './GridRow';
import { RequestQuoteHeaderForm } from './RequestQuoteForm';
import { cancelQuote$, requestQuote$, setOrderedIds } from './service';
import { OTCStreamsContainer } from './styled';

const [useOTCStreamResponse, otcStreamState$] = bind(
  merge(requestQuote$, cancelQuote$),
);

const OTCStreamsComponent = () => {
  const response = useOTCStreamResponse();

  const handleDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) {
      return;
    }

    if (response) {
      const reorderedList = [...response.keys()];
      reorderedList.splice(
        destination.index,
        0,
        reorderedList.splice(source.index, 1)[0],
      );

      setOrderedIds(new Set(reorderedList));
    }
  };

  return (
    <OTCStreamsContainer>
      <div className="gridContainer">
        <RequestQuoteHeaderForm />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="otc-container">
            {(droppable) => (
              <div {...droppable.droppableProps} ref={droppable.innerRef}>
                <GridHeader />
                {response
                  && [...response.keys()].map((counterpartyRequestId, index) => (
                    <Draggable
                      index={index}
                      draggableId={counterpartyRequestId}
                      key={counterpartyRequestId}
                    >
                      {(draggable) => (
                        <GridRow
                          otcStreamResponse={
                            response.get(counterpartyRequestId)!
                          }
                          draggable={draggable}
                        />
                      )}
                    </Draggable>
                  ))}
                {droppable.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </OTCStreamsContainer>
  );
};

export const OTCStreams = withSubscribe(OTCStreamsComponent, otcStreamState$);
