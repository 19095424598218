import React from 'react';

export const WarningIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
}> = ({ height = 20, width = 20 }) => (
  <svg width={width} height={height} viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>atoms/icons/warning</title>
    <g id="Iconography" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-187.000000, -1737.000000)" id="Group">
        <g transform="translate(186.000000, 1736.000000)">
          <g id="icons/event/warning/normal" transform="translate(1.000000, 1.000000)">
            <circle id="Oval" fill="#FF8D00" cx="11" cy="11" r="11"></circle>
            <path d="M12.1080713,12.5 L12.1080713,4 C12.1080713,3.44771525 11.6603561,3 11.1080713,3 C10.5557866,3 10.1080713,3.44771525 10.1080713,4 L10.1080713,12.5 C10.1080713,13.0522847 10.5557866,13.5 11.1080713,13.5 C11.6603561,13.5 12.1080713,13.0522847 12.1080713,12.5 Z" id="Line" fill="#FFFFFF"></path>
            <path d="M12.1080713,16.5 L12.1080713,16 C12.1080713,15.4477153 11.6603561,15 11.1080713,15 C10.5557866,15 10.1080713,15.4477153 10.1080713,16 L10.1080713,16.5 C10.1080713,17.0522847 10.5557866,17.5 11.1080713,17.5 C11.6603561,17.5 12.1080713,17.0522847 12.1080713,16.5 Z" id="Line-Copy" fill="#FFFFFF"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
