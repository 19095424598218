import { ProductValue } from '../../shared/ProductValue';
import { UserPermissions } from '../../shared/services/userPermissionsService';

export const canSubmitForMarket = (
  permissions: UserPermissions,
  market: ProductValue,
  account_number: string,
) => {
  if (permissions.error) {
    return true;
  }
  if (market.type === 'FUT') {
    return (
      permissions.futuresPermissions.findIndex((p) => p === account_number) > -1
    );
  }
  if (market.type === 'SPOT') {
    return (
      permissions.spotPermissions.findIndex((p) => p === account_number) > -1
    );
  }
  return false;
};

export const marketLabel = (market?: ProductValue) => {
  if (market) {
    return market.type === 'FUT' ? 'Futures' : 'Spot';
  }
  return '';
};
