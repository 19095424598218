import styled from 'styled-components/macro';

import { AbsoluteModalWrapper } from '../../shared/components/Modal';
import { WarningPanel } from '../../shared/components/WarningMessage';

export const BalanceInfoWrapper = styled.div<{ isOverlayActive?: boolean }>`
width: 100%;
grid-template-rows: repeat(35px,auto);
grid-column-gap: 15x;
align-items: center;
justify-content: center;
padding-left: 10px;
opacity: ${({ isOverlayActive }) => isOverlayActive && 0.5};
margin-left: -15px;
`;

export const BalanceInfoRow = styled.div`
display: grid;
grid-template-columns: 21px 30px minmax(0,1fr);
grid-column-gap: 10px;
align-items: center;
justify-content: center;

.usd-icon {
    margin-bottom: -1px;
}
`;

export const BalanceInfoTitle = styled.div`
font-size: 11px;
color: ${({ theme }) => theme.palette.secondary.secondary5};
text-align: right;
`;

export const BalanceInfoName = styled.div<{ color?: string }>`
text-align: left;
font-size: 11px;
font-family: 'Roboto Mono';
color: ${({ theme, color }) => color ?? theme.palette.secondary.secondary};
line-height: 35px;
`;

export const BalanceInfoValue = styled.div<{ rightPadding?: number }>`
text-align: right;
font-size: 11px;
font-family: 'Roboto Mono';
color: ${({ theme, color }) => color ?? theme.palette.secondary.secondary};
padding-right: ${({ rightPadding }) => rightPadding && `${rightPadding}px`};
`;

// On mobile, we darken the background behind the balance widget on the bottom
// and disable it, similar to a Modal implementation
export const BalanceMobileBackground = styled(AbsoluteModalWrapper)`
    z-index: 4;
`;

export const BalanceTotalBalanceRow = styled.div`
    font-family: Roboto Mono;
    display: grid;
    grid-template-rows: auto auto;
    grid-column-gap: 10px;
`;

export const BalanceTotalPercent = styled.div<{ color?: string }>`
    color: ${({ theme, color }) => color ?? theme.palette.secondary.secondary};
    margin-left: auto;
`;

export const BalanceWarningPanel = styled(WarningPanel)`
    padding: 10px 0px 10px 5px;
`;
