import { bind, shareLatest } from '@react-rxjs/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FUT } from '../../components/orderEntry/services/trade/sendEntry';
import { formatProductForURLString } from '../formatters';
import { correlationId } from '../helperFunctions/correlationId';
import { ProductValue } from '../ProductValue';

export interface SelectedMarketRequest {
  product: ProductValue;
  correlation: string;
}

export const selectedMarketSubject$ = new Subject<SelectedMarketRequest>();

export const selectedMarketState$ = selectedMarketSubject$.pipe(
  map((request) => request.product),
  shareLatest(),
);

export const selectedMarketRequestCorrelation$ = selectedMarketSubject$.pipe(
  map((request) => request.correlation),
  shareLatest(),
);

selectedMarketRequestCorrelation$.subscribe();

export const requestMarketData = (product: ProductValue) => {
  window.localStorage.setItem('market', product.symbol);
  selectedMarketSubject$.next({ product, correlation: correlationId() });
};

export const [useSelectedMarket, selectedMarket$] = bind(
  selectedMarketState$,
  undefined,
);
selectedMarket$.subscribe();

export const [useUrl, selectedMarketUrl$] = bind(
  selectedMarketState$.pipe(
    map((selectedMarket) => {
      if (selectedMarket.type !== FUT) {
        const currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.delete('contract');
        currentUrlParams.set(
          'product',
          formatProductForURLString(selectedMarket.symbol),
        );
        return `${window.location.pathname}?${currentUrlParams.toString()}`;
      }
      const currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set(
        'product',
        formatProductForURLString(selectedMarket.productCode || ''),
      );
      currentUrlParams.set('contract', selectedMarket.symbol);
      return `${window.location.pathname}?${currentUrlParams.toString()}`;
    }),
  ),
  `${window.location.pathname}?${new URLSearchParams(
    window.location.search,
  ).toString()}`,
);
