import { bind, shareLatest } from '@react-rxjs/core';
import { mergeWithKey } from '@react-rxjs/utils';
import {
  defer,
  merge,
  Observable,
  Subject,
} from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  scan,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { getExecutionOrderDateMilliseconds } from '../../../../shared/formatters';
import { correlationId } from '../../../../shared/helperFunctions/correlationId';
import {
  send,
  subscribeToMessageStream$,
} from '../../../../shared/websocket/transport';
import {
  accountMap$,
  accountState$,
} from '../../../../shared/services/accountsService';
import {
  ExecutionReport,
  OrderCancelReject,
  initialOpenOrderMap,
  openOrdersReducer,
} from './openOrdersReducer';

const newExecutionReports$ = defer(() => subscribeToMessageStream$('ExecutionReport').pipe(
  filter(
    (msg: any): msg is ExecutionReport => msg.execType !== 'ORDER_STATUS',
  ),
));

export const newOrderCancelRejects$ = defer(() => subscribeToMessageStream$('OrderCancelReject').pipe(
  filter(
    (msg: any): msg is OrderCancelReject => msg,
  ),
));

export const getMassOrders$ = (partyID?: string) => defer(() => {
  const correlation = correlationId();
  send({
    correlation,
    type: 'OrderMassStatusRequest',
    partyID,
  });
  return subscribeToMessageStream$('ExecutionReport').pipe(
    filter(
      (msg: any): msg is ExecutionReport => msg.correlation === correlation
          && msg.execType === 'ORDER_STATUS',
    ),
  );
});

export const openOrderRequest$ = new Subject<string>();

export const requestMassOrders = (partyId?: string) => {
  openOrderRequest$.next(partyId);
};

const currentExecutionReports$ = openOrderRequest$.pipe(
  mergeMap((partyId) => getMassOrders$(partyId)),
);

export const executionReportWithDateAndAccountNumber$ = merge(
  currentExecutionReports$,
  newExecutionReports$,
).pipe(
  withLatestFrom(accountMap$),
  map(
    ([executionReport, accountMap]) => ({
      ...executionReport,
      accountNumber:
          accountMap.get(executionReport.partyIDs[0])
          || executionReport.partyIDs[0],
      timeInMilliseconds: getExecutionOrderDateMilliseconds(
        executionReport.transactTime,
      ),
    } as ExecutionReport),
  ),
  shareLatest(),
) as Observable<ExecutionReport>;

export const [useOpenOrders, openOrderState$] = bind(
  mergeWithKey({
    newExecutionReport: executionReportWithDateAndAccountNumber$,
    newMassOrderRequest: openOrderRequest$,
  }).pipe(scan(openOrdersReducer, initialOpenOrderMap)),
  initialOpenOrderMap,
);

export const getMassOrdersWhenAccounts$ = accountState$.pipe(
  tap(() => requestMassOrders()),
);
