import React from 'react';

export const InfoIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
  className?: string;
}> = ({ height = 24, width = 24, className = 'info-icon' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={className}
    viewBox="0 0 24 24"
  >
    <g fill="#668090" fillRule="evenodd">
      <path d="M13,17h-2v-6h2V17z M13,9h-2V7h2V9z" />
      <path fill="none" stroke="#668090" strokeMiterlimit="10" strokeWidth="2" d="M12 3A9 9 0 1 0 12 21A9 9 0 1 0 12 3Z" />
    </g>
  </svg>
);
