import { Observable, of } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import {
  catchError, map, mergeMap, switchMap, take,
} from 'rxjs/operators';

import { Config } from '../../config/config';
import { getRestUrl } from '../helperFunctions/getRestUrl';
import { getToken } from '../websocket/token';
import { AuthStatus, authStatusState$ } from './authStatusService';

// HISTORY_REST_API_URL(?_UNAUTHENTICATED)getTrades?params (response.payload === trades[])
// HISTORY_REST_API_URL(?_UNAUTHENTICATED)ohlc?params
// CLEARING_REST_API_URL/v1/accounts (POST, no body)
// CLEARING_REST_API_URL/v1/balances (POST, JSON accountId)
// CLEARING_REST_API_URL/v1/positions (POST, no body)
// CLEARING_REST_API_URL/v1/trades?limit=25 (POST, no body)
// CLEARING_REST_API_URL/v1/submit_block_trade (POST, JSON entry)
// CLEARING_REST_API_URL/v1/block_trade_requests (POST, JSON filter data)
// CLEARING_REST_API_URL/v1/authorize (POST, no body, JSON type?! Removed, still works)
// CLEARING_REST_API_URL/v1/user (permissions) (GET !!)
// Config.auth0_DOMAIN}/userinfo (info?) (GET, creds: undefined, JSON type?!)

export type FetchRequest = {
  restCallName: string;
  params?: URLSearchParams;
  method?: string;
  body?: any,
};

export type FetchResponse<T = any> = {
  response: T;
  error: boolean;
};

/** Clearing Rest Api
 *
 *  - all use /v1 in path
 *  - most use POST
 *  - a few have JSON request body
 */
export const getFetchClearing$ = (request: FetchRequest): Observable<FetchResponse> => {
  const {
    restCallName, params, method, body,
  } = request;
  const url = `${Config.CLEARING_REST_API_URL}v1/${restCallName}${params ? `?${params.toString()}` : ''}`;
  const authHeaders = { authorization: `Bearer ${getToken()}` };
  const noBodyRequestInit: RequestInit = {
    method: method || 'POST',
    headers: authHeaders,
    mode: 'cors',
    credentials: 'include',
  };

  return fromFetch(url, body ? {
    ...noBodyRequestInit,
    headers: { ...authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  } : noBodyRequestInit).pipe(
    mergeMap((response) => {
      const error = !response.ok;
      return response.json()
        .then((responseFromJson) => ({
          response: responseFromJson,
          error,
        }))
        .catch((jsonError) => ({
          response: jsonError,
          error: true,
        }));
    }),
    catchError((error) => of({
      response: error.toString(),
      error: true,
    })),
  );
};

/** History Rest Api
 *
 *  - all GET
 *  - requires auth status to determine url
 *  - several instances of /getTrades used around the app, plus /ohlc for charts
 */
const getHistoryUrl = (authStatus: AuthStatus, restCallName: string, params: URLSearchParams | undefined) => `${getRestUrl(
  'HISTORY_REST_API_URL',
  authStatus,
)}${restCallName}${params ? `?${params.toString()}` : ''}`;

export const getFetchHistory$ = (
  request: FetchRequest,
): Observable<FetchResponse> => {
  const { restCallName, params } = request;

  return authStatusState$.pipe(
    take(1),
    switchMap((currAuthStatus) => fromFetch(
      getHistoryUrl(currAuthStatus, restCallName, params),
      {
        method: 'GET',
        headers: { authorization: `Bearer ${getToken()}` },
        mode: 'cors',
        credentials: 'include',
      },
    )),
    mergeMap((response) => {
      if (!response.ok) throw new Error(response.status.toString());
      else return response.json();
    }),
    map((jsonResponse) => ({
      response: jsonResponse,
      error: false,
    })),
    catchError(() => of({ response: [], error: true })),
  );
};
