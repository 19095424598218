import React from 'react';
import { formatPrice } from '../../../shared/formatters';
import { FUT } from '../../orderEntry/services/trade/sendEntry';
import {
  TwentyFourHourTitle,
  TwentyFourHourMobileWrapper,
  TwentyFourHourValue,
  TwentyFourHourValueLastPrice,
  TwentyFourHourValueFuture,
} from './styled';

interface PresentationProps {
  volume: string;
  percent: string;
  latestPrice: string;
  color: string;
}

const TwentyFourHourMobileSpot: React.FC<PresentationProps> = ({
  volume, percent, latestPrice, color,
}) => (
  <TwentyFourHourMobileWrapper future={false}>
    <TwentyFourHourTitle>
      Last Trade Price
    </TwentyFourHourTitle>
    <TwentyFourHourTitle>
      {'Price \u0394'}
    </TwentyFourHourTitle>
    <TwentyFourHourTitle>
      24h Volume
    </TwentyFourHourTitle>
    <TwentyFourHourValue align={'left'}>
      {latestPrice}
    </TwentyFourHourValue>
    <TwentyFourHourValue color={color} align="left">
      {percent}
    </TwentyFourHourValue>
    <TwentyFourHourValue align={'left'}>
      {volume}
    </TwentyFourHourValue>
  </TwentyFourHourMobileWrapper>
);

const TwentyFourHourMobileFuture: React.FC<PresentationProps> = ({
  volume, percent, latestPrice, color,
}) => (
  <TwentyFourHourMobileWrapper future>
    <TwentyFourHourValueLastPrice>
      {latestPrice}
    </TwentyFourHourValueLastPrice>
    <TwentyFourHourTitle>
      24h Volume
    </TwentyFourHourTitle>
    <TwentyFourHourValueFuture color={color}>
      {percent}
    </TwentyFourHourValueFuture>
    <TwentyFourHourValue align="left">
      {volume}
    </TwentyFourHourValue>
  </TwentyFourHourMobileWrapper >
);

interface Props {
  volume: string;
  color: string;
  percent: number;
  currency: string;
  latestPrice?: number;
  minPriceIncrement: number;
  marketType: 'FUT' | 'SPOT'
}

// One column TwentyFourHour variant for mobile use
export const TwentyFourHourMobile: React.FunctionComponent<Props> = ({
  volume, color, percent, currency, latestPrice, minPriceIncrement, marketType,
}) => {
  const volumeString = `${volume} ${currency}`;
  const latestPriceString = latestPrice ? formatPrice(latestPrice, minPriceIncrement) : '-';
  const percentString = `${percent.toFixed(2)}%`;

  return marketType === FUT
    ? <TwentyFourHourMobileFuture
      latestPrice={latestPriceString}
      volume={volumeString}
      percent={percentString}
      color={color}
    />
    : <TwentyFourHourMobileSpot
      latestPrice={latestPriceString}
      volume={volumeString}
      percent={percentString}
      color={color}
    />;
};
