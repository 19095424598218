import { Map } from 'immutable';
import { combineLatest, Observable, Subject } from 'rxjs';
import { bind, shareLatest } from '@react-rxjs/core';
import {
  filter, map, mergeMap, scan, startWith, tap,
} from 'rxjs/operators';
import { AuthStatus, authStatusState$ } from './authStatusService';
import { ConnectionStatus, connectionStatusState$ } from '../websocket/connectionStatus';
import { FUT } from '../../components/orderEntry/services/trade/sendEntry';
import { getSecurities$, SecuritiesResponse } from './getSecurities$';
import { ProductValue } from '../ProductValue';

export const securityRequests$ = new Subject();
export const requestSecurities = () => {
  securityRequests$.next();
};
export const securityListState$ = securityRequests$.pipe(
  mergeMap(() => getSecurities$),
  shareLatest(),
);

securityListState$.subscribe();

export const getSecuritiesWhenAuthenticated$ = combineLatest(
  [connectionStatusState$, authStatusState$],
).pipe(
  filter(([status, currAuthStatus]) => (
    currAuthStatus === AuthStatus.AUTHENTICATED && status === ConnectionStatus.AUTHENTICATED
  ) || (
    currAuthStatus === AuthStatus.UNAUTHENTICATED && status === ConnectionStatus.OPEN
  )),
  tap(() => {
    requestSecurities();
  }),
);

export type FutureProductCodeMap = Map<string, string>;
export const initialfutureProductCodeMap = Map<string, string>();

export const futureProductCodeMapReducer = (exisitingMap: FutureProductCodeMap,
  securityResponse: SecuritiesResponse): FutureProductCodeMap => exisitingMap
  .withMutations((m) => securityResponse.securities
    .filter((security) => security.securityType === FUT)
    .forEach((future) => {
      m.set(future.symbol, future.productCode);
    }));

export const futureProductCodeMap$: Observable<FutureProductCodeMap> = securityListState$.pipe(
  scan(futureProductCodeMapReducer, initialfutureProductCodeMap),
  startWith(initialfutureProductCodeMap),
  shareLatest(),
);

export type SecurityProductMap = Map<string, ProductValue>;
export const initialSecurityProductMap = Map<string, ProductValue>();

export const securityProductMapReducer = (exisitingMap: SecurityProductMap,
  securityResponse: SecuritiesResponse): SecurityProductMap => exisitingMap
  .withMutations((m) => securityResponse.securities
    .forEach((security) => {
      if (security.securityType === FUT) {
        m.set(security.symbol, {
          type: FUT,
          productCode: security.productCode,
          symbol: security.symbol,
          value: security.symbol,
          minTradeVol: security.minTradeVol,
          maxTradeVol: security.maxTradeVol,
          roundLot: security.roundLot,
          minPriceIncrement: security.minPriceIncrement,
          currency: security.currency,
          contractMultiplier: security.contractMultiplier,
        });
      } else {
        m.set(security.symbol, {
          type: 'SPOT',
          symbol: security.symbol,
          value: security.symbol,
          minTradeVol: security.minTradeVol,
          maxTradeVol: security.maxTradeVol,
          roundLot: security.roundLot,
          minPriceIncrement: security.minPriceIncrement,
          currency: security.currency,
          contractMultiplier: security.contractMultiplier,
        });
      }
    }));

export const securityProductValueMap$: Observable<SecurityProductMap> = securityListState$.pipe(
  scan(securityProductMapReducer, initialSecurityProductMap),
  startWith(initialSecurityProductMap),
  shareLatest(),
);

export const [useSecurityList, securityList$] = bind(securityListState$.pipe(
  map((response) => response.securities),
), []);
