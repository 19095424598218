import { shareLatest } from '@react-rxjs/core';
import { mergeWithKey } from '@react-rxjs/utils';
import Decimal from 'decimal.js';
import { combineLatest, Observable } from 'rxjs';
import { map, scan, startWith } from 'rxjs/operators';

import { getTradeHistory$, Trade } from '../../watchlist/services/tradeHistory';
import {
  AccountBalance,
  accountsBalances$,
  balanceInfoRest$,
  convertRestBalanceClosingPrice,
} from './balanceService';

export interface BalanceTradesMapping {
  [symbol: string]: {
    firstTrade: Trade;
    lastTrade: Trade;
    symbol: string;
  };
}

const relevantSymbols = ['BTC/USD', 'ETH/USD', 'BCH/USD', 'LTC/USD', 'USDC/USD'];

export const totalBalanceTrades$ = mergeWithKey(
  Object.fromEntries(
    relevantSymbols.map((symbol) => [symbol, getTradeHistory$(symbol)] as const),
  ) as { [symbol: string]: Observable<Trade[]> },
).pipe(
  scan((acc, { type: symbol, payload: trades }) => {
    const updatedMap = { ...acc };
    const currency = symbol.split('/')[0];
    if (currency) {
      updatedMap[currency] = {
        lastTrade: trades[0],
        firstTrade: trades[trades.length - 1],
        symbol: currency,
      };
    }
    return updatedMap;
  }, {} as BalanceTradesMapping),
  startWith({} as BalanceTradesMapping),
  shareLatest(),
);

const balanceInfoRestClosingPrice$ = balanceInfoRest$.pipe(
  map((balanceInfo) => convertRestBalanceClosingPrice(balanceInfo)),
);

export interface AccountTotalComputedBalance {
  [account: string]: {
    currentBalance: number;
    oldBalance: number;
  };
}

const getTotalComputedBalanceAndChange = (
  balanceTrades: BalanceTradesMapping,
  closingPrices: AccountBalance,
  balances: AccountBalance,
): AccountTotalComputedBalance => {
  const accountTotal = {} as AccountTotalComputedBalance;

  Object.keys(balances).forEach((account) => {
    let currentBalance = 0;
    let oldBalance = 0;
    Object.keys(balances[account]).forEach((currency) => {
      if (currency === 'USD') {
        currentBalance += balances[account][currency];
        oldBalance += balances[account][currency];
        return;
      }

      // if an order triggered this calc, introducing a new currency for this account,
      // but there is no balance in that currency yet, then skip this cycle
      // if trade has occurred and no closingPrices entry for that currency is available
      // when the _Execution Report_ triggered this calc, wait for _trade message_ to
      // trigger the calc later, then there will be a last/firstTrade to work with

      if (balanceTrades[currency]?.lastTrade) {
        currentBalance += Decimal.mul(
            balanceTrades[currency]?.lastTrade.price,
            balances[account][currency],
        ).toNumber();
      } else if (balances[account] && balances[account][currency] > 0
        && (closingPrices[account] && closingPrices[account][currency] > 0)) {
        currentBalance += Decimal.mul(
          closingPrices[account][currency],
          balances[account][currency],
        ).toNumber();
      }

      if (balanceTrades[currency]?.firstTrade) {
        oldBalance += Decimal.mul(
            balanceTrades[currency]?.firstTrade.price,
            balances[account][currency],
        ).toNumber();
      } else if (balances[account] && balances[account][currency] > 0
              && (closingPrices[account] && closingPrices[account][currency] > 0)) {
        oldBalance += Decimal.mul(
          closingPrices[account][currency],
          balances[account][currency],
        ).toNumber();
      }
    });

    accountTotal[account] = {
      currentBalance,
      oldBalance,
    };
  });
  return accountTotal;
};

export const totalComputedBalanceForAccount$ = combineLatest([
  totalBalanceTrades$,
  balanceInfoRestClosingPrice$,
  accountsBalances$,
]).pipe(
  map((
    [balanceTrades, closingPrices, balanceInfo],
  ) => getTotalComputedBalanceAndChange(
    balanceTrades, closingPrices, balanceInfo,
  )),
);
