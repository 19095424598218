import { mergeMap } from 'rxjs/operators';
import { fromFetch } from 'rxjs/fetch';

import { getToken } from '../../../shared/websocket/token';
import { Config } from '../../../config/config';

export const fetchUserInfo$ = () => fromFetch(`https://${Config.auth0_DOMAIN}/userinfo`, {
  method: 'GET',
  headers: { authorization: `Bearer ${getToken()}`, 'Content-Type': 'application/json' },
  mode: 'cors',
  credentials: undefined,
}).pipe(
  mergeMap((response) => response.json()),
);
