import React, { Component, ReactNode } from 'react';
import styled from 'styled-components';
import { logger } from './shared/logger/index';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

const ErrorMessage = styled.div`
  background-color: #0c375a;
  color: #ffffff;
  border: 1px solid #e5e9ec;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  border-radius: 5px;
  margin-top: 200px;
  font-family: Roboto;
  body{ color: green; }
`;

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  public componentDidCatch(error: Error) {
    logger.error('Uncaught error:', error);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorMessage>
              <h1>Ooops, something went wrong. Please, try again later. </h1>
              <h1>If the issue persists you can contact us at market.operations@erisx.com. </h1>
            </ErrorMessage>;
    }

    return this.props.children;
  }
}
