import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

import { AccordionWidget } from '../../shared/components/AccordionWidget';
import { Panel } from '../../shared/components/Panel';
import {
  FIRST_BREAKPOINT,
  getBreakpointNumber,
  WIDEST_BREAKPOINT,
} from '../../shared/helperFunctions/windowSizeUtils';
import { useWindowSize } from '../../shared/hooks/useWindowSize';
import { BalanceTotalWidget } from '../balances/BalanceTotalWidget';
import { BalanceWidget } from '../balances/BalanceMainWidget';
import { ChartContainer as Chart } from '../chart/ChartContainer';
import { DesktopFooter } from '../desktopFooter/DesktopFooter';
import { NavBar } from '../navbar/NavBar';
import { NAVBAR_HEIGHT } from '../navbar/styled';
import { MainToasterContainer } from '../notifications/MainToasterContainer';
import { OrderBookContainer as OrderBook } from '../orderBook/OrderBookContainer';
import { OrderEntry } from '../orderEntry/OrderEntry';
import { LargeOrdersGridsTabPanel } from '../orderGridsTabPanel/LargeOrdersGridsTabPanel';
import { OrdersGridsTabPanel } from '../orderGridsTabPanel/OrdersGridsTabPanel';
import { TabPanel } from '../tabs/TabPanel';
import { TradeHistoryContainer as TradeHistory } from '../tradeHistory/TradeHistoryContainer';
import { Watchlist } from '../watchlist/Watchlist';
import { OTCStreams } from '../otcStreams';
import { usePermissions } from '../../shared/services/userPermissionsService';
import { Config } from '../../config/config';

const Body = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.secondary.main};
  margin: 0;
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
  width: 100vw;
  box-sizing: border-box;
  display: flex;
`;

const getTemplateColumns = (breakpoint: number) => {
  switch (breakpoint) {
    case WIDEST_BREAKPOINT:
      return '253px 253px minmax(0, 1fr) 253px';
    case FIRST_BREAKPOINT:
      return '253px minmax(0, 1fr) 253px';
    default:
      return '253px minmax(0, 1fr)';
  }
};

interface GridWrapperProps {
  breakpoint: number;
}

const GridWrapper = styled.div<GridWrapperProps>`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ breakpoint }) => getTemplateColumns(breakpoint)};
  grid-template-rows: minmax(0, 2fr) minmax(0, 1fr) 24px;
`;

const SidebarWrapper = styled.div`
  word-break: break-all;
  border: ${({ theme }) => `1px solid ${theme.palette.primary.primary1}`};
  border-left: none;
  grid-row: 1 / 3;
`;

const OrderGridWrapper = styled.div`
  grid-column: 2 / 5;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.primary.primary1}`};
`;

const DesktopChartWrapper = styled.div`
  height: inherit;
  ${Panel} {
    height: calc(100% - 2px);
  }
`;

const FooterWrapper = styled.div`
  grid-column: 1 / 5;
`;

interface Props {
  logUserOut: () => void;
}

export const DesktopLayout: React.FC<Props> = ({ logUserOut }) => {
  const { width } = useWindowSize()!;
  const permissions = usePermissions();

  const currentBreakpoint = getBreakpointNumber(width);

  const showOrderBookInGrid = currentBreakpoint === WIDEST_BREAKPOINT;
  const showTradeHistoryInGrid = currentBreakpoint >= FIRST_BREAKPOINT;

  return (
    <>
      <NavBar logUserOut={logUserOut} />
      <Body>
        <GridWrapper breakpoint={currentBreakpoint}>
          <SidebarWrapper>
            <OrderEntry />
            {!showOrderBookInGrid && (
              <AccordionWidget defaultExpanded collapsedText="Order Book">
                <OrderBook />
              </AccordionWidget>
            )}
            {!showTradeHistoryInGrid && (
              <AccordionWidget defaultExpanded collapsedText="Trade History">
                <TradeHistory />
              </AccordionWidget>
            )}
            {
              <AccordionWidget
                defaultExpanded
                collapsedText="Purchasing Power"
                rightContent={<BalanceTotalWidget />}
              >
                <BalanceWidget />
              </AccordionWidget>
            }
          </SidebarWrapper>
          {showOrderBookInGrid && <OrderBook />}
          <TabPanel
            tabs={[
              {
                title: 'Chart',
                tabId: 'chart',
                content: (
                  <DesktopChartWrapper>
                    <Chart />
                  </DesktopChartWrapper>
                ),
              },
              {
                title: 'Watchlist',
                tabId: 'watchlist',
                content: <Watchlist />,
              },
              // TODO: remove OTC_ENABLED once fully supported in prod - DA
              ...(Config.OTC_ENABLED === 'true'
              && !permissions?.error
              && permissions?.blockTrades
                ? [
                  {
                    title: 'Quote Streams',
                    tabId: 'otcstreams',
                    content: <OTCStreams />,
                  },
                ]
                : []),
            ]}
          />
          {showTradeHistoryInGrid && <TradeHistory />}
          <OrderGridWrapper>
            {currentBreakpoint === 4 ? (
              <LargeOrdersGridsTabPanel />
            ) : (
              <OrdersGridsTabPanel />
            )}
          </OrderGridWrapper>
          <FooterWrapper>
            <DesktopFooter />
          </FooterWrapper>
        </GridWrapper>
        <MainToasterContainer />
      </Body>
    </>
  );
};
