import React,
{
  useContext,
  useLayoutEffect,
  useState,
} from 'react';
import styled, { ThemeContext } from 'styled-components';
import {
  widget,
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  ResolutionString,
} from '../../charting_library/charting_library';
import { ProductValue } from '../../shared/ProductValue';
import { chartDataApi } from './services/chartDataApi';

const ChartContainer = styled.div<{ ready: boolean}>`
  display: ${({ ready }) => (ready ? 'unset' : 'none')};
  height: 100%;
`;

const defaultInterval = '60';

interface Props {
  market: ProductValue;
}

export const Chart: React.FC<Props> = ({ market }) => {
  const selectedSymbol = market.symbol;
  const [chartWidget, setChartWidget] = useState<IChartingLibraryWidget>();
  const [chartReady, setChartReady] = useState<boolean>(false);
  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    if (!chartWidget && selectedSymbol) {
      const widgetOptions: ChartingLibraryWidgetOptions = {
        symbol: selectedSymbol,
        datafeed: chartDataApi,
        interval: defaultInterval as ChartingLibraryWidgetOptions['interval'],
        container_id: 'tv_chart_container' as ChartingLibraryWidgetOptions['container_id'],
        library_path: '/charting_library/',
        locale: 'en',
        disabled_features: ['header_symbol_search',
          'header_compare',
          'header_screenshot',
          'header_settings',
          'use_localstorage_for_settings',
          'symbol_info',
          'study_templates',
          'header_saveload',
          'delete_button_in_legend',
          'format_button_in_legend',
          'go_to_date',
        ],
        enabled_features: [],
        time_frames: [
          { text: '1h', resolution: '60' as ResolutionString, description: '1 Hour' },
          { text: '1d', resolution: '1D' as ResolutionString, description: '1 Day' },
        ],
        client_id: 'tradingview.com',
        user_id: 'public_user_id',
        fullscreen: false,
        autosize: true,
        studies_overrides: {
          'volume.volume ma.visible': false,
        },
        theme: 'Light',
        custom_css_url: '../chartTheme.css',
        loading_screen: { backgroundColor: `${theme.palette.primary.main}` },
        overrides: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          'paneProperties.background': `${theme.palette.primary.main}`,
          'paneProperties.vertGridProperties.color': `${theme.palette.primary.primary1}`,
          'paneProperties.horzGridProperties.color': `${theme.palette.primary.primary1}`,
          'scalesProperties.backgroundColor': `${theme.palette.primary.main}`,
          'scalesProperties.textColor': `${theme.palette.secondary.secondary5}`,
        },
        // debug: true,
      };
      // eslint-disable-next-line new-cap
      const newChartWidget = new widget(widgetOptions);
      newChartWidget.onChartReady(() => {
        newChartWidget.activeChart().onDataLoaded().subscribe(null,
          () => {
            const range = chartDataApi.getCandlesRange();
            if (range) {
              newChartWidget.activeChart().setVisibleRange(range);
            }
          },
          false);
        setChartReady(true);
      });
      setChartWidget(newChartWidget);
    } else if (chartWidget && chartReady && selectedSymbol) {
      chartWidget.activeChart().setSymbol(selectedSymbol, () => {});
    }
  }, [chartWidget, chartReady, selectedSymbol, theme]);

  return <ChartContainer ready={chartReady}
    id={'tv_chart_container'}
    className={'TVChartContainer'}
  />;
};
