import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { getTomorrowsDate } from '../../../shared/helperFunctions/dateFunctions';
import { OrderEntryDate } from '../styled';
import { OrderEntryFormState as FormState } from '../services/trade/sendEntry';
import { TextFieldWithTooltip } from '../../../shared/components/TextFieldWithTooltip';

interface Props {
  values: FormState;
  setFieldValue: <K extends keyof FormState>(field: K, value: FormState[K]) => void;
  setFieldError: <K extends keyof FormState>(field: K, value: string) => void;
  error?: string;
}

export const OrderEntryDateField: React.FunctionComponent<Props> = (props) => {
  useEffect(() => {
    if (props.values.timeInForce === 'GoodTillDate' && !props.values.goodTillDate) {
      const setGoodTIll = () => props.setFieldValue('goodTillDate', getTomorrowsDate());
      setGoodTIll();
    }
  }, [props, props.setFieldValue]);

  return (
    (props.values.goodTillDate
      && <OrderEntryDate
        value={props.values.goodTillDate && format(props.values.goodTillDate, 'MM/dd/yyyy')}
        onChange={(date) => {
          if (date && !Number.isNaN(date.getTime())) {
            props.setFieldValue('goodTillDate', date || undefined);
            props.setFieldError('goodTillDate', '');
          }
        }}
        errorfield={props.error}
        format="MM/dd/yyyy"
        className="datePickerRoot"
        disablePast
        TextFieldComponent={
          (inputProps) => <TextFieldWithTooltip errorText={props.error} {...inputProps} />
        }
      > </OrderEntryDate>) || null);
};
