import React from 'react';

export const LitecoinIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
}> = ({ height = 20, width = 20 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF">
        <g>
          <path d="M12 22.956c6.041 0 10.956-4.914 10.956-10.956S18.041 1.044 12 1.044C5.959 1.044 1.044 5.958 1.044 12S5.959 22.956 12 22.956zM12 0c6.617 0 12 5.383 12 12s-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0zm0 19.826c4.315 0 7.826-3.51 7.826-7.826 0-4.315-3.51-7.826-7.826-7.826-4.315 0-7.826 3.51-7.826 7.826 0 4.315 3.51 7.826 7.826 7.826zM12 3.13c4.89 0 8.87 3.98 8.87 8.87 0 4.89-3.98 8.87-8.87 8.87-4.89 0-8.87-3.98-8.87-8.87 0-4.89 3.98-8.87 8.87-8.87zm3.13 11.479c.288 0 .522.233.522.521 0 .289-.234.522-.522.522H8.87c-.181 0-.349-.094-.444-.247-.095-.154-.104-.346-.023-.508l1.188-2.375H7.826c-.288 0-.522-.234-.522-.522 0-.288.234-.522.522-.522h2.286l1.421-2.842c.131-.254.442-.355.697-.227.255.127.36.437.237.694l-1.188 2.375h2.808c.288 0 .522.234.522.522 0 .288-.234.522-.522.522h-3.33l-1.043 2.087h5.417z" transform="translate(-4 -112) translate(4 112)" />
        </g>
      </g>
    </g>
  </svg>
);
