import styled from 'styled-components/macro';

export const TwentyFourHourWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 15px;
  padding-left: 10px;
  
`;

export const TwentyFourHourRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TwentyFourHourTitle = styled.div`
 font-size: 11px;
 color: ${({ theme }) => theme.palette.secondary.secondary5};
 text-align: right;
`;

export const TwentyFourHourValue = styled.div<{ color?: string, align?: 'left' | 'right' }>`
 text-align: ${({ align }) => align || 'right'};
 font-size: 11px;
 font-family: 'Roboto Mono';
 font-weight: bold;
 min-width: 40px;
 color: ${({ theme, color }) => color ?? theme.palette.secondary.secondary};
`;

export const TwentyFourHourValueFuture = styled(TwentyFourHourValue)`
 padding-right: 10px;
`;

export const TwentyFourHourValueLastPrice = styled.div`
 text-align: right;
 font-size: 11px;
 font-family: 'Roboto Mono';
 color: ${({ theme }) => theme.palette.secondary.secondary};
 padding-right: 10px;
`;

export const TwentyFourHourMobileWrapper = styled.div<{ future?: boolean }>`
  display: grid;
  grid-template-columns: ${({ future }) => (future ? 'auto auto' : 'auto auto auto')};

  background-color: ${({ theme }) => theme.palette.primary.primary1};
  padding-left: 0;
  padding-left: 10px;
  padding-right: ${({ future }) => (future ? '8px' : '0')};
  width: ${({ future }) => (future ? '33%' : '67%')};
  padding-top: 8px;
  padding-bottom: 8px;

  ${TwentyFourHourTitle} {
    text-align: left;
  }

  ${TwentyFourHourValue} {
    font-weight: normal;
  }
`;
