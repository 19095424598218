import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../../shared/style/styled';

interface TabPanelWrapperProps {
  className?: string;
  children: React.ReactNode;
}

export const TabPanelWrapper = ({ className, children }: TabPanelWrapperProps) => (
    <div className={className}>
        {children}
    </div>
);

export const GridWrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const LeftTabPanel = styled(TabPanelWrapper)`
    width: 50%;
    height: 100%;
`;

export const RightTabPanel = styled(TabPanelWrapper)`
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-left: 0.5px solid ${({ theme }) => theme.palette.primary.primary1};
`;

export const FilterButton = styled(PrimaryButton)`
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline:none;
    border-radius: 10px;
    z-index: 1;
    padding: 0;
    margin: 0;
    svg {
       :hover {
           width: 26px;
           height: 26px;
       }
   }
    `;

export const ActiveFilterButton = styled(FilterButton)`
    background-color: #00EBB9;
    height: 16px;
    position: relative;
    bottom: 2px;  
    line-height:16px;
    label{
        position: relative;
        bottom: 12px;
    }
    svg{
        position: relative;
        bottom: 4px; 
    }
`;

export const SettingButton = styled(PrimaryButton)`
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline:none;
    z-index: 1;
    padding: 0;
    margin: 0;
   svg {
       :hover {
           width: 26px;
           height: 26px;
       }
   }
    `;

export const NumberLabel = styled.label`
    position: relative;
    bottom: 8px;
    left: 3px;
    color: #00EBB9;
    font-weight: bold;
    font-size: 11px;
    `;

export const ActiveNumberLabel = styled(NumberLabel)`
    color: #1C4B79;
`;

export const ConfigWrapper = styled.div`
  display: flex; 
  align-items: center;
  justify-content: flex-end;
  width: 15%;
  min-width: 48px;
  margin-right: 8px;
  margin-top: 4px;
`;
