import { bind } from '@react-rxjs/core';

import { format } from 'date-fns';
import {
  defer, merge, Observable, Subject,
} from 'rxjs';
import {
  filter, map, skip, switchMap,
} from 'rxjs/operators';

import { Direction } from '../../../../shared/Direction';
import { getLocation } from '../../../../shared/formatters';
import { ProductValue } from '../../../../shared/ProductValue';
import {
  send,
  subscribeToMessageStream$,
} from '../../../../shared/websocket/transport';
import { UserPrefsState } from '../../../navbar/services/userPrefsService';
import {
  getFixId,
  UserAccountRaw,
} from '../../../../shared/services/accountsService';

export type OrderType = 'LIMIT' | 'STOP_LIMIT' | 'MARKET';

export const isMarketOrderType = (type: OrderType) => type === 'MARKET';

export type TimeInForce =
  | 'Day'
  | 'GoodTillCancel'
  | 'ImmediateOrCancel'
  | 'FillOrKill'
  | 'GoodTillDate';
export type YesOrNo = 'N' | 'Y';
export type OrderEntryMessageType =
  | 'ExecutionReport'
  | 'OrderReject'
  | 'BusinessMessageRejectMessage'
  | 'ERROR_MESSAGE';

export const FUT = 'FUT';

const isFuturesMarketType = (market: ProductValue) => market.type === FUT;

const getOrderQty = (
  market: ProductValue,
  entry: OrderEntryFormState,
): number | undefined => {
  const isFutures = isFuturesMarketType(market);
  const isMarket = isMarketOrderType(entry.orderType);
  // orderQty is only defined for orders that are:
  // - Futures & Market; Non-market ; non-futures sell market
  if (isFutures && isMarket) return entry.quantity;
  if (
    !isMarket
    || (!isFutures && isMarket && isSellOrderEntry(entry.direction))
  ) {
    return entry.quantity;
  }
  return undefined;
};

const getCashOrderQty = (
  market: ProductValue,
  entry: OrderEntryFormState,
): number | undefined => {
  const isMarket = isMarketOrderType(entry.orderType);
  const isFutures = isFuturesMarketType(market);
  const isBuy = entry.direction === Direction.Buy;
  // cashOrderQty is only defined for non-futures buy market orders
  if (isMarket && isBuy && !isFutures) return entry.quantity;
  return undefined;
};

const isSellOrderEntry = (direction: Direction) => direction === Direction.Sell;
export interface OrderEntryFormState {
  account: UserAccountRaw;
  orderType: OrderType;
  limitPrice?: number;
  quantity?: number;
  timeInForce: TimeInForce;
  direction: Direction;
  postOnly?: YesOrNo;
  goodTillDate?: Date;
  stopPrice?: number;
  convertValue?: number;
  cashOrderQty?: number;
}

export interface OrderEntrySubject {
  product: ProductValue;
  entry: OrderEntryFormState;
  correlation: string;
  userPrefs: UserPrefsState;
}

export interface OrderEntryRequest {
  correlation: string;
  type:
  | 'NewStopLimitOrderSingle'
  | 'NewLimitOrderSingle'
  | 'NewMarketOrderSingle';
  clOrdID: string;
  currency: string;
  side: Direction;
  symbol: string;
  timeInForce: TimeInForce;
  transactionTime: string;
  orderQty?: number;
  ordType: OrderType;
  price: number;
  partyID: string;
  postOnly?: YesOrNo;
  expireDate?: string;
  stopPrice?: number; // '11790'; // ask
  accountType?: number;
  custOrderCapacity?: number;
  senderLocationId?: string;
  senderSubId?: string;
  cashOrderQty?: number;
  customerAccountRef?: string;
  positionAccountRef?: string;
}

export interface OrderResponse {
  avgPrice?: number;
  correlation: string;
  type: OrderEntryMessageType;
  ordStatus: string;
  clOrdID?: string;
  currency?: string;
  side?: Direction;
  symbol?: string;
  execType?: string;
  contract?: string;
  leavesQty?: number;
  cumQty?: number;
  accountNumber?: string;
  timeInForce?: TimeInForce;
  transactTime?: string;
  orderQty?: number;
  ordType?: OrderType;
  ordID?: string;
  price?: number;
  partyID?: string;
  postOnly?: YesOrNo;
  expireDate?: string;
  stopPrice?: number;
  message?: string;
  partyIDs?: string[];
  commCalculated?: number;
  businessRejectReason?: string;
  historyID?: string;
  rejectTime?: string;
  orderID?: string;
  lastQty?: number;
  lastPrice?: number;
  text?: string;
  cashOrderQty?: number;
  commCurrency?: string;
  commission?: number;
}

export const convertEntryToRequest = (
  entry: OrderEntryFormState,
  selectedMarket: ProductValue,
  correlation: string,
  userPrefs: UserPrefsState,
): OrderEntryRequest => ({
  correlation,
  type:
    entry.orderType === 'MARKET'
      ? 'NewMarketOrderSingle'
      : entry.orderType === 'LIMIT'
        ? 'NewLimitOrderSingle'
        : 'NewStopLimitOrderSingle',
  clOrdID: `${getFixId(entry.account)}-TUI-${correlation}`,
  currency: selectedMarket.currency,
  side: entry.direction,
  symbol: selectedMarket.symbol,
  timeInForce: entry.timeInForce,
  transactionTime: format(Date.now(), 'yyyyMMdd-hh:mm:ss.SSS'),
  orderQty: getOrderQty(selectedMarket, entry),
  cashOrderQty: getCashOrderQty(selectedMarket, entry),
  ordType: entry.orderType,
  price: entry.limitPrice!,
  partyID: getFixId(entry.account),
  postOnly: entry.orderType !== 'MARKET' ? entry.postOnly : undefined,
  expireDate:
    entry.timeInForce === 'GoodTillDate'
      ? entry.goodTillDate && format(entry.goodTillDate, 'yyyyMMdd')
      : undefined,
  stopPrice: entry.stopPrice,
  accountType: selectedMarket.type === FUT ? entry.account.origin : undefined,
  custOrderCapacity:
    selectedMarket.type === FUT ? entry.account.cti : undefined,
  senderLocationId:
    selectedMarket.type === FUT ? getLocation(userPrefs) : undefined,
  senderSubId: userPrefs.traderId || undefined,
  customerAccountRef:
    selectedMarket.type === FUT ? userPrefs?.customerAccountRef : undefined,
  positionAccountRef:
    selectedMarket.type === FUT ? userPrefs?.positionAccountRef : undefined,
});
export const orderEntry$ = new Subject<OrderEntrySubject>();

export const submitOrderEntry = (orderEntry: OrderEntrySubject) => {
  orderEntry$.next(orderEntry);
};

export const sendOrderEntry$ = (orderRequest: OrderEntryRequest) => defer(() => {
  send(orderRequest);

  return merge(
    subscribeToMessageStream$('ExecutionReport'),
    subscribeToMessageStream$('OrderReject'),
    subscribeToMessageStream$('BusinessMessageRejectMessage'),
    subscribeToMessageStream$('ERROR_MESSAGE'),
  ).pipe(
    filter((msg) => msg.correlation === orderRequest.correlation),
  ) as Observable<OrderResponse>;
});

export const orderEntryRequest$ = orderEntry$.pipe(
  map((orderEntry) => convertEntryToRequest(
    orderEntry.entry,
    orderEntry.product,
    orderEntry.correlation,
    orderEntry.userPrefs,
  )),
);

export const getOrderEntryRequest$ = () => orderEntryRequest$;

export const orderEntryResponse$ = orderEntryRequest$.pipe(
  switchMap((orderEntry) => sendOrderEntry$(orderEntry)),
);

// eslint-disable-next-line
const [useOrderEntryResponse, orderEntryResponse_$] = bind(
  orderEntryResponse$,
  null,
);

export { useOrderEntryResponse };
export const orderEntryState$ = orderEntryResponse_$.pipe(skip(1));
