import { ListSubheader, Menu } from '@material-ui/core';
import { bind } from '@react-rxjs/core';
import { createListener } from '@react-rxjs/utils';
import React, { useState } from 'react';
import { combineLatest } from 'rxjs';
import { map, startWith, withLatestFrom } from 'rxjs/operators';
import styled from 'styled-components/macro';

import { PencilIcon } from '../../../icons/PencilIcon';
import { FilterSearchPredicate } from '../../../shared/components/FilterSearchPredicate/FilterSearchPredicate';
import { ThemedCheckbox } from '../../../shared/components/ThemedCheckbox';
import { withAuth } from '../../../shared/services/authStatusService';
import { MenuItemStyled, PrimaryButton } from '../../../shared/style/styled';
import { Security } from '../../../shared/services/getSecurities$';
import {
  securities$,
  selectedSymbols$,
  setSelectedSymbols,
  useSelectedSymbols,
} from '../services/watchlistSelection';

const EditButton = styled(PrimaryButton)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 11px;
  background-color: ${({ theme }) => theme.palette.primary.primary5};
  color: ${({ theme }) => theme.palette.secondary.secondary};
  border: none;
  outline: none;
  cursor: pointer;
  width: 60px;
  height: 30px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 2px;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const EditButtonText = styled.div`
  position: relative;
  right: 2px;
`;

const WatchListEditListWrapper = styled(Menu)`
  .editMenu {
    background-color: ${({ theme }) => theme.palette.primary.primary};
  }
`;

export const ListGroupedSubHeaderStyled = styled(ListSubheader)`
  background-color: ${({ theme }) => theme.palette.primary.primary};
  color: ${({ theme }) => theme.palette.secondary.secondary};
  line-height: 32px;
  font-size: 10px;
  font-weight: 600;
  padding-left: 10px;
`;

export const ListSubheaderStyled = styled(ListSubheader)`
  background-color: ${({ theme }) => theme.palette.primary.primary};
  color: ${({ theme }) => theme.palette.secondary.secondary};
  line-height: 32px;
  font-size: 12px;
  font-weight: 600;
  padding-left: 8px;
`;

const EditSearchBarRow = styled.div`
  margin: 8px;
  display: grid;
  grid-template-columns: 50px 100px;
  background-color: ${({ theme }) => theme.palette.primary.primary};
  color: ${({ theme }) => theme.palette.secondary.secondary};
`;

const EditSearchBarLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
`;

const WatchListEditItem = styled(MenuItemStyled)`
  display: flex;
  justify-content: space-between;
  width: 150px;
`;

const WatchlistEditItemSymbol = styled.div``;

const [editSearchText$, setEditSearchText] = createListener<string>();
const [toggleSecurity$, onToggleSecurity] = createListener<string>();

toggleSecurity$
  .pipe(
    withLatestFrom(selectedSymbols$),
    map(([symbol, selectedSymbols]) => {
      const result = new Set(selectedSymbols);
      if (selectedSymbols.has(symbol)) {
        result.delete(symbol);
      } else {
        result.add(symbol);
      }
      return result;
    }),
  )
  .subscribe(setSelectedSymbols);

const [useSpotsAndFutures] = bind(
  combineLatest([securities$, editSearchText$.pipe(startWith(''))]).pipe(
    map(([securities, editSearchText]) => (editSearchText === ''
      ? securities
      : {
        spots: securities.spots.filter((spotSecurity) => spotSecurity.symbol
          .toUpperCase()
          .includes(editSearchText.toUpperCase())),
        futures: securities.futures.filter((futureSecurity) => futureSecurity.symbol
          .toUpperCase()
          .includes(editSearchText.toUpperCase())),
      })),
    withAuth(),
  ),
  { spots: [], futures: [] },
);

// eslint-disable-next-line max-len
const securitiesToMenuRows = (secs: Security[], watchlistState: Set<string>) => secs.map((security) => (
    <WatchListEditItem
      key={security.symbol}
      onClick={() => onToggleSecurity(security.symbol)}
    >
      <WatchlistEditItemSymbol>{security.symbol}</WatchlistEditItemSymbol>
      <ThemedCheckbox
        type="checkbox"
        checked={watchlistState.has(security.symbol)}
        readOnly
      />
    </WatchListEditItem>
));

export const WatchListEditList = () => {
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const { spots, futures } = useSpotsAndFutures();
  const selectedSecurities = useSelectedSymbols();

  return (
    <>
      <EditButton onClick={(e) => setAnchorEl(e.target)}>
        <PencilIcon />
        <EditButtonText>Edit</EditButtonText>
      </EditButton>
      <WatchListEditListWrapper
        id="simple-menu"
        anchorEl={anchorEl as Element}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={() => setAnchorEl(null)}
        MenuListProps={{ disablePadding: true }}
        classes={{ paper: 'editMenu' }}
      >
        <EditSearchBarRow>
          <EditSearchBarLabel>Search:</EditSearchBarLabel>
          <FilterSearchPredicate hidePlaceholder onChange={setEditSearchText} />
        </EditSearchBarRow>
        {spots.length > 0 && <ListSubheaderStyled>SPOT</ListSubheaderStyled>}
        {securitiesToMenuRows(spots, selectedSecurities)}
        {futures.length > 0 && (
          <ListSubheaderStyled>FUTURES</ListSubheaderStyled>
        )}
        {securitiesToMenuRows(futures, selectedSecurities)}
      </WatchListEditListWrapper>
    </>
  );
};
