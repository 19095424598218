import styled from 'styled-components';
import {
  Select,
} from '../../../../shared/style/styled';
import { LoadingIndicator as LoadingIndicatorComponent } from '../../components/LoadingIndicator/LoadingIndicator';
import { Button } from '../../components/Button/Button';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 361px;
    background-color:  ${({ theme }) => theme.palette.primary.primary};
    padding-bottom: 32px;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:  ${({ theme }) => theme.palette.primary.primary1};
    padding-top: 17px;
`;
export const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    align-items: center;
`;
export const ConfirmationTitle = styled.h2`
    padding-top: 13px;
    text-transform: uppercase;
`;

export const ApprovingText = styled.span`
    color: ${({ theme }) => theme.palette.accent.accentPrimary};
`;

export const RejectingText = styled.span`
    color: ${({ theme }) => theme.palette.accent.accentNegative1};
`;

export const ConfirmationDate = styled.h1`
    font-family: Roboto;
    font-size: 18px;
    font-weight: 200;
    padding-top: 13px;
    padding-bottom: 29px;
`;

export const AccountDetails = styled.div`
    display : flex;
    flex: 1;
    flex-direction: column;
`;

export const AccountLabel = styled.div`
    padding-bottom: 5px;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 11px;
`;

export const Account = styled.div`
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 11px;
`;

export const AccountDetailsContainer = styled.div`
    display: flex;
    align-self: stretch;
    align-items: center;
    padding: 10px 0;
    ${AccountDetails}:first-of-type ${Account} {
        color: ${({ theme }) => theme.palette.secondary.secondary2};
    }
`;

export const TransferIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    margin: 0 12px;
    background-color: ${({ theme }) => theme.palette.primary.primary1};
    svg {
        width: 18px;
        height: 18px;

    }
`;

export const AssetDetails = styled.div`
    margin: 10px 0;
    border-top: 1px solid ${({ theme }) => theme.palette.primary.primary2};
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.primary2};
    display: flex;
    align-self: stretch;
`;

export const AssetTable = styled.table`
    font-family: 'Roboto';
    font-size: 11px;
    margin: 0 auto;
    th, td {
        padding: 5px 10px;
    }

    th {
        font-weight: 800;
        color: ${({ theme }) => theme.palette.secondary.secondary};
    }

    td {
        font-weight: 400;
        text-align: left;
    }


    tr td:first-of-type {
        text-transform: uppercase;
    }

`;

export const SettlementAccountSelector = styled.div`
    display: flex;
    padding-top: 10px;
    align-self: stretch;
    justify-content: space-between;
    align-items: center;
    > * {
        flex: 1;
    }
    padding-bottom: 10px;
`;

export const Label = styled.label`
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 11px;
`;

export const CommentsLabel = styled(Label)`
    align-self: flex-start;
`;

export const Comments = styled.p`
    align-self: stretch;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    padding-top: 5px;
    color: ${({ theme }) => theme.palette.secondary.secondary2};
`;

export const ConfirmButton = styled(Button)`
    margin: 10px 0 5px 0;
    border-radius: 3px;
    height: 34px;
    align-self: stretch;
    text-transform: uppercase;
    font-weight: 800;
    transition: opacity 150ms ease-in;
`;

export const AccountSelector = styled(Select)`
    align-self: stretch;
`;

export const LoadingIndicator = styled(LoadingIndicatorComponent)`
    padding: 15px;
`;
