import { Select } from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components/macro';
import { Modal } from '../../shared/components/Modal';
import { OrderEntryButton } from '../orderEntry/styled';
import { OrderEntryCloseButton } from '../orderEntry/transitionScreens/styled';
import { ThemedCheckbox } from '../../shared/components/ThemedCheckbox';
import {
  setNewUserPrefs,
  setShowUserPrefs,
  UserPrefsState,
  useUserPrefs,
} from './services/userPrefsService';
import { Config } from '../../config/config';
import { TextFieldWithTooltip } from '../../shared/components/TextFieldWithTooltip';

export const UserPrefFormRowsWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(25px, 7);
  grid-row-gap: 5px;
  align-items: center;
  justify-content: center;
`;

export const UserPrefsButtonsWrapper = styled(OrderEntryCloseButton)`
  margin: 0;
  background-color: ${({ theme }) => theme.palette.primary.primary};
  min-height: 25px;
  padding: 0 5px;
`;

export const UserPrefsFormName = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  text-align: right;
  padding-right: 10px;
  justify-content: center;
  vertical-align: middle;
  line-height: 25px;
`;

export const UserPrefsFormRow = styled.div`
  display: grid;
  grid-template-columns: 100px 140px;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.palette.secondary.secondary};
    padding-top: 3px;
  }
`;

export const UserPrefsText = styled(TextFieldWithTooltip) <{ errorfield?: string }>`
  background-color: ${({ theme }) => theme.palette.primary.primary1};
  outline: ${({ errorfield, theme }) => (errorfield ? `1px solid ${theme.palette.trading.tradingSell}` : 'none')};
  input {
    color: ${({ theme }) => theme.palette.secondary.secondary};
    font-size: 11px;
    padding-left: 10px;
  }
`;

export const UserPrefsSelect = styled(Select) <{ errorfield?: string }>`
  display: grid;
  grid-template-columns: 105px 40px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.primary.primary1};
  border: ${({ errorfield, theme }) => (errorfield ? `1px solid ${theme.palette.trading.tradingSell}` : 'none')};
  max-height: 25px;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  font-size: 11px;
  padding-left: 20px;
  font-weight: 550;
`;

export const UserPrefsSaveButton = styled(OrderEntryButton)`
  background-color: ${({ theme }) => theme.palette.accent.accentPrimary};
  color: ${({ theme }) => theme.palette.primary.primary};
  min-width: 86px;
  float: right;
  opacity: ${({ disabled }) => disabled && 0.5};
  margin: 0;
`;

const UserPrefsConfirmButtonsWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-around;
`;

const checkIfValuesTheSame = (
  values: UserPrefsState,
  initialValues: UserPrefsState,
) => JSON.stringify(values) === JSON.stringify(initialValues);

export const UserPrefsModal = () => {
  const userPrefs = useUserPrefs();
  const UserPrefsForm = (
    <Formik<UserPrefsState>
      initialValues={{
        ...userPrefs,
      }}
      validate={(values) => {
        if (
          (values.locationCountry === 'US'
            || values.locationCountry === 'CA')
          && !values.locationState
        ) {
          return { locationState: 'Please enter a valid state/province' };
        }
        if (values.traderId && (values.traderId?.length < 3 || values.traderId?.length > 18)) {
          return { traderId: 'Trader ID must be between 3 and 18 characters long' };
        }
        return {};
      }}
      onSubmit={(values) => {
        setNewUserPrefs(values);
        setShowUserPrefs(false);
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => {
        const {
          values,
          setFieldValue,
          handleSubmit,
          errors,
        } = formikProps;

        const disabled = checkIfValuesTheSame(values, userPrefs);

        return (
          <>
            {' '}
            <UserPrefFormRowsWrapper>
              <UserPrefsFormRow>
                <UserPrefsFormName>Review Orders:</UserPrefsFormName>
                <ThemedCheckbox
                  type="checkbox"
                  checked={values.reviewOrder}
                  onChange={() => {
                    setFieldValue('reviewOrder', !values.reviewOrder);
                  }}
                />
              </UserPrefsFormRow>
              {Config.CAR_CGM_PREFERENCES_ENABLED === 'true' && (
                <>
                  <UserPrefsFormRow>
                    <UserPrefsFormName>CGM:</UserPrefsFormName>
                    <UserPrefsText
                      title={'title'}
                      InputProps={{ disableUnderline: true }}
                      value={values.positionAccountRef}
                      onChange={(e) => {
                        setFieldValue('positionAccountRef', e.target.value);
                      }}
                    />
                  </UserPrefsFormRow>
                  <UserPrefsFormRow>
                    <UserPrefsFormName>CAR:</UserPrefsFormName>
                    <UserPrefsText
                      title={'title'}
                      InputProps={{ disableUnderline: true }}
                      value={values.customerAccountRef}
                      onChange={(e) => {
                        setFieldValue('customerAccountRef', e.target.value);
                      }}
                    />
                  </UserPrefsFormRow>
                </>
              )}
              <UserPrefsFormRow>
                <UserPrefsFormName>
                  Trader ID:
                </UserPrefsFormName>
                <UserPrefsText
                  InputProps={{ disableUnderline: true }}
                  value={values?.traderId}
                  onChange={(e) => {
                    setFieldValue('traderId', e.target.value);
                  }}
                  errorfield={errors.traderId}
                  errorText={errors.traderId}
                />
              </UserPrefsFormRow>
              <UserPrefsConfirmButtonsWrapper>
                <UserPrefsButtonsWrapper
                  onClick={() => setShowUserPrefs(false)}
                >
                  CLOSE
                </UserPrefsButtonsWrapper>
                <UserPrefsSaveButton
                  type="button"
                  disabled={disabled}
                  onClick={() => handleSubmit()}
                >
                  {' '}
                  Save{' '}
                </UserPrefsSaveButton>
              </UserPrefsConfirmButtonsWrapper>
            </UserPrefFormRowsWrapper>
          </>
        );
      }}
    </Formik>
  );
  return <Modal title={'User Preferences'} content={UserPrefsForm} />;
};
