import { bind } from '@react-rxjs/core';
import { createListener } from '@react-rxjs/utils';
import React, { ReactPortal, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { isNotNil } from '../helperFunctions/typeHelpers';

type PortalRegistry = Map<string, Element>;
const [portalRegistry$, setRegistry] = createListener<PortalRegistry>();
const [usePortalRegistry] = bind<PortalRegistry>(portalRegistry$, new Map());

interface PortalProps {
  name: string;
}

export const Portal:React.FC<PortalProps> = ({ name }) => {
  const [ref, setRef] = useState<Element | null>();
  const portalRegistry = usePortalRegistry();

  useEffect(() => {
    if (isNotNil(ref)) {
      if (portalRegistry.has(name)) {
        throw new Error(`Portal with name ${name} already registered`);
      } else {
        setRegistry(new Map([...portalRegistry.set(name, ref)]));
      }
    }
    return () => {
      portalRegistry.delete(name);
      setRegistry(new Map([...portalRegistry]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return <div id={`portal-${name}`} ref={setRef}></div>;
};

interface PortalContentProps {
  name: string;
}

export const PortalContent:React.FC<PortalContentProps> = ({ name, children }) => {
  const [portal, setPortal] = useState<ReactPortal|null>(null);
  const portalRegistry = usePortalRegistry();
  useEffect(() => {
    const el = portalRegistry.get(name);
    if (el) {
      setPortal(
        createPortal(children, el),
      );
    }
  }, [children, name, portalRegistry]);
  return portal;
};
