import { webSocket } from 'rxjs/webSocket';
import { Config } from '../../config/config';
import { setConnectionStatus, ConnectionStatus } from './connectionStatus';
import { Message } from './types';

export const publicWebSocketSubject = webSocket<Message>({
  url: Config.TRADE_WEBSOCKET_URL_UNAUTHENTICATED,
  openObserver: {
    next: () => {
      setConnectionStatus(ConnectionStatus.OPEN);
    },
  },
  closeObserver: {
    next: () => {
      setConnectionStatus(ConnectionStatus.CLOSED);
    },
  },
});
