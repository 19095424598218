import styled from 'styled-components';

export const BannerBody = styled.section`
    grid-area: banner;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 0 0 5px 5px;
    background-color: #b77f5a;
    color: black;
    text-align: center;
    font-size: 1rem;
    height: 2rem;
    line-height: 2.2rem;
    @media (max-width: 480px) {
        width: 100%!important;
        height: 4rem;
      }
`;

export const Mono = styled.span`
    font-family: Roboto;
    font-weight: 200;
    position: relative;
    left: 10px;
    bottom: 3px;
`;

export const TextLabel = styled.label`
    font-weight: bold;
`;
