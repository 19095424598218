import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  createSettlementRequest,
  submitSettlementConfirmationAction,
  useIsPending,
  useSettlementConfirmationResponseFailure,
} from './SettlementConfirmationService';
import {
  SettlementConfirmation,
  SettlementConfirmationProps,
} from './SettlementConfirmation';

import { accountsResponse$, useAccounts } from '../../../../shared/services/accountsService';
import { withSubscribe } from '../../../../shared/helperFunctions/withSubscribe';
import { getSettlementAccountsUserHasAccessTo } from './helpers/getSettlementAccountsUserHasAccessTo';
import { LoadingIndicator } from './SettlementConfirmation.styled';

export const SettlementConfirmationContainer: React.FC<Omit<
SettlementConfirmationProps,
'onCancel' | 'onConfirm' | 'isBusy' | 'showAccountSelector'
>> = withSubscribe((props) => {
  const { settlementConfirmationAction } = props;
  const { settlement } = settlementConfirmationAction;

  const failure = useSettlementConfirmationResponseFailure();

  const isLoading = useIsPending();

  const [
    showAccountSelector,
    setShowAccountSelector,
  ] = useState<boolean>(false);

  const accountsResponse = useAccounts();
  const userOwnedAccounts = useMemo(
    () => getSettlementAccountsUserHasAccessTo(settlement, accountsResponse?.accounts),
    [accountsResponse, settlement],
  );

  useEffect(() => {
    setShowAccountSelector(userOwnedAccounts.length === 2);
  }, [userOwnedAccounts.length]);

  const onConfirm = useCallback((userSelectedAccount?: string) => {
    createSettlementRequest({
      account: userSelectedAccount ?? userOwnedAccounts[0],
      settlement_request_id: settlement.id,
      type: settlementConfirmationAction.type,
    });
  }, [settlement.id, settlementConfirmationAction.type, userOwnedAccounts]);

  return (
    <SettlementConfirmation
      {...props}
      errorMessage={failure?.errorMessage}
      isBusy={isLoading}
      onCancel={() => submitSettlementConfirmationAction(undefined)}
      onConfirm={onConfirm}
      showAccountSelector={showAccountSelector}
    />
  );
}, accountsResponse$, <LoadingIndicator />);
