import React, { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components/macro';

const PopupOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`;

const PopupPosition = styled.div<{ x: number; y: number; width?: number }>`
    position: absolute;
    left: ${(p) => p.x}px;
    top: ${(p) => p.y}px;
    width: ${(p) => p.width}px;
`;

export interface Position {
  x: number;
  y: number;
  width?: number;
}

interface OverlayPopupProps {
  position: Position;
  onClose: () => void;
}
export const OverlayPopup: React.FC<OverlayPopupProps> = (props) => {
  const popUpRef = useRef<HTMLDivElement | null>(null);
  const [popUpdimensions, setPopUpDimensions] = useState<DOMRect>();

  useEffect(() => setPopUpDimensions(popUpRef.current?.firstElementChild?.getBoundingClientRect()),
    []);

  return createPortal(
        <PopupOverlay onClick={() => props.onClose()} onContextMenu={() => props.onClose()}>
            <PopupPosition
                x={props.position.x}
                y={props.position.y + (popUpdimensions?.height || 0)}
                width={props.position.width}
                onClick={(e) => e.stopPropagation()}
                ref={popUpRef}
            >
                {props.children}
            </PopupPosition>
        </PopupOverlay>,
        window.document.body,
  );
};
