import { bind, shareLatest } from '@react-rxjs/core';
import { mergeWithKey } from '@react-rxjs/utils';
import {
  combineLatest,
  defer,
  merge,
  Observable,
  Subject,
} from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  scan,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  algoConnectionStatusState$,
} from '../../../../shared/algoWebsocket/algoConnectionStatus';
import {
  algoSend,
  subscribeToAlgoMessageStream$,
} from '../../../../shared/algoWebsocket/transport';
import { getExecutionOrderDateMilliseconds } from '../../../../shared/formatters';
import { correlationId } from '../../../../shared/helperFunctions/correlationId';
import { ConnectionStatus } from '../../../../shared/websocket/connectionStatus';
import {
  accountMap$,
  accountState$,
} from '../../../../shared/services/accountsService';
import { ExecutionReport } from '../openOrders/openOrdersReducer';
import { algoOrdersReducer, initialAlgoOrdersMap } from './algoOrdersReducer';

const newExecutionReports$ = defer(() => subscribeToAlgoMessageStream$('ExecutionReport')
  .pipe(filter((msg: any): msg is ExecutionReport => msg.execType !== 'ORDER_STATUS'))) as Observable<ExecutionReport>;

export const getMassOrders$ = (partyID?: string) => defer(() => {
  const correlation = correlationId();
  algoSend({
    correlation,
    type: 'OrderMassStatusRequest',
    partyID,
  });
  return subscribeToAlgoMessageStream$('ExecutionReport').pipe(
    filter(
      (msg: any): msg is ExecutionReport => msg.correlation === correlation
        && msg.execType === 'ORDER_STATUS',
    ),
  );
});

export const openOrderRequest$ = new Subject<string>();

export const requestMassOrders = (partyId?: string) => {
  openOrderRequest$.next(partyId);
};

const currentExecutionReports$ = openOrderRequest$.pipe(
  mergeMap((partyId) => getMassOrders$(partyId)),
);

export const algoExecutionReportsWithAccountNumber$ = merge(
  currentExecutionReports$,
  newExecutionReports$,
).pipe(
  withLatestFrom(accountMap$),
  map(
    ([executionReport, accountMap]) => ({
      ...executionReport,
      accountNumber: accountMap.get(executionReport.partyIDs[0]) || executionReport.partyIDs[0],
      timeInMilliseconds: getExecutionOrderDateMilliseconds(executionReport.transactTime),
    } as ExecutionReport),
  ),
  shareLatest(),
) as Observable<ExecutionReport>;

export const [useAlgoOrders, algoOrderState$] = bind(
  mergeWithKey({
    newAlgoOrder: algoExecutionReportsWithAccountNumber$,
    newMassOrderRequest: openOrderRequest$,
  }).pipe(scan(algoOrdersReducer, initialAlgoOrdersMap)),
  initialAlgoOrdersMap,
);

export const algoStatusCorrelation$ = new Subject<string>();

export const addAlgoStatusCorrelation = (correlation: string) => {
  algoStatusCorrelation$.next(correlation);
};
export const algoStatusCorrelations$ = algoStatusCorrelation$.pipe(
  scan((a: string[], b: string) => [...a, b], []),
  shareLatest(),
);

export const getMassAlgoInstructions = (partyID: string) => {
  const correlation = correlationId();

  addAlgoStatusCorrelation(correlation);

  algoSend({
    correlation,
    type: 'AlgoInstructionsStatus',
    partyID,
  });
};

export const getMassAlgoOrdersWhenAccounts$ = combineLatest([
  algoConnectionStatusState$,
  accountState$,
]).pipe(
  tap(([algoStatus, accounts]) => {
    if (algoStatus === ConnectionStatus.AUTHENTICATED) {
      requestMassOrders();
      accounts.accounts.forEach(
        (account) => {
          if (account.fix_ids && account.fix_ids[0]) {
            getMassAlgoInstructions(account.fix_ids[0]);
          }
        },
      );
    }
  }),
);
