import React from 'react';
import { ErisXIconSmall } from '../../../../icons/ErisXIconSmall';
import { OrderEntryTransitionTitle } from '../styled';

interface Props {
}

export const OrderEntryLoading: React.FunctionComponent<Props> = () => (
  <OrderEntryTransitionTitle>
    <ErisXIconSmall /> <div>PLACING YOUR ORDER</div>
  </OrderEntryTransitionTitle>
);
