import { defer, of } from 'rxjs';
import {
  catchError,
  concatMap,
  expand,
  map,
  reduce,
  switchMap,
  takeWhile,
} from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { Direction } from '../../shared/Direction';
import {
  formatToUtcDateTime,
  getYesterdaysDate,
  toDateFromUtc,
} from '../../shared/helperFunctions/dateFunctions';
import {
  FetchResponse,
  getFetchHistory$,
} from '../../shared/services/fetchService';
import { TradeHistoryData } from './types';
import {
  AuthStatus,
  authStatusState$,
} from '../../shared/services/authStatusService';
import { Config } from '../../config/config';

export interface MarketDataRestTrade {
  amount: number;
  price: number;
  tickerType: string;
  transactTime: string;
}

export interface MarketDataRestResponse {
  payload: MarketDataRestTrade[];
}

const mapTickerTypeToDirection = (
  tickerType?: string | null,
): Direction | null => {
  if (!tickerType) return null;
  return tickerType === 'PAID' ? Direction.Buy : Direction.Sell;
};

export const historyToTrades = (
  msg: MarketDataRestResponse,
  symbol: string,
): TradeHistoryData => ({
  trades: msg.payload.map((entry: MarketDataRestTrade) => ({
    direction: mapTickerTypeToDirection(entry.tickerType),
    price: entry.price,
    quantity: entry.amount,
    time: toDateFromUtc(entry.transactTime),
    id: `${entry.transactTime}-${entry.price}-${entry.amount}-${uuidv4()}`,
  })),
  error: false,
  symbol,
});

const DEFAULT_PAGE_SIZE = '100';
const INITIAL_PAGE = '1';

export const getPaginatedRestTradeHistory$ = (symbol: string) => authStatusState$.pipe(
  // First we get current AuthStatus to check which page size to use
  switchMap((authStatus) => defer(() => {
    const envPageSize = authStatus === AuthStatus.AUTHENTICATED
      ? Config.AUTHENTICATED_GET_TRADES_PAGE_SIZE
      : Config.UNAUTHENTICATED_GET_TRADES_PAGE_SIZE;

    const recordsPerPage = envPageSize || DEFAULT_PAGE_SIZE;
    // Build URL search params
    const params = new URLSearchParams({
      startDate: formatToUtcDateTime(getYesterdaysDate()),
      endDate: formatToUtcDateTime(new Date()),
      recordsPerPage,
      page: INITIAL_PAGE,
      symbol,
    });
    // Control pagination from outside expand
    let hasMorePages = true;
    const restCallName = 'getTrades';
    // Call fetch history rest endpoint
    const pages$ = getFetchHistory$({
      restCallName,
      params,
    }).pipe(
      // Recursively check for pages and fetch them if necessary
      expand((fetchResponse) => {
        if (
              fetchResponse.response?.page < fetchResponse.response?.totalPages
        ) {
          params.set('page', (fetchResponse.response?.page + 1).toString());
          return getFetchHistory$({
            restCallName,
            params,
          }).pipe(
            catchError(() => of({
              error: true,
              response: { payload: [] as MarketDataRestTrade[] },
            } as FetchResponse<any>)),
          );
        }
        // No more pages
        hasMorePages = false;
        // Return an empty FetchResponse so not to stop emissions
        return of({
          error: false,
          response: { payload: [] as MarketDataRestTrade[] },
        } as FetchResponse<any>);
      }),
      // Transform each emitted page into a TradeHistoryData
      concatMap((fetchResponse) => {
        if (fetchResponse.error || fetchResponse.response.hasError) {
          return of({
            trades: [],
            error: true,
            symbol,
          });
        }
        return of(historyToTrades(fetchResponse.response, symbol));
      }),
      takeWhile(() => hasMorePages, true),
    );
    // Combine all emitted TradeHistoryData into a single one
    // So we don't emit any values until all pages are in
    const combined$ = pages$.pipe(
      reduce(
        (acc, page) => {
          // Concatenate trades of new page
          acc.trades.push(...page.trades);
          // Keep error status if present
          acc.error = acc.error || page.error;
          // Keep symbol
          acc.symbol = page.symbol;
          return acc;
        },
        {
          trades: [],
          error: false,
          symbol,
        } as TradeHistoryData,
      ),
    );
    // Return observable that emits a single TradeHistoryData with the
    // trades of all the pages
    return combined$;
  })),
);

export const getRestTradeHistory$ = (symbol: string) => defer(() => {
  const params = new URLSearchParams({
    startDate: formatToUtcDateTime(getYesterdaysDate()),
    endDate: formatToUtcDateTime(new Date()),
    recordsPerPage: '10000000', // waiting for backend to be optimized
    symbol,
  });
  return getFetchHistory$({
    restCallName: 'getTrades',
    params,
  }).pipe(
    map((fetchResponse) => {
      if (fetchResponse.error || fetchResponse.response.hasError) {
        return {
          trades: [],
          error: true,
          symbol,
        } as TradeHistoryData;
      }
      return historyToTrades(fetchResponse.response, symbol);
    }),
  );
});

export interface MarketDataTrade {
  price: number;
  size: number;
  tickerType: string;
  transactTime: string;
}

export interface MarketDataTradeMessage {
  type: 'MarketDataIncrementalRefreshTrade';
  correlation: string;
  trades: MarketDataTrade[];
  symbol: string;
}

export const marketDataToTrades = (
  msg: MarketDataTradeMessage,
): TradeHistoryData => ({
  trades: msg.trades.map((entry) => ({
    direction: mapTickerTypeToDirection(entry.tickerType),
    price: entry.price,
    quantity: entry.size,
    time: toDateFromUtc(entry.transactTime),
    id: `${entry.transactTime}-${entry.price}-${entry.size}-${uuidv4()}`,
  })),
  error: false,
  symbol: msg.symbol,
});
