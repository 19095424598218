import React from 'react';
import styled from 'styled-components';
import { LinkButton } from '../../AccountManagement.styled';
import {
  TabButton as DefaultTabButton,
  ContentWrapper as DefaultContentWrapper,
} from '../../../tabs/styled';
import { Button, ButtonProps } from '../../components/Button/Button';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f2f4f6;
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 20px 40px;
  height: 100%;
  min-width: 0;
`;

export const Body = styled.div`
  padding-bottom: 15px;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const TabButton = styled(DefaultTabButton)`
  font-size: 11px;
  color: ${({ theme, selected }) => (selected
    ? theme.palette.primary.primary1
    : theme.palette.secondary.secondary5)};
  background-color: ${({ theme }) => theme.palette.primary};
`;

export const ContentWrapper = styled(DefaultContentWrapper)`
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

export const GridWrapper = styled.div`
  flex: 1;
    height: 100%;
    padding-top: 25px;
    box-sizing: border-box;
  background-color: #f2f4f6;
`;

export const AddNewSettlementButton = styled((props: ButtonProps) => <Button colorTheme="secondary" {...props} />)`
  width: fit-content;
  align-self: flex-start;
`;

export const RefreshLink = styled(LinkButton)`
  display: flex;
  font-size: 12px;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.secondary5};
  svg {
    padding-right: 5px;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const LoadingInlay = styled.div`
  align-self: center;
  flex: 1;
  text-align: center;
`;
