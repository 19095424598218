import { Map } from 'immutable';
import { OrderResponse } from '../../../orderEntry/services/trade/sendEntry';

export type OrderHistoryMap = Map<string, OrderResponse>;

export const initialOrderHistoryMap: OrderHistoryMap = Map<string, OrderResponse>();

export const orderHistoryReducer = (existingOrderHistory: OrderHistoryMap,
  orderUpdate: OrderResponse): OrderHistoryMap => existingOrderHistory
  .withMutations((map) => map
    .set(map.size.toString(), { ...orderUpdate, historyID: map.size.toString() }));
