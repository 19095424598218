import React from 'react';
import { AlgoOrderEntrySuccess } from './AlgoOrderEntrySuccess';
import { AlgoOrderEntryUnsuccessful } from './AlgoOrderEntryUnsuccessful';
import { OrderEntryLoading } from '../trade/OrderEntryLoading';
import { OrderEntryFormTransitionsWrapper } from '../styled';
import { InstructionResponse } from '../../services/algos/InstructionResponse';

interface Props {
  latestEntryResponse: InstructionResponse | null
  isOrderLoading: boolean;
  setIsOverlayActive: (isOverlayActive: boolean) => void;
}

const getOverlay = (
  isLoading: boolean,
  latestEntryResponse: InstructionResponse,
  setIsOverlayActive: (isOverlayActive: boolean) => void,
) => {
  if (isLoading) {
    return <OrderEntryLoading />;
  }
  if (latestEntryResponse?.instructionStatus === 'NEW') {
    return <AlgoOrderEntrySuccess
      latestEntryMessage={latestEntryResponse}
      setIsOverlayActive={setIsOverlayActive}
    />;
  }
  if (latestEntryResponse.type === 'ERROR_MESSAGE') {
    return <AlgoOrderEntryUnsuccessful
      latestEntryMessage={latestEntryResponse}
      setIsOverlayActive={setIsOverlayActive}
    />;
  }
  return null;
};

export const AlgoOrderEntryTransitionScreens: React.FunctionComponent<Props> = (props) => (
  <OrderEntryFormTransitionsWrapper>
    {props.latestEntryResponse
      && getOverlay(props.isOrderLoading, props.latestEntryResponse, props.setIsOverlayActive)}
  </OrderEntryFormTransitionsWrapper>
);
