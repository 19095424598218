import React from 'react';
import {
  ConnectionStatus,
  useConnectionStatus,
} from '../websocket/connectionStatus';
import { useMobileCheck } from '../hooks/useMobileCheck';
import { StatusCircle } from '../style/styled';

const desktopStatusMessages = {
  [ConnectionStatus.CONNECTING]: 'Connecting...',
  [ConnectionStatus.OPEN]: 'All services connected',
  [ConnectionStatus.AUTHENTICATED]: 'All services connected',
  [ConnectionStatus.CLOSED]: 'Connection closed',
};

// slightly smaller status messages to accommodate the less space that the menu has
const mobileStatusMessages = {
  [ConnectionStatus.CONNECTING]: 'Connecting...',
  [ConnectionStatus.OPEN]: 'Connected',
  [ConnectionStatus.AUTHENTICATED]: 'Connected',
  [ConnectionStatus.CLOSED]: 'Not connected',
};

export const ConnectionStatusWidget: React.FC = () => {
  const connectionStatus = useConnectionStatus();

  const isMobile = useMobileCheck();

  return (
    <>
      <StatusCircle connectionStatus={connectionStatus} />
      <div>
        {isMobile
          ? mobileStatusMessages[connectionStatus]
          : desktopStatusMessages[connectionStatus]}
      </div>
    </>
  );
};
