import { Security } from './services/getSecurities$';
import { PositionRow } from '../components/orderGridsTabPanel/services/positions/positionsReducer';
import { ProductValue } from './ProductValue';

export const sortByMilliseconds = (date1: number, date2: number) => {
  if (!date1 && !date2) {
    return 0;
  }
  if (!date1) {
    return -1;
  }
  if (!date2) {
    return 1;
  }
  return date1 - date2;
};

export const sortByTimeString = (dateInput1: string, dateInput2: string) => {
  const date1 = new Date(dateInput1).getTime();
  const date2 = new Date(dateInput2).getTime();

  if (!date1 && !date2) {
    return 0;
  }
  if (!date1) {
    return -1;
  }
  if (!date2) {
    return 1;
  }
  return date1 - date2;
};

// sorts open position table rows by account, then by product, then by contract
export const sortPositionRows = (row1: PositionRow, row2: PositionRow) => {
  const { account: account1, product: product1, contract: contract1 } = row1;
  const { account: account2, product: product2, contract: contract2 } = row2;

  const accountCompare = account1.localeCompare(account2);
  const productCompare = product1.localeCompare(product2);
  const contractCompare = contract1.localeCompare(contract2);

  return accountCompare || productCompare || contractCompare;
};

export const sortSpotSecurities = (sec1: ProductValue,
  sec2: ProductValue) => (sec1.symbol < sec2.symbol ? -1 : 1);

export const sortFutureSecurities = (sec1: Security, sec2: Security) => {
  if (sec1.productCode === null) return -1;
  if (sec2.productCode === null) return 1;
  return sec1.productCode < sec2.productCode ? -1 : 1;
};

export const sortWatchlistFutureSecurities = (sec1: Security, sec2: Security) => {
  if (sec1.maturityDate === null) return -1;
  if (sec2.maturityDate === null) return 1;
  return sec1.maturityDate < sec2.maturityDate ? -1 : 1;
};

export const sortBoundedFuturesByAlpha = (sec1: Security, sec2: Security) => {
  if (sec1.symbol === null) return -1;
  if (sec2.symbol === null) return 1;
  return sec1.symbol < sec2.symbol ? -1 : 1;
};
