import { bind, shareLatest } from '@react-rxjs/core';
import { createListener, mergeWithKey } from '@react-rxjs/utils';
import { defer, Observable } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import {
  map, scan, startWith, switchMap,
} from 'rxjs/operators';

import { Config } from '../../../../config/config';

import { getToken } from '../../../../shared/websocket/token';
import { successfulCreateSettlementResponse$ } from '../AddNewSettlement/AddNewSettlementService';

import {
  ApiResponse,
  SettlementResponse,
  SettlementRows,
} from '../types';
import { settlementResponseToSettlementRowMapper } from '../SettlementList/typeMappers';
import { fetchResponseJsonHandler } from '../helpers/fetchResponseJsonHandler';

import {
  filterResponseIsComplete, filterResponseIsFailure,
  filterResponseIsSuccessful, useIsPendingResponse,
} from '../helpers/responseUtils';
import { successfulSettlementConfirmationResponse$ } from '../SettlementConfirmation/SettlementConfirmationService';

export const getSettlementsResponse$ = () => fromFetch(`${Config.CLEARING_REST_API_URL}v1/settlement_instructions`, {
  method: 'GET',
  headers: {
    authorization: `Bearer ${getToken()}`,
    'Content-Type': 'application/json',
  },
}).pipe(fetchResponseJsonHandler<SettlementResponse[]>());

export const [refreshSettlements$, refreshSettlements] = createListener();

const settlementResponse$: Observable<
ApiResponse<SettlementResponse[]>
> = refreshSettlements$.pipe(
  startWith(null),
  switchMap(getSettlementsResponse$),
  shareLatest(),
);

export const useSettlementIsLoading = useIsPendingResponse(settlementResponse$);

export const completedSettlementResponse$ = settlementResponse$.pipe(
  filterResponseIsComplete(),
);
const successfulSettlementResponse$ = completedSettlementResponse$.pipe(
  filterResponseIsSuccessful(),
);

export const [useSettlementsResponseFailure] = bind(
  completedSettlementResponse$.pipe(filterResponseIsFailure()),
  undefined,
);

const mergedSettlements$ = defer(() => mergeWithKey({
  allSettlements: successfulSettlementResponse$,
  newSettlement: successfulCreateSettlementResponse$,
  updatedSettlement: successfulSettlementConfirmationResponse$,
}).pipe(
  scan(
    (existing: SettlementRows, e) => {
      switch (e.type) {
        case 'allSettlements':
          return {
            settlements: e.payload,
          };
        case 'newSettlement':
          return {
            settlements: [
              ...(existing.settlements || []),
              settlementResponseToSettlementRowMapper(e.payload),
            ],
          };
        case 'updatedSettlement':
          return {
            error: false,
            settlements: [
              ...(existing.settlements || []).filter((s) => s.id !== e.payload.id),
              settlementResponseToSettlementRowMapper(e.payload),
            ],
          };

        default:
          return { settlements: [] };
      }
    },
    { settlements: undefined },
  ),
));

export const [useSettlements] = bind(mergedSettlements$, {
  settlements: undefined,
});

export const [
  settlmentModalVisibleFlag$,
  setAddSettlementModalVisible,
] = createListener<boolean>();

const addSettlementModalVisible$ = mergeWithKey({
  settlementResponse: successfulCreateSettlementResponse$,
  modalVisibleFlag: settlmentModalVisibleFlag$,
}).pipe(
  map((e) => {
    switch (e.type) {
      case 'settlementResponse':
        return false;

      case 'modalVisibleFlag':
        return e.payload;

      default:
        return false;
    }
  }),
);

export const [useAddSettlementModalVisible] = bind(
  addSettlementModalVisible$,
  false,
);
