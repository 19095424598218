import React, { ButtonHTMLAttributes } from 'react';
import { ButtonStyled, ColorTheme, DisabledIcon } from './Button.styled';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  colorTheme?: ColorTheme;
};

export const Button: React.FC<ButtonProps> = (props) => {
  const { disabled, children } = props;
  return (
    <ButtonStyled {...props}>
      {disabled ? <DisabledIcon /> : children}
    </ButtonStyled>
  );
};
