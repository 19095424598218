import React from 'react';
import { BaseGrid } from '../../../../../../shared/Grid/Grid';
import { SettlementRow } from '../../../types';
import {
  columnsWithActions,
  columnsWithoutActions,
} from './settlementsColDefs';
import { SettlementsGridAgGridStyleWrapper } from './SettlementsGrid.styled';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { ConfirmationActionButtons } from '../../../SettlementConfirmation/components/ConfirmationActionButtons/ConfirmationActionButtons';

import { SettlementDetailsPanel } from './components/SettlementDetailsPanel/SettlementDetailsPanel';
import { getRowHeight } from './components/SettlementDetailsPanel/helpers/getRowHeight';
import { getRowClass } from './components/SettlementDetailsPanel/helpers/getRowClass';
import { DetailsExpander } from './components/DetailsExpander/DetailsExpander';
import { onGridResize, onGridScroll } from './components/SettlementDetailsPanel/SettlementDetailsPanelService';

interface SettlementsGridProps {
  data: SettlementRow[] | undefined;
}

const SettlementsGrid: React.FC<
SettlementsGridProps & {
  showActions?: boolean;
  loadingMessage: string;
  noRowsMessage: string;
}
> = ({
  data, showActions, loadingMessage, noRowsMessage,
}) => (
  <SettlementsGridAgGridStyleWrapper className="ag-theme-alpine">
    <BaseGrid
      defaultColDef={{
        suppressMenu: true,
      }}
      detailCellRenderer={'settlementDetailsRenderer'}
      rowData={data}
      detailCellRendererParams={{}}
      columnDefs={showActions ? columnsWithActions : columnsWithoutActions}
      enableRangeSelection={false}
      frameworkComponents={{
        actionCellRenderer: ConfirmationActionButtons,
        settlementDetailsRenderer: SettlementDetailsPanel,
        detailsExpanderCellRenderer: DetailsExpander,
      }}
      onGridSizeChanged={onGridResize}
      gridOptions={{
        onColumnResized: onGridResize,
        onBodyScroll: onGridScroll,
        animateRows: true,
        getRowHeight,
        getRowClass,
        masterDetail: true,
        suppressMovableColumns: true,
        suppressCellSelection: true,
      }}
      getRowNodeId={(row: SettlementRow) => row.id + row.from_account_label + row.time
      }
      sideBar={undefined}
      noRowsMessage={noRowsMessage}
      loadingMessage={loadingMessage}
    />
  </SettlementsGridAgGridStyleWrapper>
);

export const HistoricalSettlementsGrid: React.FC<SettlementsGridProps> = (
  props,
) => (
  <SettlementsGrid
    {...props}
    noRowsMessage="No hisorical Settlement Instructions"
    loadingMessage="Loading Settlement Instructions"
  />
);

export const PendingSettlementsGrid: React.FC<SettlementsGridProps> = (
  props,
) => (
  <SettlementsGrid
    {...props}
    showActions
    noRowsMessage="No pending Settlement Instructions"
    loadingMessage="Loading Settlement Instructions"
  />
);
