import React from 'react';

export const SuccessIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
}> = ({ height = 16, width = 16 }) => (
  <svg width={width} height={height} viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>icons/event/success</title>
    <g id="Iconography" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-187.000000, -1774.000000)" id="Group">
        <g transform="translate(186.000000, 1773.000000)">
          <g id="icons/event/success" transform="translate(1.000000, 1.000000)">
            <circle id="Oval" fill="#01C38D" cx="11" cy="11" r="11"></circle>
            <path d="M15.8514258,6.24984356 C16.0569512,5.97962949 16.4536772,5.91917892 16.7375383,6.11482344 C17.0213993,6.31046796 17.0849029,6.68812096 16.8793775,6.95833504 L10.9528939,14.7501665 C10.7375893,15.0332377 10.3156227,15.0838388 10.0332943,14.8604423 L6.22894513,11.8501947 C5.9594402,11.6369449 5.9225672,11.2560985 6.14658699,10.9995503 C6.37060678,10.7430021 6.77068761,10.7079018 7.04019254,10.9211516 L10.3228138,13.5185742 L15.8514258,6.24984356 Z" id="Tick" fill="#FFFFFF"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
