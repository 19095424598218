import { Map } from 'immutable';
import { safeSubtract, safeAdd } from '../../../shared/mathFunctions';
import { OrderBookEntry } from './common';

export interface OrderUpdate {
  id: string,
  price: number,
  amount: number,
  updateAction: 'NEW' | 'DELETE';
}
export interface MarketDataMessage {
  type: 'MarketDataIncrementalRefresh',
  correlation: string;
  bids: [];
  offers: [];
}
export interface OrderBookTotalMaps {
  bids: Map<string, OrderUpdate>;
  bidsPrice: Map<number, OrderBookEntry>;
  bidTotal: number;
  offers: Map<string, OrderUpdate>;
  offersPrice: Map<number, OrderBookEntry>;
  offerTotal: number;
}

export const initialOrderBookMaps = {
  bids: Map<string, OrderUpdate>(),
  bidTotal: 0,
  offers: Map<string, OrderUpdate>(),
  bidsPrice: Map<number, OrderBookEntry>(),
  offersPrice: Map<number, OrderBookEntry>(),
  offerTotal: 0,
};

const entriesReducer = (entries: OrderUpdate[],
  entryMap: Map<string, OrderUpdate>,
  priceMap: Map<number, OrderBookEntry>,
  total: number) => {
  let newTotal = total;
  let newEntryMap = entryMap;
  let newPriceMap = priceMap;

  entries.forEach((entry) => {
    if (entry.updateAction === 'DELETE') {
      const oldEntry = newEntryMap.get(entry.id);
      if (oldEntry) {
        newTotal = safeSubtract(newTotal, oldEntry.amount);
        const oldPriceTotal = newPriceMap.get(oldEntry.price);
        if (oldPriceTotal !== undefined) {
          newPriceMap = newPriceMap
            .set(oldEntry.price,
              {
                price: oldEntry.price,
                amount: safeSubtract(oldPriceTotal.amount, oldEntry.amount),
              });
        }
      }
      newEntryMap = newEntryMap.delete(entry.id);
    } else {
      newEntryMap = newEntryMap.update(entry.id, (oldEntry) => {
        if (oldEntry) {
          newTotal = safeSubtract(newTotal, oldEntry.amount);
          const oldPriceTotal = newPriceMap.get(oldEntry.price);
          if (oldPriceTotal !== undefined) {
            newPriceMap = newPriceMap
              .set(oldEntry.price,
                {
                  price: oldEntry.price,
                  amount: safeSubtract(oldPriceTotal.amount, oldEntry.amount),
                });
          }
        }
        newTotal = safeAdd(newTotal, entry.amount);
        const oldTotal = newPriceMap.get(entry.price);
        if (oldTotal !== undefined) {
          newPriceMap = newPriceMap.set(entry.price, {
            price: entry.price,
            amount: safeAdd(oldTotal.amount, entry.amount),
          });
        } else {
          newPriceMap = newPriceMap.set(entry.price, {
            price: entry.price,
            amount: entry.amount,
          });
        }
        return entry;
      });
    }
  });

  return {
    newTotal,
    newPriceMap,
    newEntryMap,
  };
};

export const orderBookReducer = (orderBook: OrderBookTotalMaps,
  orderBookMessage: MarketDataMessage): OrderBookTotalMaps => {
  const newBids = entriesReducer(orderBookMessage.bids,
    orderBook.bids,
    orderBook.bidsPrice,
    orderBook.bidTotal);

  const newOffers = entriesReducer(orderBookMessage.offers,
    orderBook.offers,
    orderBook.offersPrice,
    orderBook.offerTotal);

  return {
    bids: newBids.newEntryMap,
    bidsPrice: newBids.newPriceMap,
    bidTotal: newBids.newTotal,
    offers: newOffers.newEntryMap,
    offersPrice: newOffers.newPriceMap,
    offerTotal: newOffers.newTotal,
  };
};
