import React from 'react';

export const ErisXLogo: React.FC<{
  width?: number;
  height?: number;
}> = ({ height = 52, width = 52 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" height={height} width={width} xmlSpace="preserve">
    <path d="M254.246 429.359H93.75v-58.003h160.496v58.003zm152.004-99.648H93.75V271.71h312.5v58.002zM254.246 130.418H93.75V72.414h160.496v58.004zm152.004 99.648H252.761v-58.004H406.25v58.004z" fill="#00ebb9"/>
  </svg>
);
