import { UserAccountRaw } from '../../../../../shared/services/accountsService';
import { SettlementRow } from '../../types';

export const getSettlementAccountsUserHasAccessTo = (
  settlement: SettlementRow,
  allAccounts?: UserAccountRaw[],
) => {
  const userAccounts = allAccounts?.map((a) => a.account_number) || [];
  const settlementAccounts = [
    settlement.to_account_label,
    settlement.from_account_label,
  ];
  return settlementAccounts.filter((a) => userAccounts.includes(a));
};
