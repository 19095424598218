import { Direction } from '../../shared/Direction';

export enum NotificationType {
  TRADE = 'TRADE',
  ORDER_CANCEL = 'ORDER_CANCEL',
  INSTRUCTION = 'INSTRUCTION',
  OTC_TRADE = 'OTC_TRADE',
  OTC_REJECTED = 'OTC_REJECTED',
  ORDER_CANCEL_REJECT = 'ORDER_CANCEL_REJECT',
}

export interface NewNotification {
  quantity: number;
  direction: Direction;
  symbol?: string;
  price?: number;
  clicked: boolean;
  notificationID: string;
  timeStamp: number;
  status: string;
  type: NotificationType;
}
