export const Colors = {
  primary: '#002C46',
  primary1: '#0C375A',
  primary2: '#134268',
  primary3: '#16446B',
  primary4: '#1C4B79',
  primary5: '#205080',

  secondary: '#FFFFFF',
  secondary1: '#E5E9EC',
  secondary2: '#B2BFC7',
  secondary3: '#99ABB5',
  secondary4: '#7F95A2',
  secondary5: '#668090',

  accentPrimary: '#00EBB9',
  accentPrimary1: '#02C09A',
  accentPrimary2: '#33EFC7',

  accentPositive: '#01C38D',
  accentPositive1: '#03A077',
  accentPositive2: '#0C9674',
  accentPositive3: '#99E7D1',

  accentBeware: '#FF8D00',
  accentBeware1: '#E57E00',
  accentBeware2: '#FFC57F',
  accentBeware3: '#FFE8CC',

  accentNegative: '#FF274B',
  accentNegative1: '#E62343',
  accentNegative2: '#FF92A4',
  accentNegative3: '#FFD3DB',

  successMain: '#4DB885',

  tradingBuy: '#2D95FF',
  tradingBuy1: '#BFDEFF',
  tradingBuy2: '#174A80',

  tradingSell: '#FF274B',
  tradingSell1: '#FFD3DB',
  tradingSell2: '#63223F',
  tradingSell3: '#322642',

  chartVolume: '#898991',
  errorMain: '#E83948',
};

export const themePalette = {
  palette: {
    primary: {
      main: Colors.primary,
      primary: Colors.primary,
      primary1: Colors.primary1,
      primary2: Colors.primary2,
      primary3: Colors.primary3,
      primary4: Colors.primary4,
      primary5: Colors.primary5,
    },
    secondary: {
      main: Colors.secondary,
      secondary: Colors.secondary,
      secondary1: Colors.secondary1,
      secondary2: Colors.secondary2,
      secondary3: Colors.secondary3,
      secondary4: Colors.secondary4,
      secondary5: Colors.secondary5,
    },
    accent: {
      accentPrimary: Colors.accentPrimary,
      accentPrimary1: Colors.accentPrimary1,
      accentPrimary2: Colors.accentPrimary2,

      accentPositive: Colors.accentPositive,
      accentPositive1: Colors.accentPositive1,
      accentPositive2: Colors.accentPositive2,
      accentPositive3: Colors.accentPositive3,

      accentBeware: Colors.accentBeware,
      accentBeware1: Colors.accentBeware1,
      accentBeware2: Colors.accentBeware2,
      accentBeware3: Colors.accentBeware3,

      accentNegative: Colors.accentNegative,
      accentNegative1: Colors.accentNegative1,
      accentNegative2: Colors.accentNegative2,
      accentNegative3: Colors.accentNegative3,
    },
    success: {
      main: Colors.successMain,
    },
    error: {
      main: Colors.errorMain,
    },
    trading: {
      tradingBuy: Colors.tradingBuy,
      tradingBuy1: Colors.tradingBuy1,
      tradingBuy2: Colors.tradingBuy2,

      tradingSell: Colors.tradingSell,
      tradingSell1: Colors.tradingSell1,
      tradingSell2: Colors.tradingSell2,
      tradingSell3: Colors.tradingSell3,
    },
    chart: {
      volume: Colors.chartVolume,
    },
  },
};

export type ThemePalette = typeof themePalette;
