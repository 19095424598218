import { Popover } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { format } from 'date-fns';
import React, {
  useState,
} from 'react';
import { AlertIcon } from '../../icons/AlertIcon';
import { CloseIcon } from '../../icons/CloseIcon';
import { NotificationIcon } from '../../icons/NotificationIcon';
import { SuccessIcon } from '../../icons/SuccessIcon';
import { formatNumber } from '../../shared/formatters';
import { copyToClipboard } from '../../shared/helperFunctions/copyToClipboard';
import { NewNotification, NotificationType } from './NewNotification';
import { useUnreadNotifications, updateNotification } from './notificationsService';
import {
  NotificationDropdownRow,
  NotificationDropdownTitle,
  NotificationDropdownWrapper,
  NotificationIconWrapper,
  NotificationDropdownTitleName,
  NotificationDropdownTitleButton,
  NotificationDropdownRowContent,
  NotificationDropdownRowDate,
  NotificationIconBadge,
  NotificationDropdownCloseIconWrapper,
  NotificationStatusRow,
  NotificationStatusRowLeft,
  NotificationStatusRowRight,
} from './styled';

interface Props {
}

const getNotificationText = (newNotification: NewNotification) => {
  if (newNotification.type === NotificationType.TRADE) {
    return `TRADE - ${newNotification.direction} ${formatNumber(newNotification.quantity)} ${newNotification.symbol} @ ${formatNumber(newNotification.price)}`;
  }
  if (newNotification.type === NotificationType.ORDER_CANCEL) {
    return <NotificationStatusRow>
      <NotificationStatusRowLeft>
        <AlertIcon width={14} height={14} />
        {' ORDER CANCELED'}
      </NotificationStatusRowLeft>
      <NotificationStatusRowRight>
        {'Copy ID '}
        <FileCopy id='copy' onClick={() => copyToClipboard(newNotification.notificationID)} />
      </NotificationStatusRowRight>
    </NotificationStatusRow>;
  }
  if (newNotification.type === NotificationType.INSTRUCTION && newNotification.status === 'CANCELED') {
    return <NotificationStatusRow>
      <NotificationStatusRowLeft>
        <AlertIcon width={14} height={14} />
        {' ALGO CANCELED'}
      </NotificationStatusRowLeft>
      <NotificationStatusRowRight>
        {'Copy ID '}
        <FileCopy id='copy' onClick={() => copyToClipboard(newNotification.notificationID)} />
      </NotificationStatusRowRight>
    </NotificationStatusRow>;
  }
  if (newNotification.type === NotificationType.INSTRUCTION && newNotification.status === 'COMPLETE') {
    return <NotificationStatusRow>
      <NotificationStatusRowLeft>
        <SuccessIcon width={14} height={14} />
        {' ALGO COMPLETED'}
      </NotificationStatusRowLeft>
      <NotificationStatusRowRight>
        {'Copy ID '}
        <FileCopy id='copy' onClick={() => copyToClipboard(newNotification.notificationID)} />
      </NotificationStatusRowRight>
    </NotificationStatusRow>;
  }
  if (newNotification.type === NotificationType.INSTRUCTION && newNotification.status === 'TRIGGERED') {
    return <NotificationStatusRow>
      <NotificationStatusRowLeft>
        <SuccessIcon width={14} height={14} />
        {' ALGO TRIGGERED'}
      </NotificationStatusRowLeft>
      <NotificationStatusRowRight>
        {'Copy ID '}
        <FileCopy id='copy' onClick={() => copyToClipboard(newNotification.notificationID)} />
      </NotificationStatusRowRight>
    </NotificationStatusRow>;
  }
  if (newNotification.type === NotificationType.OTC_TRADE) {
    return <NotificationStatusRow>
      <NotificationStatusRowLeft>
        <SuccessIcon width={14} height={14} />
        {'TRADE COMPLETED'}
      </NotificationStatusRowLeft>
      <NotificationStatusRowRight>
        {'Copy ID '}
        <FileCopy id='copy' onClick={() => copyToClipboard(newNotification.notificationID)} />
      </NotificationStatusRowRight>
    </NotificationStatusRow>;
  }
  if (newNotification.type === NotificationType.OTC_REJECTED) {
    return <NotificationStatusRow>
      <NotificationStatusRowLeft>
        <AlertIcon width={14} height={14} />
        {'TRADE REJECTED'}
      </NotificationStatusRowLeft>
      <NotificationStatusRowRight>
        {'Copy ID '}
        <FileCopy id='copy' onClick={() => copyToClipboard(newNotification.notificationID)} />
      </NotificationStatusRowRight>
    </NotificationStatusRow>;
  }
  if (newNotification.type === NotificationType.ORDER_CANCEL_REJECT) {
    return <NotificationStatusRow>
      <NotificationStatusRowLeft>
        <AlertIcon width={14} height={14} />
        {' ORDER CANCEL REJECTED'}
      </NotificationStatusRowLeft>
      <NotificationStatusRowRight>
        {'Copy ID '}
        <FileCopy id='copy' onClick={() => copyToClipboard(newNotification.notificationID)} />
      </NotificationStatusRowRight>
    </NotificationStatusRow>;
  }
  return '';
};

export const NotificationDropdown: React.FC<Props> = () => {
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const notifications = useUnreadNotifications()
    .valueSeq()
    .toArray()
    .sort((notificationA, notificationB) => notificationB.timeStamp - notificationA.timeStamp);

  const handleClick = (event: React.MouseEvent) => {
    // if (notifications.length) {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClear = (notification: NewNotification) => {
    if (notifications.length === 1) {
      setAnchorEl(null);
    }
    updateNotification({ ...notification, clicked: true });
  };

  const clearAll = () => {
    setAnchorEl(null);
    notifications
      .forEach((notification) => updateNotification({ ...notification, clicked: true }));
  };

  return (<>
    <NotificationIconWrapper
      selected={Boolean(anchorEl)}
      onClick={handleClick}
    >
      <NotificationIcon />
      {notifications.length
        ? <NotificationIconBadge>
          {notifications.length}
        </NotificationIconBadge>
        : null}
    </NotificationIconWrapper>
    <Popover
      id={'notificationPopup'}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl as Element}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {<NotificationDropdownWrapper>
        <NotificationDropdownTitle>
          <NotificationDropdownTitleName>NOTIFICATIONS</NotificationDropdownTitleName>
          <NotificationDropdownTitleButton onClick={() => clearAll()}>
            Clear all
            </NotificationDropdownTitleButton>
        </NotificationDropdownTitle>
        {notifications.map((notification) => (<NotificationDropdownRow
          key={notification.notificationID}
          direction={notification.direction}>
          <NotificationDropdownRowContent>
            {getNotificationText(notification)}
          </NotificationDropdownRowContent>
          <NotificationDropdownRowDate>
            {format(notification.timeStamp, 'hh:mma')}
          </NotificationDropdownRowDate>
          <NotificationDropdownCloseIconWrapper
            onClick={() => handleClear(notification)}
          >
            <CloseIcon width={18} height={18} />
          </NotificationDropdownCloseIconWrapper>
        </NotificationDropdownRow>))}
      </NotificationDropdownWrapper >}
    </Popover>
  </>);
};
