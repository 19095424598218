import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MenuItemStyled as SelectOption } from '../../../../shared/style/styled';
import { ErisXLogo } from '../../../../shared/assets/ErisXLogo';
import { DimmableContentWrapper, LinkButton } from '../../AccountManagement.styled';
import {
  SettlementConfirmationAction,
  SettlementConfirmationType,
} from '../types';
import {
  Container,
  Header,
  ConfirmationTitle,
  ConfirmationDate,
  ApprovingText,
  RejectingText,
  Body,
  AccountDetailsContainer,
  AccountDetails,
  TransferIcon,
  AccountLabel,
  Account,
  AssetDetails,
  AssetTable,
  Comments,
  CommentsLabel,
  Label,
  ConfirmButton,
  AccountSelector,
  SettlementAccountSelector,
} from './SettlementConfirmation.styled';
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage';

export interface SettlementConfirmationProps {
  errorMessage?: string;
  isBusy: boolean;
  settlementConfirmationAction: SettlementConfirmationAction;
  showAccountSelector: boolean;
  onCancel: () => void;
  onConfirm: (userSelectedAccount?: string) => void;
}

export const SettlementConfirmation: React.FC<SettlementConfirmationProps> = ({
  errorMessage,
  isBusy,
  settlementConfirmationAction: { type, settlement },
  showAccountSelector,
  onCancel,
  onConfirm,
}) => {
  const [userSelectedAccount, setUserSelectedAccount] = useState<string>(
    settlement.to_account_label,
  );
  const isApproval = type === SettlementConfirmationType.APPROVAL;
  return (
    <Container>
      <Header>
        <ErisXLogo width={40} height={40} />
        <ConfirmationTitle>
          You are{' '}
          {isApproval ? (
            <ApprovingText>approving</ApprovingText>
          ) : (
            <RejectingText>rejecting</RejectingText>
          )}{' '}
          details from
        </ConfirmationTitle>
        <ConfirmationDate>
          {format(parseISO(settlement.time), 'LLL do, yyyy @ H:mm')}
        </ConfirmationDate>
      </Header>
      <Body>
      <DimmableContentWrapper busy={isBusy}>
        <AccountDetailsContainer>
          <AccountDetails>
            <AccountLabel>From Account</AccountLabel>
            <Account>{settlement.from_account_label}</Account>
          </AccountDetails>
          <TransferIcon>
            <ArrowRightIcon />
          </TransferIcon>
          <AccountDetails>
            <AccountLabel>To Account</AccountLabel>
            <Account>{settlement.to_account_label}</Account>
          </AccountDetails>
        </AccountDetailsContainer>
        <AssetDetails>
          <AssetTable>
            <thead>
              <tr>
                <th>Asset</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
            {settlement.settlement.map((s) => (
              <tr key={s.asset_type}>
                <td>{s.asset_type}</td>
                <td>{s.amount}</td>
              </tr>
            ))}
            </tbody>
          </AssetTable>
        </AssetDetails>

        <CommentsLabel>Comments</CommentsLabel>
        <Comments>{settlement.text ?? 'None provided'}</Comments>

        {showAccountSelector && (
          <SettlementAccountSelector>
            <Label>Settlement Account</Label>
            <AccountSelector
              IconComponent={ExpandMoreIcon}
              MenuProps={{ MenuListProps: { disablePadding: true } }}
              placeholder="Select account"
              onChange={(e) => setUserSelectedAccount(e.target.value as string)}
              value={userSelectedAccount}
            >
              <SelectOption value={settlement.from_account_label}>
                {settlement.from_account_label}
              </SelectOption>
              <SelectOption value={settlement.to_account_label}>
                {settlement.to_account_label}
              </SelectOption>
            </AccountSelector>
          </SettlementAccountSelector>
        )}

        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </DimmableContentWrapper>
        <ConfirmButton
          colorTheme={isApproval ? 'primary' : 'danger'}
          onClick={() => onConfirm(showAccountSelector ? userSelectedAccount : undefined)}
          disabled={isBusy}
        >
          {isApproval ? 'Confirm' : 'Reject'} settlement details
        </ConfirmButton>
        <LinkButton onClick={onCancel}>Cancel</LinkButton>
      </Body>
    </Container>
  );
};
