export const handleMouseclickPosition = (e: React.MouseEvent) => {
  let x;
  let y;
  const root = document.getElementById('root');

  x = e.clientX + document.body.scrollLeft
    + document.documentElement.scrollLeft;
  y = e.clientY + document.body.scrollTop
    + document.documentElement.scrollTop;

  x -= root!.offsetLeft;
  y -= root!.offsetTop + 225;

  return {
    x,
    y,
  };
};

export const handleMouseclickPositionLeft = (e: React.MouseEvent) => {
  let x;
  let y;
  const root = document.getElementById('root');

  x = e.clientX + document.body.scrollLeft
    + document.documentElement.scrollLeft;
  y = e.clientY + document.body.scrollTop
    + document.documentElement.scrollTop;

  x -= root!.offsetLeft + 260;
  y -= root!.offsetTop + 225;

  return {
    x,
    y,
  };
};
