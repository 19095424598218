import React, { useMemo } from 'react';
import { bind } from '@react-rxjs/core';
import { TradeHistory } from './TradeHistory';
import { all24HourTrades$ } from './tradeHistoryService';
import { useSelectedMarket } from '../../shared/services/selectedMarketService';
import { TRADE_HISTORY_MAX_VISIBLE } from './constants';

const [useTradeHistory] = bind(all24HourTrades$, {
  trades: [],
  error: false,
  symbol: '',
});

export const TradeHistoryContainer: React.FC = () => {
  const tradeHistory = useTradeHistory();
  const market = useSelectedMarket();

  const tradeHistoryRows = useMemo(
    () => tradeHistory.trades.slice(0, TRADE_HISTORY_MAX_VISIBLE), [tradeHistory.trades],
  );

  return (
    <TradeHistory
      rows={tradeHistoryRows}
      market={market}
      error={tradeHistory.error}
    />
  );
};
