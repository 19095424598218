import React, { useMemo } from 'react';
import { bind } from '@react-rxjs/core';
import {
  all24HourTrades$,
  emptyTradeHistory,
} from '../../tradeHistory/tradeHistoryService';
import { TwentyFourHour } from './TwentyFourHour';
import { TwentyFourHourMobile } from './TwentyFourHourMobile';
import { useMobileCheck } from '../../../shared/hooks/useMobileCheck';
import { useSelectedMarket } from '../../../shared/services/selectedMarketService';
import { formatQuantity } from '../../../shared/formatters';
import { latestTradePrice$ } from '../../../shared/services/latestDataService';
import { TradeHistoryDetail } from '../../tradeHistory/types';
import { ProductValue } from '../../../shared/ProductValue';
import { getPriceValueColor } from '../../../shared/helperFunctions/getPriceChangeColor';
import { FUT } from '../../orderEntry/services/trade/sendEntry';

interface Props {}

const [useTwentyHourVolume] = bind(all24HourTrades$, emptyTradeHistory);
const [useLatestTradePrice] = bind(latestTradePrice$, undefined);

export const getTotalVolume = (
  trades: TradeHistoryDetail[],
  market?: ProductValue,
) => {
  const multiplier = market?.type !== FUT && market?.contractMultiplier
    ? market.contractMultiplier
    : 1;
  const volumeNumber = trades.reduce((volume, trade) => volume + trade.quantity, 0) * multiplier;
  return formatQuantity(volumeNumber, 0.01);
};

export const getTwentyFourHourPercent = (trades: TradeHistoryDetail[]) => trades.length
  && ((trades[0].price - trades[trades.length - 1].price) * 100)
    / trades[trades.length - 1].price;

export const TwentyFourHourContainer: React.FunctionComponent<Props> = () => {
  const twentyFourHourData = useTwentyHourVolume();
  const { trades } = twentyFourHourData;
  const marketData = useSelectedMarket();
  const latestTradePrice = useLatestTradePrice();

  const twentyFourVolume = useMemo(() => getTotalVolume(trades, marketData), [
    marketData,
    trades,
  ]);

  const twentyFourPricePercent = useMemo(
    () => getTwentyFourHourPercent(trades),
    [trades],
  );

  const isMobile = useMobileCheck();

  if (!marketData || marketData?.symbol !== twentyFourHourData.symbol) {
    return null;
  }

  return !isMobile ? (
    <TwentyFourHour
      volume={twentyFourVolume}
      currency={marketData.currency}
      percent={twentyFourPricePercent}
      color={getPriceValueColor(twentyFourPricePercent)}
      minPriceIncrement={marketData.minPriceIncrement}
      latestPrice={latestTradePrice}
      marketType={marketData.type}
    />
  ) : (
    <TwentyFourHourMobile
      volume={twentyFourVolume}
      currency={marketData.currency}
      percent={twentyFourPricePercent}
      color={getPriceValueColor(twentyFourPricePercent)}
      minPriceIncrement={marketData.minPriceIncrement}
      latestPrice={latestTradePrice}
      marketType={marketData.type}
    />
  );
};
