import { sub } from 'date-fns';
import { UTCTimestamp } from 'lightweight-charts';

export const candleGraphRange = 130;
export const candleGraphVisibleRange = 130;

export type CandleGranularity = 1 | 5 | 15 | 60 | 1440;

export const toTimeStamp = (date: Date) => Math.floor(date.getTime() / 1000) as UTCTimestamp;

export const toGranularity = (resolution: string): CandleGranularity => {
  if (resolution === '1D') {
    return 1440 as CandleGranularity;
  }
  return parseInt(resolution, 10) as CandleGranularity;
};

export const toCandleTimestamp = (date: Date,
  granularity: CandleGranularity) => {
  const candleRange = granularity * 60;
  const tradeTimestamp = toTimeStamp(date);
  return tradeTimestamp - (tradeTimestamp % candleRange) as UTCTimestamp;
};

export const getPriceScale = (priceIncrement: number): number => {
  if (Math.floor(priceIncrement) !== priceIncrement) {
    const decimalPlaces = priceIncrement.toString().split('.')[1]?.length || 0;
    return 10 ** decimalPlaces;
  }
  return priceIncrement;
};

export const calcCandlesDateRange = (date: Date, granularity: CandleGranularity, range: number) => {
  const newDate = sub(date, {
    minutes: (granularity * range),
  });
  return newDate;
};

export const parseYYYYMMDD = (date: string) => {
  if (!date) return '';

  const year = date.substring(0, 4);
  const month = date.substring(4, 6);
  const day = date.substring(6, 8);

  return `${year}-${month}-${day}`;
};
