import React from 'react';

export const PencilIcon: React.FunctionComponent<{
  width?: number;
  height?: number;
  className?: string;
}> = ({ height = 24, width = 24, className = 'pencil-icon' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF">
        <path d="M18.648 7.62L17.37 6.34c-.456-.455-1.249-.455-1.703 0l-8.302 8.31-.072.071c-.27.27-.462.61-.555.981l-.73 2.923c-.026.102.004.21.08.286.056.057.133.088.212.088.024 0 .049-.003.073-.009l2.92-.73c.37-.093.71-.285.98-.556l.071-.071.001-.001 7.237-7.244 1.065-1.065c.47-.47.47-1.234 0-1.704zm-8.515 8.22h-.976v-.976c0-.167-.135-.302-.301-.302h-.55l6.51-6.516 2.129 2.13-6.511 6.517v-.551c0-.166-.135-.301-.301-.301zm-.985 1.835l-1.195.3c-.09-.205-.215-.395-.375-.555-.16-.16-.349-.285-.553-.375l.298-1.196c.065-.258.197-.493.382-.684h.85v.977c0 .166.134.301.3.301h.977v.85c-.19.185-.426.318-.684.382zm9.074-8.777l-.851.852-2.13-2.13.852-.853c.114-.113.265-.176.426-.176.16 0 .312.063.426.176l1.277 1.279c.235.235.235.617 0 .852z" />
      </g>
    </g>
  </svg>
);
