import { Config } from '../../config/config';
import { formatQuantityRoundDown, getLocation } from '../../shared/formatters';
import { correlationId } from '../../shared/helperFunctions/correlationId';
import { getToken } from '../../shared/websocket/token';
import {
  CancelQuoteRequest,
  OTCStreamQuoteRequest,
  OTCStreamQuoteResponse,
  QuoteResponseId,
  RequestQuoteFormState,
  STREAMING_QUOTE_CLOSE_REQUEST,
  STREAMING_QUOTE_REQUEST,
  TradeFormState,
  TradeRequest,
  TRADE_REQUEST,
  TransformedOTCStreamQuoteResponse,
} from './types';

export const transformFormStateToRequest = ({
  selectedCurrency,
  selectedProduct,
  quantity,
}: RequestQuoteFormState): OTCStreamQuoteRequest => ({
  type: STREAMING_QUOTE_REQUEST,
  correlation: correlationId(),
  ticker: selectedProduct!.replace('/', '_'),
  quantity: {
    quantity,
    currency: selectedCurrency!,
  },
  externalIdentity: {
    counterpartyId: Config.OTC_EXT_IDENT_COUNTER_PARTY_ID,
    userId: Config.OTC_EXT_IDENT_USER_ID,
  },
});

const transformOTCStreamQuoteResponse = ({
  quoteRequest: {
    ticker,
    quantity: { currency, quantity },
    counterpartyRequestId,
  },
  buyUnitPrice: { price: bidPrice },
  sellUnitPrice: { price: offerPrice },
  status,
  reason,
  buyQuantity: bidQuantity,
  sellQuantity: offerQuantity,
  buyTotalAmount: bidTotalAmount,
  sellTotalAmount: offerTotalAmount,
  quoteResponseId,
}: OTCStreamQuoteResponse): TransformedOTCStreamQuoteResponse => ({
  counterpartyRequestId,
  quoteRequest: {
    symbol: ticker.replace('_', '/'),
    quantity,
    currency,
  },
  bidPrice,
  offerPrice,
  status,
  reason,
  bidQuantity,
  offerQuantity,
  quoteResponseId,
  bidTotalAmount: {
    quantity: bidTotalAmount.amount,
    currency: bidTotalAmount.currency,
  },
  offerTotalAmount: {
    quantity: offerTotalAmount.amount,
    currency: offerTotalAmount.currency,
  },
});

// reorders map when user DnD + transform the response into better form
export const transformResponseMapWithOrdering = (
  response: Map<string, OTCStreamQuoteResponse>,
  orderedIds: Set<string> | null,
) => (orderedIds
  ? new Map(
    [...orderedIds].map((counterpartyRequestId) => [
      counterpartyRequestId,
      transformOTCStreamQuoteResponse(response.get(counterpartyRequestId)!),
    ]),
  )
  : new Map(
    [...response].map(([counterpartyRequestId, otcStreamResponse]) => [
      counterpartyRequestId,
      transformOTCStreamQuoteResponse(otcStreamResponse),
    ]),
  ));

export const transformFormStateToTradeRequest = (
  formState: TradeFormState,
  roundLot: number,
): TradeRequest => ({
  type: TRADE_REQUEST,
  correlation: correlationId(),
  token: getToken(),
  quantity: {
    currency: formState.currency,
    quantity: Number(formatQuantityRoundDown(formState.amount, roundLot)),
  },
  counterpartyAction: formState.direction,
  quoteResponseId: formState.quoteResponseId,
  // form ensures these values are set before submission
  senderLocationId: getLocation(formState.userPrefs)!,
  senderSubId: formState.userPrefs.traderId,
  accountNumber: formState.accountNumber,
});

export const createCancelQuoteRequest = (
  counterpartyRequestId: string,
  quoteResponseId: QuoteResponseId,
): CancelQuoteRequest => ({
  type: STREAMING_QUOTE_CLOSE_REQUEST,
  correlation: correlationId(),
  quoteResponseId,
  counterpartyRequestId,
});
