import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { ContentWithTooltip } from './ContentWithTooltip';

type Props = TextFieldProps & { errorText?: string };

// wraps a text input field with a tooltip that shows when hovered over
export const TextFieldWithTooltip : React.FC<Props> = ({ errorText, ...props }) => (
  <ContentWithTooltip tooltipText={errorText || ''}>
    <TextField {...props} />
  </ContentWithTooltip>
);
