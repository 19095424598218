import { logger } from '../logger';

export const copyToClipboard = async (text: string) => {
  try {
    // @ts-ignore
    await navigator.clipboard.writeText(text);
    logger.debug('Copied to clipboard');
    return true;
  } catch (err) {
    logger.error('Failed to copy: ', err);
    return false;
  }
};
