import bigInt from 'big-integer';
import {
  formatNumber,
  formatDateFromMilliseconds,
  pegOrOOf,
} from '../../../shared/formatters';
import { AlgoCancelCell } from '../gridComponents/AlgoCancelCell';
import { GridColDef } from '../../../shared/Grid/GridColDef';
import { DirectionCell } from '../gridComponents/DirectionCell';
import { sortByMilliseconds } from '../../../shared/comparators';
import { InstructionResponse } from '../../orderEntry/services/algos/InstructionResponse';

export const algoInstructionColDef = [
  {
    headerName: 'ACTIONS',
    cellRendererFramework: AlgoCancelCell,
    colId: 'actions',
    width: 77,
    cellClass: 'ag-grid-no-padding-cell',
    sortable: false,
    floatingFilter: false,
    valueGetter: (params) => params.data,
  } as GridColDef<InstructionResponse>,
  {
    headerName: 'TIME',
    colId: 'time',
    valueGetter: (params) => params.data.timeInMilliseconds,
    valueFormatter: (params) => params.value && formatDateFromMilliseconds(params.value),
    comparator: sortByMilliseconds,
    width: 150,
    filter: 'agTextColumnFilter',
    sort: 'desc',
    sortable: true,
  } as GridColDef<InstructionResponse>,
  {
    headerName: 'STATUS',
    colId: 'status',
    valueGetter: (params) => params.data.instructionStatus,
    width: 79,
    filter: 'agTextColumnFilter',
  } as GridColDef<InstructionResponse>,
  {
    headerName: 'TYPE',
    colId: 'type',
    valueGetter: (params) => params.data.type,
    valueFormatter: (params) => params.value && pegOrOOf(params.value),
    width: 79,
    filter: 'agTextColumnFilter',
  } as GridColDef<InstructionResponse>,
  {
    headerName: 'CONTRACT',
    colId: 'contract',
    valueGetter: (params) => params.data.symbol,
    width: 79,
    filter: 'agTextColumnFilter',
  } as GridColDef<InstructionResponse>,
  {
    headerName: 'QTY',
    colId: 'qty',
    valueGetter: (params) => params.data.orderQty,
    valueFormatter: (params) => params.value && formatNumber(params.value),
    cellClass: 'ag-grid-number-cell',
    width: 90,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<InstructionResponse>,
  {
    headerName: 'SIDE',
    colId: 'side',
    valueGetter: (params) => params.data.side,
    width: 60,
    cellRendererFramework: DirectionCell,
    cellClass: 'ag-grid-no-padding-cell',
    sortable: false,
    filter: 'agTextColumnFilter',
    filterValueGetter: (params) => params.data.side,
  } as GridColDef<InstructionResponse>,
  {
    headerName: 'OFFSET',
    colId: 'price',
    valueGetter: (params) => params.data.offset,
    valueFormatter: (params) => params.value && formatNumber(params.value),
    cellClass: 'ag-grid-number-cell',
    width: 70,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<InstructionResponse>,
  {
    headerName: 'LIMIT PRICE',
    colId: 'qtyFilled',
    valueGetter: (params) => params.data.price,
    valueFormatter: (params) => params.value && formatNumber(params.value),
    cellClass: 'ag-grid-number-cell',
    width: 100,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<InstructionResponse>,
  {
    headerName: 'ACCOUNT',
    colId: 'account',
    valueGetter: (params) => params.data.accountNumber,
    width: 90,
    hide: true,
    filter: 'agTextColumnFilter',
  } as GridColDef<InstructionResponse>,
  {
    headerName: 'MESSAGE',
    colId: 'message',
    valueGetter: (params) => params.data.message,
    width: 150,
    filter: 'agTextColumnFilter',
  } as GridColDef<InstructionResponse>,
  {
    headerName: 'ID',
    colId: 'id',
    valueGetter: (params) => bigInt(params.data.responseID),
    width: 120,
    hide: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<InstructionResponse>,
  {
    headerName: 'ITERATIONS',
    colId: 'oofIterations',
    valueGetter: (params) => params.data.oofIterations,
    width: 80,
    hide: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<InstructionResponse>,
  {
    headerName: 'CURRENT ITERATION',
    colId: 'oofIterationsCounter',
    valueGetter: (params) => params.data.oofIterationsCounter,
    width: 120,
    hide: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<InstructionResponse>,
  {
    headerName: 'CANCEL ON DISCONNECT',
    colId: 'cancelOnDisconnect',
    valueGetter: (params) => (params.data.cancelOnDisconnect === 'Y' ? 'YES' : 'NO'),
    width: 130,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'startsWith',
    },
  } as GridColDef<InstructionResponse>,
];
