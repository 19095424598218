import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { withSubscribe } from '../../../../../../../../shared/helperFunctions/withSubscribe';
import { SettlementRow } from '../../../../../types';

import {
  AssetDetails,
  Container,
  Row,
} from './SettlementDetailsPanel.styled';
import { leftPosition$, useLeftPosition } from './SettlementDetailsPanelService';

interface SettlementDetailsPanelProps extends ICellRendererParams {
  data: SettlementRow;
}

const Component: React.FC<SettlementDetailsPanelProps> = ({
  data,
}) => {
  const offset = useLeftPosition();

  return (
  <Container>
    {data.settlement.map((s) => (
      <Row key={s.asset_type} offset={offset - 30}>
          <AssetDetails>
            Asset: <strong>{s.asset_type}</strong> | Amount:{' '}
            <strong>{s.amount}</strong>
          </AssetDetails>
      </Row>
    ))}
  </Container>);
};

export const SettlementDetailsPanel = withSubscribe(
  Component,
  leftPosition$,
  <></>,
);
