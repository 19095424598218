import { Colors } from '../style/theme';

export const getPriceValueColor = (value: number) => {
  if (Math.sign(value) === 0) {
    return Colors.secondary;
  }
  return Math.sign(value) < 0
    ? Colors.tradingSell
    : Colors.accentPositive;
};
