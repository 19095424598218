import styled from 'styled-components';

import { DownArrowIcon } from '../../icons/DownArrowIcon';
import { UpArrowIcon } from '../../icons/UpArrowIcon';
import { Panel } from '../../shared/components/Panel';
import { Direction } from '../../shared/Direction';
import { ThemePalette } from '../../shared/style/theme';

// TradeHistory components
const headerHeight = 28;

export const TradeHistoryPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  border-right: none;
`;

export const Table = styled.div<{ showError: boolean }>`
  flex-grow: 1;
  display: grid;
  grid-template-rows: ${headerHeight}px auto ${({ showError }) => (showError ? `${headerHeight}px` : '')};
`;

export const TableHeaders = styled.div`
  height: ${headerHeight}px;
  line-height: ${headerHeight}px;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.primary.primary1}`};
  display: flex;
  font-family: Roboto;
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
`;

export const PriceHeader = styled.div`
  width: 66px;
`;

export const QuantityHeader = styled.div`
  width: 85px;
`;

export const TimeHeader = styled.div`
  width: 85px;
  padding-right: 8px;
`;

export const Body = styled.div`
  > :first-child div:first-of-type {
    font-weight: bold;
  }
  overflow: scroll;

  &:not(:hover) {
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border: 0;
      box-shadow: none;
    }
  }
`;

// TradeHistoryRow components
const priceWidth = 38;
const quantityWidth = 117;
const timeWidth = 117;

const getDirectionColor = (direction: Direction, theme: ThemePalette) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  (direction === Direction.Buy
    ? theme.palette.trading.tradingBuy
    : theme.palette.trading.tradingSell);

const getRowHighlightColor = (
  direction: Direction | null,
  theme: ThemePalette,
) => (direction === Direction.Buy
  ? theme.palette.primary.primary1
  : theme.palette.trading.tradingSell3);

export const Row = styled.div<{
  direction: Direction | null;
  highlightRow: boolean;
}>`
  height: 22px;
  line-height: 22px;
  display: flex;
  background-color: ${({ theme, direction, highlightRow }) => (highlightRow
    ? getRowHighlightColor(direction, theme)
    : theme.palette.primary.primary)};
  border-left: 2px solid
    ${({ direction, theme }) => {
    if (direction) {
      return direction === Direction.Buy
        ? theme.palette.trading.tradingBuy
        : theme.palette.trading.tradingSell;
    }

    return 'none';
  }};
`;

const Cell = styled.div`
  font-family: Roboto Mono;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
`;

export const PriceCell = styled(Cell)<{ direction: Direction | null }>`
  width: ${priceWidth}px;
  color: ${({ direction, theme }) => (direction === null ? 'white' : getDirectionColor(direction, theme))};
  font-weight: bold;
`;

export const QuantityCell = styled(Cell)`
  width: ${quantityWidth}px;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  font-weight: bold;
`;

export const TimeCell = styled(Cell)`
  width: ${timeWidth}px;
  color: ${({ theme }) => theme.palette.secondary.secondary3};
  padding-right: 8px;
`;

export const DownArrow = styled(DownArrowIcon)`
  margin-top: auto;
  margin-bottom: auto;
  color: ${({ theme }) => theme.palette.trading.tradingSell};
  transform: rotate(45deg);
`;

export const UpArrow = styled(UpArrowIcon)`
  margin-top: auto;
  margin-bottom: auto;
  color: ${({ theme }) => theme.palette.trading.tradingBuy};
  transform: rotate(-45deg);
`;

// blank by default, but has styling applied in mobile layout
export const IconWrapper = styled.div<{ direction: Direction | null }>`
  opacity: ${({ direction }) => (direction ? '1' : '0')};
`;
