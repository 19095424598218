import { isMobileOnly } from 'react-device-detect';
import { useWindowSize } from './useWindowSize';
import { MOBILE_BREAKPOINT, getBreakpointNumber } from '../helperFunctions/windowSizeUtils';

export const useMobileCheck = () => {
  const { width, height } = useWindowSize();
  // we want to be able to detect mobile in landscape and portrait, so check both dimensions
  const isMobileSize = getBreakpointNumber(height) === MOBILE_BREAKPOINT
    || getBreakpointNumber(width) === MOBILE_BREAKPOINT;

  return isMobileOnly || isMobileSize;
};
