import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { OrderEntryCancelButton } from '../../orderEntry/styled';
import { OrderEntrySummary, OrderEntryCloseButton } from '../../orderEntry/transitionScreens/styled';
import { ThemedCheckbox } from '../../../shared/components/ThemedCheckbox';
import { ContentWithTooltip } from '../../../shared/components/ContentWithTooltip';

interface Props {
  close: () => void;
  handleClose: () => void;
  handleCancelOrder: () => void;
  handleCancelAlgo: () => void;
  handleCancelBuy: () => void;
  handleCancelSell: () => void;
  isTradeConnected: boolean;
  isAlgoConnected: boolean;
}

export const CancelConfirmWrapper = styled.div`
    border-radius: 3px;
    background-color: ${({ theme }) => theme.palette.primary.primary1};
    padding: 4px 5px;
    z-index: 100000;
    min-width: 100px;
    min-height: 100px;
    border: 1px solid ${({ theme }) => theme.palette.secondary.secondary};
`;

export const OrderOptionsWrapper = styled.div`
    position: relative;
    left: 15px;
    padding-bottom: 2.5px;
    display: grid;
    grid-template-columns: 20px 30px;
    grid-column-gap: 15px;
`;

export const MainOptionsWrapper = styled.div`
    padding-top: 2.5px;
    padding-bottom: 1px;
    display: grid;
    grid-template-columns: 20px 100px;
    grid-column-gap: 15px;
`;

const CancelConfirmPopupWrapper = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  padding: 15px 10px 10px 10px;

  ${OrderEntrySummary}{
    margin: 12px;
    word-break: break-word;
  }
`;

const CancelConfirmPopupButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px 95px;
  grid-column-gap: 30px;
  align-items: center;
  justify-content: center;
  padding-top: 10px; 

  ${OrderEntryCloseButton}{
    display: flex;
    padding: 5px;
    margin-top: -1px;
    margin-bottom: 0px;
  }
`;

const CheckboxesWrapper = styled.div`
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  padding-left: 15px;
`;

const StyledLabel = styled.label`
  padding-top: 3px;
`;

const CancelConfirmPopupTitle = styled.div`
  display: flex;
  justify-content: center;
  font-size: 13px;
  color: ${({ theme }) => theme.palette.secondary.secondary};
  font-weight: 600;
  word-break: break-word;
`;

interface CheckboxProps {
  checked: boolean,
  disabled: boolean,
  onCheckboxClick: () => void,
}

const CancelCheckbox: React.FC<CheckboxProps> = ({ checked, disabled, onCheckboxClick }) => (
  <ContentWithTooltip tooltipText={disabled ? 'Websocket connection is closed. Please try again later.' : ''}>
    <span>
      <ThemedCheckbox
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={onCheckboxClick}
      />
    </span>
  </ContentWithTooltip>
);

export const CancelAllConfirmation: React.FunctionComponent<Props> = (props) => {
  const [orderCheckBox, setOrderCheckBox] = useState(true);
  const [buyCheckBox, setBuyCheckBox] = useState(true);
  const [sellCheckBox, setSellCheckBox] = useState(true);
  const [algoCheckBox, setAlgoCheckBox] = useState(true);

  const isOrderChecked = props.isTradeConnected && orderCheckBox;
  const isBuyChecked = props.isTradeConnected && buyCheckBox;
  const isSellChecked = props.isTradeConnected && sellCheckBox;
  const isAlgoChecked = props.isAlgoConnected && algoCheckBox;

  useEffect(() => {
    if (!orderCheckBox) {
      setBuyCheckBox(false);
      setSellCheckBox(false);
    } else {
      setBuyCheckBox(true);
      setSellCheckBox(true);
    }
  }, [orderCheckBox]);

  return (
    <CancelConfirmWrapper>
      <CancelConfirmPopupWrapper>
        <CancelConfirmPopupTitle>
          Cancel All Orders Confirmation
        </CancelConfirmPopupTitle>
        <OrderEntrySummary>
          {' Select what you would like to cancel:'}
        </OrderEntrySummary>
        <CheckboxesWrapper>
          <MainOptionsWrapper>
            <CancelCheckbox
              checked={isOrderChecked}
              onCheckboxClick={() => setOrderCheckBox((current) => !current)}
              disabled={!props.isTradeConnected}
            />
            <StyledLabel>
              Orders
                </StyledLabel>
          </MainOptionsWrapper>
          {orderCheckBox && props.isTradeConnected && (
            <OrderOptionsWrapper>
              <CancelCheckbox
                checked={isBuyChecked}
                onCheckboxClick={() => setBuyCheckBox((current) => !current)}
                disabled={!props.isTradeConnected}
              />
              <StyledLabel>Buys</StyledLabel>
              <CancelCheckbox
                checked={isSellChecked}
                onCheckboxClick={() => setSellCheckBox((current) => !current)}
                disabled={!props.isTradeConnected}
              />
              <StyledLabel>Sells</StyledLabel>
            </OrderOptionsWrapper>
          )}
          <MainOptionsWrapper>
            <CancelCheckbox
              checked={isAlgoChecked}
              onCheckboxClick={() => setAlgoCheckBox((current) => !current)}
              disabled={!props.isAlgoConnected}
            />
            <StyledLabel>Algo Instructions</StyledLabel>
          </MainOptionsWrapper>
        </CheckboxesWrapper>
        <CancelConfirmPopupButtonsWrapper>
          <OrderEntryCloseButton
            onClick={() => props.close()}
          >
            CLOSE
              </OrderEntryCloseButton>
          <OrderEntryCancelButton
            type='button'
            onClick={(e) => {
              e.preventDefault();
              if (isBuyChecked && isSellChecked) props.handleCancelOrder();
              if (isBuyChecked) props.handleCancelBuy();
              if (isSellChecked) props.handleCancelSell();
              if (isAlgoChecked) props.handleCancelAlgo();
              props.handleClose();
            }}>
            {'Cancel Orders'}
          </OrderEntryCancelButton>
        </CancelConfirmPopupButtonsWrapper>

      </CancelConfirmPopupWrapper>
    </CancelConfirmWrapper>
  );
};
