import React, { useEffect } from 'react';
import { bind } from '@react-rxjs/core';
import { GridApi } from 'ag-grid-community';

import { getWarningMessageAsHtmlString } from '../../../shared/components/WarningMessage';
import { Grid } from '../../../shared/Grid/Grid';
import { blockTradesColDefs } from '../colDefs/blockTradesColDefs';
import { BlockTradeRecord } from '../services/blockTrades/blockTradesReducer';
import {
  blockTradeError$,
  BlockTradesResponse,
  historicalBlockTradeCall$,
  useBlockTrades,
} from '../services/blockTrades/blockTradesService';

interface Props {
  isFilterHidden: boolean;
  changeNumber: (numberOfFilter: number) => void;
  gridAPI: React.MutableRefObject<GridApi | undefined>;
}

const [useBlockErrors] = bind(blockTradeError$, null);
const [useBlockCalls] = bind(historicalBlockTradeCall$, null);

export const BlockTradesGrid: React.FunctionComponent<Props> = (props) => {
  const blockTrades = useBlockTrades();
  const blockErrors = useBlockErrors();
  const blockCalls = useBlockCalls();
  useEffect(() => {
    const localApi = props.gridAPI.current;
    if (localApi) localApi.showNoRowsOverlay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockErrors]);
  const getNoRowMessage = (
    error: BlockTradesResponse | null,
    call: BlockTradesResponse | null,
  ) => {
    if (error?.error) {
      return getWarningMessageAsHtmlString('Block Trades could not be loaded');
    }
    if (call) {
      return 'No Rows to Show';
    }
    return 'Loading Block Trades ...';
  };

  return (
    <Grid
      columnDefs={blockTradesColDefs}
      rowData={blockTrades?.valueSeq().toArray()}
      getRowNodeId={(row: BlockTradeRecord) => row.request_id}
      suppressCellSelection={true}
      isFilterHidden={props.isFilterHidden}
      onFilterChanged={(event) => props.changeNumber(Object.keys(event.api.getFilterModel()).length)
      }
      gridApiRef={props.gridAPI}
      noRowsMessage={getNoRowMessage(blockErrors, blockCalls)}
    ></Grid>
  );
};
