import React from 'react';
import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import styled from 'styled-components/macro';
import { Direction } from '../../../shared/Direction';
import { UpArrow, DownArrow } from '../../tradeHistory/styled';

const DirectionCellWrapper = styled.div`
    display: grid;
    flex: 1;
    grid-template-columns: 20px 30px;
    align-items: center;
    justify-content: left;
    margin-left: -5px;
`;

const NACell = styled.div`
  position: relative;
  top: 6px;
  left: 1px;
`;

export const DirectionCellValue = styled.div<{ direction: Direction }>`
    font-size: 11px;
    color: ${({ theme, direction }) => (direction === Direction.Buy ? theme.palette.trading.tradingBuy : theme.palette.trading.tradingSell)};
`;

interface DirectionCellState {
  side?: Direction,
}

export class DirectionCell extends React.Component<ICellRendererParams, DirectionCellState>
  implements ICellRenderer {
  constructor(props: ICellRendererParams) {
    super(props);
    this.state = {
      side: props.value as Direction,
    };
  }

  public render() {
    const DirectionIcon = this.state.side === Direction.Buy ? <UpArrow /> : <DownArrow />;
    return (
      this.state.side ? (
        <DirectionCellWrapper>
          {DirectionIcon}
          <DirectionCellValue direction={this.state.side}>{this.state.side}</DirectionCellValue>
        </DirectionCellWrapper>) : <NACell>{'N/A'}</NACell>
    );
  }

  public refresh(params: ICellRendererParams): boolean {
    this.setState({
      side: params.value as Direction,
    });
    return true;
  }
}
