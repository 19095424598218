import React from 'react';
import { bind } from '@react-rxjs/core';
import { GridApi } from 'ag-grid-community';
import { Grid } from '../../../shared/Grid/Grid';
import { algoInstructionColDef } from '../colDefs/algoInstructionColDef';
import { algoInstructionState$ } from '../services/algoInstructions/algoInstructionsService';
import { InstructionResponse } from '../../orderEntry/services/algos/InstructionResponse';

interface Props {
  isFilterHidden: boolean;
  changeNumber: (numberOfFilter: number) => void;
  gridAPI: React.MutableRefObject<GridApi | undefined>;
}

const [useAlgoInstructions] = bind(algoInstructionState$, null);
export const AlgoInstructionsGrid: React.FunctionComponent<Props> = (props) => {
  const algoInstructions = useAlgoInstructions();
  return (
    <Grid
      columnDefs={algoInstructionColDef}
      rowData={algoInstructions?.valueSeq().toArray()}
      getRowNodeId={(row: InstructionResponse) => row.responseID.toString()}
      suppressCellSelection={true}
      isFilterHidden={props.isFilterHidden}
      onFilterChanged={(event) => props.changeNumber(Object.keys(event.api.getFilterModel()).length)
      }
      gridApiRef={props.gridAPI}
    ></Grid>
  );
};
