import { Button, InputLabel, TextField } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import { bind } from '@react-rxjs/core';
import { createListener } from '@react-rxjs/utils';
import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AlertIcon } from '../../icons/AlertIcon';
import { Direction } from '../../shared/Direction';
import { formatThousandSeparator } from '../../shared/formatters';
import { withSubscribe } from '../../shared/helperFunctions/withSubscribe';
import {
  areUserPrefsSet$,
  setShowUserPrefs,
  userPrefsState$,
} from '../navbar/services/userPrefsService';
import { selectedAccount$ } from '../../shared/services/accountsService';
import { submitCancelQuote, submitTrade$, submitTradeRequest } from './service';
import {
  GridRowContainer,
  GridRowFormContainer,
  TradeButton,
  TradeButtonsContainer,
  ValidationWrapper,
} from './styled';
import { TransformedOTCStreamQuoteResponse } from './types';

function getErrorText(reason: string) {
  if (reason.includes('Stale quote version')) {
    return 'Quote was too old. Please try again.';
  }
  return reason;
}

function formatPriceAndQuantity(
  bidPrice: number,
  offerPrice: number,
  amount: number,
  bidMaxQuantity: number,
  offerMaxQuantity: number,
) {
  return {
    formattedBidPrice: formatThousandSeparator(bidPrice),
    formattedOfferPrice: formatThousandSeparator(offerPrice),
    formattedAmount: formatThousandSeparator(amount),
    formattedBidMaxQuantity: formatThousandSeparator(bidMaxQuantity),
    formattedOfferMaxQuantity: formatThousandSeparator(offerMaxQuantity),
  };
}

export const MissingUserPreferences = () => (
  <ValidationWrapper>
    <AlertIcon />
    <p onClick={() => setShowUserPrefs(true)} className="text">
      Fix missing user preferences
    </p>
  </ValidationWrapper>
);

const [buyOrSell$, setBuyOrSell] = createListener<{
  selectedCounterpartyRequestId: string | null;
  direction: Direction | null;
}>();

const [useGridRowState, gridRowState$] = bind(
  combineLatest([
    buyOrSell$.pipe(
      startWith({ selectedCounterpartyRequestId: null, direction: null }),
    ),
    submitTrade$,
    areUserPrefsSet$,
    userPrefsState$,
    selectedAccount$,
  ]).pipe(
    map(
      ([
        { selectedCounterpartyRequestId, direction },
        tradeResponse,
        areUserPrefsSet,
        userPrefs,
        selectedAccount,
      ]) => ({
        selectedCounterpartyRequestId,
        direction,
        tradeResponse,
        areUserPrefsSet,
        userPrefs,
        selectedAccount: selectedAccount?.account_number,
      }),
    ),
  ),
);

const GridRowComponent = ({
  otcStreamResponse,
  draggable,
}: {
  otcStreamResponse: TransformedOTCStreamQuoteResponse;
  draggable: DraggableProvided;
}) => {
  const {
    selectedCounterpartyRequestId,
    direction,
    tradeResponse,
    userPrefs,
    selectedAccount,
  } = useGridRowState();
  const {
    quoteRequest: { symbol, currency: requestCurrency },
    bidPrice,
    offerPrice,
    counterpartyRequestId,
    bidQuantity,
    bidTotalAmount,
    offerQuantity,
    offerTotalAmount,
    quoteResponseId,
  } = otcStreamResponse;
  const baseCurrency = symbol.split('/')[0];
  const isBaseCurrency = baseCurrency === requestCurrency;
  const isSelected = selectedCounterpartyRequestId === counterpartyRequestId;
  // first condition is to ensure error is shown for correct row
  const isSelectedError = quoteResponseId.id === tradeResponse?.tradeRequest.quoteResponseId.id
    && !!tradeResponse?.reason;
  const { currency: bidCurrency, quantity: bidMaxQuantity } = isBaseCurrency
    ? bidQuantity
    : bidTotalAmount;
  const { currency: offerCurrency, quantity: offerMaxQuantity } = isBaseCurrency
    ? offerQuantity
    : offerTotalAmount;
  const amount = direction === Direction.Buy ? bidMaxQuantity : offerMaxQuantity;
  const {
    formattedBidPrice,
    formattedOfferPrice,
    formattedAmount,
    formattedBidMaxQuantity,
    formattedOfferMaxQuantity,
  } = formatPriceAndQuantity(
    bidPrice,
    offerPrice,
    amount,
    bidMaxQuantity,
    offerMaxQuantity,
  );

  return (
    <div {...draggable.draggableProps} ref={draggable.innerRef}>
      <GridRowContainer isSelected={isSelected}>
        <div className="symbolCell">
          <CancelIcon
            className="cancelIcon"
            onClick={() => submitCancelQuote({
              counterpartyRequestId,
              quoteResponseId,
            })
            }
          />
          <div {...draggable.dragHandleProps}>
            <MoreVert className="handleIcon" />
          </div>
          <span>{symbol}</span>
        </div>
        <div className="cell">{formattedBidPrice}</div>
        <div className="cell">{formattedOfferPrice}</div>
        <div className="cell maxQuantity">
          <span>{formattedBidMaxQuantity}</span>
          <span className="currency">{bidCurrency}</span>
        </div>
        <div className="cell maxQuantity">
          <span>{formattedOfferMaxQuantity}</span>
          <span className="currency">{offerCurrency}</span>
        </div>
        <TradeButtonsContainer>
          <TradeButton
            variant="outlined"
            direction={Direction.Buy}
            onClick={() => setBuyOrSell({
              selectedCounterpartyRequestId: counterpartyRequestId,
              direction: Direction.Buy,
            })
            }
          >
            BUY
          </TradeButton>
          <TradeButton
            variant="outlined"
            direction={Direction.Sell}
            onClick={() => setBuyOrSell({
              selectedCounterpartyRequestId: counterpartyRequestId,
              direction: Direction.Sell,
            })
            }
          >
            SELL
          </TradeButton>
        </TradeButtonsContainer>
      </GridRowContainer>
      {isSelected && direction && (
        <GridRowFormContainer>
          <span className="orderTitle">{`${direction} ORDER`}</span>
          <div className="formRowGrid">
            <div>
              <InputLabel focused={false}>Amount</InputLabel>
              <div className="amountForm">
                <TextField
                  name="amount"
                  value={formattedAmount}
                  className="amountInput"
                  disabled
                />
                <TextField
                  className="currencySelect"
                  value={requestCurrency}
                  disabled
                />
              </div>
            </div>
            <div>
              <InputLabel focused={false}>Price</InputLabel>
              <TextField
                disabled
                value={
                  direction === Direction.Buy
                    ? formattedOfferPrice
                    : formattedBidPrice
                }
                className="priceField"
              />
            </div>
            <div>
              <InputLabel focused={false}>Product</InputLabel>
              <TextField
                disabled
                defaultValue={symbol}
                className="productField"
              />
            </div>
            <TradeButtonsContainer>
              <TradeButton
                direction={direction}
                onClick={() => submitTradeRequest({
                  direction,
                  quoteResponseId,
                  // from OTC desk perspective
                  // eslint-disable-next-line
                    amount:
                      direction === Direction.Buy
                        ? offerQuantity.quantity
                        : bidQuantity.quantity,
                  currency: baseCurrency,
                  userPrefs,
                  accountNumber: selectedAccount!,
                  symbol,
                })
                }
              >
                {direction}
              </TradeButton>
              <Button
                onClick={() => setBuyOrSell({
                  selectedCounterpartyRequestId: null,
                  direction: null,
                })
                }
              >
                CANCEL
              </Button>
            </TradeButtonsContainer>
          </div>
          {isSelectedError && (
            <div className="tradeError">
              <AlertIcon />
              <p>{getErrorText(tradeResponse!.reason!)}</p>
            </div>
          )}
        </GridRowFormContainer>
      )}
    </div>
  );
};

export const GridRow = withSubscribe(GridRowComponent, gridRowState$);
