import { Direction } from '../../shared/Direction';
import { UserPrefsState } from '../navbar/services/userPrefsService';

export const STREAMING_QUOTE_REQUEST = 'STREAMING_QUOTE_REQUEST';
export const STREAMING_QUOTE_RESPONSE = 'STREAMING_QUOTE_RESPONSE';
export const STREAMING_QUOTE_CLOSE_REQUEST = 'STREAMING_QUOTE_CLOSE_REQUEST';
export const TRADE_REQUEST = 'TRADE_REQUEST';
export const TRADE_RESPONSE = 'TRADE_RESPONSE';

export enum Status {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  REJECTED = 'REJECTED',
  FILLED = 'FILLED',
}

export interface QuoteResponseId {
  version: number;
  id: string;
}
export interface RequestQuoteFormState {
  selectedProduct: string | null;
  selectedCurrency: string | null;
  quantity: number;
}

export interface OTCStreamQuoteRequest {
  type: 'STREAMING_QUOTE_REQUEST';
  correlation: string;
  ticker: string;
  quantity: {
    quantity: number;
    currency: string;
  };
  externalIdentity: {
    counterpartyId: string;
    userId: string;
  };
}

export interface OTCStreamQuoteResponse {
  type: 'STREAMING_QUOTE_RESPONSE';
  correlation: string;
  quoteResponseId: QuoteResponseId;
  quoteRequest: {
    messageType: 'STREAMING_QUOTE_REQUEST';
    counterpartyRequestId: string;
    ticker: string;
    quantity: { currency: string; quantity: number };
    receivedTime: number;
  };
  reason?: string
  creationTime: number;
  expireTime: number;
  status: Status;
  buyQuantity: { currency: string; quantity: number };
  sellQuantity: { currency: string; quantity: number };
  buyUnitPrice: { currency: string; price: number; ticker: string };
  sellUnitPrice: { currency: string; price: number; ticker: string };
  buyTotalAmount: { amount: number; currency: string };
  sellTotalAmount: { amount: number; currency: string };
  timeToLive: number;
}

export interface TransformedOTCStreamQuoteResponse {
  counterpartyRequestId: string;
  quoteRequest: {
    symbol: string;
    quantity: number;
    currency: string;
  };
  bidPrice: number;
  offerPrice: number;
  status: Status;
  reason?: string;
  bidQuantity: {
    quantity: number;
    currency: string;
  };
  offerQuantity: {
    quantity: number;
    currency: string;
  };
  bidTotalAmount: {
    quantity: number;
    currency: string;
  };
  offerTotalAmount: {
    quantity: number;
    currency: string;
  };
  quoteResponseId: QuoteResponseId;
}

export interface CancelQuoteRequest {
  type: 'STREAMING_QUOTE_CLOSE_REQUEST';
  correlation: string;
  quoteResponseId: {
    version: number;
    id: string;
  };
  counterpartyRequestId: string;
}

export interface TradeRequest {
  type: 'TRADE_REQUEST';
  counterpartyAction: Direction;
  correlation: string;
  token: string
  quoteResponseId: QuoteResponseId;
  quantity: {
    currency: string;
    quantity: number;
  };
  senderLocationId: string;
  accountNumber: string
  senderSubId?: string;
}

export interface TradeFormState {
  direction: Direction;
  quoteResponseId: QuoteResponseId;
  amount: number;
  currency: string;
  userPrefs: UserPrefsState
  accountNumber: string
  symbol: string
}

export interface TradeResponse {
  type: 'TRADE_RESPONSE';
  reason?: string;
  status: Status;
  tradeTime: number;
  tradeId: string;
  unitPrice: {
    currency: string;
    price: number;
    ticker: string;
  };
  totalAmount: {
    amount: number;
    currency: string;
  };
  tradeResponseId: {
    version: number;
    id: number;
  };
  quantity: {
    currency: string;
    quantity: number;
  };
  tradeRequest: TradeRequest & { counterpartyRequestId: string };
  quoteResponse: OTCStreamQuoteResponse;
  blockTradeId: string
}
