import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  formatNumber, formatPercentage, formatPrice,
} from '../../../shared/formatters';
import { isBuySide } from '../../../shared/helperFunctions/direction';
import { withSubscribe } from '../../../shared/helperFunctions/withSubscribe';
import { securityList$, useSecurityList } from '../../../shared/services/securitiesService';
import { isPartiallyFilled } from './services/helpers';
import { MarketOrderExecutionSummary } from './services/marketOrderExecutionSummaryReducer';

export interface MarketOrderExecutionSummaryComponentProps {
  summary: MarketOrderExecutionSummary;
  onClose: () => void;
}

export const Container = styled.div`
    min-width: 320px;
    width: 360px;
    background-color: ${({ theme }) => theme.palette.primary.primary};
    overflow-x: hidden;
`;

const Body = styled.div`
`;

const Row = styled.div`
  height: 42px;
  border-bottom: 1px solid #15446e;
  align-items: center;
  justify-content: space-between;
  display: flex;
  &:last-of-type {
    border-bottom: none;
  }
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

const Value = styled.div`
  font-family: 'Roboto Mono';
  font-size: 14px;
`;

const Status = styled.div`
  background-color: #0c375a;
  color: ##9696a0;
  padding: 13px;
  margin-top: 10px;
  font-size: 10px;
`;

const Component:React.FC<MarketOrderExecutionSummaryComponentProps> = ({
  summary: {
    amount, price, fees, quantity, side, symbol, commCurrency, status,
  },
}) => {
  const isBuy = isBuySide(side);
  const securities = useSecurityList();
  const security = useMemo(
    () => securities.find((s) => s.symbol.toLowerCase() === symbol.toLowerCase()),
    [securities, symbol],
  );

  if (!security) {
    return null;
  }

  const isCurrencyUSD = commCurrency === 'USD';
  const isCurrencyUSDC = commCurrency === 'USDC';

  const quoteCurrencySymbol = isCurrencyUSD ? '$' : commCurrency;
  const total = formatNumber(
    isBuy ? amount + fees : amount, isCurrencyUSD || isCurrencyUSDC ? 5 : 8,
  );

  const concatPriceWithSymbol = (p: string) => (
    isCurrencyUSD ? `$${p}` : `${p} ${quoteCurrencySymbol}`
  );

  return (
    <Container>
      <Body>
        <Row aria-label='order direction'>
          <Label>You {isBuy ? 'bought' : 'sold'}</Label><Value>{formatNumber(quantity)} {security.currency}</Value>
        </Row>
        <Row aria-label='average price'>
          <Label>Avergage Price</Label>
          <Value>{formatPrice(price, security.minPriceIncrement)}</Value>
        </Row>
        <Row aria-label='percentage fees'>
          <Label>% Fee</Label><Value>{formatPercentage(fees / amount)}</Value>
        </Row>
        <Row aria-label='total'>
          <Label>Total</Label><Value>{concatPriceWithSymbol(total)}</Value>
        </Row>
      </Body>
      <Status>
        Your order was {isPartiallyFilled(status) ? 'partially ' : ''}filled. Please check your trades tab for detailed information of your fills.
      </Status>
    </Container>
  );
};

export const MarketOrderExecutionSummaryComponent = withSubscribe(Component, securityList$);
