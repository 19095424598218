import React, { useEffect, useMemo } from 'react';
import { bind, useSubscribe } from '@react-rxjs/core';
import { GridApi } from 'ag-grid-community';
import { Grid } from '../../../shared/Grid/Grid';
import { tradeColDefs } from '../colDefs/tradesColDefs';
import {
  initialTradeMap,
  TradeRow,
  NewTradeResponse,
} from '../services/fills/fillsReducer';
import {
  fillState$,
  historicalTradeState$,
  getTradesWhenAccounts$,
  tradeError$,
  tradeCall$,
  getTradesOnAlgoReconnect$,
} from '../services/fills/fillsService';
import {
  AuthStatus,
  useAuthStatus,
} from '../../../shared/services/authStatusService';
import { getWarningMessageAsHtmlString } from '../../../shared/components/WarningMessage';
import {
  ConnectionStatus,
  useConnectionStatus,
} from '../../../shared/websocket/connectionStatus';

interface Props {
  isFilterHidden: boolean;
  changeNumber: (numberOfFilter: number) => void;
  gridAPI: React.MutableRefObject<GridApi | undefined>;
}

const [useFills] = bind(fillState$, initialTradeMap);
const [useHistoricalFills] = bind(historicalTradeState$, initialTradeMap);
const [useHistoricalError] = bind(tradeError$, null);
const [useTradeCall] = bind(tradeCall$, null);

export const TradesGrid: React.FunctionComponent<Props> = (props) => {
  useSubscribe(getTradesWhenAccounts$);
  useSubscribe(getTradesOnAlgoReconnect$);

  const fills = useFills();
  const historicalFills = useHistoricalFills();
  const historicalErrors = useHistoricalError();
  const tradeCalls = useTradeCall();
  const authStatus = useAuthStatus();
  const tradeConnectionStatus = useConnectionStatus();

  useEffect(() => {
    const localApi = props.gridAPI.current;
    if (localApi) localApi.showNoRowsOverlay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historicalErrors]);

  const tradeRows = useMemo(
    () => fills.merge(historicalFills).valueSeq().toArray(),
    [historicalFills, fills],
  );

  const getNoRowMessage = (
    error: NewTradeResponse | null,
    call: NewTradeResponse | null,
  ) => {
    if (
      error?.error
      && authStatus === AuthStatus.AUTHENTICATED
      && tradeConnectionStatus === ConnectionStatus.AUTHENTICATED
    ) {
      return getWarningMessageAsHtmlString('Trades could not be loaded');
    }
    if (call || authStatus !== AuthStatus.AUTHENTICATED) {
      return 'No Rows to Show';
    }
    return 'Loading Trades ...';
  };

  return (
    <Grid
      columnDefs={tradeColDefs}
      rowData={tradeRows}
      getRowNodeId={(row: TradeRow) => row.tradeID}
      isFilterHidden={props.isFilterHidden}
      onFilterChanged={(event) => props.changeNumber(Object.keys(event.api.getFilterModel()).length)
      }
      gridApiRef={props.gridAPI}
      noRowsMessage={getNoRowMessage(historicalErrors, tradeCalls)}
    ></Grid>
  );
};
