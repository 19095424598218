import { bind, shareLatest } from '@react-rxjs/core';
import { createListener } from '@react-rxjs/utils';
import { combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { withAuth } from '../../../shared/services/authStatusService';
import { getSecurities$, Security } from '../../../shared/services/getSecurities$';
import { FUT } from '../../orderEntry/services/trade/sendEntry';

const defaultWatchlistItems: string[] = ['BTC/USD'];

const getWatchlistItems = () => new Set(
    window.localStorage.getItem('watchlistItems')?.split(',')
      ?? defaultWatchlistItems,
);

const sortWatchlistFutureSecurities = (sec1: Security, sec2: Security) => {
  if (sec1.maturityDate === null) return -1;
  if (sec2.maturityDate === null) return 1;
  return sec1.maturityDate < sec2.maturityDate ? -1 : 1;
};

export const securities$ = getSecurities$.pipe(
  withAuth(),
  map(({ securities }) => ({
    spots: securities
      .filter((sec) => sec.securityType !== FUT)
      .sort((a, b) => a.symbol.localeCompare(b.symbol)),
    futures: securities
      .filter((sec) => sec.securityType === FUT)
      .sort(sortWatchlistFutureSecurities),
  })),
  shareLatest(),
);

const [setSelectedSymbols$, setSelectedSymbols] = createListener<Set<string>>();
export { setSelectedSymbols };

export const [useSelectedSymbols, selectedSymbols$] = bind(
  setSelectedSymbols$.pipe(
    tap((selectedSecurities) => {
      window.localStorage.setItem(
        'watchlistItems',
        [...selectedSecurities].join(','),
      );
    }),
  ),
  getWatchlistItems(),
);

export const [useSelectedSecurities, selectedSecurities$] = bind(
  combineLatest([securities$, selectedSymbols$]).pipe(
    map(([{ spots, futures }, selected]) => Array.from(selected).reduce((acc, next) => {
      const found = spots.find((security) => security.symbol === next)
          || futures.find((security) => security.symbol === next);
      if (found) {
        acc.push(found);
        return acc;
      }
      // TUI-290 - remove expired contracts from localStorage
      const newWatchListItems = selected;
      newWatchListItems.delete(next);
      setSelectedSymbols(newWatchListItems);
      return acc;
    }, [] as Security[])),
  ),
  [],
);
