import { defer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Bar } from '../../../charting_library/charting_library';
import { CandleGranularity } from './chartHelperFunctions';
import { getTradeHistorySubscription$ } from '../../tradeHistory/tradeHistoryService';

export const toCandleTimestamp = (date: Date, granularity: CandleGranularity) => {
  const candleRange = granularity * 60 * 1000;
  const tradeTimestamp = date.getTime();
  return tradeTimestamp - (tradeTimestamp % candleRange);
};

export const getCandleSubscription = (symbol: string,
  granularity: CandleGranularity,
  lastTradeDate?: Date) => defer(() => (getTradeHistorySubscription$()
  .pipe(
    mergeMap((tradeUpdate) => tradeUpdate.trades
      .filter((trade) => (lastTradeDate ? (trade.time.getTime() > lastTradeDate.getTime()) : true))
      .map((trade) => {
        const candleTimestamp = toCandleTimestamp(trade.time, granularity);
        return {
          time: candleTimestamp,
          open: trade.price,
          close: trade.price,
          high: trade.price,
          low: trade.price,
          volume: trade.quantity,
        } as Bar;
      })),
  )
));
