import { Observable, of } from 'rxjs';
import { mergeMap, catchError, startWith } from 'rxjs/operators';
import {
  ApiResponse, ApiResponsePending, ApiResponseSuccess, ApiResponseFailure,
} from '../types';

export const fetchResponseJsonHandler = <T>() => (
  (source: Observable<Response>) => source.pipe(
    mergeMap((response) => response
      .json()
      .then<ApiResponse<T>>((json) => (response.ok
      ? {
        error: false,
        isComplete: true,
        response: json,
      } as ApiResponseSuccess<T>
      : {
        error: true,
        errorMessage: json.error,
        isComplete: true,
      } as ApiResponseFailure))
      .catch((errorMessage) => ({
        error: true,
        errorMessage,
        isComplete: true,
      } as ApiResponseFailure))),
    catchError((err) => of(
      { error: true, errorMessage: err.message, isComplete: true } as ApiResponseFailure,
    )),
    startWith({
      isComplete: false,
      error: false,
    } as ApiResponsePending),
  ));
