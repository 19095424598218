import React, { useCallback } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { TextInput } from '../../../../shared/components/TextInput';
import { UserAccountRaw } from '../../../../shared/services/accountsService';

import {
  Content,
  Container,
  Label,
  Header,
  Title,
  SubTitle,
  HeaderTitle,
  SendButton,
  AddNewAllocationButton,
  Footer,
  Row,
  SettlmentAllocations,
  SettlementAllocationLabels,
} from './AddNewSettlement.styled';

import {
  SettlementAsset, String, ValueOrEmptyString,
} from '../types';
import { SettlementAllocation, settlementAllocationIsNonEmpty } from './components/SettlementAllocation';
import { DimmableContentWrapper, LinkButton } from '../../AccountManagement.styled';
import { DismissButton } from '../../../../shared/components/DismissButton/DismissButton';
import { CrossIcon } from '../../../../icons/CrossIcon';
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage';
import { AccountSelectorContainer } from '../../../../shared/components/AccountSelector/AccountSelector.container';
import { Nullable } from '../../../../shared/types';

export interface UserAccountRawWithLabel extends UserAccountRaw {
  account_label: string;
}
interface AddNewSettlementProps {
  busy: boolean;
  errorText?: string;
  assets: SettlementAsset[];
  onClose: () => void;
  onCreate: (
    formValues: ValidatedSettlementFormState,
    formikHelpers: FormikHelpers<SettlementFormState>
  ) => void;
}
export interface SettlementFormState {
  fromAccount: Nullable<UserAccountRawWithLabel>;
  toAccount: string;
  comments: string;
  settlements: SettlementAllocation[];
}

export interface ValidatedSettlementFormState extends SettlementFormState {
  fromAccount: UserAccountRawWithLabel;
}

const initialValues = {
  fromAccount: null,
  toAccount: '',
  comments: '',
  settlements: [{ uid: 1, asset: String.EMPTY, amount: String.EMPTY }],
};

const getErrorText = (errorText:string, errors: Record<any, any>, touched: Record<any, any>) => (
  (touched.fromAccount && errors.fromAccount)
  || (touched.toAccount && errors.toAccount)
  || (touched.settlements && errors.settlements)
  || errorText || ''
);

export const AddNewSettlement: React.FC<AddNewSettlementProps> = ({
  busy,
  errorText = '',
  assets,
  onClose,
  onCreate,
}) => {
  const getAssetList = useCallback(
    (formValues: SettlementFormState) => (
      selectedAsset: ValueOrEmptyString<SettlementAsset>,
    ) => assets.filter(
      (asset) => asset === selectedAsset
          || !formValues.settlements.map((a) => a.asset).includes(asset),
    ),
    [assets],
  );

  return (
    <Container>
      <Header>
        <HeaderTitle>
          <SubTitle>New</SubTitle>
          <Title>Settlement Instruction</Title>
        </HeaderTitle>
        <DismissButton onClick={onClose} dark/>
      </Header>
      <Content>
        <Formik<SettlementFormState>
          initialValues={initialValues}
          isInitialValid={false}
          validate={(values) => {
            const errors:Record<string, string> = {};
            if (!values.fromAccount) {
              errors.fromAccount = 'From Account is required';
            }

            if (values.toAccount.length < 1) {
              errors.toAccount = 'To Account is required';
            }
            if (values.settlements.filter(settlementAllocationIsNonEmpty).length < 1) {
              errors.settlements = 'Please specify at least 1 settlement asset';
            }
            return errors;
          }}
          onSubmit={(values, formikHelpers) => onCreate(
            values as ValidatedSettlementFormState,
            formikHelpers,
          )}
        >
          {({
            getFieldProps, setFieldTouched, setFieldValue, submitForm,
            errors, touched, values, isValid,
          }) => (
            <>
            <DimmableContentWrapper busy={busy}>
              <Row>
                <Label>From Account</Label>
                <AccountSelectorContainer
                  onAccountSelected={(account) => {
                    setFieldValue('fromAccount', account);
                  }}
                  selectedAccount={values.fromAccount}
                  onBlur={() => setFieldTouched('fromAccount')}
                  errorfield={(touched.fromAccount && errors.fromAccount) || undefined}
                  />
              </Row>
              <Row>
                <Label>To Account</Label>
                <TextInput
                  {...getFieldProps('toAccount')}
                  placeholder="Enter destination account"
                  errorfield={(touched.toAccount && errors.toAccount) || undefined}
                />
              </Row>
              <Row>
                <Label>Comments</Label>
                <TextInput
                  {...getFieldProps('comments')}
                  placeholder="Enter comments"
                  multiline
                  rows={4}
                />
              </Row>
              <SettlmentAllocations>
                <SettlementAllocationLabels>
                  <Label>Asset</Label>
                  <Label>Amount</Label>
                </SettlementAllocationLabels>

                {values.settlements.map((s) => (
                  <SettlementAllocation
                    error={(touched.settlements && !!errors.settlements) || false}
                    key={s.uid}
                    getAssets={getAssetList(values)}
                    settlement={s}
                    onBlur={() => setFieldTouched('settlements')}
                    onChange={(updatedAllocation) => {
                      const { settlements } = values;
                      const idx = settlements.findIndex(
                        ({ uid }) => uid === s.uid,
                      );
                      setFieldValue('settlements', [
                        ...settlements.slice(0, idx),
                        updatedAllocation,
                        ...settlements.slice(idx + 1, settlements.length),
                      ]);
                    }}
                  />
                ))}
              </SettlmentAllocations>

              {values.settlements.length < 5 && (
                <AddNewAllocationButton
                  onClick={() => setFieldValue('settlements', [
                    ...values.settlements,
                    {
                      uid: values.settlements.length + 1,
                      asset: String.EMPTY,
                      amount: String.EMPTY,
                    },
                  ])
                  }
                >
                  <CrossIcon />
                  Add another asset
                </AddNewAllocationButton>
              )}
              { (() => {
                const errorBarText = getErrorText(errorText, errors, touched);
                return errorBarText && <ErrorMessage>{errorBarText}</ErrorMessage>;
              })()}
            </DimmableContentWrapper>
              <Footer>
                <SendButton onClick={submitForm} disabled={busy} colorTheme={isValid ? 'primary' : 'secondary'}>
                  Send these instructions
                </SendButton>
                <LinkButton onClick={() => onClose()}>Cancel</LinkButton>
              </Footer>
            </>
          )}
        </Formik>
      </Content>
    </Container>
  );
};
