import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {
  Tooltip, withStyles, Theme, ClickAwayListener,
} from '@material-ui/core';
import { InfoIcon } from '../../icons/InfoIcon';

const TooltipRow = styled.div`
  display: grid;
  grid-template-columns: 128px 12px;
  align-items: center;
  justify-content: center;

  .MuiSelect-select {
    padding-left: 10px;
    padding-right: 12px;
  }
`;

const IconWrapper = styled.div`
  padding-left: 5px;
`;

const StyledTooltipText = styled.div`
  color: ${({ theme }) => theme.palette.primary.primary};
  span {
    background-color: ${({ theme }) => theme.palette.accent.accentPrimary};
  }
`;

const TooltipText: React.FC<{ text: string | React.ReactNode }> = ({ text }) => (
  <StyledTooltipText>
    {text}
  </StyledTooltipText>
);

const StyledTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
  },
}))(Tooltip);

interface ContentTooltipProps {
  tooltipText: string | React.ReactNode,
  interactive?: boolean,
  openOnError?: boolean;
}

// renders the given children with a tooltip wrapped all around it
export const ContentWithTooltip: React.FC<ContentTooltipProps> = ({
  interactive, tooltipText, children, openOnError,
}) => {
  // used to manually control the tooltip opening via onClick and click away events,
  // but only on mobile where this is the method for opening them.
  // on desktop, it is just hover
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (openOnError) {
      setTimeout(() => {
        setIsOpen(!!tooltipText);
      }, 0);
    }
  }, [tooltipText, openOnError]);

  return (
    <ClickAwayListener onClickAway={() => {
      if (isOpen) {
        setIsOpen(false);
      }
    }}
    mouseEvent="onMouseDown"
    touchEvent="onTouchStart"
    >
      <StyledTooltip
        onClick={() => {
          setIsOpen(true);
        }}
        disableTouchListener
        disableFocusListener
        open={isOpen}
        disableHoverListener={true}
        interactive={interactive}
        placement="top"
        enterDelay={500}
        title={tooltipText ? <TooltipText text={tooltipText} /> : ''}
      >
        {children as React.ReactElement}
      </StyledTooltip>
    </ClickAwayListener>
  );
};

const ContentWithTooltipIconBase: React.FC<ContentWithTooltipProps> = ({
  children, interactive, tooltipText,
}) => (
  <>
    {children}
    <ContentWithTooltip interactive={interactive} tooltipText={tooltipText}>
      <IconWrapper><InfoIcon /></IconWrapper>
    </ContentWithTooltip>
  </>
);

interface ContentWithTooltipProps {
  tooltipText: string | React.ReactNode
  interactive?: boolean,
}

// Renders the given children with a tooltip icon next to it
export const ContentWithTooltipIcon: React.FC<ContentWithTooltipProps> = (props) => (
  <TooltipRow>
    <ContentWithTooltipIconBase {...props}/>
  </TooltipRow>
);

const InlineContentContainer = styled.div`
  display: flex;
  align-items: center;
  ${IconWrapper} {
    display: flex;
    width: 16px;
    transform: translateY(1px);
  }
`;

export const InlineContentWithTooltipIcon: React.FC<ContentWithTooltipProps> = (props) => (
  <InlineContentContainer>
    <ContentWithTooltipIconBase {...props}/>
  </InlineContentContainer>
);
