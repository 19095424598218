import React from 'react';
import styled from 'styled-components';
import { ErisXLogo } from '../../../../shared/assets/ErisXLogo';

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  > span {
      padding-left: 10px;
  }
`;

export const IconWrapper = styled.div`
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
`;

export const LoadingIcon = styled(() => <ErisXLogo />)``;
