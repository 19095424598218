import React from 'react';
import { LoginButtonWrapper } from './styled';
import { login } from '../../shared/services/auth0Service';

interface Props { }

export const LoginButton: React.FunctionComponent<Props> = () => (
  <LoginButtonWrapper onClick={() => login()}>
    LOG IN / SIGN UP
  </LoginButtonWrapper >
);
