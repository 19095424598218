import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const AdvancedOrderEntryPanel = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'grid' : 'none')};
  grid-row-gap: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.primary2};
  padding-bottom: 4px;
  `;

export const ExpanderIcon = styled(ExpandMoreIcon)<{ expanded: boolean }>`
  background-color: ${({ theme }) => theme.palette.primary.primary2};
  transform: rotate(${({ expanded }) => (expanded ? '180' : '0')}deg);
  transition: transform 150ms ease-in;
  width: 16px;
  height: 16px;
  `;

export const AdvancedOrderEntryPanelExpander = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  padding-bottom: 8px;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.palette.primary.primary};
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 11px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.primary2};
`;
