import { bind } from '@react-rxjs/core';
import React, { useMemo } from 'react';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BitcoinCashIcon } from '../../icons/BitcoinCashIcon';
import { BitcoinIcon } from '../../icons/BitcoinIcon';
import { ERC20Icon } from '../../icons/ERC20Icon';
import { EthereumIcon } from '../../icons/EthereumIcon';
import { LitecoinIcon } from '../../icons/LitecoinIcon';
import { USDCIcon } from '../../icons/USDCIcon';
import { USDIcon } from '../../icons/USDIcon';
import { formatBalance } from '../../shared/formatters';
import { withSubscribe } from '../../shared/helperFunctions/withSubscribe';
import {
  AuthStatus,
  authStatusState$,
} from '../../shared/services/authStatusService';
import { selectedAccount$ } from '../../shared/services/accountsService';
import {
  BalanceInfo,
  accountsBalances$,
  balanceErrorRest$,
  balanceInfoRest$,
  emptyRestAccountBalance,
} from './services/balanceService';
import {
  BalanceInfoName,
  BalanceInfoRow,
  BalanceInfoValue,
  BalanceInfoWrapper,
  BalanceWarningPanel,
} from './styled';

interface Asset {
  name: string;
  value?: string;
}

const getAssetIcon = (asset: string) => {
  switch (asset) {
    case 'USD':
      return <USDIcon />;
    case 'BTC':
      return <BitcoinIcon />;
    case 'LTC':
      return <LitecoinIcon />;
    case 'ETH':
      return <EthereumIcon />;
    case 'BCH':
      return <BitcoinCashIcon />;
    case 'USDC':
      return <USDCIcon />;
    default:
      return <ERC20Icon />;
  }
};

const [useBalanceWidgetState, balanceWidgetState$] = bind(
  combineLatest([
    selectedAccount$,
    authStatusState$,
    accountsBalances$,
    balanceErrorRest$.pipe(startWith(false)),
    balanceInfoRest$.pipe(startWith(emptyRestAccountBalance)),
  ]).pipe(
    map(
      ([
        selectedAccount,
        authStatus,
        accountsBalances,
        balanceError,
        restBalance,
      ]) => ({
        selectedAccount: selectedAccount?.emarket_account_number,
        authStatus,
        accountsBalances,
        balanceError,
        restBalance,
      }),
    ),
  ),
);

const BalanceWidgetComponent = () => {
  const {
    selectedAccount,
    authStatus,
    accountsBalances,
    balanceError,
    restBalance,
  } = useBalanceWidgetState();
  const accountBalance = selectedAccount && accountsBalances[selectedAccount];
  const assets = accountBalance && Object.keys(accountBalance);
  // make sure we show balance when rest
  const restBalanceArrived = selectedAccount && restBalance[selectedAccount];

  const assetMap = useMemo(() => {
    if (assets && restBalanceArrived) {
      return assets.sort().map((asset) => ({
        name: asset,
        value: formatBalance((accountBalance as BalanceInfo)[asset]),
      })) as Asset[];
    }
    if (authStatus !== AuthStatus.AUTHENTICATED) {
      return ['BCH', 'BTC', 'ETH', 'LTC', 'USD', 'USDC'].map((asset) => ({
        name: asset,
        value: undefined,
      })) as Asset[];
    }
    return undefined;
  }, [accountBalance, assets, authStatus, restBalanceArrived]);

  return assetMap ? (
    <BalanceInfoWrapper
      isOverlayActive={authStatus !== AuthStatus.AUTHENTICATED}
    >
      {assetMap.map((asset) => (
        <BalanceInfoRow key={asset.name}>
          <div>{getAssetIcon(asset.name)} </div>
          <BalanceInfoName>{asset.name} </BalanceInfoName>
          <BalanceInfoValue rightPadding={!asset.value ? 20 : undefined}>
            {asset.value || '-'}
          </BalanceInfoValue>
        </BalanceInfoRow>
      ))}
    </BalanceInfoWrapper>
  ) : (
    <BalanceInfoWrapper>
      {balanceError && (
        <BalanceWarningPanel message="Balances could not be loaded" />
      )}
    </BalanceInfoWrapper>
  );
};

export const BalanceWidget = withSubscribe(
  BalanceWidgetComponent,
  balanceWidgetState$,
);
