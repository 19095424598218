import { bind } from '@react-rxjs/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  ApiResponseComplete, ApiResponseSuccess, ApiResponse, ApiResponsePending, ApiResponseFailure,
} from '../types';

const responseIsPending = <T>(
  response: ApiResponse<T>,
): response is ApiResponsePending => (
    response.isComplete === false
  );

export const mapResponseIsPending = <T>() => (
  (source: Observable<ApiResponse<T>>) => source.pipe(
    map(responseIsPending),
  ));

export const useIsPendingResponse = <T>(response$: Observable<ApiResponse<T>>) => {
  const [h] = bind(
    response$.pipe(mapResponseIsPending()),
    false,
  );

  return h;
};

const responseIsSuccessful = <T>(
  response: ApiResponseComplete<T>,
): response is ApiResponseSuccess<T> => (
    response.error === false
  );

const responseIsFailure = <T>(
  response: ApiResponseComplete<T>,
): response is ApiResponseFailure => (
    response.error === true
  );

export const responseIsComplete = <T>(
  response: ApiResponse<T>,
): response is ApiResponseComplete<T> => response.isComplete === true;

export const filterResponseIsSuccessful = <T>() => (
  (source: Observable<ApiResponseComplete<T>>) => source.pipe(
    filter(responseIsSuccessful),
    map((r) => r.response),
  ));

export const filterResponseIsFailure = <T>() => (
  (source: Observable<ApiResponseComplete<T>>) => source.pipe(
    filter(responseIsFailure),
  ));

export const filterResponseIsComplete = <T>() => (
  (source: Observable<ApiResponse<T>>) => source.pipe(
    filter(responseIsComplete),
  ));
